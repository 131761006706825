import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import LeftArrowIcon from "../../../../common/assets/icons/left_arrow_icon";
import { insertUserNameInText } from "../../../../common/utils/functions/admin_page_functions";
import { useUserDataContext } from "../../../../user_data_context";

const NoteComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  border-radius: 25px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 10px 18px;
  min-height: 36px;
  min-width: 76%;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 15px;
  background: #f8d254;
  cursor: pointer;
`;

const QuestionText = styled.div`
  max-width: 93%;
  letter-spacing: 0.16px;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 100%;
  padding-right: 2px;
  transform: rotate(${({ openNote }) => (openNote ? "90deg" : "-90deg")});
`;

const ArrowStyled = styled(LeftArrowIcon)`
  width: 17px;
  height: 17px;
`;

const NoteContainer = styled.div`
  width: 100%;
  min-height: 52px;
  padding: 12px 24px;
  border-radius: 15px;
  border: 2px solid #f8d254;
  background: #fff;
  line-height: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const NoteLessonComponent = ({ componentData }) => {
  const { userData } = useUserDataContext();
  const [openNote, setOpenNote] = useState(true);

  const currentUserName = userData.user_name || "";

  useEffect(() => {
    if (componentData.with_question) {
      setOpenNote(false);
    }
  }, []);

  const handleOpenNote = useCallback((event) => {
    event.stopPropagation();
    setOpenNote((prevState) => !prevState);
  }, []);

  return (
    <NoteComponentContainer>
      {componentData.with_question && (
        <QuestionContainer onClick={handleOpenNote}>
          <QuestionText>
            {insertUserNameInText(componentData.question, currentUserName)}
          </QuestionText>

          <ArrowContainer openNote={openNote}>
            <ArrowStyled color={"#383838"} />
          </ArrowContainer>
        </QuestionContainer>
      )}

      {openNote && (
        <NoteContainer>
          {insertUserNameInText(componentData.note, currentUserName)}
        </NoteContainer>
      )}
    </NoteComponentContainer>
  );
};

export default memo(NoteLessonComponent);
