import React, { useState, useEffect, memo } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const MotionDiv = styled(motion.div)``;

const VisibilityWrapperComponent = ({ children, withAnimation = true }) => {
  const controls = useAnimation();
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const [hasAnimated, setHasAnimated] = useState(false);

  const checkPartialVisibility = () => {
    if (!entry) return false;

    const rect = entry.target.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return rect.top < windowHeight && rect.bottom > 0;
  };

  useEffect(() => {
    if (withAnimation) {
      if (inView && checkPartialVisibility() && !hasAnimated) {
        controls.start({
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5, ease: "easeOut" },
        });
        setHasAnimated(true);
      }
    } else {
      controls.start({ opacity: 1, scale: 1 });
    }
  }, [inView, controls, withAnimation, hasAnimated, entry]);

  return (
    <MotionDiv
      ref={ref}
      initial={{
        opacity: withAnimation ? 0 : 1,
        scale: withAnimation ? 0.6 : 1,
      }}
      animate={controls}
    >
      {children}
    </MotionDiv>
  );
};

export default memo(VisibilityWrapperComponent);
