import React, { useState } from "react";
import {
  MessageBlockContainer,
  YourMessageBlockWrapper,
  MessageContentContainer,
  MessageMainContent,
  MessageStatusAndTimeContainer,
  MessageText,
  FavoriteIconStyled,
  UnFavoriteIconStyled,
  YourFavoriteIconContainer,
  MessageStatusContainer,
} from "./message_styled_components";
import { YOUR_MESSAGE_STATUS_RENDERERS } from "../../../../chats_page";
import useMessageContextMenu from "../use_message_context_menu";
import ReplyInMessage from "../reply_in_message";
import FramedBibleVerse from "../../../../../../common/components/framed_bible_verse";
import MessageEmoji from "../message_emoji";
import DeleteModalWindow from "../../../../../../common/components/delete_modal_window";
import ForwardMessageToChat from "../use_message_context_menu/forward_message_to_chat";
import { useChatContentContext } from "../../chat_content_context";

const YourMessage = ({
  message,
  setContextMenuMessageData,
  contextMenuMessageData,
}) => {
  const { isAdmin } = useChatContentContext();
  const [openForwardMessageToChat, setOpenForwardMessageToChat] =
    useState(false);
  const [openDeleteMessageQuestion, setOpenDeleteMessageQuestion] =
    useState(false);

  const YourMessageStatus =
    message.yourMessageStatus &&
    YOUR_MESSAGE_STATUS_RENDERERS[message.yourMessageStatus];

  const [MessageContextMenu, handleContextMenu] = useMessageContextMenu({
    setContextMenuMessageData,
    contextMenuMessageData,
    message,
    isYourMessage: true,
    setOpenDeleteMessageQuestion,
    openDeleteMessageQuestion,
    setOpenForwardMessageToChat,
  });

  return (
    <YourMessageBlockWrapper>
      <MessageBlockContainer>
        <MessageContentContainer onContextMenu={handleContextMenu}>
          {contextMenuMessageData?.id === message.id && <MessageContextMenu />}

          {isAdmin && (
            <YourFavoriteIconContainer>
              {message.favorite ? (
                <FavoriteIconStyled />
              ) : (
                <UnFavoriteIconStyled />
              )}
            </YourFavoriteIconContainer>
          )}

          <MessageMainContent>
            {message.replyInMessage && (
              <ReplyInMessage replyInMessage={message.replyInMessage} />
            )}
            {!!message.biblicalVerses.length &&
              message.biblicalVerses.map((biblicalVerse, index) => (
                <FramedBibleVerse
                  key={biblicalVerse._id + message.id + index}
                  verse={biblicalVerse}
                  inComponent={"chats_page-messages"}
                />
              ))}
            <MessageText>{message.text}</MessageText>
            <MessageEmoji messageEmoji={message.emoji} />
          </MessageMainContent>

          <MessageStatusAndTimeContainer>
            {message.edited && <div>(изменено)</div>}
            <div>{message.time}</div>
            <MessageStatusContainer>
              <YourMessageStatus />
            </MessageStatusContainer>
          </MessageStatusAndTimeContainer>
        </MessageContentContainer>

        <DeleteModalWindow
          isOpen={openDeleteMessageQuestion}
          onClose={setOpenDeleteMessageQuestion}
          onClickRemoveButton={() => {}}
        />

        <ForwardMessageToChat
          isOpen={openForwardMessageToChat}
          onClose={setOpenForwardMessageToChat}
        />
      </MessageBlockContainer>
    </YourMessageBlockWrapper>
  );
};

export default YourMessage;
