import React, { memo, useCallback } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import UnsavedChangesModalWindow from "../../../../common/components/modal_windows/unsaved_changes_modal_window";
import { useParams } from "react-router-dom";
import { useUserDataContext } from "../../../../user_data_context";
import { LESSON_COMPONENTS_MAPPING } from "..";

const SettingsBlockContainer = styled.div`
  width: 434px;
`;

const CreateLessonSettingsBlock = ({
  lessonData,
  activeSettingsBlock,
  insertBlockIndex,
  editBlockIndex,
  settingsBlockData,
  openEditeComponentModal,
  settingsBlockMemoData,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  setActiveSettingsBlock,
  setOpenEditeComponentModal,
  resetAllActiveBlocks,
}) => {
  const { id } = useParams();
  const { userData } = useUserDataContext();

  const SettingsComponent =
    LESSON_COMPONENTS_MAPPING[activeSettingsBlock]?.settingsComponent;

  const onClickSaveButton = useCallback(
    async (event) => {
      event.stopPropagation();

      let updatedSettingsBlockData = JSON.parse(
        JSON.stringify(settingsBlockData)
      );

      if (
        Object.prototype.hasOwnProperty.call(
          updatedSettingsBlockData,
          "selectedBranches"
        )
      ) {
        updatedSettingsBlockData = {
          ...updatedSettingsBlockData,
          selectedBranches: ["first"],
        };
      }

      let updatedLessonData = [];

      if (insertBlockIndex !== null) {
        const before = lessonData.lesson_data.slice(0, insertBlockIndex);
        const after = lessonData.lesson_data.slice(insertBlockIndex);
        updatedLessonData = [...before, updatedSettingsBlockData, ...after];
      } else if (editBlockIndex !== null) {
        updatedLessonData = lessonData.lesson_data.map((item, index) =>
          index === editBlockIndex ? updatedSettingsBlockData : item
        );
      } else {
        updatedLessonData = [
          ...lessonData.lesson_data,
          updatedSettingsBlockData,
        ];
      }

      try {
        await axios.patch(`${BASE_API_URL}/lessons/${id}`, {
          ...lessonData,
          lesson_modified_at: new Date().toISOString(),
          lesson_modified_by: { _id: userData._id },
          lesson_data: updatedLessonData,
        });

        if (
          settingsBlockMemoData?.image_url &&
          settingsBlockMemoData.image_url !== updatedSettingsBlockData.image_url
        ) {
          const imageName = new URL(settingsBlockMemoData.image_url).pathname
            .split("/")
            .pop();
          await axios.delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`);
        }
      } catch (error) {
        console.error("Ошибка при запросе:", error);
      } finally {
        resetAllActiveBlocks();
      }
    },
    [
      settingsBlockData,
      settingsBlockMemoData,
      insertBlockIndex,
      editBlockIndex,
      lessonData,
      userData,
      id,
    ]
  );

  const onClickNotSaveButton = useCallback(async () => {
    try {
      if (
        settingsBlockData?.image_url &&
        settingsBlockData.image_url !== settingsBlockMemoData.image_url
      ) {
        const imageName = new URL(settingsBlockData.image_url).pathname
          .split("/")
          .pop();
        await axios.delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`);
      }
    } catch (error) {
      console.error("Ошибка при удалении картинки:", error);
    } finally {
      resetAllActiveBlocks();
    }
  }, [settingsBlockData, settingsBlockMemoData, resetAllActiveBlocks]);

  return (
    <SettingsBlockContainer>
      {SettingsComponent && (
        <SettingsComponent
          lessonData={lessonData}
          settingsBlockData={settingsBlockData}
          settingsBlockMemoData={settingsBlockMemoData}
          editBlockIndex={editBlockIndex}
          setSettingsBlockData={setSettingsBlockData}
          setSettingsBlockMemoData={setSettingsBlockMemoData}
          setActiveSettingsBlock={setActiveSettingsBlock}
          onClickSaveButton={onClickSaveButton}
        />
      )}

      {openEditeComponentModal && (
        <UnsavedChangesModalWindow
          setOpen={setOpenEditeComponentModal}
          onSaveSettingsButton={onClickSaveButton}
          onClickNotSaveButton={onClickNotSaveButton}
        />
      )}
    </SettingsBlockContainer>
  );
};

export default memo(CreateLessonSettingsBlock);
