import React, { useCallback } from "react";
import styled from "styled-components";
import FileIcon from "../../../../../common/assets/icons/file_icon";
import NoteIcon from "../../../../../common/assets/icons/note_icon";
import PlayIcon from "../../../../../common/assets/icons/play_icon";
import ThreeDotsIcon from "../../../../../common/assets/icons/three_dots_icon";

const ChatHeaderButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  color: #fff;
  flex: 0 0 auto;
  margin-left: auto;

  @media (max-width: 744px) {
    padding-bottom: 8px;
  }

  @media (max-width: 500px) {
    padding-bottom: 6px;
  }

  @media (max-width: 420px) {
    padding-bottom: 0;
  }
`;

const ChatHeaderButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  padding: 0 9px;
  background: linear-gradient(180deg, #05baa4 0%, #288bce 100%);
  border-radius: 9px;
  cursor: pointer;

  @media (max-width: 744px) {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const ThreeDotsButton = styled(ChatHeaderButton)`
  display: none;

  @media (max-width: 744px) {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 30px;
  }
`;

const PlayIconStyles = styled(PlayIcon)`
  width: 12px;
  height: 12px;
`;

const ChatHeaderButtons = ({
  toggleShowButtons,
  showButtons,
  setShowOnlyFavoriteMessages,
  showOnlyFavoriteMessages,
}) => {
  const handleShowFavoriteMessage = useCallback(() => {
    setShowOnlyFavoriteMessages((prevState) => !prevState);
  }, []);

  return (
    <>
      <ThreeDotsButton onClick={toggleShowButtons}>
        <ThreeDotsIcon />
      </ThreeDotsButton>

      {showButtons && (
        <ChatHeaderButtonsContainer>
          <ChatHeaderButton onClick={handleShowFavoriteMessage}>
            <NoteIcon />
            {showOnlyFavoriteMessages ? "ВСЕ" : "ГЛАВНОЕ"}
          </ChatHeaderButton>
          <ChatHeaderButton>
            <PlayIconStyles />
            LIVE
          </ChatHeaderButton>
          <ChatHeaderButton>
            <FileIcon />
            ТЕТРАДЬ
          </ChatHeaderButton>
        </ChatHeaderButtonsContainer>
      )}
    </>
  );
};

export default ChatHeaderButtons;
