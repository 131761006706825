import React, { memo, useCallback } from "react";
import styled from "styled-components";
import {
  PlusAndMinusButtonsContainer,
  PlusOrMinusButton,
} from "../../../../create_lesson_components/create_lesson_styled_components";
import PlusIcon from "../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import MinusIcon from "../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";

const QuestionSettingsOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ withGap }) => withGap && "gap: 10px;"}
`;

const QuestionSettingsOptionInput = styled.input`
  padding: 0 15px;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
`;

const QuestionSettingsOption = ({
  optionIndex,
  optionData,
  isLastElement,
  setSettingsBlockData,
}) => {
  const handleOptionValue = useCallback(
    (event) => {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          answer_options: prevState.data.answer_options.map((option, index) =>
            index === optionIndex ? event.target.value : option
          ),
        },
      }));
    },
    [optionIndex]
  );

  const onClickButton = useCallback((button) => {
    setSettingsBlockData((prevState) => {
      let newOptions;

      if (button === "plus") {
        newOptions = [...prevState.data.answer_options, ""];
      } else if (button === "minus") {
        newOptions = prevState.data.answer_options.slice(0, -1);
      }

      const updatedData = { ...prevState.data, answer_options: newOptions };

      return { ...prevState, data: updatedData };
    });
  }, []);

  return (
    <QuestionSettingsOptionContainer withGap={isLastElement}>
      <QuestionSettingsOptionInput
        placeholder={"Ответ " + (optionIndex + 1)}
        maxLength={"50"}
        value={optionData}
        onChange={handleOptionValue}
      />

      {!!optionIndex && (
        <PlusAndMinusButtonsContainer>
          {optionIndex > 1 && isLastElement && (
            <PlusOrMinusButton onClick={() => onClickButton("minus")}>
              <MinusIcon />
            </PlusOrMinusButton>
          )}

          {isLastElement && (
            <PlusOrMinusButton onClick={() => onClickButton("plus")}>
              <PlusIcon />
            </PlusOrMinusButton>
          )}
        </PlusAndMinusButtonsContainer>
      )}
    </QuestionSettingsOptionContainer>
  );
};

export default memo(QuestionSettingsOption);
