import React from "react";

const AdminPageGroupInfoIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
        stroke={color || "#CACACA"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.127 18.1893L12.127 10.6104"
        stroke={color || "#CACACA"}
        strokeWidth="1.5775"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.127 7.57812H12.1385"
        stroke={color || "#CACACA"}
        strokeWidth="1.5775"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdminPageGroupInfoIcon;
