import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import LeftArrowIcon from "../../../../../common/assets/icons/left_arrow_icon";
import LeftArrowMobileIcon from "../../../../../common/assets/icons/left_arrow_mobile_icon";
import ChatHeaderButtons from "./chat_header_buttons";

const ChatsHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 9px;
  width: 100%;
  min-height: 54px;
  margin: 0 auto;
  padding: 15px 29px 10px;
  border-bottom: 1px solid #cacaca;

  @media (max-width: 744px) {
    background: linear-gradient(220deg, #2046e9 -0.52%, #a78be2 92.11%);
    border-bottom: none;
  }

  @media (max-width: 420px) {
    padding: 58px 30px 16px;
  }
`;

const ArrowAndChatTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;

  @media (max-width: 980px) {
    gap: 23px;
  }

  @media (max-width: 744px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    width: 85%;
    gap: 17px;
  }

  @media (max-width: 420px) {
    gap: 13px;
  }
`;

const LeftArrowIconContainer = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
`;

const LeftArrowStyled = styled(LeftArrowIcon)`
  display: block;

  @media (max-width: 420px) {
    display: none;
  }
`;

const LeftArrowMobileStyled = styled(LeftArrowMobileIcon)`
  display: none;

  @media (max-width: 420px) {
    display: block;
  }
`;

const ChatTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #383838;

  @media (max-width: 744px) {
    color: #fff;
  }

  @media (max-width: 420px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: 0.24px;
  }
`;

const ChatHeader = ({
  onBackClick,
  chatTitle,
  windowWidth,
  setShowOnlyFavoriteMessages,
  showOnlyFavoriteMessages,
}) => {
  const [showButtons, setShowButtons] = useState(
    windowWidth <= 744 ? false : true
  );

  const toggleShowButtons = () => {
    setShowButtons((prevState) => !prevState);
  };

  useEffect(() => {
    if (windowWidth > 744) {
      setShowButtons(true);
    }

    return () => {
      if (windowWidth <= 744) {
        setShowButtons(false);
      }
    };
  }, [windowWidth]);

  const chatLogOut = useCallback(() => onBackClick(null), [onBackClick]);

  return (
    <ChatsHeaderContainer>
      <ArrowAndChatTitleContainer>
        <LeftArrowIconContainer onClick={chatLogOut}>
          <LeftArrowStyled color={windowWidth <= 744 ? "#fff" : "#383838"} />
          <LeftArrowMobileStyled />
        </LeftArrowIconContainer>
        <ChatTitle>{chatTitle}</ChatTitle>
      </ArrowAndChatTitleContainer>

      <ChatHeaderButtons
        toggleShowButtons={toggleShowButtons}
        showButtons={showButtons}
        setShowOnlyFavoriteMessages={setShowOnlyFavoriteMessages}
        showOnlyFavoriteMessages={showOnlyFavoriteMessages}
      />
    </ChatsHeaderContainer>
  );
};

export default ChatHeader;
