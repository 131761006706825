import React from "react";

const CheckIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4920_6062)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5751 3.38368C24.1416 3.93692 24.1416 4.83391 23.5751 5.38715L9.06965 19.5538C8.50317 20.1071 7.58474 20.1071 7.01826 19.5538L0.424856 13.1144C-0.141619 12.5612 -0.141619 11.6642 0.424856 11.111C0.991331 10.5577 1.90977 10.5577 2.47624 11.111L8.04396 16.5486L21.5238 3.38368C22.0902 2.83044 23.0087 2.83044 23.5751 3.38368Z"
          fill="url(#paint0_linear_4920_6062)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4920_6062"
          x1="11.4199"
          y1="-0.15625"
          x2="11.4199"
          y2="21.4687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_4920_6062">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
