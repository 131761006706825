import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";

const QuestionSettingsStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #828282;
  font-size: 10px;
  line-height: 110%;
`;

const StatusSelectContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  min-height: 30px;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const StatusSelectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const StatusSelectArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const StatusSelectOptionsContainer = styled.div`
  padding: 4px;
`;

const StatusSelectOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const QuestionSettingsStatus = ({
  questionStatus,
  questionId,
  setQuestionSettingsData,
  setQuestionsList,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);

  const handleSelectOptions = useCallback(
    (option) => {
      setQuestionSettingsData((prevState) => ({
        ...prevState,
        question_status: option,
      }));
      setQuestionsList((prevState) =>
        prevState.map((question) => {
          return question._id === questionId
            ? { ...question, question_status: option }
            : question;
        })
      );
      setShowOptions(false);
    },
    [questionId]
  );

  return (
    <QuestionSettingsStatusContainer>
      Статус
      <StatusSelectContainer>
        <StatusSelectHeader
          showOptions={showOptions}
          onClick={handleShowOptions}
        >
          {questionStatus === "noanswer" ? "Без ответа" : "Отвечено"}
          <StatusSelectArrowIconContainer showOptions={showOptions}>
            <AskQuestionArrowIcon />
          </StatusSelectArrowIconContainer>
        </StatusSelectHeader>

        {showOptions && (
          <StatusSelectOptionsContainer>
            <StatusSelectOptionItem
              onClick={() => handleSelectOptions("noanswer")}
            >
              Без ответа
            </StatusSelectOptionItem>
            <StatusSelectOptionItem
              onClick={() => handleSelectOptions("answered")}
            >
              Отвечено
            </StatusSelectOptionItem>
          </StatusSelectOptionsContainer>
        )}
      </StatusSelectContainer>
    </QuestionSettingsStatusContainer>
  );
};

export default memo(QuestionSettingsStatus);
