import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import LayoutComponet from "../../common/components/layout_componet";
import HomePageStartStudy from "./home_page_components/home_page_start_study";
import HomePageDailyVerse from "./home_page_components/home_page_daily_verse";
import HomePageArticlesSection from "./home_page_components/home_page_articles_section";
import SupportUs from "./home_page_components/home_page_support_us";
import HomePageAskQuestion from "./home_page_components/home_page_ask_question";
import UserActivationModalWindow from "./home_page_components/user_activation_modal_window";
import AfterActivationModalWindow from "./home_page_components/after_activation_modal_window";
import { useAppContext } from "../../app_context";
import BibleChapterModalWindow from "../../common/components/modal_windows/bible_chapter_modal_window";

const TopContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 300px;
  margin: 40px 0 107px;
`;

const HomePage = () => {
  const location = useLocation();
  const { appColorTheme } = useAppContext();

  const [userActivationToken, setUserACtivationToken] = useState("");
  const [openUserActivationModal, setOpenUserActivationModal] = useState(false);
  const [openAfterActivationModal, setOpenAfterActivationModal] =
    useState(false);
  const [inviteUserName, setInviteUserName] = useState("");
  const [textBibleModalData, setTextBibleModalData] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const inviteToken = queryParams.get("invite");

    if (inviteToken) {
      setOpenUserActivationModal(true);
      setUserACtivationToken(inviteToken);
    }
  }, [location]);

  return (
    <>
      <SupportUs />
      <LayoutComponet>
        <TopContentContainer>
          <HomePageStartStudy />
          <HomePageDailyVerse />
        </TopContentContainer>
        <HomePageAskQuestion />
        <HomePageArticlesSection
          appColorTheme={appColorTheme}
          setTextBibleModalData={setTextBibleModalData}
        />
      </LayoutComponet>

      {openUserActivationModal && (
        <UserActivationModalWindow
          setOpen={setOpenUserActivationModal}
          setOpenAfterActivationModal={setOpenAfterActivationModal}
          userActivationToken={userActivationToken}
          setInviteUserName={setInviteUserName}
        />
      )}

      {openAfterActivationModal && (
        <AfterActivationModalWindow
          inviteUserName={inviteUserName}
          setOpen={setOpenAfterActivationModal}
        />
      )}

      {textBibleModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={textBibleModalData}
            setOpen={setTextBibleModalData}
          />,
          document.body
        )}
    </>
  );
};

export default memo(HomePage);
