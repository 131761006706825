import React from "react";

const UnselectedToggleIcon = () => {
  return (
    <svg
      width="44"
      height="22"
      viewBox="0 0 44 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 149">
        <rect
          id="Rectangle 388"
          x="0.5"
          y="0.5"
          width="43"
          height="21"
          rx="3.5"
          stroke="#828282"
        />
        <rect
          id="Rectangle 389"
          x="4"
          y="4"
          width="14"
          height="14"
          rx="2"
          fill="#828282"
        />
      </g>
    </svg>
  );
};

export default UnselectedToggleIcon;
