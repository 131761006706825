import React, { memo, useCallback } from "react";
import styled from "styled-components";
import MinusSmallIcon from "../../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_small_icon";
import BranchIcon from "../../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/branch_icon";
import BranchYellowIcon from "../../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/branch_yellow_icon";

const SettingsAnswerTypeIsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ selected }) => (selected ? "#5172ea" : "#fff")};
  background: #fff;
  padding-right: 10px;
  overflow: hidden;

  & > input {
    flex: 1;
    padding-left: 15px;
    height: 100%;
    font-size: 16px;
    color: #1e1e1e;
  }
`;

const IconContainer = styled.div`
  height: 20px;
  cursor: pointer;

  &:hover {
    transform: scale(1.12);
    transition: 0.2s;
  }
`;

const SettingsAnswerTypeIsButton = ({
  currentIndex,
  answer,
  branch,
  settingsBlockData,
  setSettingsBlockData,
  onClickOnAnswer,
  removeAnswer,
  handleAddBranch,
}) => {
  const answersHasBranches = settingsBlockData?.data?.[answer.branch];
  const selected = settingsBlockData.selectedBranches.includes(answer.branch);

  const handleAnswerInputValue = useCallback(
    (event, answerIndex) => {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [branch]: {
            ...prevState.data[branch],
            answers: {
              ...prevState.data[branch].answers,
              answers_data: prevState.data[branch].answers.answers_data.map(
                (answer, index) =>
                  index === answerIndex
                    ? { ...answer, answer_text: event.target.value }
                    : answer
              ),
            },
          },
        },
      }));
    },
    [branch]
  );

  return (
    <SettingsAnswerTypeIsButtonContainer
      selected={selected}
      onClick={(e) => onClickOnAnswer(e, answer)}
    >
      <input
        placeholder={"Ответ " + (currentIndex + 1)}
        maxLength="38"
        value={answer.answer_text}
        onChange={(e) => handleAnswerInputValue(e, currentIndex)}
      />

      {!answersHasBranches ? (
        <>
          <IconContainer onClick={(e) => removeAnswer(e, currentIndex)}>
            <MinusSmallIcon />
          </IconContainer>
          <IconContainer onClick={(e) => handleAddBranch(e, answer)}>
            <BranchIcon />
          </IconContainer>
        </>
      ) : (
        <BranchYellowIcon />
      )}
    </SettingsAnswerTypeIsButtonContainer>
  );
};

export default memo(SettingsAnswerTypeIsButton);
