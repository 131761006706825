import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../common/utils/routes";

const AuthPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === APPLICATION_ROUTES.AUTH) {
      navigate(APPLICATION_ROUTES.LOGIN, { replace: true });
    }
  }, []);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthPage;
