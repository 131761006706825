import React from "react";

const BibleCopyRightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M1.75 11L6.25 6.5L1.75 2"
        stroke="url(#paint0_linear_8293_9543)"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8293_9543"
          x1="0.922794"
          y1="6.71752"
          x2="6.64706"
          y2="6.71752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BibleCopyRightArrowIcon;
