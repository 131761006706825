import React, { memo } from "react";
import styled from "styled-components";

const ImagePreviewContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 15px;
`;

const NoImage = styled.div`
  padding: 20px;
  color: #383838;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background: #fff;
`;

const ImageLessonComponent = ({ componentData }) => {
  return (
    <ImagePreviewContainer>
      {componentData.image_url ? (
        <Image src={componentData.image_url} alt="Preview" />
      ) : (
        <NoImage>Загрузите изображение...</NoImage>
      )}
    </ImagePreviewContainer>
  );
};

export default memo(ImageLessonComponent);
