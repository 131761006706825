import React from "react";

const ForwardIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3970_12645)">
        <path
          d="M0.505297 20.3489C1.44921 12.64 7.859 6.77069 15.5593 6.43194V4.69445C15.5593 3.37463 16.2784 2.90624 16.894 2.90624C17.3424 2.90624 17.7972 3.13366 18.2455 3.5819L23.6799 9.01629C24.7734 10.1098 24.7734 11.889 23.6799 12.9825L18.2455 18.4169C17.7972 18.8652 17.3424 19.0926 16.894 19.0926C16.2784 19.0926 15.5593 18.6242 15.5593 17.3044V15.4464C14.6833 15.2754 13.7955 15.189 12.9125 15.189C8.5299 15.189 4.36461 17.3051 1.77037 20.8497C1.63487 21.0346 1.42211 21.1375 1.20275 21.1375C1.11632 21.1375 1.02861 21.1214 0.944384 21.0881C0.646837 20.9703 0.466295 20.6666 0.505297 20.3489ZM5.91955 15.4843C8.06334 14.3712 10.4814 13.7831 12.9125 13.7831C14.0868 13.7831 15.2682 13.9215 16.4241 14.1949C16.7413 14.27 16.9652 14.5532 16.9652 14.879V17.3044C16.9652 17.452 16.9799 17.5598 16.9965 17.6331C17.0602 17.5931 17.1468 17.5272 17.2512 17.4227L22.6858 11.9885C23.2311 11.443 23.2311 10.5558 22.6858 10.0104L17.2514 4.57598C17.1468 4.47161 17.0604 4.40551 16.9965 4.36559C16.9799 4.43902 16.9652 4.54669 16.9652 4.69445V7.11969C16.9652 7.50787 16.6504 7.82263 16.2623 7.82263C9.8521 7.82263 4.3106 12.0304 2.45372 17.9564C3.48845 16.9759 4.6585 16.1391 5.91955 15.4843Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_3970_12645">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24.5 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ForwardIcon;
