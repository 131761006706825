import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";

const LessonInfoModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const LessonInfoModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  width: 390px;
  height: 330px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;

  & > h4 {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.24px;
    user-select: none;
  }
`;

const ModalWindowItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 21px;
  width: 100%;
`;

const ModalWindowItemContainer = styled.div`
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;

  color: #828282;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const DeleteLessonButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;

  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.24px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.08);
    transition: 0.2s;
  }
`;

const formatDate = (isoDate, withTime = false) => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const year = date.getFullYear();

  if (withTime) {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  }

  return `${day}.${month}.${year}`;
};

const LessonInfoModalWindow = ({
  lessonInfoModalData,
  courseSettingsData,
  setLessonInfoModalData,
  setShowDeleteModal,
  userIsCreatorOrSuperAdmin,
}) => {
  const [modifiedBy, setModifiedBy] = useState({});

  const lessonNumber =
    courseSettingsData.course_lessons.findIndex(
      (lesson) => lesson._id === lessonInfoModalData._id
    ) + 1;

  const createdAt = formatDate(lessonInfoModalData.lesson_created_at);
  const modifiedAt =
    lessonInfoModalData.lesson_modified_at &&
    formatDate(lessonInfoModalData.lesson_modified_at, true);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setLessonInfoModalData({});
  }, []);

  const fetchLessonModifiedBy = useCallback(async () => {
    axios
      .get(
        BASE_API_URL + "/users/" + lessonInfoModalData.lesson_modified_by?._id
      )
      .then((response) => {
        setModifiedBy(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, [lessonInfoModalData]);

  useEffect(() => {
    if (lessonInfoModalData.lesson_modified_by?._id) {
      fetchLessonModifiedBy();
    }
  }, [lessonInfoModalData.lesson_modified_by?._id]);

  const onDeleteButton = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  return (
    <LessonInfoModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <LessonInfoModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <h4>
          Урок {lessonNumber}. {lessonInfoModalData.lesson_name}
        </h4>
        <ModalWindowItems>
          <ModalWindowItemContainer>
            <p>Дата создания: {createdAt}</p>
          </ModalWindowItemContainer>
          {lessonInfoModalData?.lesson_modified_at && modifiedBy?._id && (
            <ModalWindowItemContainer>
              <p>Дата изменения: {modifiedAt}</p>
              <p>
                ({modifiedBy.user_name} {modifiedBy.user_second_name})
              </p>
            </ModalWindowItemContainer>
          )}
        </ModalWindowItems>
        {userIsCreatorOrSuperAdmin && (
          <DeleteLessonButton onClick={onDeleteButton}>
            Удалить урок
          </DeleteLessonButton>
        )}
      </LessonInfoModalWindowContainer>
    </LessonInfoModalWindowWrapper>
  );
};

export default memo(LessonInfoModalWindow);
