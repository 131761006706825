import styled from "styled-components";

export const Avatar = styled.div`
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  max-width: ${({ size }) => size};
  max-height: ${({ size }) => size};
  border-radius: 50%;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`;
