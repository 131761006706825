export const BIBLE_PAGE_DEFAULT_URL =
  "/bible/?bibleId=rst&bookId=Gen&chapterId=1";

export const USER_LANGUAGE = "ru";

export const BIBLE_DEFAULT_FONT = {
  fontVariant: "Inter",
  fontSize: 16,
};

export const BIBLE_TRANSLATIONS_LANGUAGES = {
  ru: { name: "Русский", abbreviation: "Ru", code: "ru" },
  uk: { name: "Українська", abbreviation: "Uk", code: "uk" },
  en: { name: "English", abbreviation: "En", code: "en" },
};

export const BIBLE_BOOK_NAMES_DATA = {
  ru: {
    Gen: {
      name: "Бытие",
      number: 1,
    },
    Exo: {
      name: "Исход",
      number: 2,
    },
    Lev: {
      name: "Левит",
      number: 3,
    },
    Num: {
      name: "Числа",
      number: 4,
    },
    Deu: {
      name: "Второзаконие",
      number: 5,
    },
    Jos: {
      name: "Иисус Навин",
      number: 6,
    },
    Jdg: {
      name: "Книга Cудей",
      number: 7,
    },
    Rut: {
      name: "Руфь",
      number: 8,
    },
    "1Sa": {
      name: "1 Царств",
      number: 9,
    },
    "2Sa": {
      name: "2 Царств",
      number: 10,
    },
    "1Ki": {
      name: "3 Царств",
      number: 11,
    },
    "2Ki": {
      name: "4 Царств",
      number: 12,
    },
    "1Ch": {
      name: "1 Паралипоменон",
      number: 13,
    },
    "2Ch": {
      name: "2 Паралипоменон",
      number: 14,
    },
    Ezr: {
      name: "Ездра",
      number: 15,
    },
    Neh: {
      name: "Неемия",
      number: 16,
    },
    Est: {
      name: "Есфирь",
      number: 17,
    },
    Job: {
      name: "Иов",
      number: 18,
    },
    Psa: {
      name: "Псалтирь",
      secondName: "Псалом",
      number: 19,
    },
    Pro: {
      name: "Притчи",
      number: 20,
    },
    Ecc: {
      name: "Екклесиаст",
      number: 21,
    },
    Son: {
      name: "Песня песней",
      number: 22,
    },
    Isa: {
      name: "Исаия",
      number: 23,
    },
    Jer: {
      name: "Иеремия",
      number: 24,
    },
    Lam: {
      name: "Плач Иеремии",
      number: 25,
    },
    Eze: {
      name: "Иезекииль",
      number: 26,
    },
    Dan: {
      name: "Даниил",
      number: 27,
    },
    Hos: {
      name: "Осия",
      number: 28,
    },
    Joe: {
      name: "Иоиль",
      number: 29,
    },
    Amo: {
      name: "Амос",
      number: 30,
    },
    Oba: {
      name: "Авдий",
      number: 31,
    },
    Jon: {
      name: "Иона",
      number: 32,
    },
    Mic: {
      name: "Михей",
      number: 33,
    },
    Nah: {
      name: "Наум",
      number: 34,
    },
    Hab: {
      name: "Аввакум",
      number: 35,
    },
    Zep: {
      name: "Софония",
      number: 36,
    },
    Hag: {
      name: "Аггей",
      number: 37,
    },
    Zec: {
      name: "Захария",
      number: 38,
    },
    Mal: {
      name: "Малахия",
      number: 39,
    },
    Mat: {
      name: "Матфея",
      number: 40,
    },
    Mar: {
      name: "Марка",
      number: 41,
    },
    Luk: {
      name: "Луки",
      number: 42,
    },
    Joh: {
      name: "Иоанна",
      number: 43,
    },
    Act: {
      name: "Деяния",
      number: 44,
    },
    Jam: {
      name: "Иакова",
      number: 45,
    },
    "1Pe": {
      name: "1 Петра",
      number: 46,
    },
    "2Pe": {
      name: "2 Петра",
      number: 47,
    },
    "1Jo": {
      name: "1 Иоанна",
      number: 48,
    },
    "2Jo": {
      name: "2 Иоанна",
      number: 49,
    },
    "3Jo": {
      name: "3 Иоанна",
      number: 50,
    },
    Jud: {
      name: "Иуда",
      number: 51,
    },
    Rom: {
      name: "Римлянам",
      number: 52,
    },
    "1Co": {
      name: "1 Коринфянам",
      number: 53,
    },
    "2Co": {
      name: "2 Коринфянам",
      number: 54,
    },
    Gal: {
      name: "Галатам",
      number: 55,
    },
    Eph: {
      name: "Ефесянам",
      number: 56,
    },
    Phi: {
      name: "Филиппийцам",
      number: 57,
    },
    Col: {
      name: "Колоссянам",
      number: 58,
    },
    "1Th": {
      name: "1 Фессалоникийцам",
      number: 59,
    },
    "2Th": {
      name: "2 Фессалоникийцам",
      number: 60,
    },
    "1Ti": {
      name: "1 Тимофею",
      number: 61,
    },
    "2Ti": {
      name: "2 Тимофею",
      number: 62,
    },
    Tit: {
      name: "Титу",
      number: 63,
    },
    Phm: {
      name: "Филимону",
      number: 64,
    },
    Heb: {
      name: "Евреям",
      number: 65,
    },
    Rev: {
      name: "Откровение",
      number: 66,
    },
  },
  en: {
    Gen: {
      name: "Genesis",
      number: 1,
    },
    Exo: {
      name: "Exodus",
      number: 2,
    },
    Lev: {
      name: "Leviticus",
      number: 3,
    },
    Num: {
      name: "Numbers",
      number: 4,
    },
    Deu: {
      name: "Deuteronomy",
      number: 5,
    },
    Jos: {
      name: "Joshua",
      number: 6,
    },
    Jdg: {
      name: "Judges",
      number: 7,
    },
    Rut: {
      name: "Ruth",
      number: 8,
    },
    "1Sa": {
      name: "1 Samuel",
      number: 9,
    },
    "2Sa": {
      name: "2 Samuel",
      number: 10,
    },
    "1Ki": {
      name: "1 Kings",
      number: 11,
    },
    "2Ki": {
      name: "2 Kings",
      number: 12,
    },
    "1Ch": {
      name: "1 Chronicles",
      number: 13,
    },
    "2Ch": {
      name: "2 Chronicles",
      number: 14,
    },
    Ezr: {
      name: "Ezra",
      number: 15,
    },
    Neh: {
      name: "Nehemiah",
      number: 16,
    },
    Est: {
      name: "Esther",
      number: 17,
    },
    Job: {
      name: "Job",
      number: 18,
    },
    Psa: {
      name: "Psalms",
      secondName: "Psalm",
      number: 19,
    },
    Pro: {
      name: "Proverbs",
      number: 20,
    },
    Ecc: {
      name: "Ecclesiastes",
      number: 21,
    },
    Son: {
      name: "Song of Solomon",
      number: 22,
    },
    Isa: {
      name: "Isaiah",
      number: 23,
    },
    Jer: {
      name: "Jeremiah",
      number: 24,
    },
    Lam: {
      name: "Lamentations",
      number: 25,
    },
    Eze: {
      name: "Ezekiel",
      number: 26,
    },
    Dan: {
      name: "Daniel",
      number: 27,
    },
    Hos: {
      name: "Hosea",
      number: 28,
    },
    Joe: {
      name: "Joel",
      number: 29,
    },
    Amo: {
      name: "Amos",
      number: 30,
    },
    Oba: {
      name: "Obadiah",
      number: 31,
    },
    Jon: {
      name: "Jonah",
      number: 32,
    },
    Mic: {
      name: "Micah",
      number: 33,
    },
    Nah: {
      name: "Nahum",
      number: 34,
    },
    Hab: {
      name: "Habakkuk",
      number: 35,
    },
    Zep: {
      name: "Zephaniah",
      number: 36,
    },
    Hag: {
      name: "Haggai",
      number: 37,
    },
    Zec: {
      name: "Zechariah",
      number: 38,
    },
    Mal: {
      name: "Malachi",
      number: 39,
    },
    Mat: {
      name: "Matthew",
      number: 40,
    },
    Mar: {
      name: "Mark",
      number: 41,
    },
    Luk: {
      name: "Luke",
      number: 42,
    },
    Joh: {
      name: "John",
      number: 43,
    },
    Act: {
      name: "Acts",
      number: 44,
    },
    Rom: {
      name: "Romans",
      number: 45,
    },
    "1Co": {
      name: "1 Corinthians",
      number: 46,
    },
    "2Co": {
      name: "2 Corinthians",
      number: 47,
    },
    Gal: {
      name: "Galatians",
      number: 48,
    },
    Eph: {
      name: "Ephesians",
      number: 49,
    },
    Phi: {
      name: "Philippians",
      number: 50,
    },
    Col: {
      name: "Colossians",
      number: 51,
    },
    "1Th": {
      name: "1 Thessalonians",
      number: 52,
    },
    "2Th": {
      name: "2 Thessalonians",
      number: 53,
    },
    "1Ti": {
      name: "1 Timothy",
      number: 54,
    },
    "2Ti": {
      name: "2 Timothy",
      number: 55,
    },
    Tit: {
      name: "Titus",
      number: 56,
    },
    Phm: {
      name: "Philemon",
      number: 57,
    },
    Heb: {
      name: "Hebrews",
      number: 58,
    },
    Jam: {
      name: "James",
      number: 59,
    },
    "1Pe": {
      name: "1 Peter",
      number: 60,
    },
    "2Pe": {
      name: "2 Peter",
      number: 61,
    },
    "1Jo": {
      name: "1 John",
      number: 62,
    },
    "2Jo": {
      name: "2 John",
      number: 63,
    },
    "3Jo": {
      name: "3 John",
      number: 64,
    },
    Jud: {
      name: "Jude",
      number: 65,
    },
    Rev: {
      name: "Revelation",
      number: 66,
    },
  },
  uk: {
    Gen: {
      name: "Буття",
      number: 1,
    },
    Exo: {
      name: "Вихід",
      number: 2,
    },
    Lev: {
      name: "Левит",
      number: 3,
    },
    Num: {
      name: "Числа",
      number: 4,
    },
    Deu: {
      name: "Повторення Закону",
      number: 5,
    },
    Jos: {
      name: "Iсус Навин",
      number: 6,
    },
    Jdg: {
      name: "Книга Суддiв",
      number: 7,
    },
    Rut: {
      name: "Рут",
      number: 8,
    },
    "1Sa": {
      name: "1-а Самуїлова",
      number: 9,
    },
    "2Sa": {
      name: "2-а Самуїлова",
      number: 10,
    },
    "1Ki": {
      name: "1-а царiв",
      number: 11,
    },
    "2Ki": {
      name: "2-а царiв",
      number: 12,
    },
    "1Ch": {
      name: "1-а хронiки",
      number: 13,
    },
    "2Ch": {
      name: "2-а хронiки",
      number: 14,
    },
    Ezr: {
      name: "Ездра",
      number: 15,
    },
    Neh: {
      name: "Неемія",
      number: 16,
    },
    Est: {
      name: "Естер",
      number: 17,
    },
    Job: {
      name: "Йов",
      number: 18,
    },
    Psa: {
      name: "Псалми",
      secondName: "Псалом",
      number: 19,
    },
    Pro: {
      name: "Приповiстi",
      number: 20,
    },
    Ecc: {
      name: "Екклезiяст",
      number: 21,
    },
    Son: {
      name: "Пiсня над пiснями",
      number: 22,
    },
    Isa: {
      name: "Iсая",
      number: 23,
    },
    Jer: {
      name: "Єремiя",
      number: 24,
    },
    Lam: {
      name: "Плач Єремiї",
      number: 25,
    },
    Eze: {
      name: "Єзекiїль",
      number: 26,
    },
    Dan: {
      name: "Даниїл",
      number: 27,
    },
    Hos: {
      name: "Осiя",
      number: 28,
    },
    Joe: {
      name: "Йоїл",
      number: 29,
    },
    Amo: {
      name: "Амос",
      number: 30,
    },
    Oba: {
      name: "Овдiй",
      number: 31,
    },
    Jon: {
      name: "Йона",
      number: 32,
    },
    Mic: {
      name: "Михей",
      number: 33,
    },
    Nah: {
      name: "Наум",
      number: 34,
    },
    Hab: {
      name: "Авакум",
      number: 35,
    },
    Zep: {
      name: "Софонiя",
      number: 36,
    },
    Hag: {
      name: "Огiй",
      number: 37,
    },
    Zec: {
      name: "Захарiя",
      number: 38,
    },
    Mal: {
      name: "Малахiї",
      number: 39,
    },
    Mat: {
      name: "Вiд Матвiя",
      number: 40,
    },
    Mar: {
      name: "Вiд Марка",
      number: 41,
    },
    Luk: {
      name: "Вiд Луки",
      number: 42,
    },
    Joh: {
      name: "Вiд Iвана",
      number: 43,
    },
    Act: {
      name: "Дiї",
      number: 44,
    },
    Rom: {
      name: "До римлян",
      number: 45,
    },
    "1Co": {
      name: "1-е до коринтян",
      number: 46,
    },
    "2Co": {
      name: "2-е до коринтян",
      number: 47,
    },
    Gal: {
      name: "До галатiв",
      number: 48,
    },
    Eph: {
      name: "До ефесян",
      number: 49,
    },
    Phi: {
      name: "До филип'ян",
      number: 50,
    },
    Col: {
      name: "До колоссян",
      number: 51,
    },
    "1Th": {
      name: "1-е до солунян",
      number: 52,
    },
    "2Th": {
      name: "2-е до солунян",
      number: 53,
    },
    "1Ti": {
      name: "1-е Тимофiю",
      number: 54,
    },
    "2Ti": {
      name: "2-е Тимофiю",
      number: 55,
    },
    Tit: {
      name: "Титові",
      number: 56,
    },
    Phm: {
      name: "Филимону",
      number: 57,
    },
    Heb: {
      name: "До євреїв",
      number: 58,
    },
    Jam: {
      name: "Якова",
      number: 59,
    },
    "1Pe": {
      name: "1-е Петра",
      number: 60,
    },
    "2Pe": {
      name: "2-е Петра",
      number: 61,
    },
    "1Jo": {
      name: "1-е Iвана",
      number: 62,
    },
    "2Jo": {
      name: "2-е Iвана",
      number: 63,
    },
    "3Jo": {
      name: "3-е Iвана",
      number: 64,
    },
    Jud: {
      name: "Юда",
      number: 65,
    },
    Rev: {
      name: "Об'явлення",
      number: 66,
    },
  },
};
