import React from "react";

const CreateLessonArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
    >
      <path
        d="M10.5 6.25L6 1.75L1.5 6.25"
        stroke="url(#paint0_linear_7528_11082)"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7528_11082"
          x1="6.21752"
          y1="7.07721"
          x2="6.21752"
          y2="1.35294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CreateLessonArrowIcon;
