import React, { memo, useCallback, useEffect, useState } from "react";
import AskQuestionArrowIcon from "../../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import styled from "styled-components";
import Checkbox from "../../../../../../common/components/checkbox";
import UserRolesCourseOrGroupItem from "../course_or_group_preview";
import PlusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import {
  AddUserButton,
  AddUserButtonContainer,
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../../../admin_page_components/admin_page_styled_components";
import ConfirmationModalWindow from "../../../admin_users_modal_windows/confirmation_modal_window";
import { useUserDataContext } from "../../../../../../user_data_context";

const CoursesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CoursesAdminCoursesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 2px 10px 8px;
  border-bottom: 1px solid #cacaca;
`;

const AddCourseButton = styled(AddUserButtonContainer)`
  padding: 0 6px;
`;

const CoursesAdmin = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
  setOpenAddingCourseModal,
}) => {
  const [extendedUserIsCoursesAdmin, setExtendedUserIsCoursesAdmin] =
    useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});

  const isInvite = userRolesSettingsData.user_type === "invite";
  const userIsCoursesAdmin =
    userRolesSettingsData.user_access.is_admin_of_courses;
  const userCourseList = userRolesSettingsData.user_access.admin_of_courses;

  useEffect(() => {
    if (!userIsCoursesAdmin) {
      setExtendedUserIsCoursesAdmin(false);
    }
  }, [userIsCoursesAdmin]);

  const handleUserIsCoursesAdmin = useCallback(
    (event) => {
      event.stopPropagation();
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          is_admin_of_courses: !prevState.user_access.is_admin_of_courses,
        },
      }));

      setUsersList((prevState) =>
        prevState.map((user) =>
          user._id === userRolesSettingsData._id || user._id === "new_invite"
            ? {
                ...user,
                user_access: {
                  ...user.user_access,
                  is_admin_of_courses: !user.user_access.is_admin_of_courses,
                },
              }
            : user
        )
      );
    },
    [userRolesSettingsData]
  );

  const onRemoveCourse = useCallback(() => {
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_access: {
        ...prevState.user_access,
        admin_of_courses: prevState.user_access.admin_of_courses.filter(
          (course) => course._id !== selectedCourse._id
        ),
      },
    }));
    setSelectedCourse({});
  }, [selectedCourse]);

  const handleExtendUserIsCoursesAdmin = useCallback(() => {
    if (userIsCoursesAdmin) {
      setExtendedUserIsCoursesAdmin((prevState) => !prevState);
    }
  }, [userIsCoursesAdmin]);

  const onOpenAddingCourseModal = useCallback(() => {
    setOpenAddingCourseModal("courses_admin");
  }, []);

  const onConfirmCourseDeletion = useCallback((courseData) => {
    setSelectedCourse(courseData);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setSelectedCourse({});
  }, []);

  return (
    <div>
      <AdminPageSettingsHeader
        clickable={userIsCoursesAdmin && !isInvite}
        onClick={handleExtendUserIsCoursesAdmin}
      >
        <Checkbox
          checked={userIsCoursesAdmin}
          onClickCheckbox={handleUserIsCoursesAdmin}
          color={"#1e1e1e"}
          fontSize={"14px"}
        >
          Админ курсов
        </Checkbox>

        {userIsCoursesAdmin && !isInvite && (
          <AdminPageSettingsHeaderArrow
            extendedSection={extendedUserIsCoursesAdmin}
          >
            <AskQuestionArrowIcon />
          </AdminPageSettingsHeaderArrow>
        )}
      </AdminPageSettingsHeader>

      {extendedUserIsCoursesAdmin && !isInvite && (
        <CoursesListContainer>
          {!!userCourseList?.length && (
            <CoursesAdminCoursesList>
              {userCourseList.map((course) => {
                return (
                  <UserRolesCourseOrGroupItem
                    key={course._id}
                    component={"course"}
                    item={course}
                    onConfirmItemDeletion={onConfirmCourseDeletion}
                  />
                );
              })}
            </CoursesAdminCoursesList>
          )}

          <AddCourseButton onClick={onOpenAddingCourseModal}>
            <AddUserButton>
              <PlusIcon />
            </AddUserButton>
          </AddCourseButton>
        </CoursesListContainer>
      )}

      {selectedCourse._id && (
        <ConfirmationModalWindow
          setOpen={closeConfirmationModal}
          userRolesSettingsData={userRolesSettingsData}
          action="removing"
          selectedObjectType={"course"}
          selectedObjectData={selectedCourse}
          onClickConfirmButton={onRemoveCourse}
        />
      )}
    </div>
  );
};

export default memo(CoursesAdmin);
