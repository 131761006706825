import React, { memo, useCallback, useEffect, useState } from "react";
import Checkbox from "../../../../../common/components/checkbox";
import styled from "styled-components";

const TestAnswerOptionContainer = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const TestAnswerOption = ({
  option,
  showAllAnswers,
  inComponent,
  componentData,
  lessonIsFinished,
  multipleCorrectAnswers,
  setLessonData,
}) => {
  const [show, setShow] = useState(false);

  const borderColor = option.right_answer
    ? "#099d68"
    : "rgba(243, 49, 49, 0.70)";

  useEffect(() => {
    const shouldShow =
      inComponent === "create_lesson" ||
      (lessonIsFinished && (showAllAnswers || option.right_answer));

    setShow(shouldShow);
  }, [inComponent, lessonIsFinished, showAllAnswers, option.right_answer]);

  const onSelectAnswer = useCallback(
    (event) => {
      event.stopPropagation();

      setLessonData((prevState) => {
        const updatedLessonData = prevState.lesson_data.map((component) => {
          if (component.id !== componentData.id) return component;

          const updatedOptions = component.data.map((item) => {
            if (multipleCorrectAnswers) {
              return item.option_text === option.option_text
                ? { ...item, user_answer: !item.user_answer }
                : item;
            } else {
              if (item.option_text === option.option_text) {
                return { ...item, user_answer: !item.user_answer };
              } else {
                return { ...item, user_answer: false };
              }
            }
          });

          return {
            ...component,
            data: updatedOptions,
          };
        });

        return {
          ...prevState,
          lesson_data: updatedLessonData,
        };
      });
    },
    [option, componentData, setLessonData, multipleCorrectAnswers]
  );

  return (
    <TestAnswerOptionContainer borderColor={show ? borderColor : "transparent"}>
      <Checkbox
        fontSize={"14px"}
        checked={option.user_answer}
        onClickCheckbox={onSelectAnswer}
        disabled={lessonIsFinished}
      >
        {option.option_text}
      </Checkbox>
    </TestAnswerOptionContainer>
  );
};

export default memo(TestAnswerOption);
