import React from "react";

const LightIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3886_5703)">
        <path
          d="M6.53082 5.53658L4.5421 3.54786C4.26751 3.27327 3.82229 3.27327 3.54774 3.54786C3.27315 3.82246 3.27315 4.26763 3.54774 4.54222L5.53646 6.53094C5.81101 6.80554 6.25627 6.80554 6.53082 6.53094C6.80541 6.25635 6.80541 5.81118 6.53082 5.53658Z"
          fill="url(#paint0_linear_3886_5703)"
        />
        <path
          d="M3.5625 11.2969H0.703125C0.314812 11.2969 0 11.6117 0 12C0 12.3883 0.314812 12.7031 0.703125 12.7031H3.5625C3.95081 12.7031 4.26562 12.3883 4.26562 12C4.26562 11.6117 3.95081 11.2969 3.5625 11.2969Z"
          fill="url(#paint1_linear_3886_5703)"
        />
        <path
          d="M23.2969 11.2969H20.4375C20.0492 11.2969 19.7344 11.6117 19.7344 12C19.7344 12.3883 20.0492 12.7031 20.4375 12.7031H23.2969C23.6852 12.7031 24 12.3883 24 12C24 11.6117 23.6852 11.2969 23.2969 11.2969Z"
          fill="url(#paint2_linear_3886_5703)"
        />
        <path
          d="M20.4527 3.54786C20.1781 3.27327 19.7329 3.27327 19.4583 3.54786L17.4696 5.53658C17.195 5.81118 17.195 6.25635 17.4696 6.53094C17.7442 6.80554 18.1894 6.80554 18.464 6.53094L20.4527 4.54222C20.7273 4.26763 20.7273 3.82246 20.4527 3.54786Z"
          fill="url(#paint3_linear_3886_5703)"
        />
        <path
          d="M12 0C11.6117 0 11.2969 0.314812 11.2969 0.703125V3.5625C11.2969 3.95081 11.6117 4.26562 12 4.26562C12.3883 4.26562 12.7031 3.95081 12.7031 3.5625V0.703125C12.7031 0.314812 12.3883 0 12 0Z"
          fill="url(#paint4_linear_3886_5703)"
        />
        <path
          d="M10.4871 5.84832C8.23601 6.37336 6.42397 8.15864 5.87065 10.3965C5.31556 12.6417 5.9509 14.9303 7.57025 16.5183C8.11751 17.055 8.4852 18.0837 8.4852 19.0781V20.4844C8.4852 21.6475 9.43147 22.5938 10.5946 22.5938H11.2977V23.2969C11.2977 23.6852 11.6125 24 12.0008 24C12.3891 24 12.704 23.6852 12.704 23.2969V22.5938H13.4071C14.5702 22.5938 15.5165 21.6475 15.5165 20.4844V19.0781C15.5165 18.0958 15.9038 17.0476 16.4804 16.4697C17.6725 15.2751 18.329 13.6877 18.329 12C18.329 7.96444 14.5889 4.89164 10.4871 5.84832ZM14.1102 20.4844C14.1102 20.8721 13.7948 21.1875 13.4071 21.1875H10.5946C10.2069 21.1875 9.89145 20.8721 9.89145 20.4844V19.7813H14.1102V20.4844ZM15.485 15.4764C14.7822 16.1806 14.3001 17.249 14.1556 18.375H9.84617C9.72378 17.4019 9.34137 16.2856 8.55486 15.5143C7.29444 14.2782 6.80131 12.4912 7.2358 10.734C7.66348 9.00422 9.06505 7.62399 10.8065 7.21782C14.0209 6.468 16.9227 8.86571 16.9227 12C16.9227 13.3126 16.4121 14.5473 15.485 15.4764Z"
          fill="url(#paint5_linear_3886_5703)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3886_5703"
          x1="4.16066"
          y1="6.61301"
          x2="6.05653"
          y2="3.34675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3886_5703"
          x1="1.02886"
          y1="12.6518"
          x2="1.36275"
          y2="10.9069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3886_5703"
          x1="20.7632"
          y1="12.6518"
          x2="21.0971"
          y2="10.9069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3886_5703"
          x1="18.0825"
          y1="6.61301"
          x2="19.9784"
          y2="3.34675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3886_5703"
          x1="11.6361"
          y1="4.10998"
          x2="13.9922"
          y2="2.77178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3886_5703"
          x1="8.72622"
          y1="23.3312"
          x2="20.3375"
          y2="9.51842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <clipPath id="clip0_3886_5703">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightIcon;
