import React from "react";

const BibleStrongArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clipPath="url(#clip0_7862_16173)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.4771 9.14068C2.21133 8.88033 2.21133 8.45822 2.4771 8.19787L6.07921 4.66927L2.4771 1.14067C2.21133 0.880325 2.21133 0.458216 2.4771 0.197866C2.74287 -0.0624838 3.17378 -0.0624838 3.43955 0.197866L7.52288 4.19787C7.78866 4.45822 7.78866 4.88033 7.52288 5.14068L3.43955 9.14068C3.17378 9.40102 2.74287 9.40102 2.4771 9.14068Z"
          fill="#5172EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_7862_16173">
          <rect
            width="9.33333"
            height="9.33333"
            fill="white"
            transform="translate(0.333313 9.33301) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BibleStrongArrowRightIcon;
