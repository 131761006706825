import React from "react";

const AdminPageUsersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_5866_6624)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.89746C9.79086 2.89746 8 4.68832 8 6.89746C8 9.1066 9.79086 10.8975 12 10.8975C14.2091 10.8975 16 9.1066 16 6.89746C16 4.68832 14.2091 2.89746 12 2.89746ZM6 6.89746C6 3.58375 8.68629 0.897461 12 0.897461C15.3137 0.897461 18 3.58375 18 6.89746C18 10.2112 15.3137 12.8975 12 12.8975C8.68629 12.8975 6 10.2112 6 6.89746ZM2.78215 17.5332C3.91182 16.4789 5.43011 15.8975 7 15.8975H17C18.5699 15.8975 20.0882 16.4789 21.2179 17.5332C22.3499 18.5898 23 20.0381 23 21.5641V23.8975C23 24.4497 22.5523 24.8975 22 24.8975C21.4477 24.8975 21 24.4497 21 23.8975V21.5641C21 20.6148 20.5966 19.6892 19.8532 18.9954C19.1075 18.2994 18.0823 17.8975 17 17.8975H7C5.91773 17.8975 4.89248 18.2994 4.14678 18.9954C3.40342 19.6892 3 20.6148 3 21.5641V23.8975C3 24.4497 2.55228 24.8975 2 24.8975C1.44772 24.8975 1 24.4497 1 23.8975V21.5641C1 20.0381 1.65014 18.5898 2.78215 17.5332Z"
          fill="url(#paint0_linear_5866_6624)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5866_6624"
          x1="11.4683"
          y1="-3.5143"
          x2="11.4683"
          y2="27.0151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_5866_6624">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.897461)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdminPageUsersIcon;
