import React from "react";

const PlusTranslationIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2423_18605)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0.291504C7.98325 0.291504 8.375 0.683255 8.375 1.1665V12.8332C8.375 13.3164 7.98325 13.7082 7.5 13.7082C7.01675 13.7082 6.625 13.3164 6.625 12.8332V1.1665C6.625 0.683255 7.01675 0.291504 7.5 0.291504Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.791748 7C0.791748 6.51675 1.1835 6.125 1.66675 6.125H13.3334C13.8167 6.125 14.2084 6.51675 14.2084 7C14.2084 7.48325 13.8167 7.875 13.3334 7.875H1.66675C1.1835 7.875 0.791748 7.48325 0.791748 7Z"
        fill="#383838"
      />
    </g>
    <defs>
      <clipPath id="clip0_2423_18605">
        <rect width="14" height="14" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default PlusTranslationIcon;
