import React from "react";

const BiblePageShareIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2.89961C16.8402 2.89961 15.9 3.83981 15.9 4.99961C15.9 6.15941 16.8402 7.09961 18 7.09961C19.1598 7.09961 20.1 6.15941 20.1 4.99961C20.1 3.83981 19.1598 2.89961 18 2.89961ZM14.1 4.99961C14.1 2.8457 15.8461 1.09961 18 1.09961C20.1539 1.09961 21.9 2.8457 21.9 4.99961C21.9 7.15352 20.1539 8.89961 18 8.89961C15.8461 8.89961 14.1 7.15352 14.1 4.99961Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99998 9.89961C4.84018 9.89961 3.89998 10.8398 3.89998 11.9996C3.89998 13.1594 4.84018 14.0996 5.99998 14.0996C7.15977 14.0996 8.09998 13.1594 8.09998 11.9996C8.09998 10.8398 7.15977 9.89961 5.99998 9.89961ZM2.09998 11.9996C2.09998 9.8457 3.84607 8.09961 5.99998 8.09961C8.15389 8.09961 9.89998 9.8457 9.89998 11.9996C9.89998 14.1535 8.15389 15.8996 5.99998 15.8996C3.84607 15.8996 2.09998 14.1535 2.09998 11.9996Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16.8996C16.8402 16.8996 15.9 17.8398 15.9 18.9996C15.9 20.1594 16.8402 21.0996 18 21.0996C19.1598 21.0996 20.1 20.1594 20.1 18.9996C20.1 17.8398 19.1598 16.8996 18 16.8996ZM14.1 18.9996C14.1 16.8457 15.8461 15.0996 18 15.0996C20.1539 15.0996 21.9 16.8457 21.9 18.9996C21.9 21.1535 20.1539 22.8996 18 22.8996C15.8461 22.8996 14.1 21.1535 14.1 18.9996Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81242 13.0564C8.06268 12.6269 8.6137 12.4817 9.04316 12.7319L15.8732 16.7119C16.3026 16.9622 16.4479 17.5132 16.1976 17.9427C15.9474 18.3721 15.3964 18.5174 14.9669 18.2671L8.1369 14.2871C7.70744 14.0369 7.56216 13.4859 7.81242 13.0564Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1873 6.0559C16.4379 6.4852 16.293 7.03631 15.8637 7.28684L9.04365 11.2668C8.61435 11.5174 8.06324 11.3724 7.81271 10.9431C7.56218 10.5138 7.7071 9.96273 8.1364 9.7122L14.9564 5.7322C15.3857 5.48167 15.9368 5.62659 16.1873 6.0559Z"
        fill="#383838"
      />
    </svg>
  );
};

export default BiblePageShareIcon;
