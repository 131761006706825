import React, { memo, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import AdminPageSidebar from "./admin_page_sidebar";
import { APPLICATION_ROUTES } from "../../common/utils/routes";
import { useUserDataContext } from "../../user_data_context";

const AdminPageContainer = styled.div`
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
`;

const AdminPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const lessonId = searchParams.get("lessonId");
  const courseId = searchParams.get("courseId");
  const lessonsPreviews = searchParams.get("lessons");
  const { accessRights } = useUserDataContext();

  const {
    isSuperadmin,
    isAdminOfCourses,
    isAdminOfGroups,
    isAuthor,
    isAdminOfPlatformUsers,
    isAdminOfPlatformCourses,
    isAdminOfPlatformGroups,
    isAdminOfPlatformQuestions,
    isAdminOfPlatformAnalytics,
    isAdminOfPlatformHomepage,
  } = accessRights;

  const hasAccess =
    isSuperadmin ||
    isAdminOfCourses ||
    isAdminOfGroups ||
    isAuthor ||
    isAdminOfPlatformUsers ||
    isAdminOfPlatformCourses ||
    isAdminOfPlatformGroups ||
    isAdminOfPlatformQuestions ||
    isAdminOfPlatformAnalytics ||
    isAdminOfPlatformHomepage;

  useEffect(() => {
    const lastUrl = localStorage.getItem("lastAdminUrl");

    if (lessonId || courseId || lessonsPreviews) {
      return;
    }

    if (lastUrl) {
      navigate(lastUrl);
    } else {
      if (isSuperadmin || isAdminOfPlatformHomepage) {
        return navigate(APPLICATION_ROUTES.ADMIN_HOME);
      } else if (
        isSuperadmin ||
        isAdminOfCourses ||
        isAdminOfPlatformCourses ||
        isAuthor
      ) {
        return navigate(APPLICATION_ROUTES.ADMIN_COURSES);
      } else if (isSuperadmin || isAdminOfPlatformUsers) {
        return navigate(APPLICATION_ROUTES.ADMIN_USERS);
      } else if (isSuperadmin || isAdminOfGroups || isAdminOfPlatformGroups) {
        return navigate(APPLICATION_ROUTES.ADMIN_GROUPS);
      } else if (isSuperadmin || isAdminOfPlatformQuestions) {
        return navigate(APPLICATION_ROUTES.ADMIN_QUESTIONS);
      } else if (isSuperadmin || isAdminOfPlatformAnalytics) {
        return navigate(APPLICATION_ROUTES.ADMIN_ANALYTICS);
      } else if (!hasAccess) {
        return navigate("/bible");
      }
    }
  }, [accessRights, hasAccess]);

  useEffect(() => {
    localStorage.setItem("lastAdminUrl", location.pathname);
  }, [location.pathname]);

  if (!hasAccess) return;

  return (
    <AdminPageContainer>
      <AdminPageSidebar />

      <Outlet />
    </AdminPageContainer>
  );
};

export default memo(AdminPage);
