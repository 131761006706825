import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import QuestionsFilterByStatus from "./questions_filter_by_status";
import QuestionsFilterByRecipient from "./questions_filter_by_recipient";
import QuestionsFilterByBible from "./questions_filter_by_bible";
import QuestionsSelectedFilters from "./questions_selected_filters";
import QuestionsFilterBySearchWords from "./questions_filter_by_search_words";
import QuestionsFilterByDate from "./questions_filter_by_date";

const QuestionsFiltersContainer = styled.div`
  width: 635px;
`;

const QuestionsFiltersSettings = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  margin-bottom: 8px;
`;

const QuestionsFiltersWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const QuestionsQuestionsFilter = ({
  questionsFilterSettings,
  setQuestionsFilterSettings,
}) => {
  const [extendedOption, setExtendedOption] = useState(""); // status, recipient, date, bible

  const hadleExtendOptions = useCallback(
    (event, optionName) => {
      event.stopPropagation();

      if (extendedOption === optionName) {
        setExtendedOption("");
      } else {
        setExtendedOption(optionName);
      }
    },
    [extendedOption]
  );

  const removeSelectedFilterOption = useCallback((option, itemIndex) => {
    if (
      option === "question_bible" ||
      option === "search_words" ||
      option === "question_date_range"
    ) {
      setQuestionsFilterSettings((prevState) => {
        const updatedFilterData = prevState[option].filter(
          (_, index) => index !== itemIndex
        );
        return {
          ...prevState,
          [option]: updatedFilterData,
        };
      });
    } else {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        [option]: "",
      }));
    }
  }, []);

  return (
    <QuestionsFiltersContainer>
      <QuestionsFiltersSettings onMouseDown={(e) => e.stopPropagation()}>
        <QuestionsFilterByStatus
          extendedOption={extendedOption}
          hadleExtendOptions={hadleExtendOptions}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />

        <QuestionsFilterByRecipient
          extendedOption={extendedOption}
          hadleExtendOptions={hadleExtendOptions}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />

        <QuestionsFilterByDate
          extendedOption={extendedOption}
          hadleExtendOptions={hadleExtendOptions}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />

        <QuestionsFilterByBible
          extendedOption={extendedOption}
          hadleExtendOptions={hadleExtendOptions}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />

        <QuestionsFilterBySearchWords
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />

        {!!extendedOption && (
          <QuestionsFiltersWrapper onClick={() => setExtendedOption("")} />
        )}
      </QuestionsFiltersSettings>

      <QuestionsSelectedFilters
        questionsFilterSettings={questionsFilterSettings}
        removeSelectedFilterOption={removeSelectedFilterOption}
      />
    </QuestionsFiltersContainer>
  );
};

export default memo(QuestionsQuestionsFilter);
