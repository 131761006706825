import React, { memo, useCallback } from "react";
import styled from "styled-components";

const AnswerTypeIsButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 30px;
  margin-bottom: 18px;
`;

const AnswerTypeIsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid rgba(81, 114, 234, 0.4);
  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%)"
      : "linear-gradient(180deg, rgba(81, 114, 234, 0.25) -18.38%, rgba(122, 90, 238, 0.25) 108.82%)"};
  color: #fff;
  cursor: pointer;
`;

const AnswerTypeIsButtons = ({ settingsBlockData, setSettingsBlockData }) => {
  const handleAnswerType = useCallback((type) => {
    setSettingsBlockData((prevState) => ({ ...prevState, answer_type: type }));
  }, []);

  return (
    <AnswerTypeIsButtonsContainer>
      <AnswerTypeIsButton
        isActive={settingsBlockData.answer_type === "input"}
        onClick={() => handleAnswerType("input")}
      >
        Поле для ввода
      </AnswerTypeIsButton>
      <AnswerTypeIsButton
        isActive={settingsBlockData.answer_type === "options"}
        onClick={() => handleAnswerType("options")}
      >
        Варианты ответа
      </AnswerTypeIsButton>
    </AnswerTypeIsButtonsContainer>
  );
};

export default memo(AnswerTypeIsButtons);
