import React, { memo, useCallback } from "react";
import TextBibleIcon from "../../../../common/assets/icons/text_editor_icons/text_bible_icon";
import styled from "styled-components";
import {
  TextSettingsButtonsHeaderContainer,
  TextSettingsButtonsHeaderWrapper,
} from "./text_settings_buttons_header";

const TextBibleButtonContainer = styled(TextSettingsButtonsHeaderContainer)`
  align-items: center;
`;

const TextBibleButton = ({ setOpenBibleModalWindow }) => {
  const toggleBibleModal = useCallback((event) => {
    event.stopPropagation();
    setOpenBibleModalWindow(true);
  }, []);

  return (
    <TextSettingsButtonsHeaderWrapper onClick={toggleBibleModal}>
      <TextBibleButtonContainer>
        <TextBibleIcon />
      </TextBibleButtonContainer>
    </TextSettingsButtonsHeaderWrapper>
  );
};

export default memo(TextBibleButton);
