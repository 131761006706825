import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../assets/icons/vertical_line";

const TextLinkModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const TextLinkModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  width: max-content;
  height: 230px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;

  & > h3 {
    font-size: 16px;
    color: #828282;
  }
`;

const TextLinkModalInput = styled.input`
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  font-size: 16px;
  padding: 0 15px;
  color: #1e1e1e;
  border-radius: 8px;
  background: #fff;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ initialUrl }) => (initialUrl ? "32%" : "49%")};
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: ${({ initialUrl }) => (initialUrl ? "33%" : "51%")};
  }
`;

const SaveButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const DeleteButton = styled(SaveButton)``;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSave }) =>
    isHoverOnSave ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 4px;
`;

const TextLinkModalWindow = ({
  setTextLinkModal,
  onConfirm,
  onDelete,
  initialUrl,
}) => {
  const [url, setUrl] = useState(initialUrl || "");
  const [isHoverOnSave, setIsHoverOnSave] = useState(false);

  const toggleHoverOnSaveButton = useCallback(() => {
    setIsHoverOnSave((prevState) => !prevState);
  }, []);

  const handleUrlInput = useCallback((event) => {
    event.stopPropagation();
    setUrl(event.target.value);
  }, []);

  const handleSave = useCallback(
    (event) => {
      event.stopPropagation();
      onConfirm(url);
    },
    [url]
  );

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setTextLinkModal(false);
  }, []);

  return (
    <TextLinkModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <TextLinkModalWindowContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h3>{initialUrl ? "Изменить ссылку" : "Вставить ссылку"}</h3>
        <TextLinkModalInput
          type="text"
          value={url}
          onChange={handleUrlInput}
          placeholder="Ссылка"
        />
        <ModalButtonsContainer>
          <SaveButton
            initialUrl={initialUrl}
            onMouseEnter={toggleHoverOnSaveButton}
            onMouseLeave={toggleHoverOnSaveButton}
            onClick={handleSave}
          >
            Сохранить
          </SaveButton>
          {initialUrl && (
            <>
              <VerticalLineStyled color={"#5172EA"} />
              <DeleteButton
                initialUrl={initialUrl}
                onMouseEnter={toggleHoverOnSaveButton}
                onMouseLeave={toggleHoverOnSaveButton}
                onClick={onDelete}
              >
                Удалить
              </DeleteButton>
            </>
          )}
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton
            initialUrl={initialUrl}
            isHoverOnSave={isHoverOnSave}
            onClick={handleClose}
          >
            Отмена
          </CancelButton>
        </ModalButtonsContainer>
      </TextLinkModalWindowContainer>
    </TextLinkModalWindowWrapper>
  );
};

export default memo(TextLinkModalWindow);
