import React, { createContext, useContext, useState } from "react";

const TextSettingsBibleContext = createContext();

export const useTextSettingsBibleState = () => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [editedVersesData, setEditedVersesData] = useState({});

  return {
    openBibleModalWindow,
    setOpenBibleModalWindow,
    editedVersesData,
    setEditedVersesData,
  };
};

export const TextSettingsBibleProvider = ({ children }) => {
  const {
    openBibleModalWindow,
    setOpenBibleModalWindow,
    editedVersesData,
    setEditedVersesData,
  } = useTextSettingsBibleState();

  return (
    <TextSettingsBibleContext.Provider
      value={{
        openBibleModalWindow,
        setOpenBibleModalWindow,
        editedVersesData,
        setEditedVersesData,
      }}
    >
      {children}
    </TextSettingsBibleContext.Provider>
  );
};

export const useTextSettingsBibleContext = () => {
  return useContext(TextSettingsBibleContext);
};
