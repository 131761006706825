import React from "react";
import styled from "styled-components";
import { Avatar } from "../../../../avatar_styles";
import GroupChatIcon from "../../../../../../../common/assets/icons/group_chat_icon";
import { useAppContext } from "../../../../../../../app_context";
import { useChatContentContext } from "../../../chat_content_context";

const ChatItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 50px;
  padding: 0 11px;
  cursor: pointer;

  &:hover {
    background: rgb(235 235 235);
    transition: 0.2s;
  }
`;

const ChatTitleContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 3px;
`;

const ChatItemTitle = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #383838;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ForwardMessageToChatItem = ({ chat, onClose }) => {
  const { setSelectedChatId, setChatIdForForwardMessage } = useAppContext();
  const { setReplyMessage } = useChatContentContext();

  const onForwardMessage = () => {
    setSelectedChatId(chat.id);
    setChatIdForForwardMessage(chat.id);
    setReplyMessage({});
    onClose(false);
  };

  const isGroupChat = chat.type === "public";

  return (
    <ChatItemContainer onClick={onForwardMessage}>
      <Avatar url={chat.avatarUrl} size={"40px"} />
      <ChatTitleContainer>
        {isGroupChat && <GroupChatIcon />}
        <ChatItemTitle>{chat.title}</ChatItemTitle>
      </ChatTitleContainer>
    </ChatItemContainer>
  );
};

export default ForwardMessageToChatItem;
