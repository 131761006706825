import React from "react";
import styled from "styled-components";

const AuthorsMessageContainer = styled.div`
  margin: 40px auto 0;
  padding: 26px 40px 20px 48px;
  width: 508px;
  background: #ffffff;
  border-radius: 34px;

  @media (max-width: 528px) {
    width: 380px;
    padding: 16px 25px 15px 18px;
    border-radius: 22px;
    margin: 45px auto 0;
  }

  @media (max-width: 412px) {
    width: 330px;
  }
`;

const AuthorsMessageText = styled.div`
  width: 390px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #383838;

  @media (max-width: 528px) {
    width: 100%;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 140%;
  }

  @media (max-width: 412px) {
    font-size: 14px;
  }
`;

const AuthorsMessageInitials = styled.div`
  text-align: end;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #383838;

  @media (max-width: 412px) {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 140%;
  }
`;

const GroupPageAuthorMessage = () => {
  return (
    <AuthorsMessageContainer>
      <AuthorsMessageText>
        “Lorem ipsum dolor sit amet consectetur. Turpis nunc id sed vitae
        ridiculus eleifend sagittis egestas ultrices.”
      </AuthorsMessageText>
      <AuthorsMessageInitials>A.Б.</AuthorsMessageInitials>
    </AuthorsMessageContainer>
  );
};

export default GroupPageAuthorMessage;
