import React, { memo } from "react";
import styled from "styled-components";
import PlatformAdmin from "./platform_admin";
import SupportOperator from "./support_operator";
import CoursesAdmin from "./courses_admin";
import GroupsAdmin from "./groups_admin";

const UserAdministrationWrapper = styled.div`
  color: #828282;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
`;

const UserAdministrationContainer = styled.div`
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const UserAdministration = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setOpenAddingCourseModal,
  setUsersList,
}) => {
  return (
    <UserAdministrationWrapper>
      Администрирование
      <UserAdministrationContainer>
        <PlatformAdmin
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setUsersList={setUsersList}
        />
        <CoursesAdmin
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setOpenAddingCourseModal={setOpenAddingCourseModal}
          setUsersList={setUsersList}
        />
        <GroupsAdmin
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setUsersList={setUsersList}
        />
        <SupportOperator
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setUsersList={setUsersList}
        />
      </UserAdministrationContainer>
    </UserAdministrationWrapper>
  );
};

export default memo(UserAdministration);
