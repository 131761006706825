import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ModalWindowMainButtons from "../modal_window_main_buttons";
import SendWarningToUser from "./send_warning_to_user";
import SelectSanctionPeriod from "./select_sanction_period";

const SanctionUserModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const SanctionUserModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  gap: 33px;
  padding: 23px 19px;
  border-radius: 15px;
  background: #fff;
`;

const SanctionUserModalWindow = ({
  setOpen,
  sanctionType,
  warningCause,
  setWarningCause,
  userRolesSettingsData,
  setUserRolesSettingsData,
  userRolesSettingsMemoData,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(7);
  const [sendWarning, setSendWarning] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false);
  const [showSendWarningBlock, setShowSendWarningBlock] = useState(true);

  const currentDate = new Date().toISOString();

  useEffect(() => {
    if (sendWarning && !warningCause) {
      setDisabledConfirmButton(true);
    } else setDisabledConfirmButton(false);
  }, [sendWarning, warningCause]);

  const clearBlockSettings = useCallback(() => {
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_permissions: {
        ...prevState.user_permissions,
        [sanctionType]: {
          isEnabled: true,
          disabled_start_date: "",
          disabled_days: "",
        },
      },
    }));
    setWarningCause("");
  }, [sanctionType, userRolesSettingsMemoData]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    clearBlockSettings();
    setOpen(false);
  }, []);

  useEffect(() => {
    if (
      userRolesSettingsMemoData.user_permissions.amount_of_warnings !==
      userRolesSettingsData.user_permissions.amount_of_warnings
    ) {
      setShowSendWarningBlock(false);
    } else {
      setShowSendWarningBlock(true);
    }
  }, [userRolesSettingsData, userRolesSettingsMemoData]);

  const onClickConfirmButton = useCallback(() => {
    if ((sendWarning && warningCause) || !sendWarning) {
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_permissions: {
          ...prevState.user_permissions,
          [sanctionType]: {
            isEnabled: false,
            disabled_start_date: currentDate,
            disabled_days: selectedPeriod,
          },
        },
      }));

      if (sendWarning) {
        setUserRolesSettingsData((prevState) => ({
          ...prevState,
          user_permissions: {
            ...prevState.user_permissions,
            amount_of_warnings:
              userRolesSettingsMemoData.user_permissions.amount_of_warnings + 1,
          },
        }));
      }

      setOpen("");
    } else {
      setEmptyInput(true);
      setTimeout(() => setEmptyInput(false), 1500);
    }
  }, [
    sendWarning,
    warningCause,
    selectedPeriod,
    sanctionType,
    currentDate,
    userRolesSettingsMemoData,
  ]);

  return (
    <SanctionUserModalWindowWrapper onClick={handleClose}>
      <SanctionUserModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <SelectSanctionPeriod
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          sanctionType={sanctionType}
        />

        {showSendWarningBlock && (
          <SendWarningToUser
            sendWarning={sendWarning}
            warningCause={warningCause}
            setSendWarning={setSendWarning}
            setWarningCause={setWarningCause}
            emptyInput={emptyInput}
          />
        )}

        <ModalWindowMainButtons
          handleClose={handleClose}
          isDisableConfirmButton={disabledConfirmButton}
          onClickConfirmButton={onClickConfirmButton}
        />
      </SanctionUserModalWindowContainer>
    </SanctionUserModalWindowWrapper>
  );
};

export default memo(SanctionUserModalWindow);
