import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../../axios_config";
import { v4 as uuidv4 } from "uuid";
import CreateLessonHeader from "./create_lesson_header";
import CreateLessonSidebar from "./create_lesson_sidebar";
import CreateLessonSettingsBlock from "./create_lesson_settings_block";
import CreateLessonPreview from "./create_lesson_preview_block";
import {
  BibleIcon,
  CheckIcon,
  QuestionIcon,
  LightIcon,
  PictureIcon,
  ButtonIcon,
  TextIcon,
  YoutubeIcon,
  DialogIcon,
} from "../../../common/assets/icons/create_lesson_icons/create_lesson_sidebar_icons";
import {
  TextSettings,
  BibleVersesSettings,
  NoteAndQuestionSettings,
  QuestionSettings,
  ImageSettings,
  VideoSettings,
  LinkSettings,
  TestSettings,
  DialogueSettings,
} from "./create_lesson_settings_block/settings_block_renderers";
import {
  TextLessonComponent,
  BibleLessonComponent,
  NoteLessonComponent,
  QuestionLessonComponent,
  ImageLessonComponent,
  VideoLessonComponent,
  DialogueLessonComponent,
  LinkLessonComponent,
  TestLessonComponent,
} from "./lesson_components";
import { BASE_API_URL } from "../../../common/endpoints";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import onBibleChatAvatar from "../../../common/assets/images/on_bible_chat_avatar.png";

export const onBibleDialogueBotData = {
  _id: "on_bible",
  user_name: "OnBible",
  user_second_name: "",
  user_image: onBibleChatAvatar,
};

export const LESSON_COMPONENTS_MAPPING = {
  TEXT: {
    type: "TEXT",
    icon: TextIcon,
    component: (props) => <TextLessonComponent {...props} />,
    settingsComponent: (props) => <TextSettings {...props} />,
  },
  BIBLE_VERSES: {
    type: "BIBLE_VERSES",
    icon: BibleIcon,
    component: (props) => <BibleLessonComponent {...props} />,
    settingsComponent: (props) => <BibleVersesSettings {...props} />,
  },
  NOTE_AND_QUESTION: {
    type: "NOTE_AND_QUESTION",
    icon: LightIcon,
    component: (props) => <NoteLessonComponent {...props} />,
    settingsComponent: (props) => <NoteAndQuestionSettings {...props} />,
  },
  QUESTION: {
    type: "QUESTION",
    icon: QuestionIcon,
    component: (props) => <QuestionLessonComponent {...props} />,
    settingsComponent: (props) => <QuestionSettings {...props} />,
  },
  IMAGE: {
    type: "IMAGE",
    icon: PictureIcon,
    component: (props) => <ImageLessonComponent {...props} />,
    settingsComponent: (props) => <ImageSettings {...props} />,
  },
  VIDEO: {
    type: "VIDEO",
    icon: YoutubeIcon,
    component: (props) => <VideoLessonComponent {...props} />,
    settingsComponent: (props) => <VideoSettings {...props} />,
  },
  DIALOGUE: {
    type: "DIALOGUE",
    icon: DialogIcon,
    component: (props) => <DialogueLessonComponent {...props} />,
    settingsComponent: (props) => <DialogueSettings {...props} />,
  },
  LINK: {
    type: "LINK",
    icon: ButtonIcon,
    component: (props) => <LinkLessonComponent {...props} />,
    settingsComponent: (props) => <LinkSettings {...props} />,
  },
  TEST: {
    type: "TEST",
    icon: CheckIcon,
    component: (props) => <TestLessonComponent {...props} />,
    settingsComponent: (props) => <TestSettings {...props} />,
  },
};

export const componentsIconsArray = () => {
  const lessonCotentMapInArray = Object.values(LESSON_COMPONENTS_MAPPING);

  return lessonCotentMapInArray.map((item) => {
    return {
      icon: item.icon,
      type: item.type,
    };
  });
};

export const createComponentId = () => {
  return uuidv4().slice(0, 23);
};

const CreateLessonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 40px;
  overflow: hidden;
  height: 88vh;
`;

const CreateLessonBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: auto;
  max-height: max-content;

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
`;

const CreateLesson = () => {
  const { id } = useParams();
  const createLessonBodyRef = useRef(null);
  const [lessonData, setLessonData] = useState({});
  const [activeSettingsBlock, setActiveSettingsBlock] = useState("");
  const [settingsBlockData, setSettingsBlockData] = useState({});
  const [settingsBlockMemoData, setSettingsBlockMemoData] = useState({});
  const [insertBlockIndex, setInsertBlockIndex] = useState(null);
  const [editBlockIndex, setEditBlockIndex] = useState(null);
  const [openEditeComponentModal, setOpenEditeComponentModal] = useState(false);

  const fetchLessonData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/lessons/${id}`);
      setLessonData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id]);

  const resetAllActiveBlocks = useCallback(() => {
    setEditBlockIndex(null);
    setInsertBlockIndex(null);
    setSettingsBlockData({});
    setSettingsBlockMemoData({});
    setActiveSettingsBlock("");
    setOpenEditeComponentModal(false);
    fetchLessonData();
  }, []);

  const getResetCondition = useCallback(() => {
    switch (activeSettingsBlock) {
      case "TEXT":
        return (
          compareObjects(
            settingsBlockData.contentState?.blocks,
            settingsBlockMemoData.contentState?.blocks
          ) ||
          (!settingsBlockData.contentState?.blocks?.[0]?.text &&
            !settingsBlockMemoData.contentState?.blocks?.[0]?.text)
        );
      case "DIALOGUE":
        return (
          compareObjects(
            settingsBlockData.data?.first?.text?.blocks,
            settingsBlockMemoData.data?.first?.text?.blocks
          ) ||
          (!settingsBlockData.data?.first?.text?.blocks?.[0]?.text &&
            !settingsBlockMemoData.data?.first?.text?.blocks?.[0]?.text)
        );
      default:
        return compareObjects(settingsBlockMemoData, settingsBlockData);
    }
  }, [activeSettingsBlock, settingsBlockData, settingsBlockMemoData]);

  const onCloseActiveSettings = useCallback(() => {
    const shouldResetAllBlocks = getResetCondition();

    if (shouldResetAllBlocks) {
      resetAllActiveBlocks();
    } else {
      setOpenEditeComponentModal(true);
    }
  }, [settingsBlockData, settingsBlockMemoData, activeSettingsBlock]);

  const handleActiveSettingsBlock = useCallback(
    (event, blockType) => {
      event.stopPropagation();

      const isTextBlockEqual = () => {
        const currentBlocks = settingsBlockData.data?.first?.text?.blocks;
        const memoBlocks = settingsBlockMemoData.data?.first?.text?.blocks;
        return (
          compareObjects(currentBlocks, memoBlocks) ||
          (!currentBlocks?.[0]?.text && !memoBlocks?.[0]?.text)
        );
      };

      const isDialogueBlockEqual = () => {
        const currentBlocks = settingsBlockData?.contentState?.blocks;
        const memoBlocks = settingsBlockMemoData?.contentState?.blocks;
        return (
          (!currentBlocks?.[0]?.text && !memoBlocks?.[0]?.text) ||
          compareObjects(currentBlocks, memoBlocks)
        );
      };

      const isDefaultBlockEqual = () =>
        compareObjects(settingsBlockMemoData, settingsBlockData);

      const shouldOpenModal = () => {
        if (activeSettingsBlock === "TEXT") return !isTextBlockEqual();
        if (activeSettingsBlock === "DIALOGUE") return !isDialogueBlockEqual();
        return !isDefaultBlockEqual();
      };

      if (shouldOpenModal()) {
        setOpenEditeComponentModal(true);
      } else {
        setActiveSettingsBlock(blockType);
        setEditBlockIndex(null);
        setInsertBlockIndex(null);
      }
    },
    [settingsBlockData, settingsBlockMemoData, activeSettingsBlock]
  );

  return (
    <div onMouseDown={onCloseActiveSettings}>
      <CreateLessonHeader lessonData={lessonData} />

      <CreateLessonContainer>
        <CreateLessonSidebar
          handleActiveSettingsBlock={handleActiveSettingsBlock}
          createLessonBodyRef={createLessonBodyRef}
        />

        <CreateLessonBody ref={createLessonBodyRef}>
          <CreateLessonPreview
            lessonData={lessonData}
            settingsBlockData={settingsBlockData}
            settingsBlockMemoData={settingsBlockMemoData}
            activeSettingsBlock={activeSettingsBlock}
            insertBlockIndex={insertBlockIndex}
            editBlockIndex={editBlockIndex}
            setInsertBlockIndex={setInsertBlockIndex}
            setSettingsBlockData={setSettingsBlockData}
            setSettingsBlockMemoData={setSettingsBlockMemoData}
            setEditBlockIndex={setEditBlockIndex}
            setActiveSettingsBlock={setActiveSettingsBlock}
            setOpenEditeComponentModal={setOpenEditeComponentModal}
            resetAllActiveBlocks={resetAllActiveBlocks}
            fetchLessonData={fetchLessonData}
          />

          <CreateLessonSettingsBlock
            lessonData={lessonData}
            settingsBlockData={settingsBlockData}
            settingsBlockMemoData={settingsBlockMemoData}
            editBlockIndex={editBlockIndex}
            activeSettingsBlock={activeSettingsBlock}
            insertBlockIndex={insertBlockIndex}
            openEditeComponentModal={openEditeComponentModal}
            setSettingsBlockData={setSettingsBlockData}
            setSettingsBlockMemoData={setSettingsBlockMemoData}
            setActiveSettingsBlock={setActiveSettingsBlock}
            setOpenEditeComponentModal={setOpenEditeComponentModal}
            resetAllActiveBlocks={resetAllActiveBlocks}
          />
        </CreateLessonBody>
      </CreateLessonContainer>
    </div>
  );
};

export default memo(CreateLesson);
