import React, { memo, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LayoutComponet from "../../common/components/layout_componet";
import StudyPageMainButtons from "./study_page_components/study_page_main_buttons";
import { APPLICATION_ROUTES } from "../../common/utils/routes";

const StudyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === APPLICATION_ROUTES.STUDY) {
      navigate(APPLICATION_ROUTES.STUDY_COURSES, { replace: true });
    }
  }, []);

  return (
    <LayoutComponet>
      <StudyPageMainButtons />

      <Outlet />
    </LayoutComponet>
  );
};

export default memo(StudyPage);
