import React from "react";

const CloseMessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
    >
      <g clipPath="url(#clip0_7779_10253)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.29949 2.29939C2.39707 2.2018 2.55529 2.2018 2.65287 2.29939L5.00873 4.65524C5.10631 4.75282 5.10631 4.91104 5.00873 5.00862C4.91114 5.1062 4.75293 5.1062 4.65535 5.00862L2.29949 2.65276C2.20191 2.55518 2.20191 2.39697 2.29949 2.29939Z"
          fill="#5172EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.29949 5.00872C2.20191 4.91114 2.20191 4.75292 2.29949 4.65534L4.65535 2.29949C4.75293 2.2019 4.91114 2.2019 5.00873 2.29949C5.10631 2.39707 5.10631 2.55528 5.00873 2.65286L2.65287 5.00872C2.55529 5.1063 2.39707 5.1063 2.29949 5.00872Z"
          fill="#5172EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_7779_10253">
          <rect
            width="3.99802"
            height="3.99802"
            fill="white"
            transform="translate(0.826904 3.65405) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseMessageIcon;
