import React from "react";
import styled from "styled-components";
import { Avatar } from "../avatar_styles";
import GroupChatIcon from "../../../../common/assets/icons/group_chat_icon";
import { YOUR_MESSAGE_STATUS_RENDERERS } from "../../chats_page";

const ChatsListItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #cacaca;

  ${({ selected }) => selected && "background: #fff;"}
`;

const AvatarAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 250px;

  @media (max-width: 790px) {
    width: 220px;
  }

  @media (max-width: 744px) {
    width: 330px;
  }

  @media (max-width: 425px) {
    width: 320px;
  }

  @media (max-width: 400px) {
    width: 305px;
  }

  @media (max-width: 380px) {
    width: 285px;
  }
`;

const ChatTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const ChatItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #383838;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChatTitleAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin-left: 8px;
  width: 183px;

  @media (max-width: 790px) {
    width: 171px;
  }

  @media (max-width: 744px) {
    flex: 1;
  }
`;

const ChatItemMessageContainer = styled.div`
  display: -webkit-box;
  max-width: 100%;
  max-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
`;

const TimeAndUnreadAndStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1px;
`;

const ChatItemTime = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`;

const ChatItemUnreadNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 18px;
  height: 16px;
  padding: 0 5px;
  background: linear-gradient(180deg, #05baa4 0%, #288bce 100%);
  border-radius: 15px;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
`;

const ChatsListItem = ({ chat, selectedChatId, setChatId }) => {
  const isGroupChat = chat.type === "public";

  const YourMessageStatus =
    chat.yourMessageStatus &&
    YOUR_MESSAGE_STATUS_RENDERERS[chat.yourMessageStatus];

  return (
    <ChatsListItemContainer
      onClick={setChatId(chat.id)}
      selected={chat.id === selectedChatId}
    >
      <AvatarAndTextContainer>
        <Avatar url={chat.avatarUrl} size={"40px"} />

        <ChatTitleAndTextContainer>
          <ChatTitleContainer>
            {isGroupChat && <GroupChatIcon />}
            <ChatItemTitle>{chat.title}</ChatItemTitle>
          </ChatTitleContainer>
          <ChatItemMessageContainer>
            {!!chat.sender && chat.type === "public" && <b>{chat.sender}:</b>}{" "}
            {chat.last_message}
          </ChatItemMessageContainer>
        </ChatTitleAndTextContainer>
      </AvatarAndTextContainer>

      <TimeAndUnreadAndStatusContainer>
        <ChatItemTime>{chat.time}</ChatItemTime>
        {!!chat.unread && (
          <ChatItemUnreadNumber>{chat.unread}</ChatItemUnreadNumber>
        )}
        {YourMessageStatus && <YourMessageStatus />}
      </TimeAndUnreadAndStatusContainer>
    </ChatsListItemContainer>
  );
};

export default ChatsListItem;
