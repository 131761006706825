import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { DownArrow } from "../../../../../../common/assets/icons/chat_page_icons/message_context_menu_icons";
import SearchIcon from "../../../../../../common/assets/icons/search_icon";

const FAVORITE_EMOJI_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const EMOJI_PACK_ARRAY = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 1, 2, 3, 4, 5, 6, 7, 8,
  9, 10, 11, 12, 13, 14, 15, 16, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
  15, 16,
];

const POSITIONS = {
  TOP: "TOP",
  MEDIUM: "MEDIUM",
  BOTTOM: "BOTTOM",
};

const EmojiBarWrapper = styled.div`
  position: absolute;
  ${({ emojiPosition, isOpen }) =>
    emojiPosition === POSITIONS.TOP
      ? "top: 0"
      : emojiPosition === POSITIONS.BOTTOM
      ? "bottom: 0"
      : isOpen
      ? "top: -100px"
      : "top: 0"};
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "370px" : "48px")};
  padding: ${({ isOpen }) => (isOpen ? "18px 0" : "12px 0")};
  border-radius: 25px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 10px;
  overflow: hidden;
  transition: 0.3s;
`;

const EmojiBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  overflow: ${({ isOpen }) => (isOpen ? "auto" : "none")};
`;

const FavoriteEmojiAndArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding-left: 18px;
`;

const EmojiPackContainer = styled(FavoriteEmojiAndArrowContainer)`
  flex-direction: column;
  gap: 8px;
`;

const EmojiPackTitle = styled.div`
  font-size: 10px;
  line-height: 140%;
  color: #828282;
  letter-spacing: -0.1px;
`;

const EmojiItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const EmojiItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    transition: 0.4s;
  }
`;

const DownArrowContainer = styled.div`
  cursor: pointer;
  display: ${({ isHide }) => (isHide ? "none" : "block")};
  margin-right: 18px;

  &:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const SearchEmojiInputContainer = styled.div`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  align-items: center;
  gap: 10px;
  padding: 0 9px;
  margin: 0 14px;
  min-height: 34px;
  max-height: 34px;
  flex: 1;
  border-radius: 8px;
  background: #eaeaea;
`;

const SearchEmojiInput = styled.input`
  flex: 1;
  color: #1e1e1e;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.1px;
  background: #eaeaea;
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 16px;
  height: 16px;
`;

const EmojiBar = () => {
  const [isOpenEmojiBlock, setIsOpenEmojiBlock] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [emojiPosition, setEmojiPosition] = useState();

  const handleOpenEmojiBlock = useCallback((e) => {
    setIsOpenEmojiBlock(true);
    if (e.clientY + 370 > window.innerHeight) {
      setEmojiPosition(POSITIONS.BOTTOM);
      return;
    }
    if (e.clientY > 130) {
      setEmojiPosition(POSITIONS.MEDIUM);
      return;
    }
    setEmojiPosition(POSITIONS.TOP);
  }, []);

  const handleSearchInputValue = useCallback((e) => {
    setSearchInputValue(e.target.value);
  }, []);

  const favoriteEmojiArray = isOpenEmojiBlock
    ? FAVORITE_EMOJI_ARRAY
    : FAVORITE_EMOJI_ARRAY.slice(0, 5);

  return (
    <EmojiBarWrapper isOpen={isOpenEmojiBlock} emojiPosition={emojiPosition}>
      <EmojiBarContainer isOpen={isOpenEmojiBlock}>
        <SearchEmojiInputContainer isShow={isOpenEmojiBlock}>
          <SearchIconStyled />
          <SearchEmojiInput
            placeholder="Поиск"
            value={searchInputValue}
            onChange={handleSearchInputValue}
          />
        </SearchEmojiInputContainer>

        {!searchInputValue && (
          <FavoriteEmojiAndArrowContainer>
            <EmojiItemsContainer>
              {favoriteEmojiArray.map((_, index) => (
                <EmojiItem key={index}>&#x1F60A;</EmojiItem>
              ))}
            </EmojiItemsContainer>

            <DownArrowContainer
              onClick={handleOpenEmojiBlock}
              isHide={isOpenEmojiBlock}
            >
              <DownArrow />
            </DownArrowContainer>
          </FavoriteEmojiAndArrowContainer>
        )}

        <EmojiPackContainer>
          {!searchInputValue && <EmojiPackTitle>Эмоджи пак 1</EmojiPackTitle>}
          <EmojiItemsContainer>
            {EMOJI_PACK_ARRAY.map((_, index) => (
              <EmojiItem key={index}>&#x1F63A;</EmojiItem>
            ))}
          </EmojiItemsContainer>
        </EmojiPackContainer>
      </EmojiBarContainer>
    </EmojiBarWrapper>
  );
};

export default EmojiBar;
