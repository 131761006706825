import React, { useCallback } from "react";
import Checkbox from "../../../../../common/components/checkbox";
import { AdminPageSettingsHeader } from "../../../admin_page_components/admin_page_styled_components";

const SupportOperator = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
}) => {
  const userIsSupportOperator =
    userRolesSettingsData.user_access.is_operator_of_support;

  const handleUserIsSupportOperator = useCallback(
    (event) => {
      event.stopPropagation();
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          is_operator_of_support: !prevState.user_access.is_operator_of_support,
        },
      }));

      setUsersList((prevState) =>
        prevState.map((user) =>
          user._id === userRolesSettingsData._id || user._id === "new_invite"
            ? {
                ...user,
                user_access: {
                  ...user.user_access,
                  is_operator_of_support:
                    !user.user_access.is_operator_of_support,
                },
              }
            : user
        )
      );
    },
    [userRolesSettingsData]
  );

  return (
    <AdminPageSettingsHeader>
      <Checkbox
        checked={userIsSupportOperator}
        onClickCheckbox={handleUserIsSupportOperator}
        color={"#1e1e1e"}
        fontSize={"14px"}
      >
        Оператор поддержки
      </Checkbox>
    </AdminPageSettingsHeader>
  );
};

export default SupportOperator;
