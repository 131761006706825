import React from "react";
import styled from "styled-components";
import BibleSearchPanel from "../bible_search_panel";
import FontSettingsBlock from "./font_settings_block";
import { FontSettingsIcon } from "../../../../common/assets/icons";

const BibleSearchAndFontSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const SearchAndFontSettingsButtonBlock = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;

const FontSettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 33px;
  height: 30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const BibleSearchAndFontSettings = ({
  activeMainSection,
  bibleFontSettings,
  bibleSearchInput,
  expandedSectionName,
  bibleSearchPlace,
  setBibleFontSettings,
  setActiveMainSection,
  setBibleSearchResults,
  setBibleSearchInput,
  setExpandedSectionName,
  setIsLoading,
  toggleOpenFontSettings,
}) => {
  return (
    <BibleSearchAndFontSettingsContainer>
      <SearchAndFontSettingsButtonBlock>
        <BibleSearchPanel
          activeMainSection={activeMainSection}
          bibleSearchInput={bibleSearchInput}
          bibleSearchPlace={bibleSearchPlace}
          setActiveMainSection={setActiveMainSection}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setExpandedSectionName={setExpandedSectionName}
          setIsLoading={setIsLoading}
        />

        {activeMainSection !== "bible_search" && (
          <FontSettingsButton onClick={toggleOpenFontSettings}>
            <FontSettingsIcon />
          </FontSettingsButton>
        )}
      </SearchAndFontSettingsButtonBlock>

      {expandedSectionName === "FONT_SETTINGS" && (
        <FontSettingsBlock
          bibleFontSettings={bibleFontSettings}
          setBibleFontSettings={setBibleFontSettings}
        />
      )}
    </BibleSearchAndFontSettingsContainer>
  );
};

export default BibleSearchAndFontSettings;
