import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";
import TextStyleButtons from "./text_editor_toolbar_buttons/text_style_buttons";
import TextBackgroundButtons from "./text_editor_toolbar_buttons/text_background_buttons";
import TextSizeButtons from "./text_editor_toolbar_buttons/text_size_buttons";
import TextListButtons from "./text_editor_toolbar_buttons/text_list_buttons";
import TextLinkButton from "./text_editor_toolbar_buttons/text_link_button";
import TextBibleButton from "./text_editor_toolbar_buttons/text_bible_button";
import BibleVerseItem from "./bible_verse_item";
import BibleModalWindow from "../../../common/components/modal_windows/bible_modal_window";
import {
  TextSettingsBibleProvider,
  useTextSettingsBibleContext,
} from "./text_editor_bible_context";

const TextEditorWrapper = styled.div`
  background: #fff;
  border-radius: 14px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
  border-bottom: 1px solid #cacaca;
`;

const StyledEditor = styled.div`
  position: relative;
  padding: 10px;
  cursor: text;

  .DraftEditor-root {
  }

  .public-DraftEditor-content {
    min-height: 48px;
    ${({ inComponent }) =>
      inComponent === "text_component"
        ? "min-height: 200px;"
        : inComponent === "course_settings"
        ? "max-height: 330px;"
        : "max-height: 160px;"}
    overflow: auto;
    font-size: 16px;
    line-height: 24px;

    ::-webkit-scrollbar {
      width: 3px;
    }
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 11px;
  left: 15px;
  color: #aaa;
  pointer-events: none;
  font-size: 16px;
  line-height: 24px;
  z-index: 2;
`;

export const styleMap = {
  NOTE: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  H1: {
    fontSize: "22px",
    lineHeight: "26px",
  },
  H2: {
    fontSize: "34px",
    lineHeight: "40px",
    letterSpacing: "-0.4px",
  },
  PERIWINKLE: {
    background: "#98AEFD",
    display: "inline-block",
    borderRadius: "6px",
  },
  LIGHT_BLUE: {
    background: "#46D7EB",
    display: "inline-block",
    borderRadius: "6px",
  },
  ORCHID: {
    background: "#EF83E4",
    display: "inline-block",
    borderRadius: "6px",
  },
  DEFAULT_SIZE: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  LINK: {
    color: "rgb(33 58 192)",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
  },
  BIBLE_VERSE: {
    border: "1px solid #2440D2",
    color: "#2440D2",
    fontSize: "16px",
    borderRadius: "4px",
    padding: "1px 7px",
    background: "#fff",
    userSelect: "none",
    cursor: "pointer",
  },
};

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styleMap.LINK}>
      {props.children}
    </a>
  );
};

const findEntityEntities =
  (entityType) => (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === entityType
      );
    }, callback);
  };

const decorator = new CompositeDecorator([
  {
    strategy: findEntityEntities("LINK"),
    component: Link,
  },
  {
    strategy: findEntityEntities("BIBLE_VERSE"),
    component: (props) => <BibleVerseItem {...props} />,
  },
]);

const textEditorHOC = (WrappedComponent) => (props) => {
  return (
    <TextSettingsBibleProvider>
      <WrappedComponent {...props} />
    </TextSettingsBibleProvider>
  );
};

const TextEditor = ({
  contentStateData,
  onEditorChange,
  placeholder = "",
  inComponent = "",
}) => {
  const {
    openBibleModalWindow,
    setOpenBibleModalWindow,
    editedVersesData,
    setEditedVersesData,
  } = useTextSettingsBibleContext();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(decorator)
  );
  const [textLinkModal, setTextLinkModal] = useState(false);
  const [currentLink, setCurrentLink] = useState(null);
  const [openTextSettings, setOpenTextSettings] = useState(""); // text_size, text_style, text_list, text_background
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const currentStyle = editorState.getCurrentInlineStyle();

  const closeSettingsButtons = useCallback(() => {
    setOpenTextSettings("");
  }, [setOpenTextSettings]);

  useEffect(() => {
    const content = editorState.getCurrentContent().getPlainText();
    setShowPlaceholder(content.length === 0);
  }, [editorState]);

  useEffect(() => {
    if (contentStateData?.blocks) {
      try {
        const contentState = convertFromRaw(contentStateData);
        setEditorState(EditorState.createWithContent(contentState, decorator));
      } catch (error) {
        console.error("Invalid contentStateData format", error);
      }
    }
  }, []);

  useEffect(() => {
    const contentState = editorState.getCurrentContent();

    if (contentState) {
      const updatedContentState = convertToRaw(contentState);
      const hasChanges =
        JSON.stringify(contentStateData) !==
        JSON.stringify(updatedContentState);

      if (hasChanges) {
        onEditorChange(updatedContentState);
      }
    }
  }, [editorState]);

  const toggleInlineStyle = (style) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    let newEditorState = editorState;

    const colorStyles = ["PERIWINKLE", "LIGHT_BLUE", "ORCHID"];
    const textStyles = ["NOTE", "H1", "H2"];

    if (colorStyles.includes(style)) {
      colorStyles.forEach((color) => {
        if (color !== style && currentStyle.has(color)) {
          newEditorState = RichUtils.toggleInlineStyle(newEditorState, color);
        }
      });
    }

    if (textStyles.includes(style)) {
      textStyles.forEach((item) => {
        if (item !== style && currentStyle.has(item)) {
          newEditorState = RichUtils.toggleInlineStyle(newEditorState, item);
        }
      });
    }

    newEditorState = RichUtils.toggleInlineStyle(newEditorState, style);
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleAddBibleVerses = (addedVerse) => {
    const contentState = editorState.getCurrentContent();
    let newEditorState = editorState;

    let updatedContentState;
    if (editedVersesData.id) {
      const entityKey = editedVersesData.id;
      updatedContentState = contentState.replaceEntityData(
        entityKey,
        addedVerse
      );
    } else {
      const contentStateWithEntity = contentState.createEntity(
        "BIBLE_VERSE",
        "IMMUTABLE",
        addedVerse
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const selectionState = editorState.getSelection();

      updatedContentState = Modifier.insertText(
        contentStateWithEntity,
        selectionState,
        " ",
        null,
        entityKey
      );

      const updatedSelection = updatedContentState.getSelectionAfter();
      updatedContentState = Modifier.insertText(
        updatedContentState,
        updatedSelection,
        " "
      );
    }

    newEditorState = EditorState.push(
      newEditorState,
      updatedContentState,
      "insert-characters"
    );

    const newStateWithSelection = EditorState.forceSelection(
      newEditorState,
      updatedContentState.getSelectionAfter()
    );

    newEditorState = newStateWithSelection;

    setEditorState(newEditorState);

    const updatedContentRaw = convertToRaw(updatedContentState);

    onEditorChange(updatedContentRaw);

    setOpenBibleModalWindow(false);
    setEditedVersesData({});
  };

  return (
    <TextEditorWrapper>
      <Toolbar onClick={closeSettingsButtons}>
        <TextSizeButtons
          editorState={editorState}
          currentStyle={currentStyle}
          setEditorState={setEditorState}
          toggleInlineStyle={toggleInlineStyle}
          openTextSettings={openTextSettings}
          setOpenTextSettings={setOpenTextSettings}
        />
        <TextStyleButtons
          currentStyle={currentStyle}
          toggleInlineStyle={toggleInlineStyle}
          openTextSettings={openTextSettings}
          setOpenTextSettings={setOpenTextSettings}
        />
        <TextListButtons
          editorState={editorState}
          setEditorState={setEditorState}
          openTextSettings={openTextSettings}
          setOpenTextSettings={setOpenTextSettings}
        />
        <TextBackgroundButtons
          currentStyle={currentStyle}
          toggleInlineStyle={toggleInlineStyle}
          openTextSettings={openTextSettings}
          setOpenTextSettings={setOpenTextSettings}
        />
        <TextLinkButton
          textLinkModal={textLinkModal}
          editorState={editorState}
          currentLink={currentLink}
          setEditorState={setEditorState}
          setCurrentLink={setCurrentLink}
          setTextLinkModal={setTextLinkModal}
        />
        <TextBibleButton setOpenBibleModalWindow={setOpenBibleModalWindow} />
      </Toolbar>

      <StyledEditor onClick={closeSettingsButtons} inComponent={inComponent}>
        <Editor
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
        />
        {showPlaceholder && placeholder && (
          <Placeholder>{placeholder}</Placeholder>
        )}
      </StyledEditor>

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            handleAddBibleVerses={handleAddBibleVerses}
            editedVersesData={editedVersesData}
            setEditedVersesData={setEditedVersesData}
            inComponent={"text_editor"}
          />,
          document.body
        )}
    </TextEditorWrapper>
  );
};

export default textEditorHOC(TextEditor);
