import React from "react";

const HeaderHomeIcon = ({ className }) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_998_957)">
      <path d="M11.7012 0.318666C11.9214 0.11338 12.2056 0 12.5 0C12.7944 0 13.0786 0.11338 13.2988 0.318666L24.1256 10.4243C24.3519 10.6501 24.4858 10.9594 24.4989 11.2869C24.5121 11.6144 24.4035 11.9344 24.1961 12.1794C23.9888 12.4244 23.6988 12.5751 23.3875 12.5998C23.0763 12.6244 22.768 12.5211 22.528 12.3115L22.1238 11.9364V21.4736C22.1238 22.1436 21.8703 22.7862 21.4191 23.26C20.9679 23.7338 20.3559 24 19.7179 24H5.28215C4.64405 24 4.03209 23.7338 3.58089 23.26C3.12968 22.7862 2.8762 22.1436 2.8762 21.4736V11.9364L2.472 12.3115C2.23196 12.5211 1.92374 12.6244 1.61247 12.5998C1.3012 12.5751 1.01125 12.4244 0.803861 12.1794C0.596476 11.9344 0.487901 11.6144 0.501072 11.2869C0.514244 10.9594 0.64813 10.6501 0.874448 10.4243L11.7012 0.318666ZM5.28215 9.68787V21.4736H8.89107C8.89107 21.4736 15 21.4736 16.1089 21.4736C17.2179 21.4736 19.7179 21.4736 19.7179 21.4736V9.68913L12.5 2.95245L5.28215 9.68787Z" />
    </g>
    <defs>
      <clipPath id="clip0_998_957">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default HeaderHomeIcon;
