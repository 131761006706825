import React, { memo, useCallback } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import CreateLessonArrowIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_arrow_icon";
import { BASE_API_URL } from "../../../../common/endpoints";
import { useUserDataContext } from "../../../../user_data_context";

const CreateLessonArrowsContainer = styled.div`
  position: absolute;
  top: 50%;
  right: -29px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
`;

const CreateLessonArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 24px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    transition: 0.2s;
  }
`;

const CreateLessonArrowUp = styled(CreateLessonArrow)``;

const CreateLessonArrowDown = styled(CreateLessonArrow)`
  transform: rotate(180deg);
`;

const CreateLessonArrows = ({
  currentIndex,
  lessonData,
  setEditBlockIndex,
  fetchLessonData,
}) => {
  const { userData } = useUserDataContext();
  const editorId = userData._id;

  const updateLessonDataOnServer = useCallback(
    async (updatedLessonData) => {
      await axios.patch(`${BASE_API_URL}/lessons/${lessonData._id}`, {
        ...lessonData,
        lesson_modified_at: new Date().toISOString(),
        lesson_modified_by: { _id: editorId },
        lesson_data: updatedLessonData,
      });
    },
    [lessonData._id, editorId]
  );

  const moveItemUp = useCallback(async () => {
    if (currentIndex <= 0) return;

    const updatedLessonData = [...lessonData.lesson_data];
    [updatedLessonData[currentIndex - 1], updatedLessonData[currentIndex]] = [
      updatedLessonData[currentIndex],
      updatedLessonData[currentIndex - 1],
    ];

    await updateLessonDataOnServer(updatedLessonData);
    await fetchLessonData();
    setEditBlockIndex((prevState) => prevState - 1);
  }, [
    currentIndex,
    lessonData.lesson_data,
    updateLessonDataOnServer,
    fetchLessonData,
    setEditBlockIndex,
  ]);

  const moveItemDown = useCallback(async () => {
    if (currentIndex >= lessonData.lesson_data.length - 1) return;

    const updatedLessonData = [...lessonData.lesson_data];
    [updatedLessonData[currentIndex], updatedLessonData[currentIndex + 1]] = [
      updatedLessonData[currentIndex + 1],
      updatedLessonData[currentIndex],
    ];

    await updateLessonDataOnServer(updatedLessonData);
    await fetchLessonData();
    setEditBlockIndex((prevState) => prevState + 1);
  }, [
    currentIndex,
    lessonData.lesson_data,
    updateLessonDataOnServer,
    fetchLessonData,
    setEditBlockIndex,
  ]);

  return (
    <CreateLessonArrowsContainer onClick={(e) => e.stopPropagation()}>
      {currentIndex > 0 && (
        <CreateLessonArrowUp onClick={moveItemUp}>
          <CreateLessonArrowIcon />
        </CreateLessonArrowUp>
      )}

      {currentIndex < lessonData.lesson_data.length - 1 && (
        <CreateLessonArrowDown onClick={moveItemDown}>
          <CreateLessonArrowIcon />
        </CreateLessonArrowDown>
      )}
    </CreateLessonArrowsContainer>
  );
};

export default memo(CreateLessonArrows);
