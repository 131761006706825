import React from "react";

const HeaderChatIcon = ({ className }) => (
  <svg
    width="21"
    height="26"
    viewBox="0 0 21 26"
    fill="none"
    className={className}
  >
    <path
      d="M19.5 13C19.5 13.5304 19.2893 14.0391 18.9142 14.4142C18.5391 14.7893 18.0304 15 17.5 15H5.5L1.5 19V3C1.5 2.46957 1.71071 1.96086 2.08579 1.58579C2.46086 1.21071 2.96957 1 3.5 1H17.5C18.0304 1 18.5391 1.21071 18.9142 1.58579C19.2893 1.96086 19.5 2.46957 19.5 3V13Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeaderChatIcon;
