import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import { createComponentId } from "../..";
import ImageDropzone from "../../../../../common/components/image_dropzone";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import ColorSelect from "../../../../../common/components/color_select";
import Checkbox from "../../../../../common/components/checkbox";
import { LESSON_COLORS } from "../../../../../common/utils/enums";

const VideoSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 26px 24px;
`;

const VideoUrlInput = styled.input`
  height: 40px;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const VideoTitleInput = styled.textarea`
  resize: none;
  max-height: 190px;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const VIDEO_SETTINGS_DEFAULT_DATA = {
  type: "VIDEO",
  image_url: "",
  video_url: "",
  with_title: false,
  video_title: "",
  background: "",
  id: "",
};

const VideoSettings = ({
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockData,
  settingsBlockMemoData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const videoTitleRef = useRef(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const isCurrentImage =
    settingsBlockData.image_url === settingsBlockMemoData.image_url;

  useEffect(() => {
    if (editBlockIndex === null) {
      const lastColor = localStorage.getItem("lastColor");

      const defaultImageData = {
        ...VIDEO_SETTINGS_DEFAULT_DATA,
        background: lastColor ? lastColor : LESSON_COLORS.COLOR_2,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultImageData);
      setSettingsBlockMemoData(defaultImageData);
    }
  }, [editBlockIndex]);

  useEffect(() => {
    const { video_url, with_title, video_title } = settingsBlockData;

    if (video_url) {
      if (with_title) {
        if (video_title) {
          setDisabledSaveButton(false);
        } else {
          setDisabledSaveButton(true);
        }
      } else {
        setDisabledSaveButton(false);
      }
    } else {
      setDisabledSaveButton(true);
    }
  }, [settingsBlockData]);

  useEffect(() => {
    if (videoTitleRef.current && settingsBlockData.video_title) {
      videoTitleRef.current.style.height = "auto";
      videoTitleRef.current.style.height =
        videoTitleRef.current.scrollHeight + "px";
    }
  }, [videoTitleRef, settingsBlockData]);

  const handleImageSettings = useCallback((image) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      image_url: image,
    }));
  }, []);

  const onClickCheckbox = useCallback(
    (event) => {
      event.stopPropagation();
      const resetTitleAndBackground = settingsBlockData.with_title
        ? {
            video_title: "",
            background: "",
          }
        : {};

      setSettingsBlockData((prevState) => {
        return {
          ...prevState,
          ...resetTitleAndBackground,
          with_title: !prevState.with_title,
        };
      });
    },
    [settingsBlockData.with_title]
  );

  const handleVideoInput = useCallback((event, key) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  }, []);

  const handleSelectBackground = useCallback((event, background) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      background,
    }));
  }, []);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <VideoSettingsContainer>
        <VideoUrlInput
          placeholder="Вставьте ссылку"
          type="text"
          value={settingsBlockData.video_url || ""}
          onChange={(e) => handleVideoInput(e, "video_url")}
        />

        <ImageDropzone
          imageUrl={settingsBlockData.image_url}
          isCurrentImage={isCurrentImage}
          handleImageSettings={handleImageSettings}
        />

        <Checkbox
          fontSize={"14px"}
          checked={settingsBlockData.with_title}
          onClickCheckbox={onClickCheckbox}
        >
          Название
        </Checkbox>

        {settingsBlockData.with_title && (
          <VideoTitleInput
            placeholder="Напишите название..."
            ref={videoTitleRef}
            rows={1}
            type="text"
            value={settingsBlockData.video_title || ""}
            onChange={(e) => handleVideoInput(e, "video_title")}
          />
        )}

        <ColorSelect
          selectedColor={settingsBlockData.background}
          handleSelectColor={handleSelectBackground}
          colors={LESSON_COLORS}
        />

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </VideoSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(VideoSettings);
