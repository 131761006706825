import React from "react";

const HorizontalLine = ({ width, height, stroke }) => {
  return (
    <svg
      width={width || "508"}
      height={height || "2"}
      viewBox="0 0 508 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1H508" stroke={stroke || "#CACACA"} />
    </svg>
  );
};

export default HorizontalLine;
