import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import { useUserDataContext } from "../../../../user_data_context";
import UserPermits from "./user_permits";
import UserTeaching from "./user_teaching";
import UserAdministration from "./user_administration";
import UserPublicity from "./user_publicity";
import CreateUserInvite from "./create_user_invite";
import UnsavedChangesModalWindow from "../../../../common/components/modal_windows/unsaved_changes_modal_window";
import UnsentInviteModalWindow from "../admin_users_modal_windows/unsent_invite_modal_window";
import UnbindMentorModalWindow from "../admin_users_modal_windows/unbind_mentor_modal_window";
import SanctionUserModalWindow from "../admin_users_modal_windows/sanction_user_modal_window";
import UsersListModalWindow from "../admin_users_modal_windows/users_list_modal_window";
import AdminPageSettingsButtons from "../../admin_page_components/admin_page_settings_buttons";
import AddingCourseModalWindow from "../admin_users_modal_windows/adding_course_modal_window";

const UserRolesSettingsWrapper = styled.div`
  position: relative;
  width: 440px;
`;

const UserRolesSettingsContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  padding: 26px 24px 100px;
  width: 437px;
  border-left: 1px solid #828282;
  max-height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const UserRolesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const AdminPageSendInviteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 1;
  min-height: 44px;
  max-height: 44px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  cursor: pointer;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    transition: 0.2s;
  }
`;

const UserRolesSettings = ({
  activeSettingsBlock,
  userRolesSettingsData,
  userRolesSettingsMemoData,
  usersList,
  setUserRolesSettingsData,
  setUsersList,
  closeUserSettings,
  openEditUserModal,
  setOpenEditUserModal,
  setSelectedUserInfo,
  fetchUsers,
}) => {
  const { userData } = useUserDataContext();
  const [emailInputError, setEmailInputError] = useState("");
  const [openUsersListModal, setOpenUsersListModal] = useState(""); // students, mentors, wards
  const [openUnbindMentorModal, setOpenUnbindMentorModal] = useState(false);
  const [openAddingCourseModal, setOpenAddingCourseModal] = useState(false);
  const [sanctionUserModalWindow, setSanctionUserModalWindow] = useState(""); // write_to_mentor, write_to_chat, write_to_support
  const [usersListByRole, setUsersListByRole] = useState([]);
  const [warningCause, setWarningCause] = useState("");
  const [unbindMentorCause, setUnbindMentorCause] = useState("");
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  useEffect(() => {
    setShowCheckIcon(false);
  }, [userRolesSettingsData]);

  const actionCreation = useCallback(
    (type, warningCount) => {
      return {
        action_author: { _id: userData._id },
        action_date: new Date().toISOString(),
        action_cause:
          type === "warning"
            ? warningCause
            : type === "unbinding"
            ? unbindMentorCause
            : "",
        action_type: type,
        ...(type === "warning" && { warning_count: warningCount }),
      };
    },
    [userData, warningCause, unbindMentorCause]
  );

  const onClickSaveButton = useCallback(async () => {
    const { _id } = userRolesSettingsData;
    const currentWarningCount =
      userRolesSettingsData.user_permissions.amount_of_warnings;
    const memoWarningCount =
      userRolesSettingsMemoData.user_permissions.amount_of_warnings;
    const memoUserMentor = userRolesSettingsMemoData.user_mentor?._id;
    const currentUserMentor = userRolesSettingsData.user_mentor?._id;
    const currentActions = [];

    if (currentWarningCount !== memoWarningCount) {
      currentActions.push(actionCreation("warning", currentWarningCount));
    }

    if (memoUserMentor && memoUserMentor !== currentUserMentor) {
      currentActions.push(actionCreation("unbinding"));
    }

    const updatedUserObject = {
      ...userRolesSettingsData,
      user_permissions: {
        ...userRolesSettingsData.user_permissions,
        actions: [
          ...currentActions,
          ...userRolesSettingsData.user_permissions.actions,
        ],
      },
    };

    setUserRolesSettingsData(updatedUserObject);

    try {
      await axios.patch(`${BASE_API_URL}/users/${_id}`, updatedUserObject);
      fetchUsers();
      setUnbindMentorCause("");
      setShowCheckIcon(true);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [
    userRolesSettingsData,
    userRolesSettingsMemoData,
    setUserRolesSettingsData,
  ]);

  const onClickSendInviteButton = useCallback(() => {
    const unduplicate = !usersList.find(
      (user) =>
        user._id !== "new_invite" &&
        user.user_email === userRolesSettingsData.user_email
    );

    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    if (emailRegex.test(userRolesSettingsData.user_email)) {
      if (unduplicate) {
        const newUser = { ...userRolesSettingsData };
        delete newUser._id;

        axios
          .post(BASE_API_URL + "/users/", newUser)
          .then((response) => {
            closeUserSettings();
            fetchUsers();
          })
          .catch((error) => {
            console.error("Ошибка при запросе:", error);
          });
      } else {
        setEmailInputError("Пользователь с такой почтой уже существует");
        setTimeout(() => setEmailInputError(""), 3000);
      }
    } else {
      setOpenEditUserModal(false);
      if (!userRolesSettingsData.user_email) {
        setEmailInputError("Введите электронную почту");
      } else {
        setEmailInputError("Некорректный формат электронной почты");
      }
      setTimeout(() => setEmailInputError(""), 3000);
    }
  }, [userRolesSettingsData, usersList]);

  const onClickUnsendButton = useCallback(() => {
    setUsersList((prevState) =>
      prevState.filter((user) => user._id !== "new_invite")
    );
    closeUserSettings();
  }, []);

  useEffect(() => {
    if (userRolesSettingsData.user_email) {
      setEmailInputError("");
    }
  }, [userRolesSettingsData]);

  return (
    <UserRolesSettingsWrapper>
      {!!activeSettingsBlock && (
        <UserRolesSettingsContainer
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <UserRolesListContainer>
            {activeSettingsBlock === "create_invite" && (
              <CreateUserInvite
                userRolesSettingsData={userRolesSettingsData}
                setUserRolesSettingsData={setUserRolesSettingsData}
                setUsersList={setUsersList}
                emailInputError={emailInputError}
              />
            )}
            <UserPermits
              usersList={usersList}
              userRolesSettingsData={userRolesSettingsData}
              userRolesSettingsMemoData={userRolesSettingsMemoData}
              setUserRolesSettingsData={setUserRolesSettingsData}
              setSelectedUserInfo={setSelectedUserInfo}
              setUsersListByRole={setUsersListByRole}
              setOpenUsersListModal={setOpenUsersListModal}
              setOpenUnbindMentorModal={setOpenUnbindMentorModal}
              setSanctionUserModalWindow={setSanctionUserModalWindow}
            />
            <UserTeaching
              usersList={usersList}
              setUsersList={setUsersList}
              userRolesSettingsData={userRolesSettingsData}
              setUserRolesSettingsData={setUserRolesSettingsData}
              setSelectedUserInfo={setSelectedUserInfo}
              setUsersListByRole={setUsersListByRole}
              setOpenUsersListModal={setOpenUsersListModal}
            />
            <UserAdministration
              userRolesSettingsData={userRolesSettingsData}
              setUserRolesSettingsData={setUserRolesSettingsData}
              setOpenAddingCourseModal={setOpenAddingCourseModal}
              setUsersList={setUsersList}
            />
            <UserPublicity
              userRolesSettingsData={userRolesSettingsData}
              setUserRolesSettingsData={setUserRolesSettingsData}
              setUsersList={setUsersList}
              setOpenAddingCourseModal={setOpenAddingCourseModal}
            />

            {activeSettingsBlock === "user_roles" ? (
              <AdminPageSettingsButtons
                showCheckIcon={showCheckIcon}
                onClickSaveButton={onClickSaveButton}
                onClickCancelButton={closeUserSettings}
              />
            ) : (
              <AdminPageSendInviteButton onClick={onClickSendInviteButton}>
                Отправить приглашение
              </AdminPageSendInviteButton>
            )}
          </UserRolesListContainer>
        </UserRolesSettingsContainer>
      )}

      {openEditUserModal && activeSettingsBlock === "user_roles" && (
        <UnsavedChangesModalWindow
          setOpen={setOpenEditUserModal}
          onSaveSettingsButton={onClickSaveButton}
          onClickNotSaveButton={closeUserSettings}
        />
      )}

      {openEditUserModal && activeSettingsBlock === "create_invite" && (
        <UnsentInviteModalWindow
          setOpen={setOpenEditUserModal}
          onClickSendInviteButton={onClickSendInviteButton}
          onClickUnsendButton={onClickUnsendButton}
        />
      )}

      {openUsersListModal && (
        <UsersListModalWindow
          usersListByRole={usersListByRole}
          openUsersListModal={openUsersListModal}
          setOpen={setOpenUsersListModal}
          setUserRolesSettingsData={setUserRolesSettingsData}
        />
      )}

      {openUnbindMentorModal && (
        <UnbindMentorModalWindow
          usersList={usersList}
          setOpen={setOpenUnbindMentorModal}
          setUnbindMentorCause={setUnbindMentorCause}
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
        />
      )}

      {sanctionUserModalWindow && (
        <SanctionUserModalWindow
          setOpen={setSanctionUserModalWindow}
          sanctionType={sanctionUserModalWindow}
          warningCause={warningCause}
          setWarningCause={setWarningCause}
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          userRolesSettingsMemoData={userRolesSettingsMemoData}
        />
      )}

      {openAddingCourseModal && (
        <AddingCourseModalWindow
          component={openAddingCourseModal}
          userRolesSettingsData={userRolesSettingsData}
          setOpen={setOpenAddingCourseModal}
          setUserRolesSettingsData={setUserRolesSettingsData}
        />
      )}
    </UserRolesSettingsWrapper>
  );
};

export default memo(UserRolesSettings);
