import React from "react";
import styled from "styled-components";

const StartBlockOfChatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100vh - 100px);
  color: #383838;
`;

const StartBlockOfChats = () => {
  return (
    <StartBlockOfChatsContainer>
      Выберите чат, чтобы начать общение...
    </StartBlockOfChatsContainer>
  );
};

export default StartBlockOfChats;
