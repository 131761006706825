import React from "react";
import styled from "styled-components";

const DividerContainer = styled.div`
  width: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-top: -7px;
  }
`;

const DialogEditorDividerIcon = () => {
  return (
    <DividerContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="385"
        height="7"
        viewBox="0 0 385 7"
        fill="none"
      >
        <path
          d="M1.5 0.255859C1.08579 0.255859 0.75 0.591646 0.75 1.00586C0.75 1.42007 1.08579 1.75586 1.5 1.75586L1.5 0.255859ZM182.002 1.00586L182.338 0.334908L182.179 0.255859H182.002V1.00586ZM192.002 6.00098L191.667 6.67193C191.878 6.77733 192.127 6.77733 192.338 6.67193L192.002 6.00098ZM202.002 1.00586V0.255859H201.825L201.667 0.334908L202.002 1.00586ZM383.5 1.75586C383.914 1.75586 384.25 1.42007 384.25 1.00586C384.25 0.591646 383.914 0.255859 383.5 0.255859V1.75586ZM1.5 1.75586H182.002V0.255859H1.5L1.5 1.75586ZM181.667 1.67681L191.667 6.67193L192.338 5.33003L182.338 0.334908L181.667 1.67681ZM192.338 6.67193L202.338 1.67681L201.667 0.334908L191.667 5.33003L192.338 6.67193ZM202.002 1.75586H383.5V0.255859H202.002V1.75586Z"
          fill="url(#paint0_linear_7634_6543)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7634_6543"
            x1="183.267"
            y1="0.0876393"
            x2="183.267"
            y2="6.44172"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5172EA" />
            <stop offset="1" stopColor="#7A5AEE" />
          </linearGradient>
        </defs>
      </svg>

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="7"
          viewBox="0 0 23 7"
          fill="none"
        >
          <path
            d="M1 1.00488L11.5 6.00488L22 1.00488"
            stroke="url(#paint0_linear_7507_13181)"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_7507_13181"
              x1="10.9924"
              y1="0.0857651"
              x2="10.9924"
              y2="6.44606"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5172EA" />
              <stop offset="1" stopColor="#7A5AEE" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </DividerContainer>
  );
};

export default DialogEditorDividerIcon;
