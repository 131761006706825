import styled from "styled-components";

export const AdminPageSettingsDeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 56px;
  padding-bottom: 1px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

//                         CALENDAR

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  border-radius: 8px;
  background: #fff;
`;

export const CalendarLeftArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  cursor: pointer;
  height: 80%;
  transition: transform 0.2s;

  &: hover {
    transform: scale(1.25);
  }
`;

export const CalendarRightArrowContainer = styled(CalendarLeftArrowContainer)`
  transform: rotate(180deg);

  &: hover {
    transform: scale(1.25) rotate(180deg);
  }
`;

export const CurrentMonthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #cacaca;
`;

export const CurrentMonth = styled.div`
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;

export const CalendarWeekDaysContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 35px;
  width: 100%;
  padding: 0 44px;
  background: var(
    --Gradient-05,
    linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
`;

export const CalendarDaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  width: 316px;
  padding: 8px 0 17px;
  margin: 0 auto;
`;

export const CalendarDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-direction: column;
  border-radius: 4px;
  background: #cacaca;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 2px solid transparent;
  cursor: pointer;

  &: hover {
    box-shadow: 2.732px 2.732px 5.281px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
  }
`;

export const AdminPageSettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  ${({ clickable }) => clickable && "cursor: pointer;"}
`;

export const AdminPageSettingsHeaderArrow = styled.div`
  padding-top: 5px;
  ${({ extendedSection }) => extendedSection && "transform: rotate(180deg);"}
`;

export const AddUserButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

export const AddUserButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding: 2px 5px 0;
  height: 30px;
  width: max-content;
  cursor: pointer;

  &: hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;
