import React from "react";

const BoldIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79688 2.9998C3.79688 2.33706 4.33413 1.7998 4.99688 1.7998H14.3302C15.8723 1.7998 17.3597 2.39013 18.463 3.45401C19.5678 4.51932 20.1969 5.9737 20.1969 7.4998C20.1969 9.02591 19.5678 10.4803 18.463 11.5456C18.4374 11.5703 18.4115 11.5948 18.3854 11.619C18.7807 11.8527 19.1495 12.1337 19.4828 12.4586C20.5766 13.5251 21.1969 14.9781 21.1969 16.4998C21.1969 18.0215 20.5766 19.4745 19.4828 20.541C18.39 21.6065 16.9142 22.1998 15.3815 22.1998H4.99688C4.33413 22.1998 3.79688 21.6625 3.79688 20.9998V2.9998ZM6.19688 4.1998V10.7998H14.3302C15.2634 10.7998 16.15 10.4419 16.7971 9.81797C17.4426 9.19546 17.7969 8.36065 17.7969 7.4998C17.7969 6.63896 17.4426 5.80415 16.7971 5.18164C16.15 4.55769 15.2634 4.1998 14.3302 4.1998H6.19688ZM6.19688 13.1998V19.7998H15.3815C16.297 19.7998 17.169 19.4449 17.8073 18.8226C18.4446 18.2012 18.7969 17.3651 18.7969 16.4998C18.7969 15.6345 18.4446 14.7984 17.8073 14.177C17.169 13.5547 16.297 13.1998 15.3815 13.1998H6.19688Z"
        fill="#383838"
      />
    </svg>
  );
};

export default BoldIcon;
