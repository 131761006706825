import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import {
  AddUserButton,
  AddUserButtonContainer,
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../admin_page_components/admin_page_styled_components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import PlusIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import AdminPagePersonPreview from "../../admin_page_components/admin_page_person_preview";

const CourseAdminsContainer = styled.div`
  border-radius: 8px;
  background: #fff;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const CourseAdminsHeader = styled(AdminPageSettingsHeader)`
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  padding: 0 12px;
`;

const CourseAdminsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 12px 12px;
`;

const AddAdminButtonContainer = styled(AddUserButtonContainer)`
  padding: 0;
`;

const CourseAdmins = ({
  adminsList,
  setCourseSettingsData,
  setOpenStaffListModal,
  setSelectedUserInfo,
}) => {
  const [extendedCourseAdmins, setExtendedCourseAdmins] = useState(false);

  const handleOpenStaffListModal = useCallback(() => {
    setOpenStaffListModal("admins");
  }, []);

  const onClickRemoveAuthorButton = useCallback((event, adminId) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_admins: prevState.course_admins.filter(
        (admin) => admin._id !== adminId
      ),
    }));
  }, []);

  const handleExtendUserIsCoursesAdmin = useCallback(() => {
    setExtendedCourseAdmins((prevState) => !prevState);
  }, []);

  return (
    <CourseAdminsContainer>
      <CourseAdminsHeader onClick={handleExtendUserIsCoursesAdmin} clickable>
        Админ
        <AdminPageSettingsHeaderArrow extendedSection={extendedCourseAdmins}>
          <AskQuestionArrowIcon />
        </AdminPageSettingsHeaderArrow>
      </CourseAdminsHeader>

      {extendedCourseAdmins && (
        <CourseAdminsList>
          {adminsList.map((author) => {
            return (
              <AdminPagePersonPreview
                key={author._id}
                personId={author._id}
                setSelectedUserInfo={setSelectedUserInfo}
                onRemoveButton={onClickRemoveAuthorButton}
              />
            );
          })}

          <AddAdminButtonContainer>
            <AddUserButton onClick={handleOpenStaffListModal}>
              <PlusIcon />
            </AddUserButton>
          </AddAdminButtonContainer>
        </CourseAdminsList>
      )}
    </CourseAdminsContainer>
  );
};

export default memo(CourseAdmins);
