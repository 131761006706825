import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../../../common/assets/icons";

const QuestionsFilterSearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  border-radius: 8px;
  background: #fff;
  width: 210px;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const QuestionsFilterSearchInput = styled.input`
  font-size: 16px;
  line-height: 24px;
  width: 90%;
  color: #1e1e1e;
`;

const QuestionsFilterSearchIcon = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 14px;
  height: 14px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -13px;
  left: 11px;
  color: red;
  font-size: 10px;
`;

const QuestionsFilterBySearchWords = ({ setQuestionsFilterSettings }) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchError, setSearchError] = useState(false);

  const handleSearchInputValue = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  const onFilterBySearchWords = useCallback(
    (event) => {
      event.stopPropagation();
      if (searchInputValue.length > 2) {
        setQuestionsFilterSettings((prevState) => ({
          ...prevState,
          search_words: [...prevState.search_words, searchInputValue],
        }));
        setSearchInputValue("");
      } else {
        setSearchError(true);
        setTimeout(() => {
          setSearchError(false);
        }, 2000);
      }
    },
    [searchInputValue]
  );

  return (
    <QuestionsFilterSearchContainer>
      <QuestionsFilterSearchInput
        placeholder="Поиск"
        value={searchInputValue}
        onChange={handleSearchInputValue}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onFilterBySearchWords(event);
          }
        }}
      />
      <QuestionsFilterSearchIcon onClick={onFilterBySearchWords}>
        <SearchIconStyled />
      </QuestionsFilterSearchIcon>
      {!!searchError && (
        <ErrorMessage>Необходимо ввести не менее 3х букв</ErrorMessage>
      )}
    </QuestionsFilterSearchContainer>
  );
};

export default QuestionsFilterBySearchWords;
