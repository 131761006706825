import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../../axios_config";
import CoursePreviewPageHeader from "../../study/course_page/course_page_header";
import AboutCourse from "../../study/course_page/about_course";
import CourseLessons from "../../study/course_page/course_lessons";
import { BASE_API_URL } from "../../../common/endpoints";
import CourseVideoModalWindow from "../../study/course_page/course_video_modal_window";
import CourseVideo from "../../study/course_page/course_video";
import AboutCourseAuthors from "../../study/course_page/about_course_authors";
import CourseCertificate from "../../study/course_page/course_certificate";
import CourseLevelAndLessonsInfo from "../../study/course_page/course_level_and_lessons_info";

export const LESSON_BG_COLOR = {
  AVAILABLE: {
    SMALL: "linear-gradient(180deg, #05baa4 0%, #288bce 100%)",
    LARGE: "linear-gradient(50deg, #05BAA4 8.65%, #2046E9 100%)",
  },
  CURRENT: "#F8D254",
  UNAVAILABLE: "#CACACA",
};

const CoursePreviewPageContainer = styled.div`
  padding-bottom: 124px;

  @media (max-width: 412px) {
    padding-bottom: 100px;
  }
`;

const CoursePreviewPage = () => {
  const { id } = useParams();
  const [courseData, setCourseData] = useState({});
  const [openCourseVideoModal, setOpenCourseVideoModal] = useState(false);

  const fetchLessonData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/courses/${id}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных курса:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickToLesson = useCallback((lessonId) => {
    console.log("to Lesson Preview Page - ", lessonId);
  }, []);

  return (
    <CoursePreviewPageContainer>
      <CoursePreviewPageHeader
        courseData={courseData}
        inComponent={"admin_page"}
      />

      <CourseLevelAndLessonsInfo
        courseData={courseData}
        inComponent={"admin_page"}
        onClickToLesson={onClickToLesson}
      />

      <AboutCourse courseData={courseData} />

      {courseData.course_video_enabled && (
        <CourseVideo
          videoImage={courseData?.course_video_image}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}

      <AboutCourseAuthors courseData={courseData} />

      <CourseLessons courseData={courseData} inComponent={"admin_page"} />

      {courseData.course_certificate_is_enabled && <CourseCertificate />}

      {openCourseVideoModal && courseData?.course_video_url && (
        <CourseVideoModalWindow
          videoUrl={courseData?.course_video_url}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}
    </CoursePreviewPageContainer>
  );
};

export default memo(CoursePreviewPage);
