import React, { memo, useCallback } from "react";
import { RichUtils } from "draft-js";
import TextSettingsButtonsHeader from "./text_settings_buttons_header";
import TextSizeIcon from "../../../../common/assets/icons/text_editor_icons/text_size_icon";
import {
  ButtonGroup,
  SettingsButtonsContainer,
  ToolbarButton,
} from "../editor_styled_components";
import styled from "styled-components";

const TextSizeButtonsContainer = styled(SettingsButtonsContainer)`
  width: 275px;
  align-items: start;
  left: -28px;
  transform: none;
`;

const TextSizeButton = styled(ToolbarButton)`
  display: flex;
  align-items: center;
  text-align: start;
  height: 36px;
  padding: 0 10px;
  width: 100%;
  color: #1e1e1e;
`;

const DefaultSize = styled(TextSizeButton)`
  font-size: 16px;
`;

const NoteSize = styled(TextSizeButton)`
  font-size: 12px;
`;

const HeadingOneSize = styled(TextSizeButton)`
  font-size: 22px;
`;

const HeadingTwoSize = styled(TextSizeButton)`
  height: 45px;
  font-size: 34px;
  letter-spacing: -0.4px;
`;

const TextSizeButtons = ({
  editorState,
  setEditorState,
  currentStyle,
  toggleInlineStyle,
  openTextSettings,
  setOpenTextSettings,
}) => {
  const clearStylesAndSetDefaultSize = () => {
    let newEditorState = editorState;
    ["H1", "H2", "NOTE"].forEach((style) => {
      if (newEditorState.getCurrentInlineStyle().has(style)) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, style);
      }
    });

    newEditorState = RichUtils.toggleInlineStyle(
      newEditorState,
      "DEFAULT_SIZE"
    );

    setEditorState(newEditorState);
    setOpenTextSettings("");
  };

  const handleSelectTextSize = useCallback(
    (textSize) => {
      toggleInlineStyle(textSize);
      setOpenTextSettings("");
    },
    [toggleInlineStyle]
  );

  const isDefaultTextActive = useCallback(() => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
      !currentStyle.has("H1") &&
      !currentStyle.has("H2") &&
      !currentStyle.has("NOTE")
    );
  }, [editorState]);

  return (
    <ButtonGroup>
      <TextSettingsButtonsHeader
        isOpen={openTextSettings === "text_size"}
        setOpen={setOpenTextSettings}
        buttonsGroup={"text_size"}
      >
        <TextSizeIcon />
      </TextSettingsButtonsHeader>

      {openTextSettings === "text_size" && (
        <TextSizeButtonsContainer>
          <DefaultSize
            active={isDefaultTextActive()}
            onClick={clearStylesAndSetDefaultSize}
          >
            Обычный текст
          </DefaultSize>
          <NoteSize
            active={currentStyle.has("NOTE")}
            onClick={() => handleSelectTextSize("NOTE")}
          >
            Заметка
          </NoteSize>
          <HeadingOneSize
            active={currentStyle.has("H1")}
            onClick={() => handleSelectTextSize("H1")}
          >
            Подзаголовок
          </HeadingOneSize>
          <HeadingTwoSize
            active={currentStyle.has("H2")}
            onClick={() => handleSelectTextSize("H2")}
          >
            Заголовок
          </HeadingTwoSize>
        </TextSizeButtonsContainer>
      )}
    </ButtonGroup>
  );
};

export default memo(TextSizeButtons);
