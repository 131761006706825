import React, { memo, useEffect, useState } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import styled from "styled-components";
import {
  replacePlaceholderWithStudentName,
  entityStyleFn,
  getInlineStyles,
  replaceSpacesWithNbsp,
} from "../../text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../../text_editor/editor_styled_components";
import { LESSON_COLORS_THEME } from "../../../utils/enums";
import { useUserDataContext } from "../../../../user_data_context";

const TextComponentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 24px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: ${({ background }) => background || "transparent"};
  ${({ withBackground }) =>
    withBackground &&
    `
  border-radius: 15px;
  padding: 20px;
  color: #fff;
  `}

  ${({ colorTheme }) => colorTheme === "DARK" && "color: #fff"};

  ${({ backgroundIsWhite, colorTheme }) =>
    backgroundIsWhite && colorTheme === "LIGHT" && "color: #1e1e1e;"};

  ${({ isGroup }) =>
    isGroup &&
    `border-radius: 25px;
     padding: 18px 0px 18px 20px;
     border-left: 3px solid #f8d254;
   `}

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const TextContent = styled(TextEditorViewComponent)`
  width: ${({ withImage }) => (withImage ? "49%" : "100%")};

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 100%;
  min-width: 48%;
  max-width: 48%;
  object-fit: contain;
  border-radius: 15px;

  @media (max-width: 720px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const NoImage = styled.div`
  padding: 20px;
  color: #383838;
  min-width: 49%;
  max-width: 49%;
  height: 180px;
  border-radius: 10px;
  background: #fff;
  line-height: 20px;
`;

const TexLessonComponent = ({
  componentData,
  colorTheme = "LIGHT",
  courseMainColor,
  courseMainGradient,
  setTextEditorComponentHtml = () => {},
}) => {
  const { userData } = useUserDataContext();
  const [html, setHtml] = useState("");

  const currentUserName = userData.user_name || "Пользователь";
  const withBackground = componentData.background !== "TRANSPARENT";
  const componentBackgroundColor = withBackground
    ? LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.[componentData.background]
    : "transparent";

  const backgroundIsWhite = componentData.background === "WHITE";

  useEffect(() => {
    if (componentData.contentState?.blocks?.length) {
      const background =
        componentData.background !== "TRANSPARENT"
          ? componentData.background
          : null;

      try {
        let contentState = convertFromRaw(componentData.contentState);

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(colorTheme, background),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [
    componentData.contentState,
    componentData.background,
    currentUserName,
    colorTheme,
    withBackground,
  ]);

  useEffect(() => {
    setTextEditorComponentHtml({});
  }, [html]);

  return (
    <TextComponentContainer
      isGroup={componentData.is_group}
      background={componentBackgroundColor}
      backgroundIsWhite={backgroundIsWhite}
      withBackground={withBackground}
      colorTheme={colorTheme}
    >
      <TextContent
        dangerouslySetInnerHTML={{ __html: html }}
        withImage={componentData.with_image}
        withBackground={withBackground}
        courseMainColor={courseMainColor}
        courseMainGradient={courseMainGradient}
      />

      {componentData.with_image &&
        (componentData.image_url ? (
          <Image src={componentData.image_url} alt="Component image" />
        ) : (
          <NoImage>Загрузите изображение...</NoImage>
        ))}
    </TextComponentContainer>
  );
};

export default memo(TexLessonComponent);
