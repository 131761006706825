import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../common/assets/icons/vertical_line";

const ConfirmationModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const ConfirmationModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  width: 420px;
  padding: 42px;
  border-radius: 25px;
  background: #fff;
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.24px;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: 52%;
  }
`;

const ConfirmButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnConfirm }) =>
    isHoverOnConfirm ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 4px;
`;

const ConfirmationModalWindow = ({
  userRolesSettingsData,
  action,
  selectedObjectType,
  selectedObjectData,
  setOpen,
  onClickConfirmButton,
}) => {
  const [isHoverOnConfirm, setIsHoverOnConfirm] = useState(false);

  const confirmButtonName = action === "removing" ? "Ограничить" : "Открыть";
  const userName = `${userRolesSettingsData.user_name} ${userRolesSettingsData.user_second_name}`;
  const objectType = selectedObjectType === "course" ? "курсу" : "группе";
  const ojectTitle = selectedObjectData.title;

  const confirmationMessage =
    action === "removing"
      ? `Вы точно хотите ограничить доступ к ${objectType} "${ojectTitle}" пользователю ${userName}?`
      : `Вы точно хотите открыть доступ к ${objectType} "${ojectTitle}" пользователю ${userName}?`;

  const toggleHoverOnConfirmButton = useCallback(() => {
    setIsHoverOnConfirm((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <ConfirmationModalWindowWrapper onClick={handleClose}>
      <ConfirmationModalWindowContainer onClick={(e) => e.stopPropagation()}>
        {confirmationMessage}
        <ModalButtonsContainer>
          <ConfirmButton
            onMouseEnter={toggleHoverOnConfirmButton}
            onMouseLeave={toggleHoverOnConfirmButton}
            onClick={onClickConfirmButton}
          >
            {confirmButtonName}
          </ConfirmButton>
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton
            isHoverOnConfirm={isHoverOnConfirm}
            onClick={handleClose}
          >
            Отмена
          </CancelButton>
        </ModalButtonsContainer>
      </ConfirmationModalWindowContainer>
    </ConfirmationModalWindowWrapper>
  );
};

export default memo(ConfirmationModalWindow);
