import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useUserDataContext } from "../../../user_data_context";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";

const StartStudyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 540px;
  height: 100%;

  @media (max-width: 1150px) {
    width: 500px;
  }

  @media (max-width: 1024px) {
    width: 432px;
    height: 238px;
    gap: 29.047px;
  }

  @media (max-width: 880px) {
    width: 400px;
    height: 100%;
    gap: 20px;
  }

  @media (max-width: 744px) {
    width: 312px;
    height: 178px;
    gap: 20px;
  }

  @media (max-width: 670px) {
    height: 200px;
    width: 100%;
  }

  @media (max-width: 412px) {
    height: 165px;
  }
`;

const StartStudyTitle = styled.p`
  font-family: "Raleway";
  font-weight: 300;
  font-size: 54px;
  line-height: 64px;
  letter-spacing: -1.2px;
  color: #1e1e1e;

  & span {
    font-family: "Raleway";
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    font-size: 50px;
    line-height: 47px;
    letter-spacing: -0.469px;
  }

  @media (max-width: 880px) {
    font-size: 42px;
    line-height: 47px;
    letter-spacing: -0.469px;
  }

  @media (max-width: 744px) {
    font-size: 38px;
    line-height: 36px;
    letter-spacing: -0.364px;
  }

  @media (max-width: 412px) {
    line-height: 130%;
    letter-spacing: -0.57px;
  }
`;

const StartButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  min-height: 70px;
  width: max-content;
  padding: 0 50px;
  border-radius: 15px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-family: Geometria;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }
`;

const StartStudyDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 744px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 412px) {
    display: none;
  }
`;

const HomePageStartStudy = () => {
  const { userData } = useUserDataContext();

  const startButtonLink = userData._id
    ? APPLICATION_ROUTES.STUDY
    : APPLICATION_ROUTES.AUTH;

  return (
    <StartStudyContainer>
      <StartStudyTitle>
        Исследуй Слово Божье c <span>On Bible</span>
      </StartStudyTitle>

      {!userData._id && (
        <StartStudyDescription>
          Зарегистрируйся чтобы пользоваться <br /> группами и изучать курсы
        </StartStudyDescription>
      )}
      <StartButton to={startButtonLink}>Начать</StartButton>
    </StartStudyContainer>
  );
};

export default HomePageStartStudy;
