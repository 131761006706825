import React, { memo } from "react";
import styled from "styled-components";
import { componentsIconsArray } from ".";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";

const CreateLessonSidebarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  max-height: 630px;
  width: 90px;
  padding: 15px 0;
`;

const SidebarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  min-height: 52px;
  max-height: 52px;
  border-radius: 17px;
  background: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const ScrollToTopButtonStyled = styled(ScrollToTopButton)`
  bottom: 50px;
  left: 95px;
`;

const CreateLessonSidebar = ({
  handleActiveSettingsBlock,
  createLessonBodyRef,
}) => {
  return (
    <CreateLessonSidebarContainer>
      {componentsIconsArray().map((item, index) => {
        const Icon = item.icon;
        return (
          <SidebarIconContainer
            key={index + item.type}
            onClick={(e) => handleActiveSettingsBlock(e, item.type)}
          >
            <Icon />
          </SidebarIconContainer>
        );
      })}
      <ScrollToTopButtonStyled parentRef={createLessonBodyRef} />
    </CreateLessonSidebarContainer>
  );
};

export default memo(CreateLessonSidebar);
