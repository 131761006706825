import React, { useCallback } from "react";
import styled from "styled-components";
import FramedBibleVerse from "../../../../../common/components/framed_bible_verse";
import ChatInputReplyOrForward from "./chat_input_reply_or_forward";
import { useAppContext } from "../../../../../app_context";
import ChatInputEdit from "./chat_input_edit";
import { useChatContentContext } from "../chat_content_context";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";

const ChatInputAdditionalBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const BiblicalVersesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 15px;
`;

const FramedBibleVerseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
`;

const RemoveIconContainer = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(1.15);
  }
`;

const ChatInputAdditionalBlock = ({
  biblicalVerses,
  setBiblicalVerses,
  chatId,
}) => {
  const { ForwardMessageChatId, forwardMessage } = useAppContext();
  const { replyMessage, editMessage } = useChatContentContext();

  const removeBiblecalVerse = useCallback((id) => {
    setBiblicalVerses((prevState) => {
      const updatedVerses = prevState.filter((verse) => verse.verseId !== id);
      return updatedVerses;
    });
  }, []);

  return (
    <ChatInputAdditionalBlockContainer>
      {!!Object.entries(replyMessage).length && (
        <ChatInputReplyOrForward message={replyMessage} component="reply" />
      )}

      {chatId === ForwardMessageChatId &&
        !!Object.entries(forwardMessage).length && (
          <ChatInputReplyOrForward message={forwardMessage} />
        )}

      {!!biblicalVerses.length && (
        <BiblicalVersesContainer>
          {biblicalVerses.map((verse, index) => (
            <FramedBibleVerseContainer key={verse.verseId + index}>
              <FramedBibleVerse verse={verse} inComponent={"chat_input"} />
              <RemoveIconContainer
                onClick={() => removeBiblecalVerse(verse.verseId)}
              >
                <RemoveIcon />
              </RemoveIconContainer>
            </FramedBibleVerseContainer>
          ))}
        </BiblicalVersesContainer>
      )}

      {!!Object.entries(editMessage).length && <ChatInputEdit />}
    </ChatInputAdditionalBlockContainer>
  );
};

export default ChatInputAdditionalBlock;
