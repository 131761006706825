import React, { memo, useRef } from "react";
import styled from "styled-components";
import { UserInfoSectionContainer } from "..";
import UserAction from "./user_action";

const UserActionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > h6 {
    font-size: 14px;
    padding: 8px 15px;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    user-select: none;
  }
`;

const UserInfoActionsSection = ({ selectedUserInfo, setSelectedUserInfo }) => {
  const userInfoActionsRef = useRef(null);
  const userInfoActions = selectedUserInfo.user_permissions.actions || [];

  return (
    <UserInfoSectionContainer ref={userInfoActionsRef}>
      Действия
      <UserActionsList>
        {userInfoActions.length ? (
          userInfoActions.map((action, index) => {
            return (
              <UserAction
                key={"action-" + index}
                action={action}
                setSelectedUserInfo={setSelectedUserInfo}
              />
            );
          })
        ) : (
          <h6>Пока нет действий...</h6>
        )}
      </UserActionsList>
    </UserInfoSectionContainer>
  );
};

export default memo(UserInfoActionsSection);
