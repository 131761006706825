import React from "react";

const RequiredIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      className={className}
    >
      <path
        d="M5.9625 2.1375L5.2125 0.8625L3.75 1.6875V0H2.25V1.6875L0.75 0.8625L0 2.1375L1.4625 3L0 3.8625L0.75 5.1375L2.25 4.3125V6H3.75V4.3125L5.2125 5.1375L5.9625 3.8625L4.4625 3L5.9625 2.1375Z"
        fill="url(#paint0_linear_5075_5587)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5075_5587"
          x1="2.83714"
          y1="-1.10294"
          x2="2.83714"
          y2="6.52941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RequiredIcon;
