import React, { memo, useCallback } from "react";
import styled from "styled-components";
import CheckIcon from "../assets/icons/admin_page_icons/check_icon";

const ColorSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ color }) => color};
  ${({ isTransparent }) => isTransparent && "border: 2px solid #cacaca;"}
  cursor: pointer;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.18);
    transition: box-shadow 0.2s;
  }
`;

const CheckedIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: #fff;
  padding: 1px 0 0 0;
  border-radius: 50%;
`;

const ColorSelect = ({ selectedColor, handleSelectColor, colors }) => {
  const colorsArray = Object.values(colors);

  const onClickColor = useCallback((e, color) => {
    handleSelectColor(e, color);
    localStorage.setItem("lastColor", color);
  }, []);

  return (
    <ColorSelectContainer>
      {colorsArray.map((color, index) => {
        return (
          <ColorContainer
            key={index * 123}
            color={color}
            isTransparent={color === "transparent"}
            onClick={(e) => onClickColor(e, color)}
          >
            {selectedColor === color && (
              <CheckedIconContainer>
                <CheckIcon />
              </CheckedIconContainer>
            )}
          </ColorContainer>
        );
      })}
    </ColorSelectContainer>
  );
};

export default memo(ColorSelect);
