import React from "react";

const FontSettingsIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    className={className}
  >
    <path
      d="M1.8922 11.4706H0.5L4.52666 0.333008H5.89744L9.9241 11.4706H8.5319L5.25489 2.095H5.16921L1.8922 11.4706ZM2.40624 7.11996H8.01786V8.31637H2.40624V7.11996Z"
      fill="url(#paint0_linear_4641_4462)"
    />
    <path
      d="M13.7304 11.6663C13.2092 11.6663 12.7362 11.5666 12.3114 11.3672C11.8866 11.1642 11.5493 10.8724 11.2994 10.4917C11.0495 10.1074 10.9245 9.64331 10.9245 9.09948C10.9245 8.62092 11.0174 8.23299 11.203 7.93569C11.3886 7.63478 11.6367 7.39912 11.9473 7.22872C12.2578 7.05832 12.6005 6.93143 12.9754 6.84804C13.3538 6.76103 13.7339 6.69215 14.1159 6.64139C14.6157 6.57613 15.0208 6.52718 15.3314 6.49456C15.6455 6.4583 15.874 6.39848 16.0168 6.31509C16.1631 6.23171 16.2363 6.08669 16.2363 5.88003V5.83653C16.2363 5.29995 16.0917 4.88302 15.8026 4.58573C15.517 4.28844 15.0833 4.13979 14.5014 4.13979C13.8981 4.13979 13.4251 4.27393 13.0825 4.54222C12.7398 4.81051 12.4988 5.09692 12.3596 5.40147L11.1602 4.9664C11.3743 4.45883 11.6599 4.06365 12.0169 3.78086C12.3774 3.49445 12.7701 3.29505 13.1949 3.18266C13.6233 3.06664 14.0445 3.00863 14.4586 3.00863C14.7227 3.00863 15.0262 3.04126 15.3689 3.10652C15.7151 3.16815 16.0489 3.29686 16.3702 3.49264C16.695 3.68841 16.9645 3.98389 17.1787 4.37907C17.3929 4.77425 17.5 5.30358 17.5 5.96704V11.4706H16.2363V10.3394H16.1721C16.0864 10.5207 15.9436 10.7146 15.7437 10.9213C15.5438 11.128 15.2778 11.3038 14.9459 11.4488C14.6139 11.5938 14.2087 11.6663 13.7304 11.6663ZM13.9231 10.5134C14.4229 10.5134 14.8441 10.4137 15.1868 10.2143C15.5331 10.0149 15.7937 9.75751 15.9686 9.44209C16.1471 9.12667 16.2363 8.79494 16.2363 8.44689V7.27223C16.1828 7.33749 16.065 7.39731 15.8829 7.45169C15.7044 7.50245 15.4974 7.54777 15.2618 7.58765C15.0297 7.6239 14.8031 7.65653 14.5817 7.68553C14.364 7.71091 14.1873 7.73267 14.0516 7.75079C13.7232 7.7943 13.4162 7.865 13.1306 7.96289C12.8486 8.05715 12.6202 8.20036 12.4453 8.39251C12.2739 8.58103 12.1882 8.83845 12.1882 9.16474C12.1882 9.61068 12.3507 9.94785 12.6755 10.1763C13.0039 10.401 13.4198 10.5134 13.9231 10.5134Z"
      fill="url(#paint1_linear_4641_4462)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4641_4462"
        x1="8.58912"
        y1="-1.75033"
        x2="8.58912"
        y2="12.6663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5172EA" />
        <stop offset="1" stopColor="#7A5AEE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4641_4462"
        x1="8.58912"
        y1="-1.75033"
        x2="8.58912"
        y2="12.6663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5172EA" />
        <stop offset="1" stopColor="#7A5AEE" />
      </linearGradient>
    </defs>
  </svg>
);

export default FontSettingsIcon;
