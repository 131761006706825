import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AskQuestionButton } from "./";
import { APPLICATION_ROUTES } from "../../../../../common/utils/routes";

const AuthModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 10000;
`;

const AuthModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 21px;
  width: 420px;
  height: 170px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
  cursor: default;
  z-index: 10000;
`;

const AuthModalWindowTitle = styled.div`
  width: 300px;
  color: #1e1e1e;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

const AuthModalWindowButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 21px;
`;

const AuthModalWindow = ({ isOpen, onClose }) => {
  const handleCloseAuthModalWindow = useCallback((event) => {
    event.stopPropagation();
    onClose(false);
  }, []);

  return (
    <AuthModalWindowWrapper
      isOpen={isOpen}
      onClick={handleCloseAuthModalWindow}
    >
      <AuthModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <AuthModalWindowTitle>
          Авторизуйтесь, чтобы задать вопрос
        </AuthModalWindowTitle>

        <AuthModalWindowButtons>
          <Link to={APPLICATION_ROUTES.REGISTRATION}>
            <AskQuestionButton>Регистрация</AskQuestionButton>
          </Link>
          <Link to={APPLICATION_ROUTES.AUTH}>
            <AskQuestionButton>Вход</AskQuestionButton>
          </Link>
        </AuthModalWindowButtons>
      </AuthModalWindowContainer>
    </AuthModalWindowWrapper>
  );
};

export default AuthModalWindow;
