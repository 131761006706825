import React from "react";

const AdminPageCopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_5446_11897)">
        <path
          d="M10.9336 18H4.21875C2.66789 18 1.40625 16.7384 1.40625 15.1875V5.66016C1.40625 4.1093 2.66789 2.84766 4.21875 2.84766H10.9336C12.4845 2.84766 13.7461 4.1093 13.7461 5.66016V15.1875C13.7461 16.7384 12.4845 18 10.9336 18ZM4.21875 4.25391C3.44339 4.25391 2.8125 4.8848 2.8125 5.66016V15.1875C2.8125 15.9629 3.44339 16.5938 4.21875 16.5938H10.9336C11.709 16.5938 12.3398 15.9629 12.3398 15.1875V5.66016C12.3398 4.8848 11.709 4.25391 10.9336 4.25391H4.21875ZM16.5586 13.4297V2.8125C16.5586 1.26164 15.297 0 13.7461 0H5.94141C5.55304 0 5.23828 0.314758 5.23828 0.703125C5.23828 1.09149 5.55304 1.40625 5.94141 1.40625H13.7461C14.5215 1.40625 15.1523 2.03714 15.1523 2.8125V13.4297C15.1523 13.8181 15.4671 14.1328 15.8555 14.1328C16.2438 14.1328 16.5586 13.8181 16.5586 13.4297Z"
          fill="#CACACA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5446_11897">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdminPageCopyIcon;
