import mqtt from "mqtt";
import { useHooks } from "../useHooks/useHooks";

const hooks = useHooks();

const mqttHost = process.env.REACT_APP_MQTT_HOST || "";
const mqttUser = process.env.REACT_APP_MQTT_USER || "";
const mqttPass = process.env.REACT_APP_MQTT_PASS || "";

let client;
let isConnected = false;
let topic = "";

function createClient(host, user, pass) {
  const client = mqtt.connect(host, {
    username: user,
    password: pass,
    clientId: "client_" + Math.random().toString(16).substring(2, 8),
  });

  return client;
}

async function subscribeOnTopic(client, topic) {
  return new Promise((resolve, reject) => {
    client.on("connect", () => {
      // console.log("[app] mqtt connected successfully");
      client.subscribe(topic);
      resolve(true);
    });
  });
}

async function subscribeOnMessages(client, topic, hooks) {
  client.on("message", (topic, message) => {
    //  console.log('on message')

    const decodedMessage = new TextDecoder().decode(message);
    const mqttPackage = JSON.parse(decodedMessage);

    // process mqtt events with eventName
    hooks.invokeHook(mqttPackage.eventName, mqttPackage);
  });
}

async function connect() {
  //   console.log("[app] mqtt connecting");
  client = createClient(mqttHost, mqttUser, mqttPass);
  if (isConnected) disconnect();

  isConnected = true;
}

async function subscribeOn(topic) {
  await subscribeOnTopic(client, topic)
    //  .then((e) => console.log("[app] mqtt listen on topic: " + topic))
    .catch((e) => console.log("mqtt error: ", e));

  await subscribeOnMessages(client, topic, hooks);
}

function disconnect() {
  client?.end();
  isConnected = false;

  //   console.log("[app] mqtt disconnected");
}

async function sendToMqtt(topic, mqttModelDto) {
  // const topic = mqttModelDto.toUser._id.toString();
  //   console.log("[app] mqtt send message on topic:", topic);

  return new Promise((resolve, reject) => {
    client.publish(topic, JSON.stringify(mqttModelDto), (err) => {
      resolve(!err);
    });
  });
}

function on(events, hook) {
  const eventNames = Array.isArray(events) ? events : [events];
  hooks.addHooks(eventNames, hook);
}

export function useMqtt() {
  return {
    connect,
    disconnect,
    subscribeOn,
    isConnected,
    sendToMqtt,
    on,
  };
}
