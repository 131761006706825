import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../../user_data_context";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  getInlineStyles,
  replacePlaceholderWithStudentName,
  replaceSpacesWithNbsp,
  entityStyleFn,
} from "../text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../text_editor/editor_styled_components";

const ArticleTitleContainer = styled(TextEditorViewComponent)`
  flex: 1;
  color: #fff;
  font-weight: 600;
  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "18px" : "31px"};
  line-height: 129%;
  letter-spacing: -0.479px;
  max-height: ${({ inComponent }) =>
    inComponent === "admin_page" ? "300px" : "540px"};
  min-height: ${({ inComponent }) =>
    inComponent === "admin_page" ? "90px" : "160px"};
  ${({ inComponent }) => inComponent === "admin_page" && "padding-top: 4px"};
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ArticleTitle = ({
  article,
  inComponent,
  appColorTheme,
  setTextBibleModalData,
}) => {
  const { userData } = useUserDataContext();

  const htmlContentRef = useRef(null);

  const [html, setHtml] = useState("");

  const currentUserName = userData.user_name || "Пользователь";
  const articleBackground = article.article_background_color;

  useEffect(() => {
    if (article.article_title?.blocks?.length) {
      try {
        let contentState = convertFromRaw(article.article_title);

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(appColorTheme, articleBackground),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [article.article_title, currentUserName]);

  const handleBibleVerseClick = useCallback((event) => {
    event.stopPropagation();

    const bibleId = event.target.dataset.bibleId;
    const bookId = event.target.dataset.bookId;
    const chapterId = event.target.dataset.chapterId;
    const selectedVerses = event.target.dataset.selectedVerses;

    setTextBibleModalData({ bibleId, bookId, chapterId, selectedVerses });
  }, []);

  useEffect(() => {
    const elements = htmlContentRef.current?.querySelectorAll(".bible-verse");

    elements?.forEach((element) => {
      element.addEventListener("click", handleBibleVerseClick);
    });

    return () => {
      elements?.forEach((element) => {
        element.removeEventListener("click", handleBibleVerseClick);
      });
    };
  }, [html]);

  return (
    <ArticleTitleContainer
      inComponent={inComponent}
      ref={htmlContentRef}
      dangerouslySetInnerHTML={{ __html: html }}
      withBackground={true}
    />
  );
};

export default memo(ArticleTitle);
