import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import { BASE_API_URL } from "../../../../../common/endpoints";
import { SearchIcon } from "../../../../../common/assets/icons";
import ModalWindowMainButtons from "../modal_window_main_buttons";
import SpinnerLoader from "../../../../../common/components/loaders/spinner_loader";
import UserRolesCourseOrGroupItem from "../../user_roles_settings/user_administration/course_or_group_preview";
import ConfirmationModalWindow from "../confirmation_modal_window";

const AddingCourseModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const AddingCourseModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 490px;
  border-radius: 15px;
  background: #fff;
`;

const SearchCoursesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 22px 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #cacaca;

  & > input {
    font-size: 16px;
    color: #1e1e1e;
    flex: 1;
    line-height: 24px;
  }
`;

const CoursesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 12px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const ButtonsContainer = styled.div`
  padding: 6px 12px 10px;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AddingCourseModalWindow = ({
  component,
  userRolesSettingsData,
  setUserRolesSettingsData,
  setOpen,
}) => {
  const [coursesList, setCoursesList] = useState([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [searchCourseInput, setSearchCourseInput] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axios.get(BASE_API_URL + "/courses");
      setCoursesList(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCourses(true);
      await fetchCourses();
      setIsLoadingCourses(false);
    };

    fetchData();
  }, []);

  const filterCourses = useCallback(
    (coursesList, searchInput, addedCourses) => {
      const filteredCourses = coursesList.filter(
        (course) =>
          !addedCourses.find((addedCourse) => addedCourse._id === course._id) &&
          course.course_title.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredCourses(filteredCourses);
    },
    []
  );

  useEffect(() => {
    if (coursesList.length) {
      if (component === "courses_author") {
        filterCourses(
          coursesList,
          searchCourseInput,
          userRolesSettingsData.user_publicity.courses
        );
      } else if (component === "courses_admin") {
        filterCourses(
          coursesList,
          searchCourseInput,
          userRolesSettingsData.user_access.admin_of_courses
        );
      }
    }
  }, [searchCourseInput, coursesList, component, userRolesSettingsData]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen("");
  }, []);

  const handleSearchCoursesInput = useCallback((event) => {
    setSearchCourseInput(event.target.value);
  }, []);

  const handleSelectButton = useCallback(() => {
    if (selectedCourse._id) {
      if (component === "courses_author") {
        setUserRolesSettingsData((prevState) => ({
          ...prevState,
          user_publicity: {
            ...prevState.user_publicity,
            courses: [
              ...prevState.user_publicity.courses,
              { _id: selectedCourse._id },
            ],
          },
        }));
      } else if (component === "courses_admin") {
        setUserRolesSettingsData((prevState) => ({
          ...prevState,
          user_access: {
            ...prevState.user_access,
            admin_of_courses: [
              ...prevState.user_access.admin_of_courses,
              { _id: selectedCourse._id },
            ],
          },
        }));
      }
    }
    setOpen("");
  }, [selectedCourse]);

  const handleOpenConfirmModal = useCallback((event) => {
    event.stopPropagation();
    setOpenConfirmModal(true);
  }, []);

  const handleSelectCourse = useCallback((course) => {
    setSelectedCourse((prevSelectedCourse) => {
      if (prevSelectedCourse._id === course._id) {
        return {};
      }
      return { _id: course._id, title: course.course_title };
    });
  }, []);

  return (
    <AddingCourseModalWindowWrapper onClick={handleClose}>
      <AddingCourseModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <SearchCoursesContainer>
          <SearchIcon />
          <input
            placeholder={"Найти курс"}
            type="text"
            value={searchCourseInput}
            onChange={handleSearchCoursesInput}
          />
        </SearchCoursesContainer>

        {!isLoadingCourses ? (
          <CoursesList>
            {filteredCourses.map((course) => {
              return (
                <UserRolesCourseOrGroupItem
                  key={course._id}
                  item={course}
                  component={"modal_course"}
                  selected={course._id === selectedCourse._id}
                  handleSelectItem={handleSelectCourse}
                />
              );
            })}
          </CoursesList>
        ) : (
          <LoaderContainer>
            <SpinnerLoader />
          </LoaderContainer>
        )}

        <ButtonsContainer>
          <ModalWindowMainButtons
            handleClose={handleClose}
            onClickConfirmButton={handleOpenConfirmModal}
            isDisableConfirmButton={!selectedCourse._id}
          />
        </ButtonsContainer>
      </AddingCourseModalWindowContainer>

      {openConfirmModal && (
        <ConfirmationModalWindow
          setOpen={setOpenConfirmModal}
          userRolesSettingsData={userRolesSettingsData}
          action="adding"
          selectedObjectType={"course"}
          selectedObjectData={selectedCourse}
          onClickConfirmButton={handleSelectButton}
        />
      )}
    </AddingCourseModalWindowWrapper>
  );
};

export default memo(AddingCourseModalWindow);
