import React from "react";
import styled from "styled-components";
import LayoutComponet from "../../common/components/layout_componet";
import SponsorshipVariants from "./donate_page_components/sponsorship_variants";
import DonateToCards from "./donate_page_components/donate_to_cards";

const DonateTitle = styled.h1`
  width: 650px;
  margin: 0 auto;
  padding-top: 100px;
  color: #000;
  text-align: center;
  font-family: Raleway;
  font-size: 54px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1.2px;

  @media (max-width: 744px) {
    width: 100%;
    padding-top: 55px;
    font-size: 40px;
    line-height: 140%;
  }
`;

const OurMainGoalTitle = styled.h3`
  width: 420px;
  margin: 44px auto 0;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 744px) {
    width: 100%;
    margin-top: 34px;
    font-size: 16px;
    line-height: 150%;
  }
`;

const WaysToSupportUsTitle = styled.h3`
  width: 700px;
  margin: 100px auto 0;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: 744px) {
    width: 100%;
    margin-top: 60px;
    font-size: 24px;
    line-height: 150%;
  }
`;

const DonatePage = () => {
  return (
    <LayoutComponet>
      <DonateTitle>Стань частью команды OnBible</DonateTitle>
      <OurMainGoalTitle>
        Наша главная цель - сделать изучение Библии максимально простым и
        приятным для тебя.
      </OurMainGoalTitle>
      <WaysToSupportUsTitle>
        Поддержи нас удобным для тебя способом
      </WaysToSupportUsTitle>

      <SponsorshipVariants />
      <DonateToCards />
    </LayoutComponet>
  );
};

export default DonatePage;
