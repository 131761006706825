import React, { useCallback, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import BibleModalWindow from "../../../../../common/components/modal_windows/bible_modal_window";
import { BASE_API_URL } from "../../../../../common/endpoints";
import DailyVerseCalendar from "./daily_verse_calendar";

const DailyVerseSettingsContainer = styled.div`
  padding: 26px 24px;
`;

const SelectedDailyVerseContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const SelectedDailyVerseText = styled.div`
  padding: 14px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #fff;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 24px;
`;

const SelectedDailyVerseAddress = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  gap: 10px;
  height: 30px;
  width: max-content;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  cursor: pointer;
  color: #383838;

  &: hover {
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const DailyVerseSettings = ({ homePageDailyVerse, setHomePageDailyVerse }) => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [editedVersesData, setEditedVersesData] = useState({});

  const dayWithoutVerse = !homePageDailyVerse?.verse_data?.verseAddress?.length;

  const handleOpenBibleModalWindow = useCallback(() => {
    setOpenBibleModalWindow(true);
  }, []);

  const updateBibleVerse = useCallback((addedVerse) => {
    axios
      .patch(BASE_API_URL + "/settings/daily_bible_verse", {
        scope_props: {
          bible_verse: addedVerse,
        },
      })
      .then((response) => {
        setHomePageDailyVerse(addedVerse);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, []);

  const onClickAddressButton = useCallback(
    (event) => {
      event.stopPropagation();
      handleOpenBibleModalWindow();

      if (homePageDailyVerse.verseText) {
        setEditedVersesData({
          versesData: homePageDailyVerse.versesData,
          versesParams: homePageDailyVerse.versesParams,
        });
      }
    },
    [homePageDailyVerse]
  );

  return (
    <DailyVerseSettingsContainer onClick={(e) => e.stopPropagation()}>
      {/*<DailyVerseCalendar /> */}

      <SelectedDailyVerseContainer>
        <SelectedDailyVerseText>
          {homePageDailyVerse?.verseText}
        </SelectedDailyVerseText>
        <SelectedDailyVerseAddress onClick={onClickAddressButton}>
          {homePageDailyVerse?.verseAddress || "Выберите стих"}
        </SelectedDailyVerseAddress>
      </SelectedDailyVerseContainer>

      {openBibleModalWindow && (
        <BibleModalWindow
          editedVersesData={editedVersesData}
          setEditedVersesData={setEditedVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          handleAddBibleVerses={updateBibleVerse}
          inComponent={"admin_page_daily_verse"}
        />
      )}
    </DailyVerseSettingsContainer>
  );
};

export default DailyVerseSettings;
