import React from "react";

const CheckIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3886_5739)">
        <path
          d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM12 21.8182C6.58618 21.8182 2.18182 17.4137 2.18182 12C2.18182 6.58625 6.58618 2.18182 12 2.18182C17.4138 2.18182 21.8182 6.58625 21.8182 12C21.8182 17.4137 17.4137 21.8182 12 21.8182Z"
          fill="url(#paint0_linear_3886_5739)"
        />
        <path
          d="M16.5005 7.75736L10.3293 13.9285L7.50096 11.1C7.075 10.6741 6.38424 10.674 5.9582 11.1C5.53216 11.526 5.53216 12.2167 5.9582 12.6427L9.55791 16.2426C9.76249 16.4472 10.0399 16.5622 10.3293 16.5622H10.3293C10.6186 16.5622 10.8961 16.4472 11.1007 16.2427L18.0433 9.30019C18.4693 8.87416 18.4693 8.18347 18.0433 7.75743C17.6173 7.33139 16.9266 7.33132 16.5005 7.75736Z"
          fill="url(#paint1_linear_3886_5739)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3886_5739"
          x1="5.78875"
          y1="23.1243"
          x2="19.1912"
          y2="0.0341448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3886_5739"
          x1="8.70771"
          y1="16.2292"
          x2="12.8712"
          y2="6.22626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <clipPath id="clip0_3886_5739">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
