import React from "react";
import styled from "styled-components";
import AdminPageGroupInfoIcon from "../../../../../../common/assets/icons/admin_page_icons/admin_page_group_info_icon";
import { UserRolesInfoButton } from "../../user_roles_styled_components";

const LeaderUserGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 9px;
  border-radius: 8px;
  border: 1px solid #5172ea;
`;

const LeaderUserGroupImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background: linear-gradient(88deg, #05baa4 -19%, #2046e9 129.9%);
  border-radius: 50%;
  color: #fff;
  font-size: 8px;
  letter-spacing: 0.231px;
  overflow: hidden;
`;

const LeaderUserGroupImage = styled.div``;

const LeaderUserGroupName = styled.div`
  flex: 1;
  margin: 0 7px;
  overflow: hidden;
  color: #383838;
  font-size: 14px;
  line-height: 18px;
  user-select: text;
`;

const LeaderUserGroup = () => {
  const groupName = "Наставники Группа С";
  const userImage = null;

  return (
    <LeaderUserGroupContainer>
      <LeaderUserGroupImageWrapper>
        {userImage ? (
          <LeaderUserGroupImage image={userImage} />
        ) : (
          <p>{groupName[0].toUpperCase()}</p>
        )}
      </LeaderUserGroupImageWrapper>

      <LeaderUserGroupName>{groupName}</LeaderUserGroupName>

      <UserRolesInfoButton>
        <AdminPageGroupInfoIcon />
      </UserRolesInfoButton>
    </LeaderUserGroupContainer>
  );
};

export default LeaderUserGroup;
