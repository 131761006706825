import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../../../../common/components/checkbox";
import AskQuestionArrowIcon from "../../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import AdminPageWhiteArrowIcon from "../../../../../../common/assets/icons/admin_page_icons/admin_page_white_arrow_icon";
import {
  UserRoleSetCount,
  UserRoleSetCountContainer,
  UserTeachingExtendedSettings,
  WhiteArrowLeft,
  WhiteArrowRight,
} from "../../user_roles_styled_components";
import LeaderUserWards from "./leader_user_wards";
import LeaderUserGroups from "./leader_user_groups";
import {
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../../../admin_page_components/admin_page_styled_components";

const LeaderUser = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
  setSelectedUserInfo,
  usersList,
  setOpenUsersListModal,
  setUsersListByRole,
}) => {
  const [extendedUserIsLeader, setExtendedUserIsLeader] = useState(false);
  const [groups, setGroups] = useState([]);

  const isInvite = userRolesSettingsData.user_type === "invite";
  const userIsLeader = userRolesSettingsData.user_access.is_leader;
  const countOfWards =
    userRolesSettingsData.user_access.leader_role.max_wards_amount;
  const wards = userRolesSettingsData.user_access.leader_role.user_wards;

  useEffect(() => {
    if (!userIsLeader) {
      setExtendedUserIsLeader(false);
    }
  }, [userIsLeader]);

  const handleUserIsLeader = useCallback(
    (event) => {
      event.stopPropagation();
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          is_leader: !prevState.user_access.is_leader,
        },
      }));

      setUsersList((prevState) =>
        prevState.map((user) =>
          user._id === userRolesSettingsData._id || user._id === "new_invite"
            ? {
                ...user,
                user_access: {
                  ...user.user_access,
                  is_leader: !user.user_access.is_leader,
                },
              }
            : user
        )
      );
    },
    [userRolesSettingsData]
  );

  const handleExtendUserIsLeader = useCallback(() => {
    if (userIsLeader) {
      setExtendedUserIsLeader((prevState) => !prevState);
    }
  }, [userIsLeader]);

  const handleMinusCountOfWards = useCallback(() => {
    if (countOfWards > 1) {
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          leader_role: {
            ...prevState.user_access.leader_role,
            max_wards_amount:
              prevState.user_access.leader_role.max_wards_amount - 1,
          },
        },
      }));
    }
  }, [countOfWards]);

  const handlePlusCountOfWards = useCallback(() => {
    if (countOfWards < 1000) {
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          leader_role: {
            ...prevState.user_access.leader_role,
            max_wards_amount:
              prevState.user_access.leader_role.max_wards_amount + 1,
          },
        },
      }));
    }
  }, [countOfWards]);

  return (
    <div>
      <AdminPageSettingsHeader
        clickable={userIsLeader}
        onClick={handleExtendUserIsLeader}
      >
        <Checkbox
          checked={userIsLeader}
          onClickCheckbox={handleUserIsLeader}
          color={"#1e1e1e"}
          fontSize={"14px"}
        >
          Лидер
        </Checkbox>
        {userIsLeader && (
          <AdminPageSettingsHeaderArrow extendedSection={extendedUserIsLeader}>
            <AskQuestionArrowIcon />
          </AdminPageSettingsHeaderArrow>
        )}
      </AdminPageSettingsHeader>

      {extendedUserIsLeader && (
        <UserTeachingExtendedSettings>
          <UserRoleSetCountContainer>
            <UserRoleSetCount>
              <WhiteArrowLeft onClick={handleMinusCountOfWards}>
                <AdminPageWhiteArrowIcon />
              </WhiteArrowLeft>
              <h5>{countOfWards}</h5>
              <WhiteArrowRight onClick={handlePlusCountOfWards}>
                <AdminPageWhiteArrowIcon />
              </WhiteArrowRight>
            </UserRoleSetCount>
            Максимум подопечных
          </UserRoleSetCountContainer>

          {!isInvite && (
            <>
              <LeaderUserWards
                wards={wards}
                setSelectedUserInfo={setSelectedUserInfo}
                setUserRolesSettingsData={setUserRolesSettingsData}
                userRolesSettingsData={userRolesSettingsData}
                usersList={usersList}
                setUsersListByRole={setUsersListByRole}
                setOpenUsersListModal={setOpenUsersListModal}
              />
              <LeaderUserGroups groups={groups} />
            </>
          )}
        </UserTeachingExtendedSettings>
      )}
    </div>
  );
};

export default LeaderUser;
