import React from "react";
import styled from "styled-components";

const EmojiContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 83%;
`;

const EmojiItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 11px;
  background: rgb(167, 139, 226, 0.5);
  padding: 3px 8px;
  cursor: pointer;

  &:hover {
    background: rgb(167, 139, 226, 0.7);
    transition: 0.2s;
  }
`;

const EmojiItem = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
`;

const EmojiCount = styled.div`
  font-weight: 600;
  line-height: 14px;
  font-size: 14px;
  color: #fff;
`;

const MessageEmoji = ({ messageEmoji }) => {
  return (
    <EmojiContainer>
      {!!messageEmoji?.length &&
        messageEmoji.map((emoji, index) => {
          if (parseInt(emoji.code, 16)) {
            return (
              <EmojiItemContainer key={index}>
                <EmojiItem>
                  {String.fromCodePoint(parseInt(emoji.code, 16))}
                </EmojiItem>
                <EmojiCount>{emoji.count}</EmojiCount>
              </EmojiItemContainer>
            );
          } else return;
        })}
    </EmojiContainer>
  );
};

export default MessageEmoji;
