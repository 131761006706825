import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import UserQuestion from "./user_question";

const UsersQuestionsListContainer = styled.div`
  margin-top: 8px;
`;

const UsersQuestionsCount = styled.div`
  padding-bottom: 8px;
  color: #000;
  text-align: right;
  font-size: 12px;
  line-height: 16px;
`;

const UsersQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuestionsListIsEmpty = styled.div`
  text-align: center;
  color: #383838;
  padding: 30px;
  font-size: 16px;
`;

const UsersQuestionsList = ({
  questionsList,
  questionsFilterSettings,
  questionSettingsData,
  questionSettingsMemoData,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  setOpenEditQuestionModal,
}) => {
  const [filteredQuestionsList, setFilteredQuestionsList] = useState([]);

  useEffect(() => {
    if (questionsList.length) {
      const statusFilter = questionsFilterSettings?.question_status;
      const recipientFilter = questionsFilterSettings?.question_recipient || {};
      const searchWords = questionsFilterSettings?.search_words || [];
      const questionBible = questionsFilterSettings?.question_bible || [];
      const dateRange = questionsFilterSettings?.question_date_range || [];

      let filtered = [...questionsList];

      if (statusFilter !== "") {
        filtered = filtered.filter(
          (question) => question.question_status === statusFilter
        );
      }

      if (recipientFilter._id) {
        filtered = filtered.filter(
          (question) => question.question_to_author?._id === recipientFilter._id
        );
      }

      if (searchWords.length > 0) {
        filtered = filtered.filter((question) => {
          const questionText = question.question_text.toLowerCase();
          const questionTags = question.question_tags
            .toLowerCase()
            .split(/\s*,\s*/);

          return searchWords.some(
            (word) =>
              questionText.includes(word.toLowerCase()) ||
              questionTags.some((tag) => tag.includes(word.toLowerCase()))
          );
        });
      }

      if (questionBible.length > 0) {
        filtered = filtered.filter((question) => {
          return questionBible.some((filter) => {
            const matchingVerse = question.question_bible_verses.find(
              (verse) => {
                return (
                  verse.versesParams.bookId === filter.bibleBookCode &&
                  (!filter.bibleChapters.length ||
                    filter.bibleChapters.includes(verse.versesParams.chapterId))
                );
              }
            );
            return matchingVerse;
          });
        });
      }

      if (dateRange.length > 0) {
        filtered = filtered.filter((question) => {
          const createdAt = new Date(question.question_created_at);
          return dateRange.some((range) => {
            if (!range.year) return true;
            const questionYear = createdAt.getFullYear();
            if (range.year !== questionYear) return false;
            if (range.monthOfYear !== null) {
              const questionMonth = createdAt.getMonth();
              if (range.monthOfYear !== questionMonth) return false;
            }
            if (
              range.dateRange.startRangeDate &&
              range.dateRange.endRangeDate
            ) {
              const startDate = new Date(range.dateRange.startRangeDate);
              startDate.setHours(0, 0, 0, 0);
              const endDate = new Date(range.dateRange.endRangeDate);
              endDate.setHours(23, 59, 59, 999);
              return createdAt >= startDate && createdAt <= endDate;
            }
            return true;
          });
        });
      }

      setFilteredQuestionsList(filtered);
    }
  }, [questionsList, questionsFilterSettings]);

  return (
    <UsersQuestionsListContainer>
      <UsersQuestionsCount>
        Найдено: {filteredQuestionsList?.length || 0}
      </UsersQuestionsCount>
      <UsersQuestions>
        {filteredQuestionsList.length ? (
          filteredQuestionsList?.map((question, index) => {
            return (
              <UserQuestion
                key={index}
                question={question}
                setQuestionSettingsData={setQuestionSettingsData}
                setQuestionSettingsMemoData={setQuestionSettingsMemoData}
                setOpenEditQuestionModal={setOpenEditQuestionModal}
                questionSettingsData={questionSettingsData}
                questionSettingsMemoData={questionSettingsMemoData}
                questionsFilterSettings={questionsFilterSettings}
              />
            );
          })
        ) : !filteredQuestionsList.length && !!questionsList.length ? (
          <QuestionsListIsEmpty>
            Вопросы по заданным фильтрам - не найдены
          </QuestionsListIsEmpty>
        ) : (
          <QuestionsListIsEmpty>Список вопросов пуст</QuestionsListIsEmpty>
        )}
      </UsersQuestions>
    </UsersQuestionsListContainer>
  );
};

export default memo(UsersQuestionsList);
