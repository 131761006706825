import React from "react";

const AsTextVariantIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80469 3.30444C2.80469 2.75216 3.2524 2.30444 3.80469 2.30444H12.5002H21.1957C21.7479 2.30444 22.1957 2.75216 22.1957 3.30444V6.56525C22.1957 7.11753 21.7479 7.56525 21.1957 7.56525C20.6434 7.56525 20.1957 7.11753 20.1957 6.56525V4.30444H13.5002V19.6954H15.761C16.3133 19.6954 16.761 20.1431 16.761 20.6954C16.761 21.2477 16.3133 21.6954 15.761 21.6954H12.5002H9.23936C8.68708 21.6954 8.23936 21.2477 8.23936 20.6954C8.23936 20.1431 8.68708 19.6954 9.23936 19.6954H11.5002V4.30444H4.80469V6.56525C4.80469 7.11753 4.35697 7.56525 3.80469 7.56525C3.2524 7.56525 2.80469 7.11753 2.80469 6.56525V3.30444Z"
        fill="url(#paint0_linear_4012_6034)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4012_6034"
          x1="12.0315"
          y1="-1.26007"
          x2="12.0315"
          y2="23.4064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AsTextVariantIcon;
