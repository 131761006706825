import React from "react";

const TextIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M4 7V4H20V7"
        stroke="url(#paint0_linear_3886_5696)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 20H15"
        stroke="url(#paint1_linear_3886_5696)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4V20"
        stroke="url(#paint2_linear_3886_5696)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_5696"
          x1="7.85916"
          y1="6.89053"
          x2="8.2742"
          y2="3.07702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3886_5696"
          x1="10.4472"
          y1="20.9635"
          x2="10.5705"
          y2="19.6892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3886_5696"
          x1="12.2412"
          y1="19.4162"
          x2="14.4317"
          y2="19.1803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TextIcon;
