import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import BiblePageCompareIcon from "../../../common/assets/icons/bible_page_icons/bible_page_compare_icon";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import BiblePageSidebarCopy from "./bible_page_sidebar_copy";
import BiblePageSidebarShare from "./bible_page_sidebar_share";

export const BiblePageSidebarItem = styled.div`
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    scale: 1.15;
  }
`;

const BiblePageSidebarContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 71px;
  height: 218px;
  padding: 28px 0 28px 30px;
  border-radius: 0px 15px 15px 0px;
  background: #fff;
  transition: all 0.4s;
  z-index: 99;

  span {
    display: none;
  }

  @media (max-width: 1200px) {
    left: -53px;

    span {
      display: block;
    }
  }

  &:hover {
    left: 0;

    span {
      display: none;
    }
  }
`;

const BiblePageSidebarArrow = styled.span`
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  left: 55px;
  width: 14px;
  transform: rotate(180deg);
`;

const BiblePageSidebar = ({
  bibleTranslations,
  selectedVerses,
  activeMainSection,
  selectedSearchVerse,
  setActiveMainSection,
  setSelectedVerses,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectVerses = searchParams.get("selectVerses");
  const selectBible = searchParams.get("selectBible");
  const bibleId = searchParams.get("bibleId");

  const openVersesCompare = useCallback(() => {
    if (activeMainSection === "bible_chapter") {
      setActiveMainSection("bible_compare");
      setSearchParams((prev) => {
        prev.set("bibleId", selectBible);
        prev.delete("2bibleId");
        return prev;
      });
    }

    if (activeMainSection === "bible_search") {
      setActiveMainSection("bible_compare");
      setSearchParams((prev) => {
        prev.set("selectBible", bibleId);
        prev.set("selectVerses", selectedSearchVerse.verse_num);
        prev.set("bookId", selectedSearchVerse.book_code);
        prev.set("chapterId", selectedSearchVerse.chapter_num);
        return prev;
      });
      setSelectedVerses({ bible: bibleId, verses: [selectedSearchVerse] });
    }
  }, [
    selectBible,
    selectVerses,
    bibleId,
    selectedSearchVerse,
    activeMainSection,
  ]);

  return (
    <>
      {(!!selectVerses || selectedSearchVerse) && (
        <BiblePageSidebarContainer>
          <BiblePageSidebarShare
            selectedSearchVerse={selectedSearchVerse}
            activeMainSection={activeMainSection}
          />
          <BiblePageSidebarCopy
            bibleTranslations={bibleTranslations}
            selectedVerses={selectedVerses}
            selectedSearchVerse={selectedSearchVerse}
            activeMainSection={activeMainSection}
          />

          <BiblePageSidebarItem onClick={openVersesCompare}>
            <BiblePageCompareIcon />
          </BiblePageSidebarItem>

          <BiblePageSidebarArrow>
            <LeftArrowIcon color={"rgb(130, 130, 130)"} />
          </BiblePageSidebarArrow>
        </BiblePageSidebarContainer>
      )}
    </>
  );
};

export default memo(BiblePageSidebar);
