import React from "react";

const SendIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82298 1.466C10.059 1.23548 10.059 0.861734 9.82298 0.631217C9.58695 0.400699 9.20426 0.400699 8.96823 0.631217L4.85165 4.65164L4.03177 3.85091C3.79574 3.6204 3.41305 3.6204 3.17702 3.85091C2.94099 4.08143 2.94099 4.45517 3.17702 4.68569L3.9969 5.48642L3.35165 6.1166L1.03177 3.85091C0.795737 3.6204 0.413055 3.6204 0.177023 3.85091C-0.0590078 4.08143 -0.0590078 4.45517 0.177023 4.68569L2.92428 7.36877C3.16031 7.59929 3.54299 7.59929 3.77902 7.36877L4.85165 6.3212L5.92428 7.36877C6.16031 7.59929 6.54299 7.59929 6.77902 7.36877L12.823 1.466C13.059 1.23548 13.059 0.861734 12.823 0.631217C12.5869 0.400699 12.2043 0.400699 11.9682 0.631217L6.35165 6.1166L5.70639 5.48642L9.82298 1.466Z"
        fill="#828282"
      />
    </svg>
  );
};

export default SendIcon;
