export const API_EVENTS = {
  EVENT_TEXT_MESSAGE: "event-new-text-message",
  EVENT_USER_SUBSCRIBED: "event-subscribed",
  EVENT_USER_UNSUBSCRIBED: "event-unsubscribed",
  EVENT_CONTACTS_SENT: "event-contacts-sent",

  EVENT_LESSON_MESSAGE: "event-new-lesson-message",
  EVENT_LESSON_COMPLETED: "event-lesson-completed",
  EVENT_LESSON_COMPLETED_NOTIF: "event-lesson-completed-notif",

  EVENT_REQUEST_CREATED: "event-new-request",
  EVENT_REQUEST_ACCEPTED: "event-request-accepted",
};
