import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { SearchIcon } from "../../../common/assets/icons";
import Checkbox from "../../../common/components/checkbox";
import RemoveIcon from "../../../common/assets/icons/remove_icon";

const BibleSearchPanelWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const BibleSearchPanelContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  min-height: 30px;
  padding: 0 10px 0 14px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  ${({ inputIsEmpty }) => inputIsEmpty && "border: 1px solid red;"}

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const BibleSearchInput = styled.input`
  flex: 1;
  font-size: 13px;
  width: 100%;
  letter-spacing: -0.13px;
`;

const SearchIconContainer = styled.button`
  display: flex;
  align-items: center;
  width: 15px;
  height: 16px;
  cursor: pointer;

  &:hover {
    scale: 1.1;
    transition: 0.2s;
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 13px;
  height: 13px;
`;

const SearchCheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 12px;
  font-size: 12px;
`;

const BibleSearchPanel = ({
  activeMainSection,
  bibleSearchPlace,
  bibleSearchInput,
  setActiveMainSection,
  setBibleSearchResults,
  setBibleSearchInput,
  setExpandedSectionName,
  setIsLoading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [wholeWordCheckbox, setWholeWordCheckbox] = useState(false);
  const [withRegisterCheckbox, setWithRegisterCheckbox] = useState(false);
  const [inputIsEmpty, setInputIsEmpty] = useState(false);
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon", remove_icon

  const bibleSearchTranslate = searchParams.get("bibleId");

  const onSearchSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (bibleSearchInput.length > 1) {
        setIsLoading(true);
        setActiveMainSection("bible_search");
        try {
          const response = await axios.post(
            "https://api.on-bible.com/bible/verses/lookup",
            {
              bible_code: bibleSearchTranslate,
              search_value: bibleSearchInput,
              search_option_place: bibleSearchPlace,
              search_option_whole_word: wholeWordCheckbox,
              search_option_with_register: withRegisterCheckbox,
            }
          );
          setBibleSearchResults({
            searchBible: bibleSearchTranslate,
            searchTestament: bibleSearchPlace,
            searchWord: bibleSearchInput,
            searchResults: response.data.results,
          });
        } catch (error) {
          console.error("Ошибка при отправке запроса", error);
        }

        setExpandedSectionName("");
        setShowedInputIcon("remove_icon");
        setSearchParams((prev) => {
          prev.delete("2bibleId");
          prev.delete("selectVerses");
          prev.delete("selectBible");
          return prev;
        });
        setIsLoading(false);
      } else {
        setInputIsEmpty(true);

        setTimeout(() => {
          setInputIsEmpty(false);
        }, 2000);
      }
    },
    [
      bibleSearchTranslate,
      bibleSearchInput,
      bibleSearchPlace,
      wholeWordCheckbox,
      withRegisterCheckbox,
    ]
  );

  useEffect(() => {
    if (bibleSearchInput && activeMainSection === "bible_search") {
      const fakeEvent = { preventDefault: () => {} };
      onSearchSubmit(fakeEvent);
    }

    if (!bibleSearchInput.length && activeMainSection === "bible_search") {
      setInputIsEmpty(true);

      setTimeout(() => {
        setInputIsEmpty(false);
      }, 2000);
    }
  }, [
    bibleSearchTranslate,
    bibleSearchPlace,
    wholeWordCheckbox,
    withRegisterCheckbox,
  ]);

  useEffect(() => {
    if (activeMainSection !== "bible_search") {
      setWholeWordCheckbox(false);
      setWithRegisterCheckbox(false);
      setShowedInputIcon("search_icon");
    }
  }, [activeMainSection]);

  useEffect(() => {
    setShowedInputIcon("search_icon");
  }, [bibleSearchInput]);

  const onRemoveIconClick = useCallback((event) => {
    event.stopPropagation();
    setBibleSearchInput("");
    setShowedInputIcon("search_icon");
  }, []);

  const handleSearchInput = useCallback((event) => {
    setBibleSearchInput(event.target.value);
  }, []);

  const handleWholeWordCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWholeWordCheckbox((prevState) => !prevState);
  }, []);

  const handleWithRegisterCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWithRegisterCheckbox((prevState) => !prevState);
  }, []);

  return (
    <BibleSearchPanelWrapper>
      <BibleSearchPanelContainer
        inputIsEmpty={inputIsEmpty}
        onSubmit={onSearchSubmit}
      >
        <BibleSearchInput
          placeholder={"Поиск по Библии"}
          value={bibleSearchInput}
          onChange={handleSearchInput}
        />

        {showedInputIcon === "search_icon" && (
          <SearchIconContainer type="submit">
            <SearchIcon />
          </SearchIconContainer>
        )}

        {showedInputIcon === "remove_icon" && (
          <SearchIconContainer onClick={onRemoveIconClick}>
            <RemoveIconStyled color="#383838" />
          </SearchIconContainer>
        )}
      </BibleSearchPanelContainer>

      {activeMainSection === "bible_search" && (
        <SearchCheckboxContainer>
          <Checkbox
            checked={wholeWordCheckbox}
            onClickCheckbox={handleWholeWordCheckbox}
            color={wholeWordCheckbox ? "#1e1e1e" : "#828282"}
            fontSize={"10px"}
            letterSpacing={"0.4px"}
            component={"bible_search"}
          >
            Целое слово
          </Checkbox>

          <Checkbox
            checked={withRegisterCheckbox}
            onClickCheckbox={handleWithRegisterCheckbox}
            color={withRegisterCheckbox ? "#1e1e1e" : "#828282"}
            fontSize={"10px"}
            letterSpacing={"0.4px"}
            component={"bible_search"}
          >
            Учитывать регистр
          </Checkbox>
        </SearchCheckboxContainer>
      )}
    </BibleSearchPanelWrapper>
  );
};

export default BibleSearchPanel;
