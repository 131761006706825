import React from "react";

const BibleIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 16">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.23991 21.3615C4.75243 21.3615 4.2848 21.5553 3.93991 21.9003C3.595 22.2454 3.40111 22.7136 3.40111 23.2019C3.40111 23.8795 2.8518 24.4288 2.17419 24.4288C1.49658 24.4288 0.947266 23.8795 0.947266 23.2019C0.947266 22.0632 1.39937 20.971 2.20433 20.1657C3.0093 19.3603 4.10122 18.9077 5.2399 18.9077H21.7948C22.4724 18.9077 23.0217 19.457 23.0217 20.1346C23.0217 20.8122 22.4724 21.3615 21.7948 21.3615H5.23991Z"
          fill="url(#paint0_linear_3902_3160)"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.23991 2.95769C4.75243 2.95769 4.2848 3.15143 3.93991 3.4965C3.595 3.84159 3.40111 4.30977 3.40111 4.79808V23.2019C3.40111 23.6902 3.595 24.1584 3.93991 24.5035C4.2848 24.8486 4.75243 25.0423 5.2399 25.0423H20.5679V2.95769H5.23991ZM2.20433 1.76182C3.0093 0.956427 4.10122 0.503845 5.2399 0.503845H21.7948C22.4724 0.503845 23.0217 1.05316 23.0217 1.73077V26.2692C23.0217 26.9468 22.4724 27.4962 21.7948 27.4962H5.2399C4.10122 27.4962 3.0093 27.0436 2.20433 26.2382C1.39937 25.4328 0.947266 24.3406 0.947266 23.2019V4.79808C0.947266 3.65938 1.39937 2.56719 2.20433 1.76182Z"
          fill="url(#paint1_linear_3902_3160)"
        />
        <path
          id="Vector 1 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9898 6.19232C12.2978 6.19232 12.5475 6.44201 12.5475 6.75001V9.53848H15.3359C15.6439 9.53848 15.8936 9.78816 15.8936 10.0962C15.8936 10.4042 15.6439 10.6539 15.3359 10.6539H12.5475V15.6731C12.5475 15.9811 12.2978 16.2308 11.9898 16.2308C11.6818 16.2308 11.4321 15.9811 11.4321 15.6731V10.6539H8.64363C8.33562 10.6539 8.08594 10.4042 8.08594 10.0962C8.08594 9.78816 8.33562 9.53848 8.64363 9.53848H11.4321V6.75001C11.4321 6.44201 11.6818 6.19232 11.9898 6.19232Z"
          fill="url(#paint2_linear_3902_3160)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3902_3160"
          x1="6.27157"
          y1="24.2274"
          x2="7.28126"
          y2="17.2725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3902_3160"
          x1="6.27157"
          y1="26.5112"
          x2="22.6582"
          y2="3.4235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3902_3160"
          x1="9.96914"
          y1="15.8645"
          x2="16.1581"
          y2="7.57144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BibleIcon;
