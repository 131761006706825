import React from "react";

const PinnedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_6040_6665)">
        <path
          d="M11.8548 3.43674L8.50879 0.0906844C8.388 -0.0302281 8.19186 -0.0302281 8.07107 0.0906844L8.0569 0.104862C7.85172 0.309974 7.73867 0.582817 7.73867 0.872932C7.73867 1.05984 7.78616 1.2392 7.87438 1.39825L4.3733 4.40298C4.10535 4.16351 3.76336 4.03245 3.40111 4.03245C3.01076 4.03245 2.64382 4.18444 2.36788 4.46044L2.34689 4.48143C2.22598 4.60228 2.22598 4.79829 2.34689 4.91914L4.35145 6.9237L2.39914 8.87595C2.36008 8.91613 1.43599 9.86838 0.828455 10.6262C0.249895 11.3476 0.135483 11.4798 0.12954 11.4866C0.0221238 11.609 0.0280673 11.7934 0.143036 11.909C0.203276 11.9695 0.282708 12 0.362388 12C0.43501 12 0.507817 11.9747 0.566385 11.9235C0.571524 11.919 0.70067 11.8068 1.42683 11.2245C2.1845 10.617 3.13675 9.69286 3.18003 9.6507L5.12918 7.70155L7.02638 9.59876C7.08681 9.65925 7.16606 9.68946 7.24524 9.68946C7.32443 9.68946 7.40373 9.65925 7.4641 9.59876L7.48509 9.57777C7.76109 9.30183 7.91308 8.93482 7.91308 8.54454C7.91308 8.18229 7.78195 7.84029 7.54254 7.57234L10.5473 4.07127C10.7063 4.15949 10.8857 4.20697 11.0726 4.20697C11.3628 4.20697 11.6355 4.09399 11.8407 3.88875L11.8548 3.87457C11.9758 3.7536 11.9758 3.55759 11.8548 3.43674Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6040_6665">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinnedIcon;
