import React from "react";

const LinkVariantIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4012_6015)">
        <path
          d="M18.6774 8.06177e-05C20.2105 3.47073e-05 21.6584 0.607579 22.7755 1.72461C23.9452 2.89432 24.5561 4.4269 24.4959 6.0401C24.4337 7.70617 23.6811 9.30662 22.3193 10.6684L19.8673 13.1303C19.502 13.4972 18.9084 13.4985 18.5415 13.1331C18.1747 12.7677 18.1734 12.1741 18.5388 11.8073L20.992 9.34394C22.9801 7.35592 23.1676 4.76848 21.4496 3.05042C19.7315 1.33222 17.144 1.51986 15.1575 3.5066L11.7878 6.8763C10.7664 7.89761 10.2032 9.06381 10.159 10.2487C10.1184 11.3348 10.5349 12.3717 11.3315 13.1684C11.5985 13.4353 11.8939 13.6618 12.2095 13.8416C12.6594 14.0978 12.8164 14.6703 12.5601 15.1202C12.3038 15.5701 11.7313 15.727 11.2815 15.4708C10.8203 15.2081 10.3911 14.8795 10.0057 14.4942C8.83602 13.3245 8.22505 11.7919 8.28524 10.1787C8.34744 8.51266 9.10011 6.91216 10.4619 5.5504L13.8316 2.1807C15.1935 0.818892 16.7939 0.0662677 18.46 0.0040174C18.5325 0.00144057 18.6051 8.0624e-05 18.6774 8.06177e-05ZM12.4398 8.87988C12.1835 9.32978 12.3406 9.90226 12.7904 10.1585C13.106 10.3382 13.4014 10.5647 13.6684 10.8316C15.3864 12.5497 15.1988 15.1372 13.2122 17.1238L9.84247 20.4935C7.85577 22.4801 5.26837 22.6677 3.55036 20.9496C1.8323 19.2316 2.01989 16.6441 4.0079 14.6561L6.46115 12.1928C6.82654 11.826 6.82532 11.2323 6.45843 10.867C6.09159 10.5016 5.49801 10.5029 5.13262 10.8697L2.68069 13.3317C1.31888 14.6935 0.566253 16.2939 0.504003 17.96C0.443817 19.5732 1.05478 21.1058 2.22445 22.2755C3.39417 23.4452 4.92754 24.056 6.53995 23.996C8.20602 23.9338 9.80647 23.1811 11.1682 21.8193L14.538 18.4496C15.8998 17.0878 16.6524 15.4874 16.7147 13.8213C16.7748 12.2081 16.1639 10.6755 14.9942 9.5058C14.6089 9.12048 14.1796 8.79194 13.7184 8.5292C13.572 8.44581 13.4126 8.40616 13.2553 8.40616C12.9294 8.40621 12.6127 8.57645 12.4398 8.87988Z"
          fill="url(#paint0_linear_4012_6015)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4012_6015"
          x1="13.08"
          y1="28.4118"
          x2="13.08"
          y2="-2.11756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_4012_6015">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(24.5 24) rotate(180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkVariantIcon;
