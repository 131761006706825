import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import PlusIcon from "../assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import MinusIcon from "../assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";
import {
  PlusAndMinusButtonsContainer,
  PlusOrMinusButton,
} from "../../pages/admin/admin_page_create_lesson/create_lesson_components/create_lesson_styled_components";
import BoldTextIcon from "../assets/icons/create_lesson_icons/create_lesson_settings_icons/bold_text_icon";

const AddBibleVerseContainer = styled.div``;

const BoldButton = styled.div`
  margin-bottom: 2px;
  cursor: pointer;
`;

const AddedBibleVerseBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BibleVersesContainer = styled.div`
  padding: 15px 15px 13px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #fff;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 24px;
`;

const BibleVersesInput = styled.textarea`
  padding: 12px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #1e1e1e;
  resize: none;
  width: 100%;
  font-size: 16px;
  line-height: 150%;
`;

const AddedBibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  color: #383838;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: all 0.2s;
  }
`;

const adjustTextareaHeight = (textarea) => {
  if (textarea) {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
};

const AddBibleVerse = ({
  verseInfo,
  verseIndex,
  handleVersesInputValue,
  isLastElement,
  handleRemoveVerse,
  handleOpenBibleModalWindow,
  setClickedButton,
  setEditedVersesData,
  setSettingsBlockData = () => {},
  inComponent = "",
  showMinusButton = true,
}) => {
  const verseBlockRef = useRef(null);

  const defaultState = verseInfo === 1;

  const removeVerse = useCallback(
    (event) => {
      event.stopPropagation();
      handleRemoveVerse(verseInfo);
    },
    [verseInfo]
  );

  const onClickPlusButton = useCallback(
    (event) => {
      event.stopPropagation();
      handleOpenBibleModalWindow();
      setClickedButton({ button: "plus_button", verseId: verseInfo.verseId });
    },
    [verseInfo]
  );

  const onClickAddressButton = useCallback(
    (event) => {
      event.stopPropagation();
      handleOpenBibleModalWindow();
      setClickedButton({
        button: "address_button",
        verseId: verseInfo.verseId,
      });
      if (!defaultState) {
        setEditedVersesData({
          versesData: verseInfo.versesData,
          versesParams: verseInfo.versesParams,
        });
      }
    },
    [verseInfo, defaultState]
  );

  useEffect(() => {
    if (isLastElement && verseBlockRef.current) {
      verseBlockRef.current.scrollIntoView({ block: "end" });
    }
  }, [verseBlockRef.current]);

  const handleBoldClick = () => {
    const textarea = document.getElementById(`textArea-${verseIndex}`);
    const { selectionStart, selectionEnd } = textarea;
    const selectedText = verseInfo.verseText.substring(
      selectionStart,
      selectionEnd
    );

    if (selectedText) {
      const newText =
        verseInfo.verseText.substring(0, selectionStart) +
        `**${selectedText}**` +
        verseInfo.verseText.substring(selectionEnd);

      setSettingsBlockData((prevState) => ({
        ...prevState,
        data: prevState.data.map((verse, index) =>
          verseIndex === index ? { ...verse, verseText: newText } : verse
        ),
      }));
    }
  };

  useEffect(() => {
    const textarea = document.getElementById(`textArea-${verseIndex}`);
    adjustTextareaHeight(textarea);
  }, [verseInfo.verseText]);

  const handleChange = (e) => {
    handleVersesInputValue(e, verseIndex);
    adjustTextareaHeight(e.target);
  };

  return (
    <AddBibleVerseContainer ref={verseBlockRef}>
      {inComponent === "home_page" && !defaultState && (
        <BibleVersesContainer>{verseInfo.verseText}</BibleVersesContainer>
      )}

      {inComponent === "create_lesson" && (
        <>
          {verseInfo.versesData?.length && (
            <>
              <BoldButton onClick={handleBoldClick}>
                <BoldTextIcon />
              </BoldButton>
              <BibleVersesInput
                id={`textArea-${verseIndex}`}
                value={verseInfo.verseText}
                onChange={handleChange}
              />
            </>
          )}
        </>
      )}

      <AddedBibleVerseBottomContainer>
        <AddedBibleVerseAddress onClick={onClickAddressButton}>
          {verseInfo.verseAddress || "Выбрать текст"}
        </AddedBibleVerseAddress>

        {!defaultState && (
          <PlusAndMinusButtonsContainer>
            {showMinusButton && (
              <PlusOrMinusButton onClick={removeVerse}>
                <MinusIcon />
              </PlusOrMinusButton>
            )}

            {isLastElement && (
              <PlusOrMinusButton onClick={onClickPlusButton}>
                <PlusIcon />
              </PlusOrMinusButton>
            )}
          </PlusAndMinusButtonsContainer>
        )}
      </AddedBibleVerseBottomContainer>
    </AddBibleVerseContainer>
  );
};

export default memo(AddBibleVerse);
