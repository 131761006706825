import { useEffect } from "react";
import axios from "../axios_config";
import { testMqtt } from "../common/composabels/useMqtt";

export const useInitApp = () => {
  useEffect(() => {
    //testMqtt();
  }, []);

  useEffect(() => {
    //axios.get("/auth/profile").then((response) => console.log(response));
  }, []);
};
