import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import UnsavedChangesModalWindow from "../../../../../common/components/modal_windows/unsaved_changes_modal_window";
import DeleteQuestionModalWindow from "../../../../../common/components/modal_windows/delete_modal_window";
import { AdminPageSettingsDeleteButton } from "../../../admin_page_components/admin_page_styled_components";
import AdminPageDeleteIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_delete_icon";
import { BASE_API_URL } from "../../../../../common/endpoints";
import AdminPageSettingsButtons from "../../../admin_page_components/admin_page_settings_buttons";

const ArticleSettingsButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ArticleSettingsButtons = ({
  articleSettingsData,
  editedArticleMemoData,
  setEmptyInput,
  openEditQuestionModal,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
  setOpenEditQuestionModal,
  closeSettingsWithoutSave,
  closeSettings,
}) => {
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  useEffect(() => {
    setShowCheckIcon(false);
  }, [articleSettingsData]);

  const onSaveSettingsButton = useCallback(async () => {
    if (
      !articleSettingsData.article_title ||
      !articleSettingsData.article_text
    ) {
      setEmptyInput(true);
      setTimeout(() => setEmptyInput(false), 1500);
      return;
    }

    try {
      if (articleSettingsData._id === "new_article") {
        const newArticle = { ...articleSettingsData };
        delete newArticle._id;
        await axios.post(`${BASE_API_URL}/articles`, newArticle);
      } else {
        await axios.patch(
          `${BASE_API_URL}/articles/${articleSettingsData._id}`,
          articleSettingsData
        );

        if (
          editedArticleMemoData?.article_image_url &&
          editedArticleMemoData.article_image_url !==
            articleSettingsData.article_image_url
        ) {
          const imageName = new URL(
            editedArticleMemoData.article_image_url
          ).pathname
            .split("/")
            .pop();
          await axios.delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`);
        }
      }

      setShowCheckIcon(true);
    } catch (error) {
      console.error("Ошибка при сохранении настроек:", error);
    }
  }, [articleSettingsData, editedArticleMemoData, closeSettings]);

  const onClickNotSaveButton = useCallback(async () => {
    try {
      if (
        articleSettingsData?.article_image_url &&
        articleSettingsData.article_image_url !==
          editedArticleMemoData.article_image_url
      ) {
        const imageName = new URL(
          articleSettingsData.article_image_url
        ).pathname
          .split("/")
          .pop();
        await axios.delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`);
      }
    } catch (error) {
      console.error("Ошибка при удалении картинки:", error);
    } finally {
      closeSettingsWithoutSave();
    }
  }, [articleSettingsData, editedArticleMemoData, closeSettingsWithoutSave]);

  const onClickDeleteButton = useCallback(() => {
    setOpenDeleteQuestionModal(true);
  }, []);

  const handleDeleteArticle = useCallback(async () => {
    try {
      await axios.delete(`${BASE_API_URL}/articles/${articleSettingsData._id}`);

      if (articleSettingsData.article_image_url) {
        const imageName = new URL(
          articleSettingsData.article_image_url
        ).pathname
          .split("/")
          .pop();
        await axios.delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`);
      }
    } catch (error) {
      console.error("Ошибка при удалении:", error);
    } finally {
      setOpenDeleteQuestionModal(false);
      closeSettings();
    }
  }, [articleSettingsData, closeSettings]);

  return (
    <div>
      <ArticleSettingsButtonsContainer>
        <AdminPageSettingsButtons
          showCheckIcon={showCheckIcon}
          onClickSaveButton={onSaveSettingsButton}
          onClickCancelButton={onClickNotSaveButton}
        />

        <AdminPageSettingsDeleteButton onClick={onClickDeleteButton}>
          <AdminPageDeleteIcon />
        </AdminPageSettingsDeleteButton>
      </ArticleSettingsButtonsContainer>

      {openEditQuestionModal && (
        <UnsavedChangesModalWindow
          setOpen={setOpenEditQuestionModal}
          onSaveSettingsButton={onSaveSettingsButton}
          onClickNotSaveButton={onClickNotSaveButton}
        />
      )}

      {openDeleteQuestionModal && (
        <DeleteQuestionModalWindow
          setOpen={setOpenDeleteQuestionModal}
          handleDelete={handleDeleteArticle}
          deletionObject={"публикацию"}
        />
      )}
    </div>
  );
};

export default memo(ArticleSettingsButtons);
