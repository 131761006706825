import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Verses from "./verses";
import { BibleBookProvider } from "./bible_book_context";

const BibleBookContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  flex: 1;

  @media (max-width: 830px) {
    flex-direction: column;
  }
`;

const BibleBook = ({
  selectedVerses,
  setSelectedVerses,
  currentTranslationFirst,
  currentTranslationSecond,
}) => {
  const [searchParams] = useSearchParams();

  return (
    <BibleBookProvider>
      <BibleBookContainer>
        <Verses
          isFirstTranslation={true}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslation={currentTranslationFirst}
        />

        {searchParams.get("2bibleId") && (
          <Verses
            isFirstTranslation={false}
            selectedVerses={selectedVerses}
            setSelectedVerses={setSelectedVerses}
            currentTranslation={currentTranslationSecond}
          />
        )}
      </BibleBookContainer>
    </BibleBookProvider>
  );
};

export default memo(BibleBook);
