import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import VerticalLine from "../../../../assets/icons/vertical_line";
import { BASE_API_URL } from "../../../../endpoints";
import BibleModalWindowVerse from "./bible_modal_window_verse";
import { bibleVersesWithoutTags } from "../../../../utils/functions/bible_page_functions";
import SpinnerLoader from "../../../loaders/spinner_loader";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../../pages/bible/bible_page_data";

const BibleChapterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  padding: 12px 1px 0 12px;
  border-radius: 15px;
  border: 2px solid var(--solid-colors-white, #fff);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const BibleChapterAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  width: 215px;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 140%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BibleVerses = styled.div`
  padding-bottom: 50px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

const BibleModalWindowVerses = ({
  selectedVerses,
  setSelectedVerses,
  currentBibleParams,
  currentTranslation,
  bibleFontSettings,
  inComponent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);

  const abbreviation = chapterData?.[0]?.bible_code.toUpperCase();
  const bibleChapterAddress =
    BIBLE_BOOK_NAMES_DATA?.[currentTranslation?.bible_locale]?.[
      currentBibleParams?.bookId
    ]?.name +
    " " +
    currentBibleParams?.chapterId;

  const chapterFetch = useCallback(async ({ bibleId, bookId, chapterId }) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const parsedChapter = response.data.verses.map((verse) => ({
        ...verse,
        verse_text: bibleVersesWithoutTags(verse.verse_text),
      }));

      setChapterData(parsedChapter);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentBibleParams.bibleId) {
      chapterFetch(currentBibleParams);
    }
  }, [currentBibleParams]);

  return (
    <BibleChapterContainer>
      <BibleChapterAddressContainer>
        <BibleTranslationVersion>{abbreviation}</BibleTranslationVersion>
        <VerticalLineStyled />
        <BibleChapterAddress>{bibleChapterAddress}</BibleChapterAddress>
      </BibleChapterAddressContainer>

      {!isLoading ? (
        <BibleVerses>
          {chapterData.map((verse) => (
            <BibleModalWindowVerse
              key={verse._id}
              verse={verse}
              bibleFontSettings={bibleFontSettings}
              selectedVerses={selectedVerses}
              setSelectedVerses={setSelectedVerses}
              inComponent={inComponent}
            />
          ))}
        </BibleVerses>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}
    </BibleChapterContainer>
  );
};

export default memo(BibleModalWindowVerses);
