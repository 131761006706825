import React, { useCallback } from "react";
import styled from "styled-components";
import MentorChangeIcon from "../../../../../common/assets/icons/admin_page_icons/mentor_change_icon";

const UsersListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 260px);
  padding: 8px 0;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const UsersListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 50px;
  max-height: 50px;
  width: 100%;
  padding: 0 14px;
  border: 2px solid transparent;
  background: #fff;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    border-top: 1px solid #5172EA;
    border-bottom: 1px solid #5172EA;
  `}

  &:hover {
    background: #f1f1f1;
    transition: 0.2s;
  }
`;

const UsersListItemName = styled.div`
  overflow: hidden;
  color: #383838;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  flex: 1;
`;

const UsersListItemImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(88deg, #05baa4 -19%, #2046e9 129.9%);
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.42px;
  overflow: hidden;
`;

const UsersListItemImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
`;

const SelectedUserIconWrapper = styled.div`
  opacity: 0.4;
`;

const UsersListModalWindowItems = ({
  filteredUsers,
  selectedUser,
  setSelectedUser,
}) => {
  const getContactNameInitials = useCallback((name) => {
    return name
      ?.split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  }, []);

  const handleSelectUser = useCallback(
    (user) => {
      if (selectedUser._id !== user._id) {
        setSelectedUser({ _id: user._id });
      } else {
        setSelectedUser({});
      }
    },
    [selectedUser]
  );

  return (
    <UsersListItemsContainer>
      {filteredUsers.map((user) => {
        return (
          <UsersListItem
            key={user._id}
            selected={user._id === selectedUser._id}
            onClick={() => handleSelectUser(user)}
          >
            <UsersListItemImageWrapper>
              {user?.user_image ? (
                <UsersListItemImage image={user?.user_image} />
              ) : (
                <p>
                  {getContactNameInitials(
                    user?.user_name,
                    user?.user_second_name
                  )}
                </p>
              )}
            </UsersListItemImageWrapper>
            <UsersListItemName>
              {user.user_name} {user.user_second_name}
            </UsersListItemName>
            {user._id === selectedUser._id && (
              <SelectedUserIconWrapper>
                <MentorChangeIcon />
              </SelectedUserIconWrapper>
            )}
          </UsersListItem>
        );
      })}
    </UsersListItemsContainer>
  );
};

export default UsersListModalWindowItems;
