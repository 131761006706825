import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

const ArticleTitleInputContainer = styled.textarea`
  height: fit-content;
  resize: none;
  min-height: 42px;
  max-height: 80px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
  max-height: 80px;
  ${({ isEmpty }) => isEmpty && "border: 1px solid red;"}
`;

const ArticleTitleInput = ({
  articleSettingsData,
  setArticleSettingsData,
  emptyInput,
}) => {
  const articleTitleInputRef = useRef(null);

  const hadleArticleTitleInput = useCallback((event) => {
    setArticleSettingsData((prevState) => ({
      ...prevState,
      article_title: event.target.value,
    }));
  }, []);

  const handleKeyDownOnTitle = useCallback((event) => {
    if (event.key === "Enter") {
      setArticleSettingsData((prevState) => ({
        ...prevState,
        article_title: prevState.article_title + "<br>",
      }));
    }
  }, []);

  useEffect(() => {
    if (articleTitleInputRef.current) {
      articleTitleInputRef.current.style.height = "auto";
      articleTitleInputRef.current.style.height =
        articleTitleInputRef.current.scrollHeight + "px";
    }
  }, [articleSettingsData.article_title, articleTitleInputRef]);

  return (
    <ArticleTitleInputContainer
      ref={articleTitleInputRef}
      placeholder="Заголовок"
      type="text"
      rows={1}
      value={articleSettingsData.article_title}
      onChange={hadleArticleTitleInput}
      onKeyDown={handleKeyDownOnTitle}
      isEmpty={emptyInput && !articleSettingsData.article_title}
    />
  );
};

export default ArticleTitleInput;
