import React from "react";

const PlayIcon = ({ className, color }) => (
  <svg
    width="54"
    height="61"
    viewBox="0 0 54 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.9001 30.8663L5.86469 7.17447L5.86469 54.5581L46.9001 30.8663ZM52.0296 33.8278C54.3093 32.5116 54.3093 29.221 52.0296 27.9048L5.86469 1.25151C3.58495 -0.064703 0.73526 1.58057 0.73526 4.21299L0.735258 57.5196C0.735257 60.152 3.58495 61.7973 5.86469 60.4811L52.0296 33.8278Z"
      fill={color || "white"}
    />
  </svg>
);

export default PlayIcon;
