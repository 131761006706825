import React from "react";

const RemoveIcon = ({ className, color = "#CACACA" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7442 1.24421C2.19242 0.795986 2.91913 0.795986 3.36736 1.24421L14.1884 12.0652C14.6366 12.5135 14.6366 13.2402 14.1884 13.6884C13.7402 14.1366 13.0135 14.1366 12.5653 13.6884L1.7442 2.86736C1.29597 2.41914 1.29597 1.69243 1.7442 1.24421Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7442 13.6884C1.29597 13.2402 1.29597 12.5135 1.7442 12.0653L12.5653 1.24422C13.0135 0.795994 13.7402 0.795994 14.1884 1.24422C14.6366 1.69244 14.6366 2.41915 14.1884 2.86737L3.36736 13.6884C2.91913 14.1366 2.19242 14.1366 1.7442 13.6884Z"
        fill={color}
      />
    </svg>
  );
};

export default RemoveIcon;
