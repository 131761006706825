import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "./axios_config";
import { BASE_API_URL } from "./common/endpoints";

const UserDataContext = createContext();

export const useUserDataState = () => {
  const [userData, setUserData] = useState({});
  const [userAuthorized, setUserAuthorized] = useState(false);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/auth/profile`);
      setUserData(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const isAuth = !!localStorage.getItem("access_token");

    if (isAuth) {
      setUserAuthorized(true);
    } else {
      setUserAuthorized(false);
    }
  }, [localStorage.getItem]);

  useEffect(() => {
    if (userAuthorized) {
      fetchUserData();
    }
  }, [userAuthorized]);

  return {
    userAuthorized,
    userData,
    fetchUserData,
    setUserAuthorized,
  };
};

export const UserDataProvider = ({ children }) => {
  const { userData, userAuthorized, fetchUserData, setUserAuthorized } =
    useUserDataState();

  return (
    <UserDataContext.Provider
      value={{
        userData,
        userAuthorized,
        fetchUserData,
        setUserAuthorized,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  return useContext(UserDataContext);
};
