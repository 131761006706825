import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import DeleteQuestionModalWindow from "../../../../common/components/modal_windows/delete_modal_window";
import AdminPageDeleteIcon from "../../../../common/assets/icons/admin_page_icons/admin_page_delete_icon";
import { AdminPageSettingsDeleteButton } from "../../admin_page_components/admin_page_styled_components";
import UnsavedChangesModalWindow from "../../../../common/components/modal_windows/unsaved_changes_modal_window";
import AdminPageSettingsButtons from "../../admin_page_components/admin_page_settings_buttons";

const QuestionSettingsButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const QuestionSettingsButtonsWrapper = styled.div`
  position: relative;
  z-index: 10000;
`;

const QuestionSettingsButtons = ({
  questionSettingsData,
  openEditQuestionModal,
  setOpenEditQuestionModal,
  setQuestionSettingsMemoData,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
  closeAllSettings,
}) => {
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  useEffect(() => {
    setShowCheckIcon(false);
  }, [questionSettingsData]);

  const onClickSaveButton = useCallback(async () => {
    try {
      await axios.patch(
        `${BASE_API_URL}/questions/${questionSettingsData._id}`,
        questionSettingsData
      );
      setShowCheckIcon(true);
      setQuestionSettingsMemoData(questionSettingsData);
    } catch (error) {
      console.error("Error making PATCH request:", error);
    }
  }, [questionSettingsData]);

  const onClickDeleteQuestion = useCallback(() => {
    setOpenDeleteQuestionModal(true);
  }, []);

  const handleDeleteQuestion = useCallback(() => {
    axios
      .delete(`${BASE_API_URL}/questions/${questionSettingsData._id}`)
      .then((response) => {})
      .catch((error) => {
        console.error("Ошибка при удалении вопроса:", error);
      })
      .finally(() => {
        closeAllSettings();
      });
  }, [questionSettingsData]);

  const onClickModalSaveButton = async () => {
    await onClickSaveButton();
    closeAllSettings();
  };

  return (
    <QuestionSettingsButtonsWrapper>
      <QuestionSettingsButtonsContainer>
        <AdminPageSettingsButtons
          showCheckIcon={showCheckIcon}
          onClickSaveButton={onClickSaveButton}
          onClickCancelButton={closeAllSettings}
        />

        <AdminPageSettingsDeleteButton onClick={onClickDeleteQuestion}>
          <AdminPageDeleteIcon />
        </AdminPageSettingsDeleteButton>
      </QuestionSettingsButtonsContainer>

      {openEditQuestionModal && (
        <UnsavedChangesModalWindow
          setOpen={setOpenEditQuestionModal}
          onSaveSettingsButton={onClickModalSaveButton}
          onClickNotSaveButton={closeAllSettings}
        />
      )}

      {openDeleteQuestionModal && (
        <DeleteQuestionModalWindow
          setOpen={setOpenDeleteQuestionModal}
          handleDelete={handleDeleteQuestion}
          deletionObject={"вопрос"}
        />
      )}
    </QuestionSettingsButtonsWrapper>
  );
};

export default memo(QuestionSettingsButtons);
