import React from "react";

const MarkedListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81641 4.2C6.81641 3.53726 7.35366 3 8.01641 3H21.0164C21.6791 3 22.2164 3.53726 22.2164 4.2C22.2164 4.86274 21.6791 5.4 21.0164 5.4H8.01641C7.35366 5.4 6.81641 4.86274 6.81641 4.2Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81641 11.2001C6.81641 10.5374 7.35366 10.0001 8.01641 10.0001H21.0164C21.6791 10.0001 22.2164 10.5374 22.2164 11.2001C22.2164 11.8628 21.6791 12.4001 21.0164 12.4001H8.01641C7.35366 12.4001 6.81641 11.8628 6.81641 11.2001Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81641 18.8C6.81641 18.1373 7.35366 17.6 8.01641 17.6H21.0164C21.6791 17.6 22.2164 18.1373 22.2164 18.8C22.2164 19.4627 21.6791 20 21.0164 20H8.01641C7.35366 20 6.81641 19.4627 6.81641 18.8Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80469 4.2C1.80469 3.53726 2.34195 3 3.00469 3H3.01469C3.67743 3 4.21469 3.53726 4.21469 4.2C4.21469 4.86274 3.67743 5.4 3.01469 5.4H3.00469C2.34195 5.4 1.80469 4.86274 1.80469 4.2Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80469 11.2001C1.80469 10.5374 2.34195 10.0001 3.00469 10.0001H3.01469C3.67743 10.0001 4.21469 10.5374 4.21469 11.2001C4.21469 11.8628 3.67743 12.4001 3.01469 12.4001H3.00469C2.34195 12.4001 1.80469 11.8628 1.80469 11.2001Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80469 18.8C1.80469 18.1373 2.34195 17.6 3.00469 17.6H3.01469C3.67743 17.6 4.21469 18.1373 4.21469 18.8C4.21469 19.4627 3.67743 20 3.01469 20H3.00469C2.34195 20 1.80469 19.4627 1.80469 18.8Z"
        fill="#383838"
      />
    </svg>
  );
};

export default MarkedListIcon;
