export const insertUserNameInText = (text = "", userName) => {
  return text.replace(/\*ИМЯ/g, userName);
};

export const getUserNameInitials = (name = "", surname = "") => {
  const firstNameInitial = name.charAt(0).toUpperCase();
  const lastNameInitial = surname.charAt(0).toUpperCase();

  return `${firstNameInitial}${lastNameInitial}`;
};
