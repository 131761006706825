import React from "react";

const UnFavoriteIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M19.947 7.69561C19.7956 7.22946 19.3444 6.90917 18.7093 6.81686L13.5331 6.0646L11.2181 1.37433C10.9342 0.798768 10.4901 0.468719 10 0.468719C9.50992 0.468719 9.06589 0.798768 8.78177 1.37433L6.46716 6.0646L1.29059 6.81686C0.65552 6.90917 0.204316 7.22946 0.0529485 7.69561C-0.0984192 8.16177 0.0782781 8.68606 0.537874 9.13391L4.28362 12.7849L3.39921 17.9404C3.28614 18.5997 3.47886 18.9949 3.66044 19.2102C3.87361 19.4629 4.18413 19.6021 4.53508 19.6021C4.79936 19.6021 5.08028 19.5249 5.3702 19.3724L10 16.9384L14.63 19.3724C14.9199 19.5249 15.2009 19.6021 15.465 19.6022H15.4651C15.8161 19.6022 16.1268 19.4629 16.3398 19.2102C16.5214 18.9949 16.7141 18.5997 16.6009 17.9404L15.7168 12.7849L19.4622 9.13406C19.9218 8.68606 20.0985 8.16177 19.947 7.69561ZM18.6443 8.29483L14.6782 12.1608C14.5402 12.2954 14.4771 12.4895 14.5098 12.6795L15.4459 18.1386C15.4732 18.2975 15.4615 18.3876 15.4506 18.4298C15.4088 18.427 15.3191 18.4108 15.1754 18.3353L10.2727 15.7578C10.1873 15.7129 10.0937 15.6905 10 15.6905C9.90635 15.6905 9.81266 15.7129 9.72736 15.7578L4.825 18.3351C4.68126 18.4107 4.59139 18.427 4.54958 18.4298C4.5389 18.3876 4.52699 18.2975 4.55431 18.1386L5.49074 12.6796C5.52324 12.4895 5.46022 12.2954 5.32213 12.161L1.3559 8.29483C1.24054 8.18237 1.19706 8.10257 1.18088 8.06213C1.21766 8.03894 1.29975 7.99987 1.4592 7.97668L6.94064 7.18017C7.13153 7.1524 7.29647 7.03246 7.38177 6.85958L9.8328 1.89298C9.90421 1.74848 9.96662 1.68256 10.0002 1.65464C10.0336 1.68256 10.0962 1.74848 10.1674 1.89298L12.6186 6.85958C12.7041 7.03246 12.869 7.1524 13.0599 7.18017L18.5409 7.97668C18.7005 7.99987 18.7824 8.03894 18.8192 8.06213C18.803 8.10241 18.7597 8.18237 18.6443 8.29483Z"
        fill="url(#paint0_linear_4052_4237)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4052_4237"
          x1="9.51662"
          y1="-3.04847"
          x2="9.51662"
          y2="21.2905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnFavoriteIcon;
