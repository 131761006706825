import React from "react";

const MinusSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="2"
      viewBox="0 0 16 2"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.777344 1.00293C0.777344 0.450645 1.22506 0.00292965 1.77734 0.00292969L14.2252 0.00292994C14.7775 0.00292927 15.2252 0.450645 15.2252 1.00293C15.2252 1.55521 14.7775 2.00293 14.2252 2.00293L1.77734 2.00293C1.22506 2.00293 0.777344 1.55521 0.777344 1.00293Z"
        fill="url(#paint0_linear_7634_6509)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7634_6509"
          x1="7.65207"
          y1="-0.364717"
          x2="7.65207"
          y2="2.1794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MinusSmallIcon;
