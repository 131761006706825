import React, { memo, useCallback, useEffect } from "react";
import axios from "../../../../../../axios_config";
import styled from "styled-components";
import ImageDropzone from "../../../../../../common/components/image_dropzone";
import Checkbox from "../../../../../../common/components/checkbox";
import { BASE_API_URL } from "../../../../../../common/endpoints";

const TextSettingsImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TextSettingsImage = ({
  settingsBlockData,
  setSettingsBlockData,
  isCurrentImage,
  setDisabledSaveButton,
}) => {
  useEffect(() => {
    const { image_url, with_image } = settingsBlockData;

    if (with_image) {
      if (image_url) {
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
      }
    } else {
      setDisabledSaveButton(false);
    }
  }, [settingsBlockData]);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      with_image: !prevState.with_image,
    }));
  }, []);

  const handleImageSettings = useCallback((image) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      image_url: image,
    }));
  }, []);

  const onRemoveImage = useCallback(() => {
    const imageUrl = settingsBlockData.image_url;

    if (isCurrentImage) {
      handleImageSettings("");
    } else {
      const imageName = new URL(imageUrl).pathname.split("/").pop();

      axios
        .delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`)
        .then((response) => {
          handleImageSettings("");
        })
        .catch((error) => {
          console.error("Ошибка при удалении картинки:", error);
        });
    }
  }, [settingsBlockData, isCurrentImage]);

  useEffect(() => {
    if (!settingsBlockData.with_image && settingsBlockData.image_url) {
      onRemoveImage();
    }
  }, [settingsBlockData.with_image]);

  return (
    <TextSettingsImageContainer>
      <Checkbox
        fontSize={"14px"}
        checked={settingsBlockData.with_image}
        onClickCheckbox={onClickCheckbox}
      >
        Изображение
      </Checkbox>
      {settingsBlockData.with_image && (
        <ImageDropzone
          imageUrl={settingsBlockData.image_url}
          isCurrentImage={isCurrentImage}
          handleImageSettings={handleImageSettings}
        />
      )}
    </TextSettingsImageContainer>
  );
};

export default memo(TextSettingsImage);
