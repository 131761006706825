import React from "react";

const BoldTextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15312 5.58802C6.15312 5.26316 6.41648 4.9998 6.74134 4.9998H11.3164C12.0723 4.9998 12.8014 5.28917 13.3422 5.81066C13.8837 6.33286 14.1921 7.04576 14.1921 7.79383C14.1921 8.5419 13.8837 9.2548 13.3422 9.777C13.3296 9.78912 13.3169 9.80112 13.3041 9.81299C13.4979 9.92753 13.6787 10.0653 13.842 10.2245C14.3782 10.7473 14.6823 11.4596 14.6823 12.2055C14.6823 12.9513 14.3782 13.6636 13.842 14.1864C13.3064 14.7086 12.583 14.9995 11.8317 14.9995H6.74134C6.41648 14.9995 6.15312 14.7361 6.15312 14.4113V5.58802ZM7.32956 6.17624V9.41143H11.3164C11.7738 9.41143 12.2084 9.236 12.5256 8.93015C12.842 8.62501 13.0156 8.2158 13.0156 7.79383C13.0156 7.37186 12.842 6.96266 12.5256 6.65751C12.2084 6.35167 11.7738 6.17624 11.3164 6.17624H7.32956ZM7.32956 10.5879V13.823H11.8317C12.2804 13.823 12.7079 13.6491 13.0208 13.344C13.3332 13.0395 13.5058 12.6296 13.5058 12.2055C13.5058 11.7813 13.3332 11.3714 13.0208 11.0669C12.7079 10.7618 12.2804 10.5879 11.8317 10.5879H7.32956Z"
        fill="#725FED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4776 9.9242C13.6065 10.0135 13.7285 10.1138 13.842 10.2245C14.3782 10.7473 14.6823 11.4596 14.6823 12.2055C14.6823 12.9513 14.3782 13.6636 13.842 14.1864C13.3064 14.7086 12.583 14.9995 11.8317 14.9995H6.74134C6.41648 14.9995 6.15312 14.7361 6.15312 14.4113V5.58802C6.15312 5.26316 6.41648 4.9998 6.74134 4.9998H11.3164C12.0723 4.9998 12.8014 5.28917 13.3422 5.81066C13.8837 6.33286 14.1921 7.04576 14.1921 7.79383C14.1921 8.49078 13.9244 9.15721 13.4499 9.66732C13.4151 9.70474 13.3792 9.74131 13.3422 9.777C13.3296 9.78912 13.3169 9.80112 13.3041 9.81299C13.3632 9.84792 13.4211 9.885 13.4776 9.9242ZM13.6189 9.779C13.7468 9.86973 13.8682 9.97067 13.9817 10.0813C14.5559 10.6412 14.8823 11.4048 14.8823 12.2055C14.8823 13.0061 14.5559 13.7697 13.9817 14.3296C13.4081 14.8888 12.6344 15.1995 11.8317 15.1995H6.74134C6.30602 15.1995 5.95312 14.8466 5.95312 14.4113V5.58802C5.95312 5.1527 6.30602 4.7998 6.74134 4.7998H11.3164C12.123 4.7998 12.9022 5.10853 13.481 5.66669C14.0608 6.22578 14.3921 6.99031 14.3921 7.79383C14.3921 8.53129 14.113 9.23591 13.6189 9.779ZM7.52956 6.37624V9.21143H11.3164C11.723 9.21143 12.1076 9.05538 12.3867 8.78618C12.6649 8.51796 12.8156 8.16039 12.8156 7.79383C12.8156 7.42728 12.6649 7.06971 12.3867 6.80148C12.1076 6.53228 11.723 6.37624 11.3164 6.37624H7.52956ZM7.52956 10.7879V13.623H11.8317C12.229 13.623 12.6061 13.469 12.8812 13.2008C13.1555 12.9334 13.3058 12.5749 13.3058 12.2055C13.3058 11.836 13.1555 11.4775 12.8812 11.2101C12.6061 10.9419 12.229 10.7879 11.8317 10.7879H7.52956ZM7.32956 6.17624H11.3164C11.7738 6.17624 12.2084 6.35167 12.5256 6.65751C12.842 6.96266 13.0156 7.37186 13.0156 7.79383C13.0156 8.2158 12.842 8.62501 12.5256 8.93015C12.2084 9.236 11.7738 9.41143 11.3164 9.41143H7.32956V6.17624ZM7.32956 10.5879H11.8317C12.2804 10.5879 12.7079 10.7618 13.0208 11.0669C13.3332 11.3714 13.5058 11.7813 13.5058 12.2055C13.5058 12.6296 13.3332 13.0395 13.0208 13.344C12.7079 13.6491 12.2804 13.823 11.8317 13.823H7.32956V10.5879Z"
        fill="#725FED"
      />
    </svg>
  );
};

export default BoldTextIcon;
