import React, { useCallback } from "react";
import styled from "styled-components";
import SelectedToggleIcon from "./selected_toggle_icon";
import UnselectedToggleIcon from "./unselected_toggle_icon";

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1e1e1e;
  font-size: 14px;
`;

const ToggleButton = styled.div`
  cursor: pointer;
  max-height: 22px;
`;

const Toggle = ({ title, selected, handleSelectToggle }) => {
  return (
    <ToggleContainer>
      {title}
      <ToggleButton onClick={handleSelectToggle}>
        {selected ? <SelectedToggleIcon /> : <UnselectedToggleIcon />}
      </ToggleButton>
    </ToggleContainer>
  );
};

export default Toggle;
