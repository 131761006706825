import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../common/assets/icons/vertical_line";
import CheckWhiteIcon from "../../../common/assets/icons/admin_page_icons/check_white_icon";

const AdminPageSettingsButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    transition: 0.2s;
  }
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  cursor: default;
  user-select: none;

  &:hover {
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
    width: 53%;
    cursor: pointer;
  }
`;

const SaveButton = styled(ButtonStyles)`
  position: relative;
  color: ${({ isHoverOnCancel }) =>
    isHoverOnCancel ? "rgba(255, 255, 255, 0.5)" : "#fff"};
`;

const CancelButton = styled(ButtonStyles)`
  color: rgba(255, 255, 255, 0.5);
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 2px;
`;

const CheckIconContainer = styled.div`
  position: absolute;
  top: 52%;
  left: 16px;
  transform: translateY(-50%);
`;

const AdminPageSettingsButtons = ({
  onClickSaveButton,
  onClickCancelButton,
  showCheckIcon,
}) => {
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);

  const toggleHoverOnSaveButton = useCallback(() => {
    setIsHoverOnCancel((prevState) => !prevState);
  }, []);

  return (
    <AdminPageSettingsButtonsContainer>
      <SaveButton isHoverOnCancel={isHoverOnCancel} onClick={onClickSaveButton}>
        {showCheckIcon && (
          <CheckIconContainer>
            <CheckWhiteIcon />
          </CheckIconContainer>
        )}
        Сохранить
      </SaveButton>
      <VerticalLineStyled />
      <CancelButton
        onMouseEnter={toggleHoverOnSaveButton}
        onMouseLeave={toggleHoverOnSaveButton}
        onClick={onClickCancelButton}
      >
        Отмена
      </CancelButton>
    </AdminPageSettingsButtonsContainer>
  );
};

export default memo(AdminPageSettingsButtons);
