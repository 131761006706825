import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AdminPageCalendarArrow from "../../../../../common/assets/icons/admin_page_icons/admin_page_calendar_arrow";
import {
  CalendarContainer,
  CalendarDay,
  CalendarDaysContainer,
  CalendarLeftArrowContainer,
  CalendarRightArrowContainer,
  CalendarWeekDaysContainer,
  CurrentMonth,
  CurrentMonthContainer,
} from "../../../admin_page_components/admin_page_styled_components";

const CalendarDayStyled = styled(CalendarDay)`
  background: ${({ withVerse }) =>
    withVerse
      ? "linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%)"
      : "#CACACA"};
  ${({ isPastDay }) => isPastDay && "opacity: 0.6;"}
  ${({ isSelectedDay }) => isSelectedDay && "border-color: #F12B2B;"}
`;

const DailyVerseCalendar = () => {
  const currentDate = new Date();
  const [selectedDay, setSelectedDay] = useState(new Date().toISOString()); // Current day
  const [monthDays, setMonthDays] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

  const monthsOfYear = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  useEffect(() => {
    const days = [];
    for (
      let date = new Date(firstDayOfMonth);
      date <= lastDayOfMonth;
      date.setDate(date.getDate() + 1)
    ) {
      days.push({
        month: date.getMonth(),
        dayOfMonth: date.getDate(),
      });
    }
    setMonthDays(days);
  }, [currentMonth, currentYear]);

  const handleClick = (day) => {
    const clickedDate = new Date(currentYear, currentMonth, day);
    console.log("toISOString===", clickedDate.toISOString());
    console.log(clickedDate);
  };

  return (
    <CalendarContainer>
      <CurrentMonthContainer>
        <CalendarLeftArrowContainer>
          <AdminPageCalendarArrow />
        </CalendarLeftArrowContainer>
        <CurrentMonth>
          {monthsOfYear[currentMonth]} {currentYear}
        </CurrentMonth>
        <CalendarRightArrowContainer>
          <AdminPageCalendarArrow />
        </CalendarRightArrowContainer>
      </CurrentMonthContainer>

      <CalendarWeekDaysContainer>
        {daysOfWeek.map((day) => {
          return <p key={day}>{day}</p>;
        })}
      </CalendarWeekDaysContainer>

      <CalendarDaysContainer>
        {monthDays.map((day, index) => (
          <CalendarDayStyled
            key={index}
            onClick={() => handleClick(day.dayOfMonth)}
          >
            {day.dayOfMonth}
          </CalendarDayStyled>
        ))}
      </CalendarDaysContainer>
    </CalendarContainer>
  );
};

export default DailyVerseCalendar;
