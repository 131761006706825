import React from "react";

const TextSizeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.10156 3.30449C2.10156 2.64175 2.63882 2.10449 3.30156 2.10449H11.997H20.6925C21.3553 2.10449 21.8925 2.64175 21.8925 3.30449V6.5653C21.8925 7.22804 21.3553 7.7653 20.6925 7.7653C20.0298 7.7653 19.4925 7.22804 19.4925 6.5653V4.50449H13.197V19.4955H15.2579C15.9206 19.4955 16.4579 20.0327 16.4579 20.6955C16.4579 21.3582 15.9206 21.8955 15.2579 21.8955H11.997H8.73624C8.0735 21.8955 7.53624 21.3582 7.53624 20.6955C7.53624 20.0327 8.0735 19.4955 8.73624 19.4955H10.797V4.50449H4.50156V6.5653C4.50156 7.22804 3.9643 7.7653 3.30156 7.7653C2.63882 7.7653 2.10156 7.22804 2.10156 6.5653V3.30449Z"
        fill="url(#paint0_linear_3886_5835)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_5835"
          x1="11.5187"
          y1="-1.53355"
          x2="11.5187"
          y2="23.6417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TextSizeIcon;
