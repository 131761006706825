import React from "react";

const AppleIcon = () => (
  <svg
    width="29"
    height="35"
    viewBox="0 0 29 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0769 18.5361C24.1254 23.6808 28.664 25.3928 28.7143 25.4146C28.6759 25.5354 27.9891 27.8544 26.3232 30.2498C24.883 32.3208 23.3883 34.3841 21.0338 34.4268C18.7202 34.4688 17.9762 33.077 15.3312 33.077C12.6869 33.077 11.8603 34.3841 9.67025 34.4688C7.3975 34.5534 5.66683 32.2293 4.21472 30.166C1.24751 25.9453 -1.02005 18.2394 2.02471 13.0377C3.53728 10.4546 6.24037 8.81879 9.17432 8.77684C11.4061 8.73496 13.5126 10.2541 14.8769 10.2541C16.2404 10.2541 18.8003 8.4272 21.4914 8.6955C22.618 8.74164 25.7803 9.14325 27.811 12.0677C27.6474 12.1675 24.0376 14.235 24.0769 18.5361V18.5361ZM19.7288 5.90316C20.9354 4.46614 21.7475 2.46567 21.5259 0.47522C19.7867 0.543995 17.6836 1.61553 16.436 3.05176C15.318 4.32361 14.3389 6.35928 14.6031 8.31034C16.5417 8.45791 18.5221 7.3411 19.7288 5.90316"
      fill="black"
    />
  </svg>
);

export default AppleIcon;
