import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import LeftArrowMobileIcon from "../../../common/assets/icons/left_arrow_mobile_icon";
import TeacherPhoto from "../../../common/assets/images/andrew_group_page.png";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";

const GroupPageHeaderContainer = styled.div`
  width: 100%;
  height: 240px;
  background: linear-gradient(180deg, #05baa4 0%, #288bce 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding-top: 19px;

  @media (max-width: 412px) {
    height: 287px;
    padding-top: 55px;
  }
`;

const GroupPageHeaderContent = styled.div`
  position: relative;
  width: 509px;
  height: 100%;
  margin: 0 auto;
  padding: 19px 0 0 25px;
  background-image: url(${({ teacher }) => teacher});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  filter: drop-shadow(0px -2.07022px 6.21065px rgba(0, 0, 0, 0.15));

  @media (max-width: 514px) {
    width: 100%;
  }

  @media (max-width: 412px) {
    padding: 0;
  }
`;

const LeftArrowStyles = styled(LeftArrowIcon)`
  position: absolute;
  top: 35px;
  left: -62px;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: 650px) {
    left: -42px;
  }

  @media (max-width: 617px) {
    left: -26px;
  }

  @media (max-width: 580px) {
    left: -20px;
  }

  @media (max-width: 570px) {
    left: -10px;
  }

  @media (max-width: 560px) {
    left: 0;
  }

  @media (max-width: 540px) {
    left: 18px;
  }

  @media (max-width: 450px) {
    display: none;
  }
`;

const LeftArrowMobileStyles = styled(LeftArrowMobileIcon)`
  display: none;
  position: absolute;

  @media (max-width: 450px) {
    display: block;
    top: 9px;
    left: 30px;
  }

  @media (max-width: 412px) {
    top: 16px;
    left: 30px;
  }
`;

const GroupPageHeaderInfo = styled.div`
  width: 310px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 570px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 260px;
    height: 100%;
    padding: 0 0 10px 30px;
  }

  @media (max-width: 450px) {
    padding: 0 0 10px 10px;
  }

  @media (max-width: 412px) {
    padding: 0 0 10px 30px;
  }
`;

const GroupTopic = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;

  @media (max-width: 450px) {
    margin-top: 28px;
    font-size: 24px;
    line-height: 125%;
  }

  @media (max-width: 412px) {
    margin-top: 55px;
    font-size: 26px;
    line-height: 130%;
  }
`;

const GroupTeacher = styled.div`
  margin-top: 9px;

  @media (max-width: 412px) {
    margin-top: -4px;
    font-size: 12px;
  }
`;

const GroupDate = styled.div`
  margin-top: 3px;

  @media (max-width: 412px) {
    margin-top: 0;
    font-size: 16px;
    line-height: 140%;
  }
`;

const EnterButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8d254;
  border-radius: 35px;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;

  font-family: "Raleway";
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  width: 105px;
  height: 44px;

  @media (max-width: 412px) {
    margin-top: 0;
    font-size: 16px;
    line-height: 140%;
    min-width: 120px;
    height: 33px;
  }
`;

const GroupPageHeader = () => {
  return (
    <GroupPageHeaderContainer>
      <GroupPageHeaderContent teacher={TeacherPhoto}>
        <Link to={APPLICATION_ROUTES.STUDY_GROUPS}>
          <LeftArrowStyles />
        </Link>

        <Link to={APPLICATION_ROUTES.STUDY_GROUPS}>
          <LeftArrowMobileStyles />
        </Link>

        <GroupPageHeaderInfo>
          <GroupTopic>Суббота или воскресенье?</GroupTopic>
          <GroupTeacher>Андрей Бедратый</GroupTeacher>
          <GroupDate>10.11. - 20.11.</GroupDate>
          <EnterButton>Войти</EnterButton>
        </GroupPageHeaderInfo>
      </GroupPageHeaderContent>
    </GroupPageHeaderContainer>
  );
};

export default GroupPageHeader;
