import React from "react";

const AdminPageDiploma = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M10.4444 16.1847V21.6236C10.4444 22.5154 11.4657 23.0273 12.1801 22.4915L14.001 21.1257L15.8219 22.4915C16.5311 23.0233 17.5576 22.522 17.5576 21.6236V16.1847C19.0552 15.0867 20.0294 13.3154 20.0294 11.3204C20.0294 7.99641 17.3252 5.29199 14.001 5.29199C10.6769 5.29199 7.97266 7.9962 7.97266 11.3204C7.97266 13.3154 8.94687 15.0867 10.4444 16.1847ZM15.388 19.454L14.6519 18.9018C14.2663 18.6124 13.7358 18.6124 13.3501 18.9018L12.6141 19.454V17.1877C13.0594 17.2928 13.5239 17.349 14.001 17.349C14.4782 17.349 14.9426 17.293 15.388 17.1877V19.454ZM14.001 7.46163C16.1287 7.46163 17.8598 9.19268 17.8598 11.3206C17.8598 13.4483 16.1287 15.1793 14.001 15.1793C11.8733 15.1793 10.1423 13.4483 10.1423 11.3204C10.1423 9.19268 11.8733 7.46163 14.001 7.46163Z"
        fill="url(#paint0_linear_5114_17195)"
      />
      <rect
        x="2.25505"
        y="2.25505"
        width="23.49"
        height="23.49"
        rx="3.64499"
        stroke="url(#paint1_linear_5114_17195)"
        strokeWidth="1.96714"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5114_17195"
          x1="13.7096"
          y1="2.09019"
          x2="13.7096"
          y2="24.2467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5114_17195"
          x1="13.3848"
          y1="-3.40813"
          x2="13.3848"
          y2="28.9748"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdminPageDiploma;
