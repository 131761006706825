import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "../../../axios_config";
import styled from "styled-components";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import { BASE_API_URL } from "../../../common/endpoints";
import { LESSON_COMPONENTS_MAPPING } from ".";
import BibleChapterModalWindow from "../../../common/components/modal_windows/bible_chapter_modal_window";
import VisibilityWrapperComponent from "./lesson_components/visibility_wrapper_component";
import LessonModeIcon from "../../../common/assets/icons/create_lesson_icons/lesson_mode_icon";

const LessonPreviewPageContainer = styled.div`
  max-width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;

  @media (max-width: 1024px) {
    height: calc(100vh - 96px);
  }

  @media (max-width: 412px) {
    height: calc(100vh - 103px);
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  position: fixed;
  z-index: 9999;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
  max-width: 710px;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 80%;
  }

  @media (max-width: 830px) {
    max-width: 94%;
    flex-wrap: wrap;
  }

  @media (max-width: 720px) {
    max-width: 100%;
  }
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  height: 100%;

  @media (max-width: 1024px) {
    gap: 35px;
  }

  @media (max-width: 830px) {
    gap: 20px;
  }
`;

const LeftArrowIconContainer = styled.div`
  cursor: pointer;
  padding: 6px 15px;

  &:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const LessonPreviewHeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  color: #fff;

  padding: 2px 0;
  margin-right: 10px;

  @media (max-width: 730px) {
    font-size: 18px;
  }

  & > span {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

const LessonPlayModeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-right: 15px;
  margin-bottom: 7px;
  margin-top: 7px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  opacity: ${({ withAnimation }) => (withAnimation ? 1 : 0.5)};
  user-select: none;

  @media (max-width: 700px) {
    flex: 1;
    justify-content: flex-end;
  }
`;

const LessonPlayModeButton = styled.div`
  height: 22px;
  cursor: pointer;
  ${({ withAnimation }) => withAnimation && "transform: rotate(180deg);"}
`;

const LessonComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 80px 15px 45px;
  max-width: 620px;
  margin: 0 auto;
`;

const FinishLessonButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: max-content;
  padding: 0 36px;
  margin: 0 auto 120px;
  border-radius: 25px;
  background: linear-gradient(30deg, #05baa4 11.17%, #2046e9 92.55%);
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
  user-select: none;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.2s;
  }

  @media (max-width: 720px) {
    font-size: 20px;
    border-radius: 12px;
  }

  @media (max-width: 412px) {
    margin-bottom: 100px;
  }
`;

const LessonPreviewPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const lessonNumber = searchParams.get("n");

  const containerRef = useRef(null);
  const [lessonData, setLessonData] = useState({});
  const [textEditorComponentHtml, setTextEditorComponentHtml] = useState(null);
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [withAnimation, setWithAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowFinishButton, setShouldShowFinishButton] = useState(false);
  const [stopDialogueIndex, setStopDialogueIndex] = useState(null);
  const [filteredComponents, setFilteredComponents] = useState([]);

  useEffect(() => {
    setShouldShowFinishButton(
      !!lessonData?.lesson_data?.length &&
        !isLoading &&
        (withAnimation ? stopDialogueIndex === null : true)
    );
  }, [lessonData, isLoading, stopDialogueIndex, withAnimation]);

  useEffect(() => {
    if (stopDialogueIndex === null || !withAnimation) {
      setFilteredComponents(lessonData.lesson_data);
    } else {
      const index = stopDialogueIndex;
      if (index >= 0 && index < lessonData.lesson_data.length) {
        setFilteredComponents(lessonData.lesson_data.slice(0, index + 1));
      }
    }
  }, [stopDialogueIndex, lessonData.lesson_data, withAnimation]);

  useEffect(() => {
    if (lessonData?.lesson_data?.length) {
      const index = lessonData.lesson_data.findIndex((component) => {
        if (component?.type === "DIALOGUE") {
          const lastSelectedBranch =
            component.selectedBranches?.[component.selectedBranches.length - 1];

          if (lastSelectedBranch && component.data[lastSelectedBranch]) {
            const hasOtherBranches =
              component.data[lastSelectedBranch].answers?.answers_data?.length >
              0;
            return hasOtherBranches;
          }
        }
        return false;
      });

      if (index !== -1) {
        setStopDialogueIndex(index);
      } else {
        setStopDialogueIndex(null);
      }
    }
  }, [lessonData]);

  const fetchLessonData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(BASE_API_URL + `/lessons/${id}`);
      setLessonData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id, withAnimation]);

  const goBack = useCallback(() => {
    window.history.back();
  }, []);

  const handleBibleVerseClick = useCallback((event) => {
    event.stopPropagation();

    const bibleId = event.target.dataset.bibleId;
    const bookId = event.target.dataset.bookId;
    const chapterId = event.target.dataset.chapterId;
    const selectedVerses = event.target.dataset.selectedVerses;

    setTextBibleModalData({ bibleId, bookId, chapterId, selectedVerses });
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleBibleVerseClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleBibleVerseClick);
      });
    };
  }, [textEditorComponentHtml]);

  const handleLessonPlayMode = useCallback((event) => {
    event.stopPropagation();
    setWithAnimation((prevState) => !prevState);
  }, []);

  const onClickFinishLesson = useCallback((event) => {
    event.stopPropagation();
    setLessonData((prevState) => ({
      ...prevState,
      lesson_is_finished: true,
    }));
  }, []);

  return (
    <LessonPreviewPageContainer>
      <HeaderWrapper>
        <HeaderContainer>
          <HeaderContentWrapper>
            <LeftArrowIconContainer onClick={goBack}>
              <LeftArrowIcon />
            </LeftArrowIconContainer>

            <LessonPreviewHeaderTitle>
              Урок {lessonNumber}. <span>{lessonData.lesson_name}</span>
            </LessonPreviewHeaderTitle>
          </HeaderContentWrapper>

          <LessonPlayModeContainer withAnimation={withAnimation}>
            Проигрывание
            <LessonPlayModeButton
              withAnimation={withAnimation}
              onClick={handleLessonPlayMode}
            >
              <LessonModeIcon />
            </LessonPlayModeButton>
          </LessonPlayModeContainer>
        </HeaderContainer>
      </HeaderWrapper>

      <LessonComponentList ref={containerRef}>
        {!isLoading && (
          <>
            {filteredComponents?.map((component) => {
              const CurrentPreviewComponent =
                LESSON_COMPONENTS_MAPPING?.[component?.type]?.component;

              return (
                <VisibilityWrapperComponent
                  key={component.id}
                  withAnimation={withAnimation}
                >
                  <CurrentPreviewComponent
                    componentData={component}
                    withAnimation={withAnimation}
                    lessonIsFinished={lessonData.lesson_is_finished}
                    setLessonData={setLessonData}
                    setTextEditorComponentHtml={setTextEditorComponentHtml}
                    setTextBibleModalData={setTextBibleModalData}
                    inComponent="preview_page"
                  />
                </VisibilityWrapperComponent>
              );
            })}
          </>
        )}
      </LessonComponentList>

      {shouldShowFinishButton && (
        <FinishLessonButton onClick={onClickFinishLesson}>
          Завершить урок
        </FinishLessonButton>
      )}

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
        />
      )}
    </LessonPreviewPageContainer>
  );
};

export default memo(LessonPreviewPage);
