import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import AdminPageQuestionsList from "./admin_page_questions_list";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";
import AdminPageQuestionSettings from "./admin_page_question_settings";
import { BASE_API_URL } from "../../../common/endpoints";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import { ToastContainerStyled } from "../../../common/components/toast_notification_styled";
import UserInfoModalWindow from "../../../common/components/modal_windows/user_info_modal_window";
import { useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../../user_data_context";

const DEFAULT_QUESTIONS_FILTERS = {
  question_status: "",
  question_recipient: {},
  question_date_range: [],
  question_bible: [],
  search_words: [],
};

const AdminPageQuestionsWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageQuestionsContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
`;

const AdminPageQuestions = () => {
  const navigate = useNavigate();
  const adminPageQuestionsRef = useRef(null);
  const { userData, accessRights, authError } = useUserDataContext();

  const [questionsList, setQuestionsList] = useState([]);
  const [questionSettingsData, setQuestionSettingsData] = useState({});
  const [questionSettingsMemoData, setQuestionSettingsMemoData] = useState({});
  const [questionsFilterSettings, setQuestionsFilterSettings] = useState(
    DEFAULT_QUESTIONS_FILTERS
  );
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const hasAccess =
    accessRights.isSuperadmin ||
    accessRights.isAdminOfPlatformQuestions ||
    accessRights.isAuthor;

  useEffect(() => {
    if (!hasAccess && (authError || userData._id)) {
      navigate("/bible");
      setTimeout(() => localStorage.removeItem("lastAdminUrl"), 1000);
    }
  }, [accessRights, authError, userData]);

  const fetchQuestions = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/questions`);
      setQuestionsList(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    const showAllQuestions =
      accessRights.isAdminOfPlatformQuestions || accessRights.isSuperadmin;

    if (userData._id && !showAllQuestions) {
      setQuestionsFilterSettings((prevState) => ({
        ...prevState,
        question_recipient: userData,
      }));
    }
  }, [userData, accessRights]);

  useEffect(() => {
    const loadQuestions = async () => {
      setIsLoading(true);
      await fetchQuestions();
      setIsLoading(false);
    };

    if (userData._id) {
      loadQuestions();
    }
  }, [userData]);

  const closeAllSettings = useCallback(() => {
    setQuestionSettingsData({});
    setQuestionSettingsMemoData({});
    setOpenEditQuestionModal(false);
    setOpenDeleteQuestionModal(false);
    fetchQuestions();
  }, []);

  const onCloseQuestionSettings = useCallback(() => {
    if (compareObjects(questionSettingsData, questionSettingsMemoData)) {
      closeAllSettings();
    } else {
      setOpenEditQuestionModal(true);
    }
  }, [questionSettingsData, questionSettingsMemoData]);

  if (!hasAccess) return;

  return (
    <AdminPageQuestionsWrapper onMouseDown={onCloseQuestionSettings}>
      <AdminPageQuestionsContainer ref={adminPageQuestionsRef}>
        <AdminPageQuestionsList
          questionsList={questionsList}
          questionsFilterSettings={questionsFilterSettings}
          questionSettingsData={questionSettingsData}
          questionSettingsMemoData={questionSettingsMemoData}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
          setQuestionSettingsData={setQuestionSettingsData}
          setQuestionSettingsMemoData={setQuestionSettingsMemoData}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          isLoading={isLoading}
        />
        <AdminPageQuestionSettings
          questionSettingsData={questionSettingsData}
          setQuestionSettingsData={setQuestionSettingsData}
          setQuestionSettingsMemoData={setQuestionSettingsMemoData}
          setQuestionsList={setQuestionsList}
          openEditQuestionModal={openEditQuestionModal}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          closeAllSettings={closeAllSettings}
          openDeleteQuestionModal={openDeleteQuestionModal}
          setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          setSelectedUserInfo={setSelectedUserInfo}
        />
      </AdminPageQuestionsContainer>

      <ScrollToTopButton parentRef={adminPageQuestionsRef} />
      <ToastContainerStyled />

      {selectedUserInfo._id && (
        <UserInfoModalWindow
          selectedUserInfo={selectedUserInfo}
          setSelectedUserInfo={setSelectedUserInfo}
        />
      )}
    </AdminPageQuestionsWrapper>
  );
};

export default memo(AdminPageQuestions);
