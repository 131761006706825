import React, { memo, useEffect, useRef } from "react";
import styled from "styled-components";
import { UserInfoSectionContainer } from "..";
import UserInfoAction from "./user_info_action";

const UserActionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > h6 {
    font-size: 14px;
    padding: 8px 15px;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    user-select: none;
  }
`;

const UserInfoActionsSection = ({
  selectedUserInfo,
  setSelectedUserInfo,
  scrollWrapperRef,
}) => {
  const userInfoActionsRef = useRef(null);
  const onFocus = selectedUserInfo.component === "user_penalty";
  const userInfoActions = selectedUserInfo.data.user_permissions.actions || [];

  useEffect(() => {
    if (onFocus && userInfoActionsRef.current && scrollWrapperRef.current) {
      const topOfViewport =
        document.documentElement.scrollTop || document.body.scrollTop || 0;

      const containerTop =
        userInfoActionsRef.current.getBoundingClientRect().top + topOfViewport;

      scrollWrapperRef.current.scrollTo({
        top: containerTop,
        behavior: "smooth",
      });
    }
  }, [onFocus, userInfoActionsRef, scrollWrapperRef]);

  return (
    <UserInfoSectionContainer ref={userInfoActionsRef}>
      Действия
      <UserActionsList>
        {userInfoActions.length ? (
          userInfoActions.map((action, index) => {
            return (
              <UserInfoAction
                key={"action-" + index}
                action={action}
                setSelectedUserInfo={setSelectedUserInfo}
              />
            );
          })
        ) : (
          <h6>Пока нет действий...</h6>
        )}
      </UserActionsList>
    </UserInfoSectionContainer>
  );
};

export default memo(UserInfoActionsSection);
