import React from "react";

const UnbindMentorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.585 6.34269L12.7064 4.22137C13.1707 3.75708 13.7219 3.38878 14.3285 3.13751C14.9351 2.88623 15.5853 2.75691 16.2419 2.75691C16.8985 2.75691 17.5487 2.88624 18.1553 3.13751C18.7619 3.38878 19.3131 3.75708 19.7774 4.22137C20.2417 4.68567 20.61 5.23686 20.8613 5.84349C21.1126 6.45012 21.2419 7.1003 21.2419 7.75691C21.2419 8.41352 21.1126 9.0637 20.8613 9.67032C20.61 10.277 20.2417 10.8281 19.7774 11.2924L17.6561 13.4138M13.4135 17.6564L11.2921 19.7777C10.8279 20.242 10.2767 20.6103 9.67003 20.8616C9.0634 21.1129 8.41322 21.2422 7.75661 21.2422C6.43053 21.2422 5.15876 20.7154 4.22108 19.7777C3.2834 18.84 2.75661 17.5683 2.75661 16.2422C2.75661 14.9161 3.2834 13.6443 4.22108 12.7067L6.3424 10.5853"
        stroke="url(#paint0_linear_6657_6609)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17188 14.8281L14.8287 9.17127"
        stroke="url(#paint1_linear_6657_6609)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6657_6609"
          x1="6.78791"
          y1="7.54017"
          x2="15.7827"
          y2="16.535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6657_6609"
          x1="11.7336"
          y1="12.0064"
          x2="12.6331"
          y2="12.9059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnbindMentorIcon;
