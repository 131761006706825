import React, { useCallback } from "react";
import styled from "styled-components";

const TranlationPanelInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 14px;
  border-top: 0.5px solid #cacaca;
  border-bottom: 0.5px solid #cacaca;
`;

const TranlationPanelInputStyled = styled.input`
  width: 80%;
`;

const TranlationPanelInputButton = styled.div`
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    scale: 1.05;
  }
`;

const TranlationPanelInput = ({
  placeholder,
  button,
  onClickButton,
  inputSearchValue,
  setInputSearchValue,
}) => {
  const handleInputValue = useCallback((e) => {
    setInputSearchValue(e.target.value);
  }, []);

  return (
    <TranlationPanelInputContainer>
      <TranlationPanelInputStyled
        placeholder={placeholder}
        value={inputSearchValue}
        onChange={handleInputValue}
      />

      <TranlationPanelInputButton onClick={onClickButton}>
        {button}
      </TranlationPanelInputButton>
    </TranlationPanelInputContainer>
  );
};

export default TranlationPanelInput;
