import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUserDataContext } from "../../../user_data_context";

const AdminPageAnalyticsWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageAnalyticsContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;

  padding: 40px;
`;

const AdminPageAnalytics = () => {
  const navigate = useNavigate();
  const { userData, accessRights } = useUserDataContext();

  const hasAccess =
    accessRights.isSuperadmin || accessRights.isAdminOfPlatformAnalytics;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
      setTimeout(() => localStorage.removeItem("lastAdminUrl"), 1000);
    }
  }, [accessRights, userData]);

  if (!hasAccess) return;

  return (
    <AdminPageAnalyticsWrapper>
      <AdminPageAnalyticsContainer>ANALYTICS ADMIN</AdminPageAnalyticsContainer>
    </AdminPageAnalyticsWrapper>
  );
};

export default memo(AdminPageAnalytics);
