import React from "react";

const ShowPasswordIcon = () => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1156 10.105L8.6025 8.59094C8.01735 8.80015 7.38483 8.83889 6.77852 8.70266C6.17221 8.56643 5.61706 8.26083 5.17764 7.82142C4.73823 7.38201 4.43263 6.82685 4.2964 6.22054C4.16017 5.61424 4.19892 4.98171 4.40812 4.39656L2.47688 2.46531C0.879375 3.88562 0 5.5 0 5.5C0 5.5 2.8125 10.6562 7.5 10.6562C8.40038 10.6531 9.29057 10.4655 10.1156 10.105ZM4.88438 0.895C5.70943 0.534461 6.59962 0.34685 7.5 0.34375C12.1875 0.34375 15 5.5 15 5.5C15 5.5 14.1197 7.11344 12.5241 8.53562L10.5909 6.6025C10.8001 6.01735 10.8389 5.38483 10.7027 4.77852C10.5664 4.17221 10.2608 3.61706 9.82142 3.17764C9.38201 2.73823 8.82685 2.43263 8.22054 2.2964C7.61424 2.16017 6.98171 2.19892 6.39656 2.40813L4.88438 0.895938V0.895Z"
      fill="#272727"
    />
  </svg>
);

export default ShowPasswordIcon;
