import React from "react";
import styled from "styled-components";

const GroupDescriptionContainer = styled.div`
  margin: 45px auto 0;
  padding: 25px 35px 38px 40px;
  width: 508px;
  background: linear-gradient(180deg, #05baa4 0%, #288bce 100%);
  border-radius: 25px;
  color: #ffffff;

  @media (max-width: 528px) {
    width: 380px;
    margin: 30px auto 0;
    padding: 25px 23px 30px 27px;
  }

  @media (max-width: 412px) {
    width: 330px;
  }
`;

const GroupDescriptionTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;

  @media (max-width: 528px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
  }
`;

const GroupDescriptionText = styled.ul`
  margin: 13px 0 0 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  > li {
    list-style-type: disc;
  }

  @media (max-width: 528px) {
    font-size: 14px;
    line-height: 140%;
    margin: 8px 0 0 17px;
  }

  @media (max-width: 412px) {
    font-size: 12px;
  }
`;

const GroupDescription = () => {
  return (
    <GroupDescriptionContainer>
      <GroupDescriptionTitle>Lorem ipsum dolor sit:</GroupDescriptionTitle>
      <GroupDescriptionText>
        <li>Lorem ipsum dolor sit amet consectetur.</li>
        <li>Vitae porttitor sit gravida amet condimentum scelerisque.</li>
        <li>Diam vehicula aliquam vitae massa varius cras interdum.</li>
        <li>Diam vehicula aliquam vitae massa varius cras interdum.</li>
        <li>Lorem ipsum dolor sit amet consectetur.</li>
      </GroupDescriptionText>
    </GroupDescriptionContainer>
  );
};

export default GroupDescription;
