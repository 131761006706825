import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../common/components/checkbox";

const SendWarningToUserContainer = styled.div``;

const CauseForWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 14px;
  color: #1e1e1e;
  font-size: 10px;
  ${({ disabled }) => disabled && "opacity 0.5;"}
`;

const CauseForWarningInputWrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #5172ea;
  display: inline-block;
  ${({ isEmpty }) => isEmpty && "border-color: red;"}
`;

const CauseForWarningInput = styled.textarea`
  height: fit-content;
  resize: none;
  min-height: 40px;
  max-height: 80px;
  width: 100%;
  padding: 8px 15px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const SendWarningToUser = ({
  sendWarning,
  setSendWarning,
  warningCause,
  setWarningCause,
  emptyInput,
}) => {
  const causeForWarningInputRef = useRef(null);

  const handleSendWarningToUser = useCallback((event) => {
    event.stopPropagation();
    setSendWarning((prevState) => !prevState);
  }, []);

  const hadleCauseForWarningInput = useCallback((event) => {
    setWarningCause(event.target.value);
  }, []);

  useEffect(() => {
    if (causeForWarningInputRef.current) {
      causeForWarningInputRef.current.style.height = "auto";
      causeForWarningInputRef.current.style.height =
        causeForWarningInputRef.current.scrollHeight + "px";
    }
  }, [warningCause, causeForWarningInputRef]);

  return (
    <SendWarningToUserContainer>
      <Checkbox
        fontSize={"14px"}
        checked={sendWarning}
        onClickCheckbox={handleSendWarningToUser}
      >
        Отправить предупреждение
      </Checkbox>
      <CauseForWarningContainer disabled={!sendWarning}>
        Опишите причину предупреждения*
        <CauseForWarningInputWrapper isEmpty={emptyInput && !warningCause}>
          <CauseForWarningInput
            ref={causeForWarningInputRef}
            placeholder="Причина предупреждения"
            type="text"
            rows={1}
            value={warningCause}
            onChange={hadleCauseForWarningInput}
            disabled={!sendWarning}
          />
        </CauseForWarningInputWrapper>
      </CauseForWarningContainer>
    </SendWarningToUserContainer>
  );
};

export default memo(SendWarningToUser);
