import React, { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const BookChapterSelect = styled.div`
  width: 30px;
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)"
      : "#D9D9D9"};

  ${({ isActive }) =>
    !isActive &&
    `
  &:hover {
    background: #bfbebe;
    transition: 0.2s;
  }`}
`;

const ChapterNavigationItem = ({ chapterNum, book }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedChapter, setSelectedChapter] = useState(false);

  useEffect(() => {
    const isSelected =
      chapterNum === +searchParams.get("chapterId") &&
      book.book_code === searchParams.get("bookId");
    setSelectedChapter(isSelected);
  }, [searchParams.get("chapterId"), searchParams.get("bookId")]);

  const handleSetParams = useCallback(() => {
    if (chapterNum) {
      setSearchParams((prev) => {
        prev.set("bibleId", book.bible_code);
        prev.set("bookId", book.book_code);
        prev.set("chapterId", chapterNum);
        prev.delete("selectVerses");
        prev.delete("selectBible");
        searchParams.get("2bibleId") &&
          prev.set("2bibleId", searchParams.get("2bibleId"));
        return prev;
      });
    }
  }, [chapterNum, searchParams.get("2bibleId")]);

  return (
    <BookChapterSelect isActive={selectedChapter} onClick={handleSetParams}>
      {chapterNum}
    </BookChapterSelect>
  );
};

export default memo(ChapterNavigationItem);
