import React, { memo, useCallback } from "react";
import styled from "styled-components";
import FramedBibleVerse from "../../../../common/components/framed_bible_verse";

const AdminPageHomePreviewDailyVerse = styled.div`
  padding-bottom: 12px;
  cursor: pointer;
  ${({ opacity }) => opacity && "opacity: 0.5"}
`;

const HomePreviewDailyVerse = ({
  homePageDailyVerse,
  editedArticleId,
  setActiveSettingsBlock,
  onCloseActiveSettings,
}) => {
  const handleClickOnDailyVerse = useCallback(
    (event) => {
      event.stopPropagation();
      if (editedArticleId) {
        onCloseActiveSettings();
      } else {
        setActiveSettingsBlock("daily_verse_settings");
      }
    },
    [editedArticleId]
  );

  return (
    <AdminPageHomePreviewDailyVerse
      opacity={editedArticleId}
      onClick={handleClickOnDailyVerse}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {homePageDailyVerse.verseText ? (
        <FramedBibleVerse
          verse={homePageDailyVerse}
          background={"#eaeaea"}
          inComponent={"home_page"}
        />
      ) : (
        <div>Кликните, чтобы открыть настройки стиха</div>
      )}
    </AdminPageHomePreviewDailyVerse>
  );
};

export default memo(HomePreviewDailyVerse);
