import React, { createContext, useContext, useState } from "react";

const ChatContentContext = createContext();

export const useChatContentState = () => {
  const [replyMessage, setReplyMessage] = useState({});
  const [editMessage, setEditMessage] = useState({});
  const [chatInputValue, setChatInputValue] = useState("");
  const isAdmin = true;

  return {
    replyMessage,
    setReplyMessage,
    editMessage,
    setEditMessage,
    chatInputValue,
    setChatInputValue,
    isAdmin,
  };
};

export const ChatContentProvider = ({ children }) => {
  const {
    replyMessage,
    setReplyMessage,
    editMessage,
    setEditMessage,
    chatInputValue,
    setChatInputValue,
    isAdmin,
  } = useChatContentState();

  return (
    <ChatContentContext.Provider
      value={{
        replyMessage,
        setReplyMessage,
        editMessage,
        setEditMessage,
        chatInputValue,
        setChatInputValue,
        isAdmin,
      }}
    >
      {children}
    </ChatContentContext.Provider>
  );
};

export const useChatContentContext = () => {
  return useContext(ChatContentContext);
};
