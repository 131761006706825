import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import BibleModalNavigation from "./bible_modal_navigation";
import InsertionVariantButtons from "./insertion_variant_buttons";
import BibleModalWindowVerses from "./bible_modal_window_verses/index.js";
import BibleModalSearchResults from "./bible_modal_search_results/index.js";
import { useAppContext } from "../../../../app_context.js";

const DEFAULT_BIBLE_PARAMS = {
  bibleId: "rst",
  bookId: "Gen",
  chapterId: 1,
};

const BibleModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const BibleModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  gap: 18px;
  padding: 18px;
  width: 691px;
  height: calc(100vh - 30px);
  max-height: 676px;
  border-radius: 25px;
  background: #eaeaea;
  z-index: 9999;
`;

const BibleModalWindowRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 236px;
`;

const BibleModalWindowCancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 21px;
  min-height: 30px;
  border-radius: 8px;
  border: 2px solid #fff;
  cursor: pointer;

  > span {
    font-size: 16px;
    line-height: 16px;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background: rgb(223 223 223);
    transition: 0.2s;
  }
`;

const BibleModalWindow = ({
  editedVersesData,
  setEditedVersesData,
  setOpenBibleModalWindow,
  setChatInputValue,
  handleAddBibleVerses,
  inComponent,
}) => {
  const { bibleFontSettings } = useAppContext();
  const [bibleTranslations, setBibleTranslations] = useState([]);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [bibleModalSearchPlace, setBibleModalSearchPlace] = useState("");
  const [bibleModalSearchInput, setBibleModalSearchInput] = useState("");
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [bibleModalSearchResults, setBibleModalSearchResults] = useState({});
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [activeMainSection, setActiveMainSection] = useState(
    "bible_modal_chapter"
  ); // bible_modal_chapter, bible_modal_search
  const [currentBibleParams, setCurrentTranslationParams] =
    useState(DEFAULT_BIBLE_PARAMS);

  useEffect(() => {
    if (Object.values(editedVersesData).length && bibleTranslations) {
      setCurrentTranslationParams(editedVersesData.versesParams);
      setSelectedVerses(editedVersesData.versesData);
    }
  }, [editedVersesData, bibleTranslations]);

  useEffect(() => {
    if (currentBibleParams.bibleId && bibleTranslations.length) {
      const translation = bibleTranslations.find(
        (transl) => transl.bible_code === currentBibleParams.bibleId
      );
      setCurrentTranslation(translation);
    }
  }, [currentBibleParams, bibleTranslations]);

  const handleCloseBibleModalWindow = useCallback((e) => {
    e.stopPropagation();
    setOpenBibleModalWindow(false);
    setSelectedVerses([]);
    setActiveMainSection("bible_modal_chapter");
    setBibleModalSearchResults({});
    setEditedVersesData({});
    setCurrentTranslationParams(DEFAULT_BIBLE_PARAMS);
  }, []);

  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    <BibleModalWindowWrapper
      onMouseDown={stopPropagationOnClick}
      onClick={handleCloseBibleModalWindow}
    >
      <BibleModalWindowContainer
        onClick={stopPropagationOnClick}
        onMouseDown={stopPropagationOnClick}
      >
        {activeMainSection === "bible_modal_chapter" && (
          <BibleModalWindowVerses
            currentBibleParams={currentBibleParams}
            currentTranslation={currentTranslation}
            bibleFontSettings={bibleFontSettings}
            selectedVerses={selectedVerses}
            setSelectedVerses={setSelectedVerses}
            inComponent={inComponent}
          />
        )}

        {activeMainSection === "bible_modal_search" && (
          <BibleModalSearchResults
            searchIsLoading={searchIsLoading}
            bibleModalSearchResults={bibleModalSearchResults}
            currentTranslation={currentTranslation}
            setBibleModalSearchResults={setBibleModalSearchResults}
            setBibleModalSearchInput={setBibleModalSearchInput}
            setActiveMainSection={setActiveMainSection}
            setCurrentTranslationParams={setCurrentTranslationParams}
            setSelectedVerses={setSelectedVerses}
          />
        )}

        <BibleModalWindowRightBlock>
          <BibleModalNavigation
            bibleTranslations={bibleTranslations}
            currentTranslation={currentTranslation}
            setBibleTranslations={setBibleTranslations}
            currentBibleParams={currentBibleParams}
            setCurrentTranslationParams={setCurrentTranslationParams}
            setSelectedVerses={setSelectedVerses}
            bibleModalSearchPlace={bibleModalSearchPlace}
            bibleModalSearchInput={bibleModalSearchInput}
            setBibleModalSearchInput={setBibleModalSearchInput}
            setSearchIsLoading={setSearchIsLoading}
            activeMainSection={activeMainSection}
            setActiveMainSection={setActiveMainSection}
            setBibleModalSearchResults={setBibleModalSearchResults}
            setBibleModalSearchPlace={setBibleModalSearchPlace}
          />

          <BibleModalWindowCancelButton onClick={handleCloseBibleModalWindow}>
            <span>Отмена</span>
          </BibleModalWindowCancelButton>
        </BibleModalWindowRightBlock>

        {activeMainSection === "bible_modal_chapter" &&
          inComponent !== "admin_page_questions" && (
            <InsertionVariantButtons
              selectedVerses={selectedVerses}
              setSelectedVerses={setSelectedVerses}
              currentTranslation={currentTranslation}
              currentBibleParams={currentBibleParams}
              handleAddBibleVerses={handleAddBibleVerses}
              handleCloseBibleModalWindow={handleCloseBibleModalWindow}
              setChatInputValue={setChatInputValue}
              setEditedVersesData={setEditedVersesData}
              inComponent={inComponent}
            />
          )}
      </BibleModalWindowContainer>
    </BibleModalWindowWrapper>
  );
};

export default memo(BibleModalWindow);
