import React from "react";

const PlusSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99928 1.78384C8.99928 1.23155 8.55157 0.783838 7.99928 0.783838C7.447 0.783838 6.99928 1.23155 6.99928 1.78384V7.00781H1.77603C1.22374 7.00781 0.776025 7.45553 0.776025 8.00781C0.776025 8.56009 1.22374 9.00781 1.77603 9.00781H6.99928V14.2317C6.99928 14.784 7.447 15.2317 7.99928 15.2317C8.55157 15.2317 8.99928 14.784 8.99928 14.2317V9.00781H14.2239C14.7762 9.00781 15.2239 8.56009 15.2239 8.00781C15.2239 7.45553 14.7762 7.00781 14.2239 7.00781H8.99928V1.78384Z"
        fill="url(#paint0_linear_7634_6564)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7634_6564"
          x1="7.64591"
          y1="-2.00911"
          x2="7.64591"
          y2="16.6244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusSmallIcon;
