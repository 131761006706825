import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { APPLICATION_ROUTES } from "../../common/utils/routes";

const Spinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid rgb(248, 210, 84);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(50vh - 70px);
`;

const loginGmail = async ({ code }) => {
  const GM_REDIRECT_URI =
    process.env.REACT_APP_BASE_URL + APPLICATION_ROUTES.OAUTH_GMAIL;
  const response = await axios.get(
    `${process.env.REACT_APP_API}/auth/bygoogleoauth?redirect_uri=${GM_REDIRECT_URI}&code=${code}`
  );

  console.log(JSON.stringify(response.data, null, 2));

  if (response?.data?.access_token) {
    localStorage.setItem("access_token", response.data.access_token);
    location.assign(APPLICATION_ROUTES.STUDY);
    return true;
  }

  return false;
};

const OauthGmailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const queryParams = {};
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  useEffect(() => {
    loginGmail(queryParams);
  }, []);

  return (
    <PageContainer>
      <Spinner />
    </PageContainer>
  );
};

export default OauthGmailPage;
