import React from "react";

const BranchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_7634_6515)">
        <path
          d="M11.0001 9.99996L17 10L17 17M11.0001 9.99996L5.00012 9.99996L5.00012 17M11.0001 9.99996L11.0001 17M11.0001 9.99996V4.99996M17 17H16M17 17L18 17M5.00012 17L6 17M5.00012 17L4 17M11.0001 17H12M11.0001 17H10M11.0001 4.99996L14 5.00004M11.0001 4.99996H8.00004"
          stroke="url(#paint0_linear_7634_6515)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7634_6515"
          x1="18.2562"
          y1="5.21208"
          x2="4.26355"
          y2="19.2047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_7634_6515">
          <rect width="22" height="22.0049" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BranchIcon;
