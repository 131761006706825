import React from "react";
import styled from "styled-components";

export const ListItemSkeleton = styled.div`
  height: 25px;
  flex: 1;
  margin: ${({ margin }) => margin || "0 10px 10px"};
  border-radius: 10px;
  background: rgb(223 223 223);
`;

const SimpleListLoader = () => {
  return (
    <>
      {Array(20)
        .fill()
        .map((value, index) => (
          <ListItemSkeleton key={index} />
        ))}
    </>
  );
};
export default SimpleListLoader;
