import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const CourseVideoModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const CourseVideoModalWindowContainer = styled.div`
  position: relative;
  width: 60%;
  padding-top: 33.75%;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0 50px;
    padding-top: 48%;
  }

  @media (max-width: 670px) {
    width: 100%;
    margin: 0 30px;
    padding-top: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 5px;
    padding-top: 54%;
  }
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
  object-fit: cover;
`;

const getEmbedUrl = (url) => {
  if (!url) {
    console.error("No URL provided");
    return null;
  }

  try {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get("v");
    if (!videoId) {
      throw new Error("Invalid YouTube URL");
    }

    return `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&modestbranding=1&autohide=1&showinfo=0&iv_load_policy=3`;
  } catch (error) {
    console.error("Invalid URL provided:", url, error);
    return null;
  }
};

const CourseVideoModalWindow = ({ videoUrl, setOpenCourseVideoModal }) => {
  const [embedUrl, setEmbedUrl] = useState(null);

  useEffect(() => {
    setEmbedUrl(getEmbedUrl(videoUrl));
  }, [videoUrl]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setEmbedUrl(null);
    setOpenCourseVideoModal(false);
  }, []);

  return (
    <CourseVideoModalWindowWrapper onClick={handleClose}>
      <CourseVideoModalWindowContainer onClick={(e) => e.stopPropagation()}>
        {embedUrl && (
          <VideoIframe
            src={embedUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </CourseVideoModalWindowContainer>
    </CourseVideoModalWindowWrapper>
  );
};

export default memo(CourseVideoModalWindow);
