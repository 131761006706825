import React from "react";
import styled from "styled-components";
import SearchIcon from "../../../common/assets/icons/search_icon";

const SearchChatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
`;

const SearchChatsInput = styled.input`
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  width: 89%;
`;

const SearchChats = ({
  searchInputValue,
  setSearchInputValue,
  className,
  component,
}) => {
  const handleSearchInputValue = (e) => {
    const value = e.target.value;
    setSearchInputValue(value);
  };

  return (
    <SearchChatsContainer className={className}>
      {component === "forward" && <SearchIcon />}
      <SearchChatsInput
        placeholder="Ищите и найдёте :)"
        value={searchInputValue}
        onChange={handleSearchInputValue}
      />
      {component !== "forward" && <SearchIcon />}
    </SearchChatsContainer>
  );
};

export default SearchChats;
