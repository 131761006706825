import React from "react";

const MentorChangeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_6657_6618)">
        <rect
          x="1"
          y="1"
          width="8"
          height="8"
          rx="2"
          stroke="url(#paint0_linear_6657_6618)"
          strokeWidth="2"
        />
        <rect
          x="15"
          y="15"
          width="8"
          height="8"
          rx="2"
          stroke="url(#paint1_linear_6657_6618)"
          strokeWidth="2"
        />
        <path
          d="M22 9C22 9.55228 22.4477 10 23 10C23.5523 10 24 9.55228 24 9H22ZM24 9V7H22V9H24ZM21 4H16V6H21V4ZM24 7C24 5.34315 22.6569 4 21 4V6C21.5523 6 22 6.44772 22 7H24Z"
          fill="url(#paint2_linear_6657_6618)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 8.06863C17 8.78135 16.1383 9.13829 15.6343 8.63432L12.5657 5.56569C12.2533 5.25327 12.2533 4.74673 12.5657 4.43431L15.6343 1.36569C16.1383 0.861713 17 1.21865 17 1.93137L17 8.06863Z"
          fill="url(#paint3_linear_6657_6618)"
        />
        <path
          d="M2 16C2 15.4477 1.55228 15 1 15C0.447715 15 3.97973e-07 15.4477 3.49691e-07 16L2 16ZM3.49691e-07 16L1.74846e-07 18L2 18L2 16L3.49691e-07 16ZM3 21L8 21L8 19L3 19L3 21ZM1.74846e-07 18C2.99987e-08 19.6569 1.34315 21 3 21L3 19C2.44771 19 2 18.5523 2 18L1.74846e-07 18Z"
          fill="url(#paint4_linear_6657_6618)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 16.9314C7 16.2186 7.86171 15.8617 8.36568 16.3657L11.4343 19.4343C11.7467 19.7467 11.7467 20.2533 11.4343 20.5657L8.36568 23.6343C7.86171 24.1383 7 23.7814 7 23.0686L7 16.9314Z"
          fill="url(#paint5_linear_6657_6618)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6657_6618"
          x1="4.80665"
          y1="-0.470588"
          x2="4.80665"
          y2="9.70588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6657_6618"
          x1="18.8066"
          y1="13.5294"
          x2="18.8066"
          y2="23.7059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6657_6618"
          x1="19.3308"
          y1="4.26471"
          x2="19.3308"
          y2="9.35294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6657_6618"
          x1="14.6208"
          y1="11.8382"
          x2="14.6208"
          y2="-0.882353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6657_6618"
          x1="4.66918"
          y1="20.7353"
          x2="4.66918"
          y2="15.6471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6657_6618"
          x1="9.37915"
          y1="13.1618"
          x2="9.37915"
          y2="25.8824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_6657_6618">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MentorChangeIcon;
