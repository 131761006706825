import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const SpinnerLoaderContainer = styled.div`
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

const SpinElement = styled.div`
  transform-origin: 40px 40px;
  animation: ${spin} 1.2s linear infinite;

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #cacaca;
  }

  ${({ rotation, delay }) => `
    transform: rotate(${rotation}deg);
    animation-delay: ${delay}s;
  `}
`;

const SpinnerLoader = () => {
  return (
    <SpinnerLoaderContainer className="lds-spinner">
      {Array.from({ length: 12 }).map((_, index) => (
        <SpinElement
          key={index}
          rotation={30 * index}
          delay={-1.1 + 0.1 * index}
        />
      ))}
    </SpinnerLoaderContainer>
  );
};

export default SpinnerLoader;
