import React from "react";

const UnselectedCheckboxSmall = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 149">
        <rect
          id="Rectangle 388"
          x="0.5"
          y="0.5"
          width="16"
          height="16"
          rx="3.5"
          stroke="url(#paint0_linear_5567_7133)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5567_7133"
          x1="8.08912"
          y1="-3.125"
          x2="8.08912"
          y2="18.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnselectedCheckboxSmall;
