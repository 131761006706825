import React, { memo } from "react";
import {
  ButtonGroup,
  SettingsButtonsContainer,
  ToolbarButton,
} from "../editor_styled_components";
import { RichUtils } from "draft-js";
import styled from "styled-components";
import MarkedListIcon from "../../../../common/assets/icons/text_editor_icons/text_list_icons/marked_list_icon";
import NumberedListIcon from "../../../../common/assets/icons/text_editor_icons/text_list_icons/numbered_list_icon";
import TextSettingsButtonsHeader from "./text_settings_buttons_header";
import TextListIcon from "../../../../common/assets/icons/text_editor_icons/text_list_icons/text_list_icon";

const TextListButton = styled(ToolbarButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 44px;
`;

const TextListButtons = ({
  editorState,
  setEditorState,
  openTextSettings,
  setOpenTextSettings,
}) => {
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  return (
    <ButtonGroup>
      <TextSettingsButtonsHeader
        isOpen={openTextSettings === "text_list"}
        setOpen={setOpenTextSettings}
        buttonsGroup={"text_list"}
      >
        <TextListIcon />
      </TextSettingsButtonsHeader>

      {openTextSettings === "text_list" && (
        <SettingsButtonsContainer>
          <TextListButton
            active={currentBlockType === "unordered-list-item"}
            onClick={() => toggleBlockType("unordered-list-item")}
          >
            <MarkedListIcon />
          </TextListButton>
          <TextListButton
            active={currentBlockType === "ordered-list-item"}
            onClick={() => toggleBlockType("ordered-list-item")}
          >
            <NumberedListIcon />
          </TextListButton>
        </SettingsButtonsContainer>
      )}
    </ButtonGroup>
  );
};

export default memo(TextListButtons);
