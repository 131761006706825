import React from "react";

const QuestionIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M14.7077 18.0596C14.705 18.0596 14.7024 18.0596 14.6997 18.0596C14.2104 18.0551 13.8169 17.655 13.8213 17.1652L13.8253 16.7221C13.8253 16.6968 13.8266 16.6711 13.8293 16.6458C13.952 15.3566 14.8078 14.5664 15.4961 13.9313C15.7292 13.716 15.9499 13.5125 16.1378 13.3002C16.3669 13.0419 16.6993 12.5127 16.3497 11.8741C15.9468 11.1366 14.9629 10.9279 14.1985 11.103C13.3998 11.2855 13.1056 11.9676 13.001 12.3576C12.8742 12.8305 12.3881 13.111 11.9152 12.9847C11.4423 12.858 11.1618 12.3718 11.2881 11.8989C11.6413 10.5796 12.5578 9.65995 13.8022 9.37499C15.477 8.99252 17.1638 9.66926 17.9043 11.0232C18.5208 12.1506 18.352 13.4735 17.4634 14.476C17.2165 14.7543 16.9528 14.9981 16.6976 15.2334C16.0616 15.8206 15.6641 16.2133 15.5967 16.7801L15.5931 17.1803C15.5896 17.6682 15.1934 18.0596 14.7077 18.0596Z"
        fill="url(#paint0_linear_7509_13640)"
      />
      <path
        d="M14.6989 20.7177C14.4684 20.7177 14.238 20.6246 14.074 20.4606C13.9056 20.2966 13.8125 20.0662 13.8125 19.8313C13.8125 19.6008 13.9056 19.3704 14.074 19.2064C14.4019 18.8785 14.9958 18.8785 15.3237 19.2064C15.4922 19.3704 15.5852 19.6008 15.5852 19.8313C15.5852 20.0662 15.4922 20.2922 15.3282 20.4606C15.1598 20.6246 14.9337 20.7177 14.6989 20.7177Z"
        fill="url(#paint1_linear_7509_13640)"
      />
      <path
        d="M14.9696 26.5208C13.9614 26.5208 12.962 26.3897 11.9989 26.1304C7.84899 25.014 4.59958 21.5763 3.72075 17.3718C2.82508 13.0884 4.47815 8.58572 7.93275 5.90048C9.92973 4.34802 12.4483 3.49268 15.025 3.49268C16.8088 3.49268 18.5802 3.90749 20.1482 4.69148C24.0203 6.62863 26.522 10.6758 26.522 15.0026C26.522 18.5329 24.8561 21.9357 22.0658 24.1046C20.0618 25.6628 17.5414 26.5208 14.9696 26.5208ZM15.0254 5.2654C12.841 5.2654 10.7084 5.98779 9.02121 7.30005C6.09754 9.57269 4.69797 13.3836 5.45625 17.0088C6.19991 20.5654 8.94897 23.474 12.4599 24.4184C13.2726 24.6369 14.1174 24.7481 14.9696 24.7481C17.1492 24.7481 19.2831 24.0226 20.9783 22.7051C23.34 20.8694 24.7497 17.99 24.7497 15.0026C24.7497 11.3414 22.6327 7.91652 19.3558 6.27719C18.0324 5.61552 16.5354 5.2654 15.0254 5.2654Z"
        fill="url(#paint2_linear_7509_13640)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7509_13640"
          x1="8.91644"
          y1="20.2572"
          x2="20.7434"
          y2="19.9231"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7509_13640"
          x1="13.2207"
          y1="21.157"
          x2="16.2086"
          y2="21.0502"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7509_13640"
          x1="-4.21165"
          y1="32.2779"
          x2="34.6259"
          y2="30.902"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuestionIcon;
