import groupAvatarExample from "../../common/assets/images/chat_group_avatar.png";
import avatarExample from "../../common/assets/images/avatar_example.png";
import onBibleChatAvatar from "../../common/assets/images/on_bible_chat_avatar.png";

const YOUR_MESSAGE_STATUS = {
  SENT: "SENT",
  READ: "READ",
  ERROR: "ERROR",
};

export const MESSAGE_TYPE = {
  ADMIN: "ADMIN",
  YOUR: "YOUR",
  TEACHER: "TEACHER",
  USER: "USER",
};

export const CHATS = [
  {
    id: 4,
    avatarUrl: onBibleChatAvatar,
    selected: false,
    title: "OnBible",
    last_message: "Поздравляем! Вы завершили...",
    unread: 1,
    time: "12:54",
    sender: null,
    type: "private",
    yourMessageStatus: null,
    blockedFromWriting: false,
    blockedFromWritingTime: null,
  },
  {
    id: 1,
    avatarUrl: groupAvatarExample,
    selected: false,
    title: "Третий храм",
    last_message: "Спасибо за интересный материал!",
    unread: 0,
    time: "10:10",
    sender: "Useeeer Nammme",
    type: "public",
    yourMessageStatus: null,
    blockedFromWriting: false,
    blockedFromWritingTime: null,
  },
  {
    id: 2,
    avatarUrl: avatarExample,
    selected: false,
    title: "Long title long",
    last_message: "Hello world, Hello world, Hello world ",
    unread: 30,
    time: "08:37",
    sender: "User Name",
    type: "private",
    yourMessageStatus: null,
    blockedFromWriting: true,
    blockedFromWritingTime: "23:59:59",
  },
  {
    id: 3,
    avatarUrl: avatarExample,
    selected: false,
    title: "Very long title long long title",
    last_message:
      "Hello world, very long message! Hello my world, very long message ",
    unread: 0,
    time: "10:01",
    sender: "User Name",
    type: "private",
    yourMessageStatus: YOUR_MESSAGE_STATUS.SENT,
    blockedFromWriting: false,
    blockedFromWritingTime: null,
  },
  {
    id: 6,
    avatarUrl: avatarExample,
    selected: false,
    title: "Mark Climber",
    last_message: "Hello my dear friend!",
    unread: 0,
    time: "10:01",
    sender: "User Name",
    type: "private",
    yourMessageStatus: YOUR_MESSAGE_STATUS.READ,
    blockedFromWriting: false,
    blockedFromWritingTime: null,
  },
  {
    id: 7,
    avatarUrl: avatarExample,
    selected: false,
    title: "Title example",
    last_message: "Hello world!",
    unread: 0,
    time: "10:01",
    sender: "User Name",
    type: "public",
    yourMessageStatus: YOUR_MESSAGE_STATUS.ERROR,
    blockedFromWriting: false,
    blockedFromWritingTime: null,
  },
];

export const MESSAGES = [
  {
    authorName: "Имя Автора",
    text: "Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimen tum scelerisque Vitae porttitor sit gravida amet condimen tum scelerisque",
    messageType: MESSAGE_TYPE.USER,
    favorite: false,
    edited: false,
    time: "10:40",
    avatarUrl: avatarExample,
    createdAt: 1696421233324,
    yourMessageStatus: null,
    replyInMessage: {
      authorName: "Добрый Человек",
      text: "Vitae porttitor sit gravida amet condiLorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimen tum scelerisque Vitae porttitor sit gravida amet condimen tum scelerisque",
    },
    biblicalVerses: [],
    emoji: [{ code: "1F44D", count: 3 }],
    id: 12,
    chatId: 1,
  },
  {
    authorName: "Имя Автора",
    text: "Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimentum scelerisque. Diam vehicula aliquam vitae massa varius cras interdum. Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimentum scelerisque. Diam vehicula aliquam vitae massa varius cras interdum.Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimentum scelerisque. Diam vehicula aliquam vitae massa varius cras interdum.",
    messageType: MESSAGE_TYPE.TEACHER,
    favorite: true,
    edited: false,
    time: "10:40",
    avatarUrl: avatarExample,
    createdAt: 1696421247017,
    yourMessageStatus: null,
    replyInMessage: null,
    biblicalVerses: [],
    emoji: [{ code: "1F44D", count: 14 }],
    id: 13,
    chatId: 1,
  },
  {
    authorName: "Имя Автора",
    text: "Соблюдайте Спокойствие",
    messageType: MESSAGE_TYPE.ADMIN,
    favorite: true,
    edited: false,
    time: "10:40",
    avatarUrl: avatarExample,
    createdAt: 1696421290014,
    yourMessageStatus: null,
    replyInMessage: null,
    biblicalVerses: [],
    emoji: [
      { code: "1F44D", count: 12 },
      { code: "1F600", count: 3 },
    ],
    id: 14,
    chatId: 1,
  },
  {
    authorName: "Имя Автора",
    text: "Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimen tum scelerisque  condimen tum scelerisque",
    messageType: MESSAGE_TYPE.USER,
    favorite: false,
    edited: false,
    time: "10:40",
    avatarUrl: avatarExample,
    createdAt: 1696421298132,
    yourMessageStatus: null,
    replyInMessage: null,
    biblicalVerses: [],
    emoji: [{ code: "1F44D", count: 5 }],
    id: 15,
    chatId: 1,
  },
  {
    authorName: "Имя Автора",
    text: "Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimen tum scelerisque",
    messageType: MESSAGE_TYPE.YOUR,
    favorite: false,
    edited: true,
    time: "10:40",
    avatarUrl: avatarExample,
    createdAt: 1696421312004,
    yourMessageStatus: YOUR_MESSAGE_STATUS.READ,
    replyInMessage: {
      authorName: "Добрый Человек",
      text: "Vitae porttitor sit gravida amet condiLorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimen tum scelerisque Vitae porttitor sit gravida amet condimen tum scelerisque",
    },
    biblicalVerses: [],
    emoji: [
      { code: "1F44D", count: 2 },
      { code: "1F9E1", count: 4 },
      { code: "1F64F", count: 3 },
    ],
    id: 11,
    chatId: 1,
  },
  {
    authorName: "Имя Автора",
    text: "Lorem ipsum dolor sit amet consectetur. Vitae porttitor sit gravida amet condimen tum scelerisque condimen tum sceleri sque condimen tum scelerisque condimen tum",
    messageType: MESSAGE_TYPE.YOUR,
    favorite: false,
    edited: true,
    time: "10:40",
    avatarUrl: avatarExample,
    createdAt: 1696421312004,
    yourMessageStatus: YOUR_MESSAGE_STATUS.SENT,
    replyInMessage: null,
    biblicalVerses: [
      {
        _id: "65d897f1312c6e445e727285",
        bible_code: "rst",
        book_code: "Joh",
        book_code_orig: "Иоан",
        book_place: "nt",
        chapter_num: 3,
        verse_num: 16,
        verse_text:
          "Ибо так возлюбил Бог мир, что отдал Сына Своего Единородного, дабы всякий верующий в Него, не погиб, но имел жизнь вечную.",
      },
      {
        _id: "65d897f1312c6e445e727a5c",
        bible_code: "rst",
        book_code: "Rom",
        book_code_orig: "Рим",
        book_place: "nt",
        chapter_num: 8,
        verse_num: 28,
        verse_text:
          "Притом знаем, что любящим Бога, призванным по Его изволению, все содействует ко благу.",
      },
    ],
    emoji: [],
    id: 10,
    chatId: 1,
  },
];
