import React from "react";

const NumberedListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81155 4.20027C6.81155 3.53753 7.34881 3.00027 8.01155 3.00027H21.0116C21.6743 3.00027 22.2116 3.53753 22.2116 4.20027C22.2116 4.86301 21.6743 5.40027 21.0116 5.40027H8.01155C7.34881 5.40027 6.81155 4.86301 6.81155 4.20027Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81155 11.2004C6.81155 10.5376 7.34881 10.0004 8.01155 10.0004H21.0116C21.6743 10.0004 22.2116 10.5376 22.2116 11.2004C22.2116 11.8631 21.6743 12.4004 21.0116 12.4004H8.01155C7.34881 12.4004 6.81155 11.8631 6.81155 11.2004Z"
        fill="#383838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81155 18.8003C6.81155 18.1375 7.34881 17.6003 8.01155 17.6003H21.0116C21.6743 17.6003 22.2116 18.1375 22.2116 18.8003C22.2116 19.463 21.6743 20.0003 21.0116 20.0003H8.01155C7.34881 20.0003 6.81155 19.463 6.81155 18.8003Z"
        fill="#383838"
      />
      <path
        d="M2.967 1.63672C3.33828 1.63672 3.63926 1.9377 3.63926 2.30898V5.53906C3.63926 5.79383 3.43274 6.00036 3.17797 6.00036C2.92321 6.00036 2.71668 5.79383 2.71668 5.53906V3.00037C2.70521 2.78151 2.46281 2.65555 2.27712 2.77195L2.20666 2.81612C1.98272 2.9565 1.69182 2.79552 1.69182 2.53122V2.5117C1.69182 2.39276 1.75273 2.2821 1.85322 2.21848L2.60739 1.74099C2.71496 1.67288 2.83967 1.63672 2.967 1.63672Z"
        fill="#383838"
      />
      <path
        d="M1.59409 13.4237C1.33858 13.4237 1.13145 13.2165 1.13145 12.961C1.13145 12.8321 1.18521 12.7091 1.27977 12.6215L2.68472 11.3207C2.81682 11.1928 2.92762 11.0778 3.01711 10.9755C3.10801 10.8732 3.17691 10.7731 3.22378 10.6751C3.27066 10.5756 3.29409 10.4684 3.29409 10.3533C3.29409 10.2255 3.26498 10.1154 3.20674 10.0231C3.1485 9.92933 3.06895 9.8576 2.9681 9.80788C2.86725 9.75675 2.7529 9.73118 2.62506 9.73118C2.49154 9.73118 2.37506 9.75817 2.27563 9.81215C2.1762 9.86612 2.09949 9.94354 2.04551 10.0444C1.94835 10.2259 1.79762 10.4045 1.5917 10.4045H1.52669C1.28487 10.4045 1.07941 10.2042 1.14625 9.97183C1.17831 9.86035 1.22381 9.7567 1.28273 9.66087C1.41199 9.45064 1.5931 9.288 1.82605 9.17294C2.05901 9.05788 2.32747 9.00036 2.63145 9.00036C2.94395 9.00036 3.21597 9.05575 3.4475 9.16655C3.68046 9.27592 3.86157 9.42791 3.99083 9.62251C4.12009 9.81712 4.18472 10.0401 4.18472 10.2915C4.18472 10.4563 4.15205 10.619 4.08671 10.7795C4.02279 10.94 3.90844 11.1183 3.74367 11.3143C3.5789 11.5089 3.34665 11.7425 3.04694 12.0153L2.41616 12.6334C2.41213 12.6373 2.40986 12.6427 2.40986 12.6484C2.40986 12.66 2.41927 12.6694 2.43087 12.6694H3.86512C4.0734 12.6694 4.24225 12.8382 4.24225 13.0465C4.24225 13.2548 4.0734 13.4237 3.86512 13.4237H1.59409Z"
        fill="#383838"
      />
      <path
        d="M2.65381 20.9066C2.33563 20.9066 2.05225 20.8519 1.80367 20.7425C1.55651 20.6317 1.3612 20.4798 1.21773 20.2866C1.16999 20.2212 1.13004 20.1524 1.09786 20.0803C0.990242 19.839 1.21483 19.6133 1.47903 19.6133H1.62229C1.79328 19.6133 1.9257 19.7603 2.03166 19.8945C2.097 19.9741 2.18364 20.0359 2.2916 20.0799C2.39955 20.1239 2.521 20.146 2.65594 20.146C2.79657 20.146 2.92086 20.1211 3.02881 20.0714C3.13677 20.0217 3.22129 19.9528 3.28237 19.8647C3.34344 19.7766 3.37398 19.6751 3.37398 19.56C3.37398 19.4435 3.34131 19.3406 3.27597 19.2511C3.21205 19.1602 3.11972 19.0891 2.99898 19.038C2.87967 18.9869 2.73762 18.9613 2.57285 18.9613H2.50467C2.31756 18.9613 2.16589 18.8096 2.16589 18.6225C2.16589 18.4354 2.31756 18.2837 2.50467 18.2837H2.57285C2.71205 18.2837 2.83492 18.2596 2.94146 18.2113C3.04941 18.163 3.13322 18.0962 3.19288 18.011C3.25254 17.9244 3.28237 17.8235 3.28237 17.7085C3.28237 17.5991 3.25609 17.5032 3.20353 17.4208C3.15239 17.337 3.07995 17.2717 2.9862 17.2248C2.89387 17.1779 2.78592 17.1545 2.66234 17.1545C2.53734 17.1545 2.42299 17.1772 2.3193 17.2227C2.2156 17.2667 2.13251 17.3299 2.07001 17.4123C1.96485 17.5509 1.83227 17.7021 1.65829 17.7021H1.53999C1.29031 17.7021 1.07839 17.4896 1.17525 17.2595C1.2081 17.1814 1.24996 17.1074 1.30083 17.0373C1.44004 16.8455 1.62754 16.6957 1.86333 16.5877C2.10055 16.4783 2.3683 16.4237 2.6666 16.4237C2.96773 16.4237 3.23123 16.4783 3.45708 16.5877C3.68293 16.6971 3.85836 16.8448 3.98336 17.0309C4.10978 17.2156 4.17228 17.4229 4.17086 17.6531C4.17228 17.8974 4.09629 18.1012 3.94288 18.2646C3.79429 18.4243 3.60159 18.5269 3.36476 18.5726C3.35532 18.5744 3.34842 18.5826 3.34842 18.5922C3.34842 18.6022 3.35589 18.6107 3.36579 18.612C3.67827 18.655 3.9168 18.7657 4.08137 18.9442C4.25041 19.1246 4.33421 19.3505 4.33279 19.6218C4.33421 19.8704 4.26248 20.0913 4.11759 20.2844C3.97413 20.4776 3.77597 20.6296 3.52313 20.7404C3.27029 20.8512 2.98052 20.9066 2.65381 20.9066Z"
        fill="#383838"
      />
    </svg>
  );
};

export default NumberedListIcon;
