import { APPLICATION_ROUTES } from "../../common/utils/routes";

export function getOAuthGmailUrl() {
  const GM_AUTH_URI = "https://accounts.google.com/o/oauth2/auth";
  const GM_CLIENT_ID =
    "236078151960-1q1mmed4s9pdmuhq4uu0hj7k03oms2ku.apps.googleusercontent.com";
  const GM_SCOPES = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ];

  const GM_REDIRECT_URI =
    process.env.REACT_APP_BASE_URL + APPLICATION_ROUTES.OAUTH_GMAIL;

  const params = {
    redirect_uri: GM_REDIRECT_URI,
    response_type: "code",
    client_id: GM_CLIENT_ID,
    scope: GM_SCOPES.join(" "),
  };

  const url = new URL(GM_AUTH_URI);
  url.search = new URLSearchParams(params).toString();

  return url;
}
