import React from "react";

const TextLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_3886_5913)">
        <path
          d="M15.3984 7H18.3984C19.055 7 19.7052 7.12933 20.3119 7.3806C20.9185 7.63188 21.4697 8.00017 21.934 8.46447C22.3983 8.92876 22.7666 9.47996 23.0178 10.0866C23.2691 10.6932 23.3984 11.3434 23.3984 12C23.3984 12.6566 23.2691 13.3068 23.0178 13.9134C22.7666 14.52 22.3983 15.0712 21.934 15.5355C21.4697 15.9998 20.9185 16.3681 20.3119 16.6194C19.7052 16.8707 19.055 17 18.3984 17H15.3984M9.39844 17H6.39844C5.74183 17 5.09165 16.8707 4.48502 16.6194C3.87839 16.3681 3.3272 15.9998 2.8629 15.5355C1.92522 14.5979 1.39844 13.3261 1.39844 12C1.39844 10.6739 1.92522 9.40215 2.8629 8.46447C3.80059 7.52678 5.07236 7 6.39844 7H9.39844"
          stroke="url(#paint0_linear_3886_5913)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.39844 12H16.3984"
          stroke="url(#paint1_linear_3886_5913)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3886_5913"
          x1="11.8667"
          y1="5.16176"
          x2="11.8667"
          y2="17.8824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3886_5913"
          x1="12.2051"
          y1="11.8162"
          x2="12.2051"
          y2="13.0882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_3886_5913">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.398438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextLinkIcon;
