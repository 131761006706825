import styled from "styled-components";

export const QuestionsFilterOption = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  height: 36px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 20px;
  user-select: none;

  ${({ isExtended }) =>
    !isExtended
      ? `
      &:hover {
         box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
         transition: 0.2s;
 }
  `
      : "z-index: 10000;"}
`;

export const QuestionsFilterOptionArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;

  ${({ isExtended }) =>
    isExtended &&
    `
  padding-bottom: 3px;
  transform: rotate(180deg);
  `}
`;

export const QuestionsFilterOptionsContainer = styled.div`
  position: absolute;
  top: 42px;
  left: 0px;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  z-index: 10000;
  cursor: default;
`;

export const QuestionsFilterOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;
  min-width: max-content;
  cursor: pointer;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;
