import React, { memo, useCallback } from "react";
import styled from "styled-components";
import CopyUserQuestion from "./copy_user_question";
import { compareObjects } from "../../../../../common/utils/functions/compare_objects";

const UserQuestionContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border-left: 2px solid;
  border-color: ${({ answered }) =>
    answered ? "#5172EA" : "rgba(241, 43, 43, 0.6)"};
  background: #fff;
  padding: 12px 11px;
  cursor: pointer;
  opacity: ${({ unselected }) => (unselected ? "0.5" : "1")};

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const UserQuestionText = styled.div`
  color: #1e1e1e;
  line-height: 20px;
  padding-right: 55px;
  margin-bottom: 10px;
`;

const UserQuestionInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #cacaca;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
`;

const UserQuestionDate = styled.div``;

const UserQuestionAuthor = styled.div``;

const HighlightedText = styled.span`
  background: #f8d25480;
`;

const UserQuestion = ({
  question,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  setOpenEditQuestionModal,
  questionSettingsData,
  questionSettingsMemoData,
  questionsFilterSettings,
}) => {
  const unselected =
    questionSettingsData.question_text &&
    questionSettingsData._id !== question._id;

  const questionCreatedAt = new Date(question.question_created_at);

  const questionFromAuthor = `${
    question.question_from_author?.user_name || ""
  } ${question.question_from_author?.user_second_name || ""}`;

  const formattedDate = `${questionCreatedAt.getDate()}.${
    questionCreatedAt.getMonth() + 1
  }.${questionCreatedAt.getFullYear()}`;

  const onClickQuestion = useCallback(
    (event) => {
      event.stopPropagation();

      if (compareObjects(questionSettingsData, questionSettingsMemoData)) {
        if (question._id === questionSettingsData._id) {
          setQuestionSettingsData({});
          setQuestionSettingsMemoData({});
          setOpenEditQuestionModal(false);
        } else {
          setQuestionSettingsData(question);
          setQuestionSettingsMemoData(question);
        }
      } else {
        setOpenEditQuestionModal(true);
      }
    },
    [question, questionSettingsData, questionSettingsMemoData]
  );

  const highlightWords = (text, targetWords) => {
    const words = text.split(" ");
    const targetWordsArray = targetWords
      .split(/\s+/)
      .map((word) => word.toLowerCase());

    return words.map((word, index) => {
      const matchesTarget = targetWordsArray.some((targetWord) =>
        word.toLowerCase().includes(targetWord)
      );

      return (
        <React.Fragment key={index}>
          {matchesTarget ? (
            <HighlightedText>{word}</HighlightedText>
          ) : (
            <span>{word}</span>
          )}
          {index < words.length - 1 && " "}
        </React.Fragment>
      );
    });
  };

  const searchWords = questionsFilterSettings.search_words.join(" ");

  const highlightedText =
    searchWords.length > 0
      ? highlightWords(question.question_text, searchWords)
      : question.question_text;

  return (
    <UserQuestionContainer
      answered={question.question_status === "answered"}
      unselected={unselected}
      onClick={onClickQuestion}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <UserQuestionText>{highlightedText}</UserQuestionText>

      <UserQuestionInfoContainer>
        <UserQuestionDate>{formattedDate}</UserQuestionDate>
        <UserQuestionAuthor>{questionFromAuthor}</UserQuestionAuthor>
      </UserQuestionInfoContainer>

      <CopyUserQuestion question={question} unselected={unselected} />
    </UserQuestionContainer>
  );
};

export default memo(UserQuestion);
