import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../app_context";

const BibleVersesContext = createContext();

export const useBibleVersesState = () => {
  const isSyncing = useRef(false);
  const [searchParams] = useSearchParams();
  const { bibleFontSettings } = useAppContext();

  const [verseHeightsFirstTransl, setVerseHeightsFirstTransl] = useState([]);
  const [verseHeightsSecondTransl, setVerseHeightsSecondTransl] = useState([]);
  const [mergedHeights, setMergedHeights] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [showStrongsCode, setShowStrongsCode] = useState(false);
  const [strongsCodeModalData, setStrongsCodeModalData] = useState(null);
  const [currentTestament, setCurrentTestament] = useState("");

  const bibleId = searchParams.get("bibleId");
  const bibleId2 = searchParams.get("2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");

  const getMaxArray = (array1, array2) => {
    return array1.map((value, index) =>
      Math.max(value, array2[index] ? array2[index] : 0)
    );
  };

  useEffect(() => {
    if (verseHeightsFirstTransl.length) {
      const maxHeights = getMaxArray(
        verseHeightsFirstTransl,
        verseHeightsSecondTransl
      );
      setMergedHeights(maxHeights);
    }
  }, [verseHeightsFirstTransl, verseHeightsSecondTransl]);

  useEffect(() => {
    setMergedHeights([]);
    setVerseHeightsFirstTransl([]);
    setVerseHeightsSecondTransl([]);
  }, [
    bibleId,
    bibleId2,
    bookId,
    chapterId,
    showStrongsCode,
    bibleFontSettings,
  ]);

  const handleScroll = useCallback(
    (scrollTopValue) => {
      if (!isSyncing.current) {
        isSyncing.current = true;
        setScrollTop(scrollTopValue);
      } else {
        isSyncing.current = false;
      }
    },
    [isSyncing.current]
  );

  return {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  };
};

export const BibleBookProvider = ({ children }) => {
  const {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  } = useBibleVersesState();

  return (
    <BibleVersesContext.Provider
      value={{
        scrollTop,
        handleScroll,
        setVerseHeightsFirstTransl,
        setVerseHeightsSecondTransl,
        mergedHeights,
        verseHeightsFirstTransl,
        verseHeightsSecondTransl,
        showStrongsCode,
        setShowStrongsCode,
        strongsCodeModalData,
        setStrongsCodeModalData,
        currentTestament,
        setCurrentTestament,
      }}
    >
      {children}
    </BibleVersesContext.Provider>
  );
};

export const useBibleBookContext = () => {
  return useContext(BibleVersesContext);
};
