export const APPLICATION_ROUTES = {
  HOME: "/home",
  STUDY: "/study",
  STUDY_GROUPS: "/study/groups",
  STUDY_COURSES: "/study/courses",
  BIBLE: "/bible",
  CHATS: "/chats",
  DONATE: "/donate",
  AUTH: "/auth",
  LOGIN: "/auth/login",
  REGISTRATION: "/auth/registration",
  OAUTH_GMAIL: "/oauth/gmail",
  ADMIN: "/admin",
  ADMIN_HOME: "/admin/home_page",
  ADMIN_GROUPS: "/admin/groups",
  ADMIN_QUESTIONS: "/admin/questions",
  ADMIN_COURSES: "/admin/courses",
  ADMIN_CREATE_LESSON: "/admin/create_lesson",
  ADMIN_LESSON_PREVIEW: "/admin/lesson_preview",
  ADMIN_COURSE_PREVIEW: "/admin/course_preview",
  ADMIN_USERS: "/admin/users",
};
