import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import PinnedIcon from "../../assets/icons/admin_page_icons/pinned_icon";
import { LESSON_COLORS_THEME } from "../../utils/enums";
import ArticleTitle from "./article_title";
import ArticleText from "./article_text";

const ArticleContainer = styled.div`
  width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "310px" : "540px"};
  display: inline-block;
  vertical-align: top;
  padding-bottom: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "15px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "15px" : "25px"};
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  cursor: ${({ inComponent }) => inComponent === "admin_page" && "pointer"};
  opacity: ${({ opacity }) => opacity};
`;

const ArticleHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 13px;
  padding: ${({ inComponent }) =>
    inComponent === "admin_page" ? "18px 17px" : "30px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "15px" : "25px"};
  width: 100%;
  background: ${({ background }) =>
    background || "linear-gradient(30.13deg, #05baa4 11.17%, #2046e9 92.55%)"};
`;

const ArticleImage = styled.div`
  flex: 1;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  min-width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "105px" : "186px"};
  max-width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "105px" : "186px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "12px" : "20px"};
`;

const ArticleShowStatus = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ isShow }) => (isShow ? "#F12B2B" : "#A1A1A1")};
`;

const ArticleIsPinned = styled.div`
  position: absolute;
  top: 7px;
  left: 23px;
`;

const Article = ({
  article,
  inComponent,
  onClick,
  editedArticleId,
  appColorTheme,
  setTextBibleModalData,
}) => {
  const [previewOpacity, setPreviewOpacity] = useState("1");

  const backgroundColor =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      article.article_background_color
    ];

  useEffect(() => {
    if (inComponent === "admin_page") {
      setPreviewOpacity(
        editedArticleId && article._id !== editedArticleId ? "0.5" : "1"
      );
    }
  }, [editedArticleId, article._id, inComponent]);

  const onClickArticle = (event, article) => {
    event.stopPropagation();
    if (inComponent === "admin_page") {
      onClick(event, article);
    }
  };

  return (
    <ArticleContainer
      inComponent={inComponent}
      opacity={previewOpacity}
      onClick={(e) => onClickArticle(e, article)}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ArticleHeader background={backgroundColor} inComponent={inComponent}>
        <ArticleTitle
          article={article}
          inComponent={inComponent}
          appColorTheme={appColorTheme}
          setTextBibleModalData={setTextBibleModalData}
        />

        {article.article_image_url && (
          <ArticleImage
            image={article.article_image_url}
            inComponent={inComponent}
          />
        )}

        {inComponent === "admin_page" && (
          <ArticleShowStatus isShow={article.article_is_visible} />
        )}

        {inComponent === "admin_page" && article.article_is_pinned && (
          <ArticleIsPinned>
            <PinnedIcon />
          </ArticleIsPinned>
        )}
      </ArticleHeader>

      <ArticleText
        article={article}
        inComponent={inComponent}
        appColorTheme={appColorTheme}
        setTextBibleModalData={setTextBibleModalData}
      />
    </ArticleContainer>
  );
};

export default memo(Article);
