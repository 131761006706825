import React, { memo, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../axios_config";
import RemoveIcon from "../assets/icons/remove_icon";
import { BASE_API_URL } from "../endpoints";

const ImageDropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DropzoneContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 70px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px dashed #5172ea;
  cursor: pointer;
  transition: 0.3s;
  background: ${({ dragging }) =>
    dragging ? "rgb(181 175 228)" : "rgb(203 199 234)"};

  &:hover {
    background: rgb(181 175 228);
    transition: 0.2s;
  }
`;

const DropzoneText = styled.div`
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 130%;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  width: max-content;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #5172ea;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  user-select: none;
`;

const RemoveIconContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 14px;
  background: #fff;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s;
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 9px;
  height: 9px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -17px;
  left: 0;
  color: red;
  font-size: 13px;
`;

const ImageDropzone = ({ imageUrl, handleImageSettings, isCurrentImage }) => {
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const fetchImage = (file) => {
    if (file) {
      const allowedFormats = ["image/jpeg", "image/png"];
      if (allowedFormats.includes(file.type)) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_name", "");
        formData.append("file_prefix", "");

        axios
          .post(BASE_API_URL + "/uploads/uploadfile", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            handleImageSettings(response.data.file_absolute_url);
            clearError();
          })
          .catch((error) => {
            setError("Ошибка при загрузке изображения");
            setTimeout(clearError, 3000);
            console.log(error);
          });
      } else {
        setError("Необходимый формат изображения: jpg, png");
        setTimeout(clearError, 3000);
      }
    }
  };

  const handleFileInputChange = useCallback(
    (event) => {
      event.preventDefault();
      if (!imageUrl) {
        const file = event.target.files[0];
        fetchImage(file);
      }
    },
    [handleImageSettings, imageUrl]
  );

  const handleClick = useCallback(() => {
    if (fileInputRef.current && !imageUrl) {
      fileInputRef.current.click();
    }
  }, [imageUrl, fileInputRef.current]);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      if (!imageUrl) {
        const file = event.dataTransfer.files[0];
        fetchImage(file);
        setDragging(false);
      }
    },
    [handleImageSettings, imageUrl]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const onRemoveImage = useCallback(() => {
    if (isCurrentImage) {
      handleImageSettings("");
    } else {
      const imageName = new URL(imageUrl).pathname.split("/").pop();
      axios
        .delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`)
        .then((response) => {
          handleImageSettings("");
          clearError();
        })
        .catch((error) => {
          console.error("Ошибка при удалении картинки:", error);
          setError("Ошибка при удалении картинки");
        });
    }
  }, [imageUrl, isCurrentImage]);

  return (
    <ImageDropzoneContainer onClick={(event) => event.stopPropagation()}>
      {!imageUrl && (
        <DropzoneContainer
          dragging={dragging}
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          enabled={!imageUrl}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept={"image/jpeg, image/png"}
            multiple={false}
            onChange={handleFileInputChange}
          />
          <DropzoneText>
            {dragging
              ? "Отпустите изображение тут"
              : "Кликните, чтобы загрузить изображение или перетащите"}
          </DropzoneText>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </DropzoneContainer>
      )}

      {imageUrl && (
        <ImagePreviewContainer>
          <ImagePreview src={imageUrl} alt="Uploaded" />

          <RemoveIconContainer onClick={onRemoveImage}>
            <RemoveIconStyled color={"#383838"} />
          </RemoveIconContainer>
        </ImagePreviewContainer>
      )}
    </ImageDropzoneContainer>
  );
};

export default memo(ImageDropzone);
