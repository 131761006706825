import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import BlackArrowDownIcon from "../../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";

const FAKE_COURSE = {
  course_name: "Курс “Так говорит Библия",
  course_lessons: [
    {
      status: "finished",
    },
    {
      status: "finished",
    },
    {
      status: "in_process",
    },
  ],
};

const CourseActivityContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const CourseActivityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 15px;
  height: 40px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background: #fff;
  cursor: pointer;
  user-select: none;
`;

const CourseActivityHeaderArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  transform: scale(1.12);

  ${({ isExtended }) =>
    isExtended &&
    `
    padding-bottom: 3px;
    transform: rotate(180deg) scale(1.12);
  `}
`;

const CourseActivityLesson = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  border-top: 1px solid #eaeaea;
  padding: 0 31px;
  background: ${({ status }) =>
    status === "finished"
      ? "linear-gradient(88deg, #05BAA4 -19%, #2046E9 129.9%)"
      : "#828282"};

  & > p {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`;

const CourseActivity = () => {
  const [extendCourseInfo, setExtendCourseInfo] = useState(true);

  const handleExtendCourseInfo = useCallback((event) => {
    event.stopPropagation();
    setExtendCourseInfo((prevState) => !prevState);
  }, []);

  return (
    <CourseActivityContainer>
      <CourseActivityHeader onClick={handleExtendCourseInfo}>
        {FAKE_COURSE.course_name}
        <CourseActivityHeaderArrow isExtended={extendCourseInfo}>
          <BlackArrowDownIcon />
        </CourseActivityHeaderArrow>
      </CourseActivityHeader>

      {extendCourseInfo &&
        FAKE_COURSE.course_lessons.map((lesson, index) => {
          const lessonStatus =
            lesson.status === "finished" ? "Пройден" : "В процессе";
          return (
            <CourseActivityLesson key={index + "lesson"} status={lesson.status}>
              <p>Урок {index + 1}</p>
              <p>{lessonStatus}</p>
            </CourseActivityLesson>
          );
        })}
    </CourseActivityContainer>
  );
};

export default memo(CourseActivity);
