import React, { memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import ImageDropzone from "../../../../../common/components/image_dropzone";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import { createComponentId } from "../..";

const ImageSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 26px 24px;
`;

const IMAGE_SETTINGS_DEFAULT_DATA = {
  type: "IMAGE",
  image_url: "",
  id: "",
};

const ImageSettings = ({
  settingsBlockData,
  settingsBlockMemoData,
  editBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const isCurrentImage =
    settingsBlockData.image_url === settingsBlockMemoData.image_url;

  useEffect(() => {
    if (editBlockIndex === null) {
      const defaultImageData = {
        ...IMAGE_SETTINGS_DEFAULT_DATA,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultImageData);
      setSettingsBlockMemoData(defaultImageData);
    }
  }, [editBlockIndex]);

  const handleImageSettings = useCallback((image) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      image_url: image,
    }));
  }, []);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <ImageSettingsContainer>
        <ImageDropzone
          imageUrl={settingsBlockData.image_url}
          isCurrentImage={isCurrentImage}
          handleImageSettings={handleImageSettings}
        />

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={false}
        />
      </ImageSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(ImageSettings);
