import React, { memo, useEffect, useState } from "react";
import { SettingsBlockFixedWrapper } from "../../../create_lesson_components/create_lesson_styled_components";
import styled from "styled-components";
import CreateLessonSaveButton from "../../../create_lesson_components/create_lesson_save_button";
import { createComponentId } from "../../..";
import DialogueSettingsBot from "./dialogue_settings_bot";
import DialogueSettingsBackgrounds from "./dialogue_settings_backgrounds";
import DialogueEditor from "./dialogue_editor";
import SettingsAnswerTypeIsButton from "./dialogue_editor/dialog_editor_answer_types/editor_answer_type_is_button";
import AnswerTypeIsButton from "../../../lesson_components/dialogue_lesson_component/dialogue_component_answer_types/answer_type_is_button";

const DialogueSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 219px;
  width: 434px;
  padding: 26px 24px;
  max-height: 83vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

export const DIALOGUE_ANSWERS_TYPE_DATA = {
  BUTTON: {
    type: "BUTTON",
    answerSettingsComponent: (props) => (
      <SettingsAnswerTypeIsButton {...props} />
    ),
    answerPreviewComponent: (props) => <AnswerTypeIsButton {...props} />,
    defaultAnswerData: {
      answer_text: "",
      selected: false,
    },
  },
};

const DEFAULT_SETTINGS_DATA_DIALOG = {
  type: "DIALOGUE",
  dialog_bot: {},
  background: "",
  student: {},
  data: {
    first: {
      text: {},
      level: 0,
      answers: { answers_type: "", answers_data: [] },
    },
  },
  selectedBranches: ["first"],
  id: "",
};

const DialogueSettings = ({
  lessonData,
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockMemoData,
  setSettingsBlockData,
  onClickSaveButton,
}) => {
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  useEffect(() => {
    if (editBlockIndex === null) {
      const lastColor = localStorage.getItem("lastColor");

      const defaultDialogueData = {
        ...DEFAULT_SETTINGS_DATA_DIALOG,
        background: lastColor
          ? lastColor
          : "linear-gradient(180deg, #5172EA 18.38%, #7A5AEE 108.82%)",
        id: createComponentId(),
      };

      setSettingsBlockData(defaultDialogueData);
      setSettingsBlockMemoData(defaultDialogueData);
    }
  }, [editBlockIndex]);

  useEffect(() => {
    if (
      !settingsBlockData.dialog_bot?._id ||
      !settingsBlockData.data.first.text.blocks?.[0]?.text
    ) {
      setDisabledSaveButton(true);
    } else {
      setDisabledSaveButton(false);
    }
  }, [settingsBlockData]);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <DialogueSettingsContainer>
        <DialogueSettingsBot
          lessonData={lessonData}
          settingsBlockData={settingsBlockData}
          setSettingsBlockData={setSettingsBlockData}
        />

        {!!settingsBlockData?.data?.first?.answers?.answers_data?.length && (
          <DialogueSettingsBackgrounds
            settingsBlockData={settingsBlockData}
            setSettingsBlockData={setSettingsBlockData}
          />
        )}

        <DialogueEditor
          settingsBlockData={settingsBlockData}
          setSettingsBlockData={setSettingsBlockData}
        />

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </DialogueSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(DialogueSettings);
