import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";
import VisibilityWrapperComponent from "../../../../common/components/lesson/visibility_wrapper_component";
import { LESSON_COMPONENTS_MAPPING } from "../../../../common/components/lesson/lesson_utils";

const LessonComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 80px 15px 45px;
  max-width: 620px;
  margin: 0 auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
`;

const LessonComponentsList = ({
  isLoading,
  filteredComponents,
  lessonData,
  withAnimation,
  courseMainGradient,
  courseMainColor,
  colorTheme,
  setLessonData,
  setTextBibleModalData,
}) => {
  const containerRef = useRef(null);
  const [textEditorComponentHtml, setTextEditorComponentHtml] = useState(null);

  const handleBibleVerseClick = useCallback((event) => {
    event.stopPropagation();

    const bibleId = event.target.dataset.bibleId;
    const bookId = event.target.dataset.bookId;
    const chapterId = event.target.dataset.chapterId;
    const selectedVerses = event.target.dataset.selectedVerses;

    setTextBibleModalData({ bibleId, bookId, chapterId, selectedVerses });
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleBibleVerseClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleBibleVerseClick);
      });
    };
  }, [textEditorComponentHtml]);

  return (
    <>
      {!isLoading ? (
        <LessonComponentList ref={containerRef}>
          {filteredComponents?.map((component) => {
            const CurrentPreviewComponent =
              LESSON_COMPONENTS_MAPPING?.[component?.type]?.component;

            return (
              <VisibilityWrapperComponent
                key={component.id}
                withAnimation={withAnimation}
              >
                <CurrentPreviewComponent
                  componentData={component}
                  withAnimation={withAnimation}
                  lessonIsFinished={lessonData.lesson_is_finished}
                  setLessonData={setLessonData}
                  setTextEditorComponentHtml={setTextEditorComponentHtml}
                  setTextBibleModalData={setTextBibleModalData}
                  inComponent="preview_page"
                  colorTheme={colorTheme}
                  courseMainColor={courseMainColor}
                  courseMainGradient={courseMainGradient}
                />
              </VisibilityWrapperComponent>
            );
          })}
        </LessonComponentList>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}
    </>
  );
};

export default memo(LessonComponentsList);
