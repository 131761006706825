import React, { memo } from "react";
import styled from "styled-components";
import AdminPagePersonPreview from "../../../../../pages/admin/admin_page_components/admin_page_person_preview";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 12px;
  background: #fff;
  border-radius: 8px;

  & > p {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.42px;

    & > span {
      color: #f12b2b;
    }
  }
`;

const ActionItem = styled.div`
  color: #828282;
  font-size: 10px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.1px;
`;

const ActionItemText = styled.div`
  padding: 4px 9px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(81, 114, 234, 0.2) -18.38%,
    rgba(122, 90, 238, 0.2) 108.82%
  );
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const UserInfoAction = ({ action, setSelectedUserInfo }) => {
  const {
    action_type,
    action_date,
    action_cause,
    action_author,
    warning_count,
  } = action;

  const dateObject = new Date(action_date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  const formattedDate = `${day < 10 ? "0" : ""}${day}.${
    month < 10 ? "0" : ""
  }${month}.${year}`;

  return (
    <ActionContainer>
      {action_type === "warning" && (
        <p>
          Предупреждение <span>{warning_count}/3</span>
        </p>
      )}
      {action_type === "unbinding" && <p>Открепление наставника</p>}

      <ActionItem>
        Дата
        <ActionItemText>{formattedDate}</ActionItemText>
      </ActionItem>
      <ActionItem>
        Причина
        <ActionItemText>{action_cause}</ActionItemText>
      </ActionItem>
      <ActionItem>
        Кто выставил
        <AdminPagePersonPreview
          personId={action_author._id}
          setSelectedUserInfo={setSelectedUserInfo}
          component={"user_info_modal"}
        />
      </ActionItem>
    </ActionContainer>
  );
};

export default memo(UserInfoAction);
