import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import {
  AddUserButton,
  AddUserButtonContainer,
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../admin_page_components/admin_page_styled_components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import PlusIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import AdminPagePersonPreview from "../../admin_page_components/admin_page_person_preview";

const CourseAuthorsContainer = styled.div`
  border-radius: 8px;
  background: #fff;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const CourseAuthorsHeader = styled(AdminPageSettingsHeader)`
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  padding: 0 12px;
`;

const CourseAuthorsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 12px 12px;
`;

const AddAuthorButtonContainer = styled(AddUserButtonContainer)`
  padding: 0;
`;

const CourseAuthors = ({
  authorsList,
  setCourseSettingsData,
  setOpenStaffListModal,
  setSelectedUserInfo,
}) => {
  const [extendedCourseAuthors, setExtendedCourseAuthors] = useState(false);

  const handleExtendUserIsCoursesAdmin = useCallback(() => {
    setExtendedCourseAuthors((prevState) => !prevState);
  }, []);

  const handleOpenStaffListModal = useCallback(() => {
    setOpenStaffListModal("authors");
  }, []);

  const onClickRemoveAuthorButton = useCallback((event, authorId) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_authors: prevState.course_authors.filter(
        (author) => author._id !== authorId
      ),
    }));
  }, []);

  return (
    <CourseAuthorsContainer>
      <CourseAuthorsHeader onClick={handleExtendUserIsCoursesAdmin} clickable>
        Автор
        <AdminPageSettingsHeaderArrow extendedSection={extendedCourseAuthors}>
          <AskQuestionArrowIcon />
        </AdminPageSettingsHeaderArrow>
      </CourseAuthorsHeader>

      {extendedCourseAuthors && (
        <CourseAuthorsList>
          {authorsList.map((author) => {
            return (
              <AdminPagePersonPreview
                key={author._id}
                personId={author._id}
                onRemoveButton={onClickRemoveAuthorButton}
                setSelectedUserInfo={setSelectedUserInfo}
              />
            );
          })}

          <AddAuthorButtonContainer>
            <AddUserButton onClick={handleOpenStaffListModal}>
              <PlusIcon />
            </AddUserButton>
          </AddAuthorButtonContainer>
        </CourseAuthorsList>
      )}
    </CourseAuthorsContainer>
  );
};

export default memo(CourseAuthors);
