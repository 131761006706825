import React, { memo } from "react";
import styled from "styled-components";

const CourseAnnouncementMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 508px;
  height: 160px;
  border: 2px solid #f8d254;
  border-top: none;
  border-radius: 0px 0px 25px 25px;
  background: #ffffff;
  box-shadow: 2px 4px 25px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 514px) {
    width: 100%;
    height: 159px;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #f8d254;
  }

  @media (max-width: 412px) {
    width: 100%;
    height: 142px;
    border-radius: 0;
    border-bottom: 2px solid #f8d254;
  }
`;

const AnnouncementMessageText = styled.div`
  width: 435px;
  padding: 17px 10px 0;
  color: #5172ea;

  & > h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 12px;

    @media (max-width: 412px) {
      font-size: 16px;
    }
  }

  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 125%;

    & > span {
      font-weight: 600;
    }

    @media (max-width: 412px) {
      font-size: 14px;
    }
  }
`;

const CourseAnnouncementMessage = () => {
  return (
    <CourseAnnouncementMessageContainer>
      <AnnouncementMessageText>
        <h5>Этот курс скоро будет опубликован</h5>
        <p>
          Если хотите не пропустить дату выхода - нажмите на кнопку
          <span> “Уведомить”</span>.
        </p>
        <p>
          Вам придет сообщение со ссылкой на курс и вы сразу же сможете начать
          прохождение.
        </p>
      </AnnouncementMessageText>
    </CourseAnnouncementMessageContainer>
  );
};

export default memo(CourseAnnouncementMessage);
