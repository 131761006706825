import React from "react";
import styled from "styled-components";

const ThreeDotsIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const ThreeDotsIcon = ({ className }) => {
  return (
    <ThreeDotsIconContainer className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
      >
        <circle cx="2" cy="2" r="1.5" fill="white" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
      >
        <circle cx="2" cy="2" r="1.5" fill="white" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill="none"
      >
        <circle cx="2" cy="2" r="1.5" fill="white" />
      </svg>
    </ThreeDotsIconContainer>
  );
};

export default ThreeDotsIcon;
