import React from "react";

const TextListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01953 4.2C7.01953 3.53726 7.55679 3 8.21953 3H21.2195C21.8823 3 22.4195 3.53726 22.4195 4.2C22.4195 4.86274 21.8823 5.4 21.2195 5.4H8.21953C7.55679 5.4 7.01953 4.86274 7.01953 4.2Z"
        fill="url(#paint0_linear_3886_5898)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01953 11.2001C7.01953 10.5374 7.55679 10.0001 8.21953 10.0001H21.2195C21.8823 10.0001 22.4195 10.5374 22.4195 11.2001C22.4195 11.8628 21.8823 12.4001 21.2195 12.4001H8.21953C7.55679 12.4001 7.01953 11.8628 7.01953 11.2001Z"
        fill="url(#paint1_linear_3886_5898)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01953 18.8C7.01953 18.1373 7.55679 17.6 8.21953 17.6H21.2195C21.8823 17.6 22.4195 18.1373 22.4195 18.8C22.4195 19.4627 21.8823 20 21.2195 20H8.21953C7.55679 20 7.01953 19.4627 7.01953 18.8Z"
        fill="url(#paint2_linear_3886_5898)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00781 4.2C2.00781 3.53726 2.54507 3 3.20781 3H3.21781C3.88055 3 4.41781 3.53726 4.41781 4.2C4.41781 4.86274 3.88055 5.4 3.21781 5.4H3.20781C2.54507 5.4 2.00781 4.86274 2.00781 4.2Z"
        fill="url(#paint3_linear_3886_5898)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00781 11.2001C2.00781 10.5374 2.54507 10.0001 3.20781 10.0001H3.21781C3.88055 10.0001 4.41781 10.5374 4.41781 11.2001C4.41781 11.8628 3.88055 12.4001 3.21781 12.4001H3.20781C2.54507 12.4001 2.00781 11.8628 2.00781 11.2001Z"
        fill="url(#paint4_linear_3886_5898)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00781 18.8C2.00781 18.1373 2.54507 17.6 3.20781 17.6H3.21781C3.88055 17.6 4.41781 18.1373 4.41781 18.8C4.41781 19.4627 3.88055 20 3.21781 20H3.20781C2.54507 20 2.00781 19.4627 2.00781 18.8Z"
        fill="url(#paint5_linear_3886_5898)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_5898"
          x1="11.7203"
          y1="-0.125"
          x2="11.7203"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3886_5898"
          x1="11.7203"
          y1="-0.125"
          x2="11.7203"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3886_5898"
          x1="11.7203"
          y1="-0.125"
          x2="11.7203"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3886_5898"
          x1="11.7203"
          y1="-0.125"
          x2="11.7203"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3886_5898"
          x1="11.7203"
          y1="-0.125"
          x2="11.7203"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3886_5898"
          x1="11.7203"
          y1="-0.125"
          x2="11.7203"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TextListIcon;
