import { LESSON_COLORS_THEME } from "../../utils/enums";

export const entityStyleFn = (entity) => {
  const entityType = entity.getType();
  if (entityType === "BIBLE_VERSE") {
    const data = entity.getData();
    const selectedVerseNum = data.versesData
      .map((verse) => verse.verse_num)
      .join();

    return {
      element: "input",
      attributes: {
        className: "bible-verse",
        type: "button",
        value: data.verseAddress,
        "data-bible-id": data.versesParams.bibleId,
        "data-book-id": data.versesParams.bookId,
        "data-chapter-id": data.versesParams.chapterId,
        "data-selected-verses": selectedVerseNum || "",
      },
      style: {
        border: "1px solid #2440D2",
        color: "#2440D2",
        fontSize: "16px",
        borderRadius: "4px",
        padding: "1px 7px",
        userSelect: "none",
        cursor: "pointer",
        backgroundColor: "transparent",
      },
    };
  }
  return null;
};

export const replacePlaceholderWithStudentName = (htmlContent, userName) => {
  return htmlContent.replace(/\*ИМЯ/g, `<span>${userName}</span>`);
};

export const replaceSpacesWithNbsp = (htmlContent) => {
  return htmlContent.replace(/(\S)\s+(<\/span>)/g, "$1&nbsp;$2");
};

export const getInlineStyles = (theme = "LIGHT", background) => {
  const { COLORS, GRADIENTS } = LESSON_COLORS_THEME[theme];

  const createBackgroundStyle = (colorName) => ({
    style: {
      background: background
        ? background === "WHITE"
          ? "#1e1e1e"
          : "#FFF"
        : GRADIENTS[colorName],
      ...(background &&
        (background === "WHITE"
          ? { color: "#FFF" }
          : { color: COLORS[background] })),
      display: "inline-block",
      borderRadius: "6px",
      padding: "0 2px",
    },
  });

  const createTextColorStyle = (color) => ({
    style: {
      color: background
        ? background === "WHITE"
          ? theme === "LIGHT"
            ? "#1e1e1e"
            : "FFF"
          : "#FFF"
        : color,
    },
  });

  const createTextStyle = (fontSize, lineHeight, letterSpacing = null) => ({
    style: {
      fontSize,
      lineHeight,
      ...(letterSpacing && { letterSpacing }),
    },
  });

  return {
    NOTE: createTextStyle("12px", "16px"),
    H1: createTextStyle("34px", "40px", "-0.4px"),
    H2: createTextStyle("22px", "26px"),
    BG_FUCHSIA: createBackgroundStyle("FUCHSIA"),
    BG_BLUE: createBackgroundStyle("BLUE"),
    BG_AQUA: createBackgroundStyle("AQUA"),
    BG_CYAN: createBackgroundStyle("CYAN"),
    BG_MAGENTA: createBackgroundStyle("MAGENTA"),
    BG_PURPLE: createBackgroundStyle("PURPLE"),
    BG_VIOLET: createBackgroundStyle("VIOLET"),
    BG_DEEP: createBackgroundStyle("DEEP"),
    BG_ORANGE: createBackgroundStyle("ORANGE"),
    BG_SKYLOR: createBackgroundStyle("SKYLOR"),
    BG_FUSORA: createBackgroundStyle("FUSORA"),
    BG_MINT: createBackgroundStyle("MINT"),
    BG_GREEN: createBackgroundStyle("GREEN"),
    BG_TEAL: createBackgroundStyle("TEAL"),
    BG_SPACE: createBackgroundStyle("SPACE"),
    BG_WHITE: createBackgroundStyle("WHITE"),
    BG_BLACK: createBackgroundStyle("BLACK"),
    TEXT_FUCHSIA: createTextColorStyle(COLORS.FUCHSIA),
    TEXT_BLUE: createTextColorStyle(COLORS.BLUE),
    TEXT_AQUA: createTextColorStyle(COLORS.AQUA),
    TEXT_CYAN: createTextColorStyle(COLORS.CYAN),
    TEXT_MAGENTA: createTextColorStyle(COLORS.MAGENTA),
    TEXT_PURPLE: createTextColorStyle(COLORS.PURPLE),
    TEXT_VIOLET: createTextColorStyle(COLORS.VIOLET),
    TEXT_DEEP: createTextColorStyle(COLORS.DEEP),
    TEXT_ORANGE: createTextColorStyle(COLORS.ORANGE),
    TEXT_SKYLOR: createTextColorStyle(COLORS.SKYLOR),
    TEXT_FUSORA: createTextColorStyle(COLORS.FUSORA),
    TEXT_MINT: createTextColorStyle(COLORS.MINT),
    TEXT_GREEN: createTextColorStyle(COLORS.GREEN),
    TEXT_TEAL: createTextColorStyle(COLORS.TEAL),
    TEXT_SPACE: createTextColorStyle(COLORS.SPACE),
    TEXT_WHITE: createTextColorStyle(COLORS.WHITE),
    TEXT_BLACK: createTextColorStyle(COLORS.BLACK),
  };
};
