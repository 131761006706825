import React from "react";

const ArrowIcon = ({ fill }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2811_4461)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24372 0.246657C9.58543 0.581392 9.58543 1.1241 9.24372 1.45884L4.61244 5.99561L9.24372 10.5324C9.58543 10.8671 9.58543 11.4098 9.24372 11.7446C8.90201 12.0793 8.34799 12.0793 8.00628 11.7446L2.75628 6.6017C2.41457 6.26696 2.41457 5.72425 2.75628 5.38951L8.00628 0.246657C8.34799 -0.0880784 8.90201 -0.0880784 9.24372 0.246657Z"
        fill={fill || "#383838"}
      />
    </g>
    <defs>
      <clipPath id="clip0_2811_4461">
        <rect
          width="12"
          height="12"
          fill={fill || "#383838"}
          transform="translate(12) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowIcon;
