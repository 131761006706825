import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 212px;
  border-radius: 8px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  user-select: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  cursor: pointer;
  opacity: 1;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    opacity: 1;
    transition: 0.2s;
  }
`;

const SendButton = styled(ButtonStyles)`
  ${({ isHoverOnDetail, showDetails }) =>
    (isHoverOnDetail || showDetails) && "opacity: 0.6;"}
`;

const ShowDetailsButton = styled(ButtonStyles)`
  opacity: ${({ showDetails }) => (showDetails ? 1 : 0.6)};

  &:hover {
    opacity: 1;
    transition: 0.2s;
  }
`;

const UserInfoModalWindowButtons = ({
  userHasAccess,
  showDetails,
  setShowDetails,
}) => {
  const [isHoverOnDetail, setIsHoverOnDetail] = useState(false);

  const toggleHoverOnSaveButton = useCallback(() => {
    setIsHoverOnDetail((prevState) => !prevState);
  }, []);

  const handleShowDetails = useCallback(() => {
    setShowDetails((prevState) => !prevState);
  }, []);

  return (
    <ButtonsContainer>
      <SendButton
        isHoverOnDetail={isHoverOnDetail}
        showDetails={showDetails}
        onClick={() => {}}
      >
        Написать
      </SendButton>

      {userHasAccess && (
        <ShowDetailsButton
          showDetails={showDetails}
          onMouseEnter={toggleHoverOnSaveButton}
          onMouseLeave={toggleHoverOnSaveButton}
          onClick={handleShowDetails}
        >
          {showDetails ? "Скрыть детальнее" : "Детальнее"}
        </ShowDetailsButton>
      )}
    </ButtonsContainer>
  );
};

export default memo(UserInfoModalWindowButtons);
