import React from "react";
import styled from "styled-components";
import { Slide, toast } from "react-toastify";

const DoneteCardImage = styled.img`
  margin-bottom: 21px;
  border-radius: 15px;
  cursor: pointer;

  @media (max-width: 670px) {
    width: 100%;
    margin-bottom: 27px;
  }
`;

const Card = ({ card }) => {
  const showNotification = () => {
    toast("Номер карты скопирован!", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
      alert("Копирование не поддерживается вашим браузером.");
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => showNotification(),
      (error) => {
        console.error("Ошибка копирования: ", error);
      }
    );
  };

  const handleCopy = () => {
    copyToClipboard(card.number);
  };

  return (
    <div onClick={handleCopy}>
      <DoneteCardImage src={card.image} alt={card.name} />
    </div>
  );
};

export default Card;
