import React from "react";

const CheckWhiteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7876 1.69184C12.0708 1.96846 12.0708 2.41695 11.7876 2.69358L4.53482 9.77691C4.25159 10.0535 3.79237 10.0535 3.50913 9.77691L0.212428 6.55721C-0.0708094 6.28059 -0.0708094 5.8321 0.212428 5.55548C0.495665 5.27886 0.954884 5.27886 1.23812 5.55548L4.02198 8.27431L10.7619 1.69184C11.0451 1.41522 11.5043 1.41522 11.7876 1.69184Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckWhiteIcon;
