import styled from "styled-components";

export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SettingsButtonsContainer = styled.div`
  position: absolute;
  top: 39px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 15px;
  background: #fff;
  border-radius: 14px;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const ToolbarButton = styled.button`
  width: 44px;
  padding: 10px;
  border: none;
  background: ${({ active }) =>
    active
      ? `
   linear-gradient(
      180deg,
      rgba(81, 114, 234, 0.3) -18.38%,
      rgba(122, 90, 238, 0.3) 108.82%
    );
   `
      : "#fff"};
  color: #1e1e1e;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    ${({ active }) =>
      !active &&
      `background: linear-gradient(
      180deg,
      rgba(81, 114, 234, 0.1) -18.38%,
      rgba(122, 90, 238, 0.1) 108.82%
    );`}
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const TextEditorViewComponent = styled.div`
  ul {
    margin: 18px 0 16px 20px;

    li {
      margin-bottom: 12px;
      margin-left: 5px;
      list-style-type: none;
      position: relative;

      &::before {
        content: "•";
        color: ${({ withBackground }) => (withBackground ? "#fff" : "#5172ea")};
        font-size: 1.3em;
        position: absolute;
        left: -18px;
        top: -1px;
      }
    }
  }

  ol {
    counter-reset: li;
    margin: 18px 0 16px 20px;

    li {
      counter-increment: li;
      margin-bottom: 12px;
      margin-left: 5px;
      list-style-type: none;
      position: relative;

      &::before {
        content: counter(li);
        position: absolute;
        left: -20px;
        top: -7px;
        font-family: "PT Serif";
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
        background: ${({ withBackground }) =>
          withBackground
            ? "#fff"
            : "linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%)"};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  a {
    color: ${({ withBackground }) =>
      withBackground ? "#fff" : "rgb(33 58 192)"};
    font-size: 16px;
    line-height: 24px;

    &: hover {
      transition: all 0.2s;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  u {
    text-underline-offset: 3px;
  }

  ${({ withBackground }) =>
    withBackground &&
    `
      input.bible-verse {
      color: #fff !important;
      border-color: #fff !important;
    }
  `}
`;
