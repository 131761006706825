import React from "react";

const ShareIcon = ({ className }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5778 0.256226C29.515 0.256226 30.2748 1.01598 30.2748 1.95318V12.1349C30.2748 13.0721 29.5151 13.8319 28.5778 13.8319C27.6406 13.8319 26.8809 13.0721 26.8809 12.1349L26.8809 3.65013H26.8793L26.8797 6.00405L14.1532 17.0351C13.445 17.6489 12.3733 17.5724 11.7595 16.8642C11.1456 16.1561 11.2221 15.0843 11.9303 14.4705L24.3359 3.65161L25.441 3.65013H18.3961C17.4589 3.65013 16.6992 2.89038 16.6992 1.95318C16.6992 1.01598 17.4589 0.256226 18.3961 0.256226H28.5778ZM6.51746 3.65027C5.11166 3.65027 3.97203 4.7899 3.97203 6.1957V24.0137C3.97203 24.4638 4.15082 24.8954 4.46906 25.2136C4.7873 25.5319 5.21893 25.7107 5.66899 25.7107H25.184C25.634 25.7107 26.0656 25.5319 26.3839 25.2136C26.7021 24.8954 26.8809 24.4638 26.8809 24.0137V18.2441C26.8809 17.3069 27.6407 16.5471 28.5779 16.5471C29.5151 16.5471 30.2748 17.3069 30.2748 18.2441V24.0137C30.2748 25.3639 29.7385 26.6588 28.7837 27.6135C27.829 28.5682 26.5341 29.1046 25.184 29.1046H5.66899C4.31881 29.1046 3.02393 28.5682 2.0692 27.6135C1.11448 26.6588 0.578125 25.3639 0.578125 24.0137V6.1957C0.578125 2.91549 3.23726 0.256361 6.51746 0.256361H11.778C12.7152 0.256361 13.475 1.01611 13.475 1.95331C13.475 2.89052 12.7152 3.65027 11.778 3.65027H6.51746Z"
        fill="white"
      />
    </svg>
  );
};

export default ShareIcon;
