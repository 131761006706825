import React from "react";

const UnselectedCheckboxLarge = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      className={className}
    >
      <rect
        x="0.5"
        y="0.5"
        width="21"
        height="21"
        rx="3.5"
        stroke="url(#paint0_linear_4324_5124)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4324_5124"
          x1="10.4683"
          y1="-4.04412"
          x2="10.4683"
          y2="23.9412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnselectedCheckboxLarge;
