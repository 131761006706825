import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import UserRolesCourseOrGroupItem from "../user_administration/course_or_group_preview";
import PlusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import {
  AddUserButton,
  AddUserButtonContainer,
} from "../../../admin_page_components/admin_page_styled_components";
import ConfirmationModalWindow from "../../admin_users_modal_windows/confirmation_modal_window";

const CoursesAuthorWrapper = styled.div`
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-top: 1px solid #cacaca;
`;

const CoursesAuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CoursesAuthorCoursesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  max-height: 320px;
  overflow: auto;
`;

const AddCourseButtonContainer = styled(AddUserButtonContainer)`
  padding: 0;
`;

const CoursesAuthor = ({
  userRolesSettingsData,
  coursesAuthorsList,
  setOpenAddingCourseModal,
  setUserRolesSettingsData,
}) => {
  const [selectedCourse, setSelectedCourse] = useState({});

  const onOpenAddingCourseModal = useCallback(() => {
    setOpenAddingCourseModal("courses_author");
  }, []);

  const onConfirmCourseDeletion = useCallback((courseData) => {
    setSelectedCourse(courseData);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setSelectedCourse({});
  }, []);

  const onRemoveCourse = useCallback(() => {
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_publicity: {
        ...prevState.user_publicity,
        courses: [
          ...prevState.user_publicity.courses.filter(
            (course) => course._id !== selectedCourse._id
          ),
        ],
      },
    }));
    setSelectedCourse({});
  }, [selectedCourse]);

  return (
    <CoursesAuthorWrapper>
      Курсы
      <CoursesAuthorContainer>
        {!!coursesAuthorsList.length && (
          <CoursesAuthorCoursesList>
            {coursesAuthorsList?.map((course) => {
              return (
                <UserRolesCourseOrGroupItem
                  key={course._id}
                  component={"course"}
                  item={course}
                  onConfirmItemDeletion={onConfirmCourseDeletion}
                />
              );
            })}
          </CoursesAuthorCoursesList>
        )}

        <AddCourseButtonContainer onClick={onOpenAddingCourseModal}>
          <AddUserButton>
            <PlusIcon />
          </AddUserButton>
        </AddCourseButtonContainer>

        {selectedCourse._id && (
          <ConfirmationModalWindow
            setOpen={closeConfirmationModal}
            userRolesSettingsData={userRolesSettingsData}
            action="removing"
            selectedObjectType={"course"}
            selectedObjectData={selectedCourse}
            onClickConfirmButton={onRemoveCourse}
          />
        )}
      </CoursesAuthorContainer>
    </CoursesAuthorWrapper>
  );
};

export default memo(CoursesAuthor);
