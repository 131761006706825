import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import TranslationsList from "./translations_list";
import TranlationPanelInput from "./translation_panel_input";

const LanguagesButton = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const TranslationsSelect = ({
  bibleTranslations,
  currentBibleLanguage,
  currentTranslation,
  setCurrentScreen,
  setCurrentTranslation,
  setBibleTranslations,
  isSecond,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedTranslations, setSelectedTranslations] = useState([]);

  const getFilteredTranslation = useCallback(
    (bibleTranslations, inputSearchValue) => {
      const sortedTranslationByFavorite = bibleTranslations?.sort(
        (tr1, tr2) => tr2.isFavorite - tr1.isFavorite
      );
      const filteredByLanguage = sortedTranslationByFavorite?.filter(
        (translation) =>
          translation.bible_locale === currentBibleLanguage?.code ||
          translation.isFavorite
      );

      const filteredBySearch = filteredByLanguage?.filter(
        (translation) =>
          translation.bible_code
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.bible_name
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.isFavorite
      );

      return filteredBySearch;
    },
    [currentBibleLanguage]
  );

  useEffect(() => {
    if (bibleTranslations) {
      setSelectedTranslations(
        getFilteredTranslation(bibleTranslations, inputSearchValue)
      );
    }
  }, [bibleTranslations, inputSearchValue]);

  const onTranlationClick = useCallback((translationData) => {
    setCurrentTranslation(translationData);
    setSearchParams((prev) => {
      prev.set(isSecond ? "2bibleId" : "bibleId", translationData.bible_code);
      prev.delete("selectVerses");
      prev.delete("selectBIble");
      return prev;
    });
    setInputSearchValue("");
  }, []);

  return (
    <div>
      <TranlationPanelInput
        placeholder={"Поиск перевода..."}
        button={
          <LanguagesButton>
            {currentBibleLanguage?.abbreviation?.toUpperCase()}
          </LanguagesButton>
        }
        onClickButton={() => setCurrentScreen("languages")}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
      />
      <TranslationsList
        selectedTranslations={selectedTranslations}
        currentTranslation={currentTranslation}
        setBibleTranslations={setBibleTranslations}
        onTranlationClick={onTranlationClick}
      />
    </div>
  );
};

export default memo(TranslationsSelect);
