import React from "react";

const LeftArrowIcon = ({ className, color = "white" }) => (
  <svg
    width="14"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    color="red"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4874 0.493802C14.1709 1.16327 14.1709 2.2487 13.4874 2.91817L4.22487 11.9917L13.4874 21.0652C14.1709 21.7347 14.1709 22.8201 13.4874 23.4896C12.804 24.1591 11.696 24.1591 11.0126 23.4896L0.512563 13.2039C-0.170855 12.5344 -0.170855 11.449 0.512563 10.7795L11.0126 0.493802C11.696 -0.175668 12.804 -0.175668 13.4874 0.493802Z"
      fill={color}
    />
  </svg>
);

export default LeftArrowIcon;
