import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";

const CourseInfoAuthorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 6px;
`;

const AuthorPhotoStyles = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;

  @media (max-width: 880px) {
    width: 35px;
    height: 35px;
  }
`;

const AuthorName = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.48px;
  width: max-content;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 880px) {
    font-size: 12px;
  }

  @media (max-width: 800px) {
    line-height: 135%;
  }

  @media (max-width: 744px) {
    font-size: 10px;
    letter-spacing: 0.3px;
  }

  @media (max-width: 412px) {
    font-size: 10px;
  }
`;

const AuthorStatus = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.12px;
  width: max-content;
  max-width: 100%;

  @media (max-width: 880px) {
    font-size: 11px;
    line-height: 140%;
    letter-spacing: -0.1px;
  }

  @media (max-width: 744px) {
    font-size: 10px;
  }

  @media (max-width: 412px) {
    font-size: 10px;
  }
`;

const CourseInfoAuthor = ({ authorId }) => {
  const [authorInfo, setAuthorInfo] = useState({});

  const courseAuthorName = authorInfo.user_name
    ? `${authorInfo.user_name} ${authorInfo.user_second_name}`
    : "";

  const fetchAuthorInfoData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + "/users/" + authorId);
      setAuthorInfo(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных автора курса:", error);
    }
  }, [authorId]);

  useEffect(() => {
    if (authorId) {
      fetchAuthorInfoData();
    }
  }, [authorId]);

  return (
    <CourseInfoAuthorContainer>
      {authorInfo.user_image && (
        <AuthorPhotoStyles src={authorInfo.user_image} alt="Author photo" />
      )}
      <div>
        <AuthorName>{courseAuthorName}</AuthorName>
        <AuthorStatus>
          {authorInfo.user_publicity?.author_info || ""}
        </AuthorStatus>
      </div>
    </CourseInfoAuthorContainer>
  );
};

export default memo(CourseInfoAuthor);
