import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";

export const useValueWithDebounce = ({
  defaultValue = "",
  onChange = () => {},
}) => {
  const [inputValue, setChatInputValue] = useState(defaultValue);
  const onInputChange = useCallback(
    (e) => {
      onChange();
      setChatInputValue(e.target.value);
    },
    [setChatInputValue, onChange]
  );
  const [debounceValue] = useDebounce(inputValue, 1000);
  return [inputValue, onInputChange, debounceValue];
};
