import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import DialogueComponentBranch from "./dialogue_component_branch";
import { BASE_API_URL } from "../../../../endpoints";
import { onBibleDialogueBotData } from "../../lesson_utils";

const DialogueLessonComponentContainer = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DialogueLessonComponent = ({
  componentData,
  inComponent,
  withAnimation,
  colorTheme = "LIGHT",
  courseMainColor,
  courseMainGradient,
  setLessonData = () => {},
  setTextEditorComponentHtml = () => {},
  setUserAnswersData = () => {},
}) => {
  const [dialogueBotData, setDialogueBotData] = useState({});

  const fetchUserInfo = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users/${userId}`);
      setDialogueBotData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (componentData.dialog_bot?._id !== dialogueBotData?._id) {
      if (componentData.dialog_bot._id === "on_bible") {
        setDialogueBotData(onBibleDialogueBotData);
      } else {
        fetchUserInfo(componentData.dialog_bot._id);
      }
    }
  }, [componentData, dialogueBotData]);

  return (
    <DialogueLessonComponentContainer>
      {componentData?.selectedBranches?.map((branch, index) => {
        return (
          componentData.data?.[branch] && (
            <DialogueComponentBranch
              key={branch}
              branch={branch}
              componentData={componentData}
              branchIndex={index}
              dialogueBotData={dialogueBotData}
              inComponent={inComponent}
              withAnimation={withAnimation}
              colorTheme={colorTheme}
              courseMainColor={courseMainColor}
              courseMainGradient={courseMainGradient}
              setLessonData={setLessonData}
              setUserAnswersData={setUserAnswersData}
              setTextEditorComponentHtml={setTextEditorComponentHtml}
            />
          )
        );
      })}
    </DialogueLessonComponentContainer>
  );
};

export default memo(DialogueLessonComponent);
