import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { BASE_API_URL } from "../../../../common/endpoints";
import BibleStrongModalHeader from "./bible_strong_modal_header";
import BibleStrongModalMainInfo from "./bible_strong_modal_main_info";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";
import BibleStrongConcordance from "./bible_strong_concordance";

const BibleStrongModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const BibleStrongModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 500px;
  height: calc(100vh - 80px);
  max-height: 700px;
  padding: 26px 21px 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
`;

const BibleStrongFullInfo = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  color: #6b6b6b;
  font-size: 16px;
  line-height: 140%;
  overflow-wrap: break-word;
  padding: 0 4px 30px 0;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

const transformHtmlContent = (htmlContent) => {
  const codeStrongStyles = `
        color: #5172ea; 
        font-size: 14px; 
        line-height: 120%; 
        cursor: pointer;
     `;

  const numbersStyles = `
        font-family: PT Serif; 
        font-size: 16px; 
        font-weight: 600; 
        line-height: normal; 
        color: #2250f2;
     `;

  const replacedLinks = htmlContent.replace(
    /<a\s+href=['"]S:(H\d+|G\d+)['"][^>]*>([^<]*)<\/a>/g,
    (match, code) => {
      return `<span class="code-strong" style="${codeStrongStyles}">${code}</span>`;
    }
  );

  const replacedNumbers = replacedLinks.replace(
    /(<br\s*\/?>\s*)(\d+)\)/g,
    (match, br, number) =>
      `<br/><br/><span style="${numbersStyles}">${number}</span>`
  );

  return replacedNumbers;
};

const BibleStrongModalWindow = ({
  strongsCodeModalData,
  currentTranslation,
  currentTestament,
  setOpen,
  setShowStrongsCode,
}) => {
  const fullInfoContainerRef = useRef(null);

  const [currentCodeIndex, setCurrentCodeIndex] = useState("");
  const [codesStrongHistory, setCodesStrongHistory] = useState([]);
  const [currentScreen, setCurrentScreen] = useState("main"); // main, concordance, chapter
  const [isLoading, setIsLoading] = useState(false);
  const [strongsData, setStrongsData] = useState({});
  const [concordanceRefs, setConcordanceRefs] = useState([]);
  const [concordanceSelectedVerse, setConcordanceSelectedVerse] = useState({});

  const currentCodeStrong = codesStrongHistory[currentCodeIndex];

  const strongsDataFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_API_URL}/strong/lookup`, {
        strongCode: currentCodeStrong,
        strongLocale: currentTranslation.bible_locale,
      });

      setStrongsData(response.data.definition);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [currentCodeStrong, currentTranslation, currentTestament]);

  useEffect(() => {
    if (currentCodeStrong && currentTranslation.bible_locale) {
      strongsDataFetch();
    }
  }, [currentCodeStrong, currentTranslation]);

  const strongsCodeRefsFetch = useCallback(async () => {
    try {
      const response = await axios(
        `${BASE_API_URL}/strongs-symphony/${currentCodeStrong}`
      );

      const refs = response.data.strong_refs.split(", ");
      const uniqueRefs = [...new Set(refs)];

      setConcordanceRefs(uniqueRefs);
    } catch (error) {
      console.error(error);
    }
  }, [currentCodeStrong]);

  useEffect(() => {
    if (currentCodeStrong) {
      strongsCodeRefsFetch();
    }
  }, [currentCodeStrong]);

  useEffect(() => {
    if (strongsCodeModalData && currentTranslation.bible_locale) {
      const codeStrong =
        (currentTestament === "ot" ? "H" : "G") + strongsCodeModalData;
      setCurrentCodeIndex(0);
      setCodesStrongHistory([codeStrong]);
    }
  }, [strongsCodeModalData, currentTestament]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(null);
  }, []);

  useEffect(() => {
    const handleCodeStrongClick = (event) => {
      const target = event.target;
      if (target && target.classList.contains("code-strong")) {
        const code = target.textContent;

        setCodesStrongHistory((prevState) => {
          setCurrentCodeIndex(prevState.length);
          return [...prevState, code];
        });
      }
    };

    const updateEventListeners = () => {
      const codeStrongElements =
        fullInfoContainerRef.current?.querySelectorAll(".code-strong");

      codeStrongElements?.forEach((element) => {
        element.addEventListener("click", handleCodeStrongClick);
      });

      return () => {
        codeStrongElements?.forEach((element) => {
          element.removeEventListener("click", handleCodeStrongClick);
        });
      };
    };

    const cleanup = updateEventListeners();

    return () => {
      cleanup();
    };
  }, [fullInfoContainerRef.current, isLoading]);

  return (
    <BibleStrongModalWindowWrapper onClick={handleClose}>
      <BibleStrongModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <BibleStrongModalHeader
          currentCodeStrong={currentCodeStrong}
          codesStrongHistory={codesStrongHistory}
          currentScreen={currentScreen}
          setCurrentScreen={setCurrentScreen}
          currentCodeIndex={currentCodeIndex}
          setCurrentCodeIndex={setCurrentCodeIndex}
          concordanceRefs={concordanceRefs}
          concordanceSelectedVerse={concordanceSelectedVerse}
        />
        {currentScreen === "main" ? (
          <>
            {!isLoading ? (
              <>
                <BibleStrongModalMainInfo strongsData={strongsData} />
                <BibleStrongFullInfo
                  ref={fullInfoContainerRef}
                  dangerouslySetInnerHTML={{
                    __html: transformHtmlContent(strongsData?.strong_def || ""),
                  }}
                />
              </>
            ) : (
              <LoaderContainer>
                <SpinnerLoader />
              </LoaderContainer>
            )}
          </>
        ) : (
          <BibleStrongConcordance
            currentTranslation={currentTranslation}
            currentCodeStrong={currentCodeStrong}
            concordanceRefs={concordanceRefs}
            currentScreen={currentScreen}
            setConcordanceRefs={setConcordanceRefs}
            concordanceSelectedVerse={concordanceSelectedVerse}
            setConcordanceSelectedVerse={setConcordanceSelectedVerse}
            setCurrentScreen={setCurrentScreen}
            setOpen={setOpen}
            setShowStrongsCode={setShowStrongsCode}
          />
        )}
      </BibleStrongModalWindowContainer>
    </BibleStrongModalWindowWrapper>
  );
};

export default memo(BibleStrongModalWindow);
