import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AdminPageInfoIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_info_icon";

const UserPenaltyContainer = styled.div`
  color: #383838;
  font-size: 10px;
  line-height: 12px;
`;

const UserPenaltyChatsBan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 28px;
`;

const BanEndInDays = styled.div`
  & > span {
    color: #828282;
  }
`;

const UserPenaltyChatsBanInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ChatsBanDate = styled.div`
  & > span {
    color: #f12b2b;
  }
`;

const PenaltyInfo = styled.div`
  &:hover {
    transition: 0.2s;
    transform: scale(1.12);
    cursor: pointer;
  }
`;

const UserPenalty = ({ openUserPenaltyInfo, userPenaltyInfo }) => {
  const [remainingTime, setRemainingTime] = useState({ days: 0, hours: 0 });

  const { disabled_start_date, disabled_days } = userPenaltyInfo;

  const remainingDaysName =
    remainingTime.days === 1
      ? "день"
      : remainingTime.days > 1 && remainingTime.days < 5
      ? "дня"
      : remainingTime.days > 100
      ? "Заблокирован навсегда"
      : "дней";

  const startDateObject = new Date(disabled_start_date);

  const day = startDateObject.getDate();
  const month = startDateObject.getMonth() + 1;
  const year = startDateObject.getFullYear();

  const formattedDate = `${day < 10 ? "0" : ""}${day}.${
    month < 10 ? "0" : ""
  }${month}.${year}`;

  useEffect(() => {
    const endDate = startDateObject;
    endDate.setDate(endDate.getDate() + disabled_days);
    endDate.setHours(23, 59, 59, 999);

    const updateRemainingTime = () => {
      const now = new Date();
      const timeDiff = endDate - now;

      let remainingDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      let remainingHours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      if (remainingDays > 0 && remainingHours < 24) {
        remainingDays -= 1;
      }

      setRemainingTime({ days: remainingDays, hours: remainingHours });
    };

    const interval = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <UserPenaltyContainer>
      <UserPenaltyChatsBan>
        <UserPenaltyChatsBanInfo>
          <ChatsBanDate>
            <span>Опция ограничена:</span> {formattedDate}
          </ChatsBanDate>
          <BanEndInDays>
            <span>Разблокировка через:</span>{" "}
            {remainingTime.days > 100
              ? remainingDaysName
              : `${remainingTime.days} ${remainingDaysName} ${remainingTime.hours} часов`}
          </BanEndInDays>
        </UserPenaltyChatsBanInfo>
        <PenaltyInfo onClick={openUserPenaltyInfo}>
          <AdminPageInfoIcon />
        </PenaltyInfo>
      </UserPenaltyChatsBan>
    </UserPenaltyContainer>
  );
};

export default UserPenalty;
