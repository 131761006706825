import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../assets/icons/vertical_line";

const DeleteModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const DeleteModalWindowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 308px;
  height: 61.973px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  border-radius: 16px;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
    width: 53%;
  }
`;

const DeleteButton = styled(ButtonStyles)`
  color: rgba(255, 255, 255, 0.5);
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnDelete }) =>
    isHoverOnDelete ? "rgba(255, 255, 255, 0.5)" : "#fff"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const DeleteModalWindow = ({ isOpen, onClose, onClickRemoveButton }) => {
  const [isHoverOnDelete, setIsHoverOnDelete] = useState(false);

  const toggleHoverOnDeleteButton = useCallback(() => {
    setIsHoverOnDelete((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    onClose(false);
  }, []);

  return (
    <DeleteModalWindowWrapper
      isOpen={isOpen}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <DeleteModalWindowContainer onMouseDown={(e) => e.stopPropagation()}>
        <DeleteButton
          onMouseEnter={toggleHoverOnDeleteButton}
          onMouseLeave={toggleHoverOnDeleteButton}
          onClick={onClickRemoveButton}
        >
          Удалить
        </DeleteButton>
        <VerticalLineStyled color={"#FFF"} />
        <CancelButton isHoverOnDelete={isHoverOnDelete}>Отмена</CancelButton>
      </DeleteModalWindowContainer>
    </DeleteModalWindowWrapper>
  );
};

export default memo(DeleteModalWindow);
