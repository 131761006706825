import React, { memo } from "react";
import styled from "styled-components";
import ShareIcon from "../../../../common/assets/icons/share_icon";
import { Link } from "react-router-dom";

const GroupInfoContainer = styled.div`
  color: #ffffff;
`;

const GroupInfoTitleAndIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const GroupInfoTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  width: 250px;

  @media (max-width: 1024px) {
    width: 200px;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
  }

  @media (max-width: 880px) {
    width: 170px;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
  }

  @media (max-width: 800px) {
    width: 160px;
    font-size: 16px;
    line-height: 150%;
  }

  @media (max-width: 744px) {
    width: 155px;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.42px;
  }

  @media (max-width: 670px) {
    width: 180px;
    font-size: 19px;
    font-weight: 600;
    line-height: 130%;
  }

  @media (max-width: 412px) {
    width: 170px;
  }
`;

const ShareIconStyles = styled(ShareIcon)`
  margin: 7px 25px 0 0;
  width: 30px;
  height: 29px;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    width: 27px;
    height: 26px;
  }

  @media (max-width: 880px) {
    width: 25px;
    height: 23px;
    margin: 3px 21px 0 0;
  }

  @media (max-width: 800px) {
    width: 23px;
    height: 21px;
    margin: 2px 16px 0 0;
  }

  @media (max-width: 744px) {
    width: 21px;
    height: 19px;
    margin-right: 12px;
  }

  @media (max-width: 670px) {
    width: 23px;
    height: 21px;
    margin-right: 16px;
  }
`;

const GroupInfoText = styled.p`
  margin: 10px 0 16px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 255px;

  @media (max-width: 1024px) {
    margin: 8px 0 12px 0;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: -0.1px;
    width: 185px;
  }

  @media (max-width: 880px) {
    margin: 8px 0 10px 0;
    width: 165px;
  }

  @media (max-width: 800px) {
    margin: 6px 0 6px 0;
    width: 155px;
  }

  @media (max-width: 744px) {
    margin: 5px 0 7px 0;
    width: 155px;
  }

  @media (max-width: 670px) {
    margin: 8px 0 7px 0;
    width: 175px;
  }

  @media (max-width: 412px) {
    margin: 6px 0 5px 0;
    width: 165px;
  }
`;

const GroupInfoDate = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: 880px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
  }

  @media (max-width: 744px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 412px) {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.42px;
  }
`;

const GroupInfo = () => {
  return (
    <GroupInfoContainer>
      <GroupInfoTitleAndIconContainer>
        <GroupInfoTitle>Суббота или воскресенье?</GroupInfoTitle>
        <ShareIconStyles />
      </GroupInfoTitleAndIconContainer>
      <GroupInfoText>
        Lorem ipsum dolor sit amet <br /> Amet cursus massa mi sapien
      </GroupInfoText>
      <GroupInfoDate>10.11. - 20.11.2023</GroupInfoDate>
    </GroupInfoContainer>
  );
};

export default memo(GroupInfo);
