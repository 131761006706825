import React from "react";
import styled from "styled-components";
import PlusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import LeaderUserGroup from "./leader_user_group";
import {
  AddUserButton,
  AddUserButtonContainer,
} from "../../../../admin_page_components/admin_page_styled_components";

const LeaderUserGroupsContainer = styled.div`
  border-top: 1px solid #cacaca;
  padding-top: 10px;

  & > h5 {
    color: #828282;
    font-size: 10px;
    line-height: 12px;
    padding: 0 10px;
  }
`;

const LeaderUserGroupsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 9px;
  padding: 0 10px;
  max-height: 320px;
  overflow: auto;
`;

const LeaderUserGroups = ({ groups }) => {
  return (
    <LeaderUserGroupsContainer>
      <h5>Группы: {groups.length}</h5>
      <LeaderUserGroupsList>
        {groups.map((student) => {
          return <LeaderUserGroup key={student} />;
        })}
      </LeaderUserGroupsList>

      <AddUserButtonContainer>
        <AddUserButton>
          <PlusIcon />
        </AddUserButton>
      </AddUserButtonContainer>
    </LeaderUserGroupsContainer>
  );
};

export default LeaderUserGroups;
