import React from "react";

const PictureIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
        stroke="url(#paint0_linear_3886_5726)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
        stroke="url(#paint1_linear_3886_5726)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 15L16 10L5 21"
        stroke="url(#paint2_linear_3886_5726)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_5726"
          x1="7.34156"
          y1="20.3432"
          x2="17.3934"
          y2="3.02561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3886_5726"
          x1="7.72359"
          y1="9.89053"
          x2="9.3989"
          y2="7.00427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3886_5726"
          x1="8.85916"
          y1="20.5986"
          x2="13.7296"
          y2="8.39368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PictureIcon;
