import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import WebFont from "webfontloader";
import styled from "styled-components";
import "./App.css";
import { APPLICATION_ROUTES } from "./common/utils/routes";
import { useInitApp } from "./hooks/use_init_app";
import { useAppContext } from "./app_context";
import { useUserDataContext } from "./user_data_context";
import Header from "./common/components/header";
import HomePage from "./pages/home/home_page";
import StudyPage from "./pages/study/study_page";
import BiblePage from "./pages/bible/bible_page";
import ChatsPage from "./pages/chats/chats_page";
import AuthPage from "./pages/auth/auth_page";
import GroupPage from "./pages/study/group_page";
import CoursePage from "./pages/study/course_page";
import DonatePage from "./pages/donate/donate_page";
import RegistrationPage from "./pages/auth/registration_page";
import LoginPage from "./pages/auth/login_page";
import OauthGmailPage from "./pages/auth/oauth_gmail";
import AdminPage from "./pages/admin/admin_page";
import AdminPageHome from "./pages/admin/admin_page_home";
import AdminPageQuestions from "./pages/admin/admin_page_questions";
import AdminPageCourses from "./pages/admin/admin_page_courses";
import AdminPageCreateLesson from "./pages/admin/admin_page_create_lesson";
import AdminPageUsers from "./pages/admin/admin_page_users";
import CoursePreviewPage from "./pages/admin/admin_page_courses/course_preview_page";
import StudyPageCourses from "./pages/study/study_page_courses";
import StudyPageGroups from "./pages/study/study_page_groups";
import LessonPreviewPage from "./pages/admin/admin_page_create_lesson/lesson_preview_page";

const MainPageContainer = styled.div`
  min-height: 100vh;
  background: #eaeaea;
`;

const MainPageContentContainer = styled.div`
  padding: 80px 0 0 0;

  @media (max-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 940px) {
    padding-bottom: 80px;
  }

  @media (max-width: 500px) {
    padding-bottom: ${({ showAppHeader }) => (showAppHeader ? "80px" : "0px")};
  }
`;

function App() {
  const { showAppHeader } = useAppContext();
  const { fetchUserData } = useUserDataContext();

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Inter",
          "Raleway",
          "PT Serif",
          "Ysabeau Infant",
          "Caveat",
          "Roboto",
        ],
      },
    });
  }, []);

  useInitApp();

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <MainPageContainer>
      <Router>
        <Header />
        <MainPageContentContainer showAppHeader={showAppHeader}>
          <Routes>
            <Route
              path="/"
              element={<Navigate replace to={APPLICATION_ROUTES.HOME} />}
            />
            <Route path={APPLICATION_ROUTES.HOME} element={<HomePage />} />
            <Route path={APPLICATION_ROUTES.STUDY} element={<StudyPage />}>
              <Route
                path={APPLICATION_ROUTES.STUDY_GROUPS}
                element={<StudyPageGroups />}
              />
              <Route
                path={APPLICATION_ROUTES.STUDY_COURSES}
                element={<StudyPageCourses />}
              />
            </Route>
            <Route
              path={APPLICATION_ROUTES.STUDY_GROUPS + "/:id"}
              element={<GroupPage />}
            />
            <Route
              path={APPLICATION_ROUTES.STUDY_COURSES + "/:id"}
              element={<CoursePage />}
            />
            <Route path={APPLICATION_ROUTES.BIBLE} element={<BiblePage />} />
            <Route path={APPLICATION_ROUTES.CHATS} element={<ChatsPage />} />
            <Route path={APPLICATION_ROUTES.DONATE} element={<DonatePage />} />

            <Route path={APPLICATION_ROUTES.AUTH} element={<AuthPage />}>
              <Route path={APPLICATION_ROUTES.LOGIN} element={<LoginPage />} />
              <Route
                path={APPLICATION_ROUTES.REGISTRATION}
                element={<RegistrationPage />}
              />
            </Route>
            <Route
              path={APPLICATION_ROUTES.OAUTH_GMAIL}
              element={<OauthGmailPage />}
            />
            <Route path={APPLICATION_ROUTES.ADMIN} element={<AdminPage />}>
              <Route
                path={APPLICATION_ROUTES.ADMIN_HOME}
                element={<AdminPageHome />}
              />
              <Route
                path={APPLICATION_ROUTES.ADMIN_COURSES}
                element={<AdminPageCourses />}
              />
              <Route
                path={APPLICATION_ROUTES.ADMIN_GROUPS}
                element={<div>ADMIN PAGE - GROUPS</div>}
              />
              <Route
                path={APPLICATION_ROUTES.ADMIN_QUESTIONS}
                element={<AdminPageQuestions />}
              />
              <Route
                path={APPLICATION_ROUTES.ADMIN_USERS}
                element={<AdminPageUsers />}
              />
            </Route>
            <Route
              path={APPLICATION_ROUTES.ADMIN_CREATE_LESSON + "/:id"}
              element={<AdminPageCreateLesson />}
            />
            <Route
              path={APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW + "/:id"}
              element={<LessonPreviewPage />}
            />
            <Route
              path={APPLICATION_ROUTES.ADMIN_COURSE_PREVIEW + "/:id"}
              element={<CoursePreviewPage />}
            />
          </Routes>
        </MainPageContentContainer>
      </Router>
    </MainPageContainer>
  );
}

export default App;
