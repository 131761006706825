import React from "react";

const NoteIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0.825582C0.5 0.503416 0.746243 0.242249 1.05 0.242249H10.95C11.2538 0.242249 11.5 0.503416 11.5 0.825582V7.82558C11.5 7.98029 11.4421 8.12866 11.3389 8.23806L9.13891 10.5714C9.03576 10.6808 8.89587 10.7422 8.75 10.7422H1.05C0.746243 10.7422 0.5 10.4811 0.5 10.1589V0.825582ZM2.1 1.40892C1.82386 1.40892 1.6 1.63277 1.6 1.90891V9.07558C1.6 9.35172 1.82386 9.57558 2.1 9.57558H8.30641C8.44412 9.57558 8.57573 9.51878 8.67021 9.41859L10.2638 7.72842C10.3513 7.63563 10.4 7.51293 10.4 7.38541V1.90892C10.4 1.63277 10.1761 1.40892 9.9 1.40892H2.1ZM4.35 4.03392C4.35 3.71175 4.59624 3.45058 4.9 3.45058H7.375C7.67876 3.45058 7.925 3.71175 7.925 4.03392C7.925 4.35608 7.67876 4.61725 7.375 4.61725H4.9C4.59624 4.61725 4.35 4.35608 4.35 4.03392ZM4.35 6.07558C4.35 5.75342 4.59624 5.49225 4.9 5.49225H7.375C7.67876 5.49225 7.925 5.75342 7.925 6.07558C7.925 6.39775 7.67876 6.65892 7.375 6.65892H4.9C4.59624 6.65892 4.35 6.39775 4.35 6.07558Z"
        fill="white"
      />
    </svg>
  );
};

export default NoteIcon;
