import React, { memo, useState } from "react";
import styled from "styled-components";
import QuestionSettingsTags from "./question_settings_tags";
import QuestionSettingsStatus from "./question_settings_status";
import QuestionSettingsBibleVerses from "./question_settings_bible_verses";
import BibleModalWindow from "../../../../common/components/modal_windows/bible_modal_window";
import QuestionSettingsButtons from "./question_settings_buttons";
import QuestionSettingsContacts from "./question_settings_contacts";

const AdminPageQuestionSettingsWrapper = styled.div`
  position: relative;
  width: 430px;
`;

const AdminPageQuestionSettingsContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  padding: 26px 24px;
  width: 430px;
  border-left: 1px solid #828282;
  max-height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const AdminPageQuestionSettings = ({
  setQuestionsList,
  questionSettingsData,
  openEditQuestionModal,
  setQuestionSettingsData,
  setQuestionSettingsMemoData,
  setOpenEditQuestionModal,
  openDeleteQuestionModal,
  setOpenDeleteQuestionModal,
  setSelectedUserInfo,
  closeAllSettings,
}) => {
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [editedVersesData, setEditedVersesData] = useState({});

  return (
    <AdminPageQuestionSettingsWrapper>
      {questionSettingsData.question_text && (
        <AdminPageQuestionSettingsContainer
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <QuestionSettingsBibleVerses
            questionBibleVerses={questionSettingsData.question_bible_verses}
            setEditedVersesData={setEditedVersesData}
            setOpenBibleModalWindow={setOpenBibleModalWindow}
          />

          <QuestionSettingsContacts
            questionSettingsData={questionSettingsData}
            setSelectedUserInfo={setSelectedUserInfo}
          />

          <QuestionSettingsTags
            questionTags={questionSettingsData.question_tags}
            setQuestionSettingsData={setQuestionSettingsData}
          />

          <QuestionSettingsStatus
            questionStatus={questionSettingsData.question_status}
            questionId={questionSettingsData._id}
            setQuestionSettingsData={setQuestionSettingsData}
            setQuestionsList={setQuestionsList}
          />

          <QuestionSettingsButtons
            questionSettingsData={questionSettingsData}
            openEditQuestionModal={openEditQuestionModal}
            setOpenEditQuestionModal={setOpenEditQuestionModal}
            setQuestionSettingsMemoData={setQuestionSettingsMemoData}
            openDeleteQuestionModal={openDeleteQuestionModal}
            setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
            closeAllSettings={closeAllSettings}
          />
        </AdminPageQuestionSettingsContainer>
      )}

      {openBibleModalWindow && (
        <BibleModalWindow
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          editedVersesData={editedVersesData}
          setEditedVersesData={setEditedVersesData}
          inComponent={"admin_page_questions"}
        />
      )}
    </AdminPageQuestionSettingsWrapper>
  );
};

export default memo(AdminPageQuestionSettings);
