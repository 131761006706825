import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import {
  HeaderBibleIcon as DefaultBibleIcon,
  HeaderChatIcon as DefaultChatIcon,
  HeaderHomeIcon as DefaultHomeIcon,
  HeaderStudyIcon as DefaultStudyIcon,
  HeaderUserIcon as DefaultUserIcon,
} from "../../../assets/icons";

export const HeaderLogoContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MainNavContainer = styled.div`
  display: flex;
  gap: 33px;

  @media (max-width: 700px) {
    justify-content: space-between;
    gap: normal;
    width: 100%;
  }
`;

export const HomeIcon = styled(DefaultHomeIcon)`
  fill: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
`;

export const StudyIcon = styled(DefaultStudyIcon)`
  fill: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
`;

export const BibleIcon = styled(DefaultBibleIcon)`
  stroke: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
`;

export const ChatIcon = styled(DefaultChatIcon)`
  stroke: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
`;

export const UserIconContainer = styled(Link)`
  cursor: pointer;
`;

export const UserIcon = styled(DefaultUserIcon)`
  stroke: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
`;

const HeartBeatFrames = keyframes`
  to { transform: scale(1.1); }
`;

export const DonateIconContainer = styled(Link)`
  cursor: pointer;
  animation: ${HeartBeatFrames} 0.85s infinite alternate;
`;

export const StickyHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  height: 80px;
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  @media (max-width: 1024px) {
    top: auto;
    bottom: 0;
    justify-content: center;
    height: 96px;
  }

  @media (max-width: 412px) {
    border-radius: 25px 25px 0px 0px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 0 20px;
    height: 103px;
  }
`;
