import React, { useCallback } from "react";
import styled from "styled-components";

const QuestionSettingsBibleVersesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #828282;
  font-size: 10px;
  line-height: 110%;
`;

const QuestionSettingsBibleVersesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const BibleVerseAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 0 10px;
  color: #383838;
  font-size: 14px;
  font-style: normal;
  border-radius: 8px;
  border: 1px solid #3e5eed;
  cursor: pointer;

  &:hover {
    background: rgb(223, 223, 223);
    transition: all 0.2s ease 0s;
  }
`;

const QuestionSettingsBibleVerses = ({
  questionBibleVerses,
  setEditedVersesData,
  setOpenBibleModalWindow,
}) => {
  const hadleClickVerseAddress = useCallback((event, verses) => {
    event.stopPropagation();
    setEditedVersesData({
      versesData: verses.versesData,
      versesParams: verses.versesParams,
    });
    setOpenBibleModalWindow(true);
  }, []);

  return (
    <>
      {!!questionBibleVerses.length && (
        <QuestionSettingsBibleVersesContainer>
          Библейские тексты
          <QuestionSettingsBibleVersesList>
            {questionBibleVerses.map((verses, index) => {
              return (
                <BibleVerseAddress
                  key={index}
                  onClick={(e) => hadleClickVerseAddress(e, verses)}
                >
                  {verses.verseAddress}
                </BibleVerseAddress>
              );
            })}
          </QuestionSettingsBibleVersesList>
        </QuestionSettingsBibleVersesContainer>
      )}
    </>
  );
};

export default QuestionSettingsBibleVerses;
