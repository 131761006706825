import React from "react";

const DeleteIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M10.625 18.375C11.1428 18.375 11.5625 17.9553 11.5625 17.4375V12.1875C11.5625 11.6697 11.1428 11.25 10.625 11.25C10.1072 11.25 9.6875 11.6697 9.6875 12.1875V17.4375C9.6875 17.9553 10.1072 18.375 10.625 18.375Z"
        fill="#383838"
      />
      <path
        d="M14.375 11.25C13.8572 11.25 13.4375 11.6697 13.4375 12.1875V17.4375C13.4375 17.9553 13.8572 18.375 14.375 18.375C14.8928 18.375 15.3125 17.9553 15.3125 17.4375V12.1875C15.3125 11.6697 14.8928 11.25 14.375 11.25Z"
        fill="#383838"
      />
      <path
        d="M9.6875 2.8125C9.6875 2.29556 10.1081 1.875 10.625 1.875H14.375C14.8919 1.875 15.3125 2.29556 15.3125 2.8125C15.3125 3.33028 15.7322 3.75 16.25 3.75C16.7678 3.75 17.1875 3.33028 17.1875 2.8125C17.1875 1.26169 15.9258 0 14.375 0H10.625C9.07419 0 7.8125 1.26169 7.8125 2.8125C7.8125 3.33028 8.23222 3.75 8.75 3.75C9.26778 3.75 9.6875 3.33028 9.6875 2.8125Z"
        fill="#383838"
      />
      <path
        d="M3.125 5.625C2.60722 5.625 2.1875 6.04472 2.1875 6.5625C2.1875 7.08028 2.60722 7.5 3.125 7.5H4.17416L5.95892 21.536C6.13316 22.9407 7.33306 24 8.75 24H16.25C17.667 24 18.8668 22.9407 19.0407 21.5389L20.8258 7.5H21.875C22.3928 7.5 22.8125 7.08028 22.8125 6.5625C22.8125 6.04472 22.3928 5.625 21.875 5.625H3.125ZM18.9357 7.5L17.1803 21.3053C17.1223 21.7726 16.7224 22.125 16.25 22.125H8.75C8.27759 22.125 7.87766 21.7725 7.8193 21.3023L6.06425 7.5H18.9357Z"
        fill="#383838"
      />
    </svg>
  );
};

export default DeleteIcon;
