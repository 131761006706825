import React, { memo, useCallback } from "react";
import styled from "styled-components";
import Checkbox from "../../../../common/components/checkbox";
import AdminPagePersonPreview from "../../admin_page_components/admin_page_person_preview";
import PlusIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import { AddUserButtonContainer } from "../../admin_page_components/admin_page_styled_components";
import { PlusOrMinusButton } from "../../admin_page_create_lesson/create_lesson_components/create_lesson_styled_components";

const CourseFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #cacaca;
`;

const AddFeedbackRecipientButtonContainer = styled(AddUserButtonContainer)`
  padding: 0;
`;

const CourseFeedback = ({
  courseSettingsData,
  setCourseSettingsData,
  setOpenStaffListModal,
  setSelectedUserInfo,
}) => {
  const handleOpenStaffListModal = useCallback(() => {
    setOpenStaffListModal("feedback");
  }, []);

  const onClickRemoveAuthorButton = useCallback((event, recipientId) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_feedback_recipients: prevState.course_feedback_recipients.filter(
        (recipient) => recipient._id !== recipientId
      ),
    }));
  }, []);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => {
      let newState = {
        ...prevState,
        course_feedback_is_enabled: !prevState.course_feedback_is_enabled,
      };

      if (prevState.course_feedback_is_enabled) {
        newState.course_feedback_recipients = [];
      }

      return newState;
    });
  }, []);

  return (
    <CourseFeedbackContainer>
      <Checkbox
        checked={courseSettingsData.course_feedback_is_enabled}
        onClickCheckbox={onClickCheckbox}
        fontSize={"14px"}
      >
        Обратная связь
      </Checkbox>

      {courseSettingsData.course_feedback_is_enabled && (
        <>
          {courseSettingsData.course_feedback_recipients.map((recipient) => {
            return (
              <AdminPagePersonPreview
                key={recipient._id}
                personId={recipient._id}
                setSelectedUserInfo={setSelectedUserInfo}
                onRemoveButton={onClickRemoveAuthorButton}
                withoutBorder={true}
              />
            );
          })}

          <AddFeedbackRecipientButtonContainer>
            <PlusOrMinusButton onClick={handleOpenStaffListModal}>
              <PlusIcon />
            </PlusOrMinusButton>
          </AddFeedbackRecipientButtonContainer>
        </>
      )}
    </CourseFeedbackContainer>
  );
};

export default memo(CourseFeedback);
