import React from "react";

const CoursesButtonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M10.3297 16.0704V21.5094C10.3297 22.4012 11.3509 22.9131 12.0654 22.3772L13.8863 21.0114L15.7072 22.3772C16.4163 22.909 17.4429 22.4077 17.4429 21.5094V16.0704C18.9404 14.9725 19.9147 13.2012 19.9147 11.2061C19.9147 7.88216 17.2105 5.17773 13.8863 5.17773C10.5621 5.17773 7.85791 7.88194 7.85791 11.2061C7.85791 13.2012 8.83213 14.9725 10.3297 16.0704ZM15.2732 19.3397L14.5372 18.7876C14.1516 18.4981 13.621 18.4981 13.2354 18.7876L12.4993 19.3397V17.0735C12.9447 17.1786 13.4091 17.2347 13.8863 17.2347C14.3634 17.2347 14.8279 17.1788 15.2732 17.0735V19.3397ZM13.8863 7.34737C16.014 7.34737 17.745 9.07842 17.745 11.2063C17.745 13.334 16.014 15.0651 13.8863 15.0651C11.7586 15.0651 10.0275 13.334 10.0275 11.2061C10.0275 9.07842 11.7586 7.34737 13.8863 7.34737Z"
        fill="white"
      />
      <rect
        x="2.1397"
        y="2.1408"
        width="23.49"
        height="23.49"
        rx="3.64499"
        stroke="white"
        strokeWidth="1.96714"
      />
    </svg>
  );
};

export default CoursesButtonIcon;
