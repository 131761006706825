import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "../../../../../axios_config";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import { BASE_API_URL } from "../../../../../common/endpoints";

const AskQuestionRecipientContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  min-height: 30px;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const AskQuestionRecipientHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showRecipients }) =>
    showRecipients ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const AskQuestionArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showRecipients }) => showRecipients && "transform: rotate(180deg);"}
`;

const AskQuestionRecipientsContainer = styled.div`
  padding: 4px;
`;

const AskQuestionRecipientsItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;
  min-height: 32px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const AskQuestionRecipient = ({ askQuestionData, setAskQuestionData }) => {
  const [authors, setAuthors] = useState([]);
  const [showRecipients, setShowRecipients] = useState(false);

  const selectedRecipient =
    askQuestionData.recipient.user_name || askQuestionData.recipient.user_name
      ? `${askQuestionData.recipient.user_name} ${askQuestionData.recipient.user_second_name}`
      : "Адресат";

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users`);
      const authors = response.data.filter(
        (user) => user.user_access.is_author
      );
      setAuthors(authors);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleShowRecipients = useCallback(() => {
    setShowRecipients((prevState) => !prevState);
  }, []);

  const handleSelectRecipient = useCallback((name) => {
    setAskQuestionData((prevState) => ({
      ...prevState,
      recipient: name,
    }));
    setShowRecipients(false);
  }, []);

  return (
    <AskQuestionRecipientContainer>
      <AskQuestionRecipientHeader
        showRecipients={showRecipients}
        onClick={handleShowRecipients}
      >
        {selectedRecipient}
        <AskQuestionArrowIconContainer showRecipients={showRecipients}>
          <AskQuestionArrowIcon />
        </AskQuestionArrowIconContainer>
      </AskQuestionRecipientHeader>

      {showRecipients && (
        <AskQuestionRecipientsContainer>
          {[{}, ...authors].map((recipient) => {
            const recipientName =
              recipient.user_name || recipient.user_second_name
                ? `${recipient.user_name} ${recipient.user_second_name}`
                : "- Без адресата -";

            return (
              <AskQuestionRecipientsItem
                key={recipient._id || "whithout_recipient"}
                onClick={() => handleSelectRecipient(recipient)}
              >
                {recipientName}
              </AskQuestionRecipientsItem>
            );
          })}
        </AskQuestionRecipientsContainer>
      )}
    </AskQuestionRecipientContainer>
  );
};

export default memo(AskQuestionRecipient);
