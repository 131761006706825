import React, { memo } from "react";
import { styleMap } from ".";
import { useTextSettingsBibleContext } from "./text_editor_bible_context";

const BibleVerseItem = ({ contentState, entityKey }) => {
  const { setOpenBibleModalWindow, setEditedVersesData } =
    useTextSettingsBibleContext();

  const addedVerse = contentState.getEntity(entityKey).getData();

  const handleClick = () => {
    setOpenBibleModalWindow(true);
    setEditedVersesData({
      ...addedVerse,
      id: entityKey,
    });
  };

  return (
    <button style={styleMap.BIBLE_VERSE} onClick={handleClick}>
      {addedVerse.verseAddress}
    </button>
  );
};

export default memo(BibleVerseItem);
