import React from "react";
import styled from "styled-components";
import BibleSearchArrowBackIcon from "../../common/assets/icons/bible_page_icons/bible_search_arrow_back_icon";

const BackButtonContainer = styled.div`
  cursor: pointer;
  border-radius: 50%;
  height: 24px;
  width: 24px;

  &: hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.2s;
  }
`;

const BackToBibleChapterButton = ({ setActiveMainSection }) => {
  const onClickBackButton = (event) => {
    event.stopPropagation();
    setActiveMainSection("bible_chapter");
  };

  return (
    <BackButtonContainer onClick={onClickBackButton}>
      <BibleSearchArrowBackIcon />
    </BackButtonContainer>
  );
};

export default BackToBibleChapterButton;
