import React from "react";

const MinusIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="3"
      viewBox="0 0 21 3"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0585937 1.5C0.0585937 0.671573 0.730167 2.89761e-07 1.55859 3.31908e-07L18.5292 -5.26688e-09C19.3576 6.69083e-07 20.0292 0.671573 20.0292 1.5C20.0292 2.32843 19.3576 3 18.5292 3L1.55859 3C0.730167 3 0.0585937 2.32843 0.0585937 1.5Z"
        fill="url(#paint0_linear_3875_4560)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3875_4560"
          x1="9.5612"
          y1="-0.551471"
          x2="9.5612"
          y2="3.26471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MinusIcon;
