import React from "react";

const ForwardMessageInInputIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      className={className}
    >
      <path
        d="M15.0593 3.93195C7.359 4.27069 0.949205 10.14 0.00529671 17.8489C-0.0337048 18.1666 0.146837 18.4703 0.444384 18.5881C0.528612 18.6214 0.61632 18.6375 0.702745 18.6375C0.922106 18.6375 1.13487 18.5346 1.27037 18.3497C3.86461 14.8051 8.0299 12.689 12.4125 12.689C13.2955 12.689 14.1833 12.7754 15.0593 12.9464V14.8044C15.0593 16.1242 15.7784 16.5926 16.394 16.5926C16.8424 16.5926 17.2972 16.3652 17.7455 15.9169L23.1799 10.4826C24.2734 9.38904 24.2734 7.60981 23.1799 6.5163L17.7455 1.08191C17.2972 0.633667 16.8424 0.40625 16.394 0.40625C15.7784 0.40625 15.0593 0.874634 15.0593 2.19446V3.93195Z"
        fill="url(#paint0_linear_4012_3798)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4012_3798"
          x1="32.0121"
          y1="23.1953"
          x2="-8.40386"
          y2="21.3115"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ForwardMessageInInputIcon;
