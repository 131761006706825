import React from "react";

const AskQuestionArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_5075_5540)">
        <path
          d="M1.5 3.75L6 8.25L10.5 3.75"
          stroke="url(#paint0_linear_5075_5540)"
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5075_5540"
          x1="5.78248"
          y1="2.92279"
          x2="5.78248"
          y2="8.64706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_5075_5540">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AskQuestionArrowIcon;
