import React from "react";

const HidePasswordIcon = () => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1156 11.105L8.6025 9.59094C8.01735 9.80014 7.38483 9.83889 6.77852 9.70266C6.17221 9.56643 5.61706 9.26083 5.17764 8.82142C4.73823 8.38201 4.43263 7.82685 4.2964 7.22054C4.16017 6.61424 4.19892 5.98171 4.40813 5.39656L2.47687 3.46531C0.879375 4.88562 0 6.5 0 6.5C0 6.5 2.8125 11.6562 7.5 11.6562C8.40038 11.6531 9.29057 11.4655 10.1156 11.105V11.105ZM4.88438 1.895C5.70943 1.53446 6.59962 1.34685 7.5 1.34375C12.1875 1.34375 15 6.5 15 6.5C15 6.5 14.1197 8.11344 12.5241 9.53562L10.5909 7.6025C10.8001 7.01735 10.8389 6.38483 10.7027 5.77852C10.5664 5.17221 10.2608 4.61706 9.82142 4.17764C9.38201 3.73823 8.82685 3.43263 8.22054 3.2964C7.61424 3.16017 6.98171 3.19892 6.39656 3.40813L4.88438 1.89594V1.895Z"
      fill="#272727"
    />
    <path
      d="M5.17965 6.16821C5.12808 6.5285 5.16113 6.89585 5.27618 7.24115C5.39123 7.58645 5.58512 7.90022 5.84248 8.15758C6.09984 8.41494 6.4136 8.60882 6.7589 8.72387C7.1042 8.83892 7.47155 8.87197 7.83184 8.8204L5.17872 6.16821H5.17965ZM9.82028 6.83196L7.16809 4.17884C7.52838 4.12727 7.89573 4.16032 8.24103 4.27537C8.58633 4.39041 8.90009 4.5843 9.15746 4.84166C9.41482 5.09902 9.6087 5.41278 9.72375 5.75808C9.8388 6.10338 9.87185 6.47073 9.82028 6.83102V6.83196ZM12.7931 12.457L1.54309 1.20696L2.20684 0.543213L13.4568 11.7932L12.7931 12.457Z"
      fill="#272727"
    />
  </svg>
);

export default HidePasswordIcon;
