import React from "react";

const AdminPageRemoveFilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <rect width="14" height="14" rx="1.86667" fill="white" />
      <path
        d="M4.03516 4L9.97485 9.9397"
        stroke="#383838"
        strokeWidth="1.86667"
        strokeLinecap="round"
      />
      <path
        d="M9.97656 4L4.03687 9.9397"
        stroke="#383838"
        strokeWidth="1.86667"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AdminPageRemoveFilterIcon;
