import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { convertFromRaw } from "draft-js";
import styled from "styled-components";
import { stateToHTML } from "draft-js-export-html";
import { useUserDataContext } from "../../../user_data_context";
import BibleChapterModalWindow from "../../../common/components/modal_windows/bible_chapter_modal_window";
import {
  getInlineStyles,
  replacePlaceholderWithStudentName,
  entityStyleFn,
  replaceSpacesWithNbsp,
} from "../../../common/components/text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../../../common/components/text_editor/editor_styled_components";
import { LESSON_COLORS_THEME } from "../../../common/utils/enums";
import { useAppContext } from "../../../app_context";

const AboutCourseContainer = styled.div`
  width: 506px;
  margin: 48px auto 0;
  background-color: #fff;
  border-radius: 25px;
  padding: 25px 35px 35px 41px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 24px;
  word-break: normal;
  overflow-wrap: break-word;

  @media (max-width: 514px) {
    width: 380px;
    margin: 38px auto 0;
    padding: 25px 23px 24px 25px;
  }

  @media (max-width: 412px) {
    width: 330px;
  }
`;

const AboutCourse = ({ courseData }) => {
  const { userData } = useUserDataContext();
  const { appColorTheme } = useAppContext();
  const htmlContentRef = useRef(null);

  const [html, setHtml] = useState("");
  const [textBibleModalData, setTextBibleModalData] = useState({});

  const currentUserName = userData.user_name || "Пользователь";

  const courseMainGradient =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      courseData.course_main_color
    ];
  const courseMainColor =
    LESSON_COLORS_THEME[appColorTheme]?.COLORS?.[courseData.course_main_color];

  useEffect(() => {
    if (courseData.about_course?.blocks?.length) {
      try {
        let contentState = convertFromRaw(courseData.about_course);

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(appColorTheme),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [courseData.about_course, currentUserName, appColorTheme]);

  const handleBibleVerseClick = useCallback((event) => {
    event.stopPropagation();

    const bibleId = event.target.dataset.bibleId;
    const bookId = event.target.dataset.bookId;
    const chapterId = event.target.dataset.chapterId;
    const selectedVerses = event.target.dataset.selectedVerses;

    setTextBibleModalData({ bibleId, bookId, chapterId, selectedVerses });
  }, []);

  useEffect(() => {
    const elements = htmlContentRef.current?.querySelectorAll(".bible-verse");

    elements?.forEach((element) => {
      element.addEventListener("click", handleBibleVerseClick);
    });

    return () => {
      elements?.forEach((element) => {
        element.removeEventListener("click", handleBibleVerseClick);
      });
    };
  }, [html]);

  return (
    <AboutCourseContainer>
      <TextEditorViewComponent
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: html }}
        courseMainGradient={courseMainGradient}
        courseMainColor={courseMainColor}
      />

      {textBibleModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={textBibleModalData}
            setOpen={setTextBibleModalData}
          />,
          document.body
        )}
    </AboutCourseContainer>
  );
};

export default memo(AboutCourse);
