export function useHooks() {
  const hooks = [];

  const addHook = (name, hook) => {
    hooks.push({ name, hook });
  };

  const addHooks = (names, hook) => {
    names.forEach((name) => addHook(name, hook));
  };

  const invokeHook = (name, e) => {
    return hooks.filter((h) => h.name == name).map((h) => h.hook(e));
  };

  return {
    addHook,
    addHooks,
    invokeHook,
  };
}
