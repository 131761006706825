import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../../../../user_data_context";
import { insertUserNameInText } from "../../../../../common/utils/functions/admin_page_functions";
import QuestionComponentBible from "./question_component_bible";

const QuestionPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const MainQuestionContainer = styled.div`
  display: flex;
  min-height: 60px;
  padding: 16px 14px;
  padding-bottom: ${({ withBible }) => (withBible ? "26px" : "16px")};
  border-radius: 15px;
  background: ${({ background }) => background};
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

const AnswerInput = styled.textarea`
  resize: none;
  max-height: 200px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
  border-radius: 15px;
  background: #fff;
  font-size: 16px;
`;

const AnswerOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const AnswerOption = styled.div`
  padding: 13px 17px;
  min-height: 54px;
  min-width: 54px;
  max-width: 100%;
  border-radius: 15px;
  background: #fff;
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    transition: 0.2s;
  }

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 24px;
    min-height: 50px;
  }
`;

const QuestionLessonComponent = ({
  componentData,
  setLessonData = () => {},
  inComponent = "",
}) => {
  const { userData } = useUserDataContext();
  const currentUserName = userData.user_name || "";

  const answerInputRef = useRef(null);
  const answerInputValue = componentData.data?.answer_input || "";

  const handleAnswerInputValue = useCallback(
    (event) => {
      event.stopPropagation();

      if (inComponent === "preview_page") {
        setLessonData((prevState) => {
          const newLessonData = prevState.lesson_data.map((component) =>
            component.id === componentData.id
              ? {
                  ...componentData,
                  data: {
                    ...componentData.data,
                    answer_input: event.target.value,
                  },
                }
              : component
          );

          return {
            ...prevState,
            lesson_data: newLessonData,
          };
        });
      }
    },
    [inComponent]
  );

  useEffect(() => {
    if (answerInputRef.current) {
      answerInputRef.current.style.height = "auto";
      answerInputRef.current.style.height =
        answerInputRef.current.scrollHeight + "px";
    }
  }, [answerInputValue, answerInputRef]);

  const onSelectAnswerOption = useCallback(
    (event, option) => {
      event.stopPropagation();

      if (inComponent === "preview_page" && !componentData.selected_answer) {
        setLessonData((prevState) => {
          const newLessonData = prevState.lesson_data.map((component) =>
            component.id === componentData.id
              ? {
                  ...componentData,
                  selected_answer: option,
                }
              : component
          );

          return {
            ...prevState,
            lesson_data: newLessonData,
          };
        });
      }
    },
    [inComponent, componentData]
  );

  return (
    <QuestionPreviewContainer>
      <MainQuestionContainer
        withBible={componentData.with_bible}
        background={componentData.background}
      >
        {insertUserNameInText(componentData.question, currentUserName)}
      </MainQuestionContainer>
      {componentData.with_bible && (
        <QuestionComponentBible previewInfo={componentData} />
      )}
      {componentData.answer_type === "input" && (
        <AnswerInput
          placeholder="Ваш ответ..."
          ref={answerInputRef}
          type="text"
          rows={3}
          value={answerInputValue}
          onChange={handleAnswerInputValue}
        />
      )}
      {componentData.answer_type === "options" && (
        <AnswerOptionsContainer>
          {componentData.data?.answer_options?.map((option, index) => {
            const showOption = componentData.selected_answer
              ? componentData.selected_answer === option
                ? true
                : false
              : true;

            return (
              showOption && (
                <AnswerOption
                  key={index + 1 + "-answer_option"}
                  onClick={(e) => onSelectAnswerOption(e, option)}
                >
                  {option}
                </AnswerOption>
              )
            );
          })}
        </AnswerOptionsContainer>
      )}
    </QuestionPreviewContainer>
  );
};

export default memo(QuestionLessonComponent);
