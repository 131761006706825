import React from "react";

const BiblePageCopyIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      className={className}
    >
      <path
        d="M20.1497 8.54395H11.0751C9.96133 8.54395 9.05847 9.4468 9.05847 10.5605V19.6352C9.05847 20.7489 9.96133 21.6518 11.0751 21.6518H20.1497C21.2635 21.6518 22.1663 20.7489 22.1663 19.6352V10.5605C22.1663 9.4468 21.2635 8.54395 20.1497 8.54395Z"
        stroke="#383838"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02489 14.5922H4.01659C3.48176 14.5922 2.96883 14.3798 2.59065 14.0016C2.21246 13.6234 2 13.1105 2 12.5756V3.50097C2 2.96613 2.21246 2.45321 2.59065 2.07502C2.96883 1.69684 3.48176 1.48438 4.01659 1.48438H13.0913C13.6261 1.48438 14.139 1.69684 14.5172 2.07502C14.8954 2.45321 15.1079 2.96613 15.1079 3.50097V4.50926"
        stroke="#383838"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BiblePageCopyIcon;
