import React from "react";

const GoogleIcon = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5287 15.2636C28.5287 14.111 28.433 13.2699 28.2258 12.3976H14.7551V17.6001H22.6621C22.5028 18.893 21.6419 20.8401 19.7289 22.1484L19.7021 22.3226L23.9613 25.5466L24.2563 25.5754C26.9664 23.1298 28.5287 19.5316 28.5287 15.2636Z"
      fill="#4285F4"
    />
    <path
      d="M14.7551 28.971C18.6289 28.971 21.8809 27.7248 24.2563 25.5753L19.7289 22.1484C18.5173 22.974 16.8912 23.5503 14.7551 23.5503C10.961 23.5503 7.74083 21.1048 6.59292 17.7247L6.42467 17.7387L1.99591 21.0877L1.93799 21.245C4.29733 25.8245 9.1436 28.971 14.7551 28.971Z"
      fill="#34A853"
    />
    <path
      d="M6.59301 17.7248C6.29013 16.8525 6.11483 15.9178 6.11483 14.9521C6.11483 13.9863 6.29013 13.0518 6.57708 12.1795L6.56905 11.9937L2.08479 8.59094L1.93808 8.65913C0.965678 10.5595 0.407715 12.6935 0.407715 14.9521C0.407715 17.2107 0.965678 19.3446 1.93808 21.245L6.59301 17.7248Z"
      fill="#FBBC05"
    />
    <path
      d="M14.7551 6.35384C17.4492 6.35384 19.2665 7.49092 20.3028 8.44116L24.3519 4.57814C21.8651 2.31955 18.6289 0.933228 14.7551 0.933228C9.1436 0.933228 4.29733 4.07967 1.93799 8.65915L6.57699 12.1795C7.74083 8.79939 10.961 6.35384 14.7551 6.35384Z"
      fill="#EB4335"
    />
  </svg>
);

export default GoogleIcon;
