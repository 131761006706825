import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { getUserNameInitials } from "../../../../../common/utils/functions/admin_page_functions";
import { useUserDataContext } from "../../../../../user_data_context";
import { DIALOGUE_ANSWERS_TYPE_DATA } from "../../create_lesson_settings_block/settings_block_renderers/dialogue_settings";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  replacePlaceholderWithStudentName,
  entityStyleFn,
  getInlineStyles,
  replaceSpacesWithNbsp,
} from "../../../../../common/components/text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../../../../../common/components/text_editor/editor_styled_components";

const DialogueComponentBranchContainer = styled.div``;

const DialogueBotMessageContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
`;

const DialogueBotImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  background: linear-gradient(
    180deg,
    rgba(81, 114, 234, 0.4) -18.38%,
    rgba(122, 90, 238, 0.4) 108.82%
  );
  border-radius: 50%;
  color: #1e1e1e;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
`;

const DialogueBotImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
`;

const DialogueUserImage = styled(DialogueBotImage)``;

const DialogueBotMessage = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 504px;
  gap: 5px;
  padding: 12px 14px 12px 16px;
  min-height: 65px;
  background: #fff;
  border-radius: 15px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 24px;
  word-break: normal;
  overflow-wrap: break-word;
  transform-origin: bottom left;
`;

const DialogueBotMessageName = styled.div`
  color: #828282;
  font-size: 10px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.1px;
`;

const DialogueUserAnswersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5px;
  margin-top: 20px;
`;

const DialogueUserAnswers = styled(motion.div)`
  width: 480px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 12px;
  transform-origin: bottom right;
`;

const commonAnimationProps = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const getAnimationProps = (withAnimation) => {
  if (withAnimation) {
    return commonAnimationProps;
  } else {
    return {
      initial: {},
      animate: {},
    };
  }
};

const DialogueComponentBranch = ({
  branch,
  componentData,
  dialogueBotData,
  inComponent,
  branchIndex,
  withAnimation,
  setLessonData,
  setTextEditorComponentHtml,
}) => {
  const branchRef = useRef(null);
  const { userData } = useUserDataContext();

  const [html, setHtml] = useState("");
  const [showUserAnswers, setShowUserAnswers] = useState(
    withAnimation ? false : true
  );

  const branchData = componentData.data?.[branch];
  const currentUserName = userData.user_name || "";
  const animationProps = getAnimationProps(withAnimation);

  useEffect(() => {
    if (withAnimation) {
      const timer = setTimeout(() => {
        setShowUserAnswers(true);
      }, 800);

      return () => clearTimeout(timer);
    } else {
      setShowUserAnswers(true);
    }
  }, [withAnimation]);

  useEffect(() => {
    if (branchData?.text?.blocks?.length) {
      try {
        let contentState = convertFromRaw(branchData.text);

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );

        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [branchData?.text?.blocks, currentUserName]);

  useEffect(() => {
    setTextEditorComponentHtml({});
  }, [html]);

  useEffect(() => {
    if (branchRef.current && branchIndex > 0 && withAnimation) {
      branchRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [branchRef.current, branchIndex, withAnimation]);

  return (
    <DialogueComponentBranchContainer ref={branchRef}>
      <DialogueBotMessageContainer>
        <DialogueBotImageWrapper>
          {dialogueBotData?.user_image ? (
            <DialogueBotImage image={dialogueBotData.user_image} />
          ) : (
            <p>
              {getUserNameInitials(
                branchData.user_name,
                branchData.user_second_name
              )}
            </p>
          )}
        </DialogueBotImageWrapper>

        <DialogueBotMessage {...animationProps}>
          {dialogueBotData?.user_name && (
            <DialogueBotMessageName>
              {dialogueBotData?.user_name} {dialogueBotData?.user_second_name}
            </DialogueBotMessageName>
          )}
          <TextEditorViewComponent dangerouslySetInnerHTML={{ __html: html }} />
        </DialogueBotMessage>
      </DialogueBotMessageContainer>

      {!!branchData?.answers?.answers_data?.length && showUserAnswers && (
        <DialogueUserAnswersContainer>
          <DialogueUserAnswers {...animationProps}>
            {branchData?.answers.answers_data.map((answer) => {
              const AnswerComponent =
                DIALOGUE_ANSWERS_TYPE_DATA[answer.type].answerPreviewComponent;

              return (
                <AnswerComponent
                  key={answer.branch}
                  answer={answer}
                  componentData={componentData}
                  setLessonData={setLessonData}
                  inComponent={inComponent}
                  branchIndex={branchIndex}
                  withAnimation={withAnimation}
                />
              );
            })}
          </DialogueUserAnswers>

          <DialogueBotImageWrapper>
            {userData?.user_image ? (
              <DialogueUserImage image={userData.user_image} />
            ) : (
              <p>
                {getUserNameInitials(
                  userData.user_name,
                  userData.user_second_name
                )}
              </p>
            )}
          </DialogueBotImageWrapper>
        </DialogueUserAnswersContainer>
      )}
    </DialogueComponentBranchContainer>
  );
};

export default memo(DialogueComponentBranch);
