import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";

const CourseStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > h3 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }
`;

const CourseStatusContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  min-height: 36px;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const CourseStatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 36px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const CourseStatusArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const CourseStatusOptionsContainer = styled.div`
  padding: 4px;
`;

const CourseStatusOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const CourseStatus = ({ courseSettingsData, setCourseSettingsData }) => {
  const [showOptions, setShowOptions] = useState(false);

  const selectedAccess =
    courseSettingsData.course_status === "open"
      ? "Открытый"
      : courseSettingsData.course_status === "closed"
      ? "Закрытый"
      : "Анонс";

  const handleShowOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);

  const handleSelectOptions = useCallback((event, status) => {
    event.stopPropagation();
    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_status: status,
    }));
    setShowOptions(false);
  }, []);

  return (
    <CourseStatusWrapper>
      <h3>Статус</h3>
      <CourseStatusContainer>
        <CourseStatusHeader
          showOptions={showOptions}
          onClick={handleShowOptions}
        >
          {selectedAccess}
          <CourseStatusArrowIconContainer showOptions={showOptions}>
            <AskQuestionArrowIcon />
          </CourseStatusArrowIconContainer>
        </CourseStatusHeader>

        {showOptions && (
          <CourseStatusOptionsContainer>
            <CourseStatusOptionItem
              onClick={(e) => handleSelectOptions(e, "open")}
            >
              Открытый
            </CourseStatusOptionItem>
            <CourseStatusOptionItem
              onClick={(e) => handleSelectOptions(e, "announcement")}
            >
              Анонс
            </CourseStatusOptionItem>
            <CourseStatusOptionItem
              onClick={(e) => handleSelectOptions(e, "closed")}
            >
              Закрытый
            </CourseStatusOptionItem>
          </CourseStatusOptionsContainer>
        )}
      </CourseStatusContainer>
    </CourseStatusWrapper>
  );
};

export default memo(CourseStatus);
