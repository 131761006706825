import React, { memo, useCallback } from "react";
import styled from "styled-components";
import BibleStrongArrowLeftIcon from "../../../../common/assets/icons/bible_page_icons/bible_strong_arrow_left_icon";
import BibleStrongArrowRightIcon from "../../../../common/assets/icons/bible_page_icons/bible_strong_arrow_right_icon";
import BibleStrongBibleIcon from "../../../../common/assets/icons/bible_page_icons/bible_strong_bible_icon";

const BibleStrongModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  padding-right: 3px;

  & > h2 {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.24px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  ${({ justContEnd }) => justContEnd && "justify-content: flex-end;"}
  width: 60px;
`;

const BibleStrongModalArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 0.6)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  padding: 2px;
  transition: 0.2s;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  }
`;

const OtherBiblePlacesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  width: max-content;
  cursor: pointer;
  transition: 0.2s;

  & > p {
    color: #5172ea;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.165px;
  }
`;

const BibleIconContainer = styled.div`
  height: 12px;
`;

const BibleStrongModalHeader = ({
  currentCodeStrong,
  codesStrongHistory,
  currentScreen,
  setCurrentScreen,
  currentCodeIndex,
  setCurrentCodeIndex,
  concordanceRefs,
}) => {
  const isLeftDisabled = currentCodeIndex === 0;
  const isRightDisabled = currentCodeIndex === codesStrongHistory.length - 1;

  const handleCurrentScreen = useCallback(
    (event) => {
      event.stopPropagation();
      setCurrentScreen((prevState) =>
        prevState === "main" ? "concordance" : "main"
      );
    },
    [setCurrentScreen]
  );

  const handleArrowClick = useCallback(
    (direction) => {
      if (currentCodeIndex === -1) return;

      let newIndex;
      if (direction === "left") {
        newIndex =
          currentCodeIndex > 0 ? currentCodeIndex - 1 : currentCodeIndex;
      } else if (direction === "right") {
        newIndex =
          currentCodeIndex < codesStrongHistory.length - 1
            ? currentCodeIndex + 1
            : currentCodeIndex;
      }

      if (newIndex !== currentCodeIndex) {
        setCurrentCodeIndex(newIndex);
      }

      setCurrentScreen("main");
    },
    [currentCodeIndex, codesStrongHistory, setCurrentCodeIndex]
  );

  const onClickBackArrow = useCallback(() => {
    if (currentScreen === "chapter") {
      setCurrentScreen("concordance");
    } else {
      setCurrentScreen("main");
    }
  }, [currentScreen]);

  return (
    <BibleStrongModalHeaderContainer>
      <ButtonsWrapper>
        <BibleStrongModalArrowsContainer>
          {currentScreen === "main" ? (
            <>
              <ArrowContainer
                onClick={() => !isLeftDisabled && handleArrowClick("left")}
                disabled={isLeftDisabled}
              >
                <BibleStrongArrowLeftIcon />
              </ArrowContainer>
              <ArrowContainer
                onClick={() => !isRightDisabled && handleArrowClick("right")}
                disabled={isRightDisabled}
              >
                <BibleStrongArrowRightIcon />
              </ArrowContainer>
            </>
          ) : (
            <ArrowContainer onClick={onClickBackArrow}>
              <BibleStrongArrowLeftIcon />
            </ArrowContainer>
          )}
        </BibleStrongModalArrowsContainer>
      </ButtonsWrapper>

      <h2>{currentCodeStrong}</h2>

      <ButtonsWrapper justContEnd={true}>
        <OtherBiblePlacesContainer onClick={handleCurrentScreen}>
          <p>({concordanceRefs.length})</p>
          <BibleIconContainer>
            <BibleStrongBibleIcon />
          </BibleIconContainer>
        </OtherBiblePlacesContainer>
      </ButtonsWrapper>
    </BibleStrongModalHeaderContainer>
  );
};

export default memo(BibleStrongModalHeader);
