import React, { memo, useCallback } from "react";
import ColorSelect from "../../../../../../common/components/color_select";
import { LESSON_COLORS } from "../../../../../../common/utils/enums";
import styled from "styled-components";

const DialogueSettingsBackgroundsContainer = styled.div`
  & > p {
    margin-bottom: 9px;
    color: #1e1e1e;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
`;

const DialogueSettingsBackgrounds = ({
  settingsBlockData,
  setSettingsBlockData,
}) => {
  const handleSelectAnswersBackground = useCallback((event, background) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      background,
    }));
  }, []);

  return (
    <DialogueSettingsBackgroundsContainer>
      <p>Цвет ответов</p>
      <ColorSelect
        selectedColor={settingsBlockData.background}
        handleSelectColor={handleSelectAnswersBackground}
        colors={LESSON_COLORS}
      />
    </DialogueSettingsBackgroundsContainer>
  );
};

export default memo(DialogueSettingsBackgrounds);
