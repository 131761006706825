import React from "react";

const HeaderBibleIcon = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="26"
      viewBox="0 0 21 26"
      fill="none"
      className={className}
    >
      <path
        d="M1.78516 21.25C1.78516 20.5207 2.07474 19.8212 2.59019 19.3055C3.10565 18.7897 3.80476 18.5 4.53373 18.5H19.376"
        stroke={stroke}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.53373 2H19.376V24H4.53373C3.80476 24 3.10565 23.7103 2.59019 23.1945C2.07474 22.6788 1.78516 21.9793 1.78516 21.25V4.75C1.78516 4.02065 2.07474 3.32118 2.59019 2.80546C3.10565 2.28973 3.80476 2 4.53373 2Z"
        stroke={stroke}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.585 6.5V14.5M7.58496 9.5H13.585"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeaderBibleIcon;
