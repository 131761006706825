import React from "react";

const SelectedCheckboxSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="16"
        height="16"
        rx="3.5"
        stroke="url(#paint0_linear_5567_7138)"
      />
      <rect
        x="3.09082"
        y="3.09082"
        width="10.8182"
        height="10.8182"
        rx="2"
        fill="url(#paint1_linear_5567_7138)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5567_7138"
          x1="8.08912"
          y1="-3.125"
          x2="8.08912"
          y2="18.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5567_7138"
          x1="8.23845"
          y1="1.10218"
          x2="8.23845"
          y2="14.8635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SelectedCheckboxSmall;
