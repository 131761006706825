import React, { useCallback } from "react";
import styled from "styled-components";
import PlusIcon from "../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import AdminPagePersonPreview from "../../../admin_page_components/admin_page_person_preview";
import {
  AddUserButton,
  AddUserButtonContainer,
} from "../../../admin_page_components/admin_page_styled_components";

const UserMentorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 11px;

  & > h5 {
    color: #828282;
    font-size: 10px;
    line-height: 120%; 
`;

const UserRoleAddMentorButton = styled(AddUserButtonContainer)`
  height: 40px;
  align-items: center;
`;

const UserMentor = ({
  usersList,
  userRolesSettingsData,
  userRolesSettingsMemoData,
  setUserRolesSettingsData,
  setUsersListByRole,
  setOpenUsersListModal,
  setSelectedUserInfo,
  setOpenUnbindMentorModal,
}) => {
  const userMentor = userRolesSettingsData?.user_mentor;

  const openUsersListByMentorRole = useCallback(
    (roleTitle) => {
      const filteredUsers = usersList.filter(
        (user) =>
          user.user_name &&
          user.user_access.is_mentor &&
          user._id !== userRolesSettingsData._id &&
          user._id !== userMentor?._id
      );

      setUsersListByRole(filteredUsers);
      setOpenUsersListModal(roleTitle);
    },
    [usersList, userRolesSettingsData, userMentor]
  );

  const unbindUserMentor = useCallback(
    (event) => {
      event.stopPropagation();
      if (
        userRolesSettingsMemoData.user_mentor?._id ===
        userRolesSettingsData.user_mentor?._id
      ) {
        setOpenUnbindMentorModal(true);
      } else {
        setUserRolesSettingsData((prevState) => ({
          ...prevState,
          user_mentor: {},
        }));
      }
    },
    [userRolesSettingsMemoData, userRolesSettingsData]
  );

  const handleChangeUserMentor = useCallback((event) => {
    event.stopPropagation();
    openUsersListByMentorRole("mentors");
  }, []);

  return (
    <UserMentorContainer>
      <h5>{userMentor?._id ? "Наставник" : "Наставник отсутствует"}</h5>
      {userMentor?._id ? (
        <AdminPagePersonPreview
          setSelectedUserInfo={setSelectedUserInfo}
          unbindUserMentor={unbindUserMentor}
          handleChangeUserMentor={handleChangeUserMentor}
          personId={userMentor._id}
          component={"user_mentor"}
          modalWindow={"admin_users_info"}
        />
      ) : (
        <UserRoleAddMentorButton>
          <AddUserButton onClick={handleChangeUserMentor}>
            <PlusIcon />
          </AddUserButton>
        </UserRoleAddMentorButton>
      )}
    </UserMentorContainer>
  );
};

export default UserMentor;
