import React from "react";

const FileIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    className={className}
  >
    <path
      d="M6.99951 1.00012H2.99951C2.7343 1.00012 2.47994 1.10548 2.2924 1.29302C2.10487 1.48055 1.99951 1.73491 1.99951 2.00012V10.0001C1.99951 10.2653 2.10487 10.5197 2.2924 10.7072C2.47994 10.8948 2.7343 11.0001 2.99951 11.0001H8.99951C9.26473 11.0001 9.51908 10.8948 9.70662 10.7072C9.89415 10.5197 9.99951 10.2653 9.99951 10.0001V4.00012L6.99951 1.00012Z"
      stroke="white"
      strokeWidth="1.05238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99902 1.00012V4.00012H9.99902"
      stroke="white"
      strokeWidth="1.05238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00049 6.49988H4.00049"
      stroke="white"
      strokeWidth="1.05238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00049 8.50012H4.00049"
      stroke="white"
      strokeWidth="1.05238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00049 4.5H4.50049H4.00049"
      stroke="white"
      strokeWidth="1.05238"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FileIcon;
