import React, { memo, useCallback } from "react";
import Checkbox from "../../../../common/components/checkbox";
import styled from "styled-components";

const CourseDurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const CourseDuration = ({ courseSettingsData, setCourseSettingsData }) => {
  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_duration_is_enabled: !prevState.course_duration_is_enabled,
    }));
  }, []);

  return (
    <CourseDurationContainer>
      <Checkbox
        checked={courseSettingsData.course_duration_is_enabled}
        onClickCheckbox={onClickCheckbox}
        fontSize={"14px"}
      >
        Время прохождения
      </Checkbox>
    </CourseDurationContainer>
  );
};

export default memo(CourseDuration);
