import React from "react";

const HeaderLanguageIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.19318C7.02944 3.19318 3 7.22261 3 12.1932C3 17.1637 7.02944 21.1932 12 21.1932C16.9706 21.1932 21 17.1637 21 12.1932C21 7.22261 16.9706 3.19318 12 3.19318ZM1 12.1932C1 6.11804 5.92487 1.19318 12 1.19318C18.0751 1.19318 23 6.11804 23 12.1932C23 18.2683 18.0751 23.1932 12 23.1932C5.92487 23.1932 1 18.2683 1 12.1932Z"
      fill="#383838"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12.1932C1 11.6409 1.44772 11.1932 2 11.1932H22C22.5523 11.1932 23 11.6409 23 12.1932C23 12.7455 22.5523 13.1932 22 13.1932H2C1.44772 13.1932 1 12.7455 1 12.1932Z"
      fill="#383838"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.19318C12.2809 1.19318 12.5489 1.31134 12.7383 1.51875C15.4031 4.43608 16.9175 8.22203 16.9998 12.1723C17.0001 12.1862 17.0001 12.2001 16.9998 12.214C16.9175 16.1643 15.4031 19.9503 12.7383 22.8676C12.5489 23.075 12.2809 23.1932 12 23.1932C11.7191 23.1932 11.4511 23.075 11.2617 22.8676C8.59689 19.9503 7.08251 16.1643 7.00022 12.214C6.99993 12.2001 6.99993 12.1862 7.00022 12.1723C7.08251 8.22203 8.59689 4.43608 11.2617 1.51875C11.4511 1.31134 11.7191 1.19318 12 1.19318ZM9.00023 12.1932C9.06877 15.268 10.1263 18.2283 12 20.6424C13.8737 18.2283 14.9312 15.268 14.9998 12.1932C14.9312 9.11833 13.8737 6.15802 12 3.74397C10.1263 6.15802 9.06877 9.11833 9.00023 12.1932Z"
      fill="#383838"
    />
  </svg>
);

export default HeaderLanguageIcon;
