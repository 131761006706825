import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import AdminPageDeleteIcon from "../../../../../common/assets/icons/admin_page_icons/admin_page_delete_icon";
import { AdminPageSettingsDeleteButton } from "../../../admin_page_components/admin_page_styled_components";
import DeleteQuestionModalWindow from "../../../../../common/components/modal_windows/delete_modal_window";
import { BASE_API_URL } from "../../../../../common/endpoints";
import UserInformationSection from "./user_information_section";
import UserActivitySection from "./user_activity_section";
import UserInfoActionsSection from "./user_info_actions_section";

const AdminUsersInfoModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const ScrollWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ isInvite }) => isInvite && "align-items: center;"}
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 40px;
`;

const AdminUsersInfoModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 480px;
  min-height: 200px;
  height: max-content;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
`;

export const UserInfoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.24px;
`;

const UserInviteInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 7px;
  padding: 15px 0;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AdminUsersInfoModalWindow = ({
  selectedUserInfo,
  setSelectedUserInfo,
  closeUserSettings,
  fetchUsers,
}) => {
  const scrollWrapperRef = useRef(null);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setSelectedUserInfo({});
  }, []);

  const onClickDeleteUser = useCallback((event) => {
    setOpenDeleteUserModal(true);
  }, []);

  const handleDeleteUser = useCallback(async () => {
    try {
      await axios.delete(`${BASE_API_URL}/users/${selectedUserInfo.data._id}`);
    } catch (error) {
      console.error("Ошибка при удалении пользователя:", error);
    } finally {
      fetchUsers();
      closeUserSettings();
      setOpenDeleteUserModal(false);
      setSelectedUserInfo({});
    }
  }, [selectedUserInfo, fetchUsers, closeUserSettings]);

  useEffect(() => {
    if (
      selectedUserInfo.data?._id &&
      selectedUserInfo.component !== "user_penalty" &&
      scrollWrapperRef.current
    ) {
      scrollWrapperRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [selectedUserInfo, scrollWrapperRef]);

  return (
    <AdminUsersInfoModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ScrollWrapper
        ref={scrollWrapperRef}
        isInvite={!selectedUserInfo.data.user_name}
      >
        <AdminUsersInfoModalWindowContainer
          onClick={(e) => e.stopPropagation()}
        >
          {selectedUserInfo.data.user_type === "user" ? (
            <>
              <UserInformationSection selectedUserInfo={selectedUserInfo} />
              <UserActivitySection selectedUserInfo={selectedUserInfo} />
              <UserInfoActionsSection
                selectedUserInfo={selectedUserInfo}
                scrollWrapperRef={scrollWrapperRef}
                setSelectedUserInfo={setSelectedUserInfo}
              />
            </>
          ) : (
            <UserInviteInfo>
              <p>Пользователю отправлено приглашение</p>
              <p>
                на почту - <b>{selectedUserInfo.data.user_email}</b>
              </p>
            </UserInviteInfo>
          )}

          <ButtonsContainer>
            <AdminPageSettingsDeleteButton onClick={onClickDeleteUser}>
              <AdminPageDeleteIcon />
            </AdminPageSettingsDeleteButton>
          </ButtonsContainer>
        </AdminUsersInfoModalWindowContainer>
      </ScrollWrapper>

      {openDeleteUserModal && (
        <DeleteQuestionModalWindow
          setOpen={setOpenDeleteUserModal}
          handleDelete={handleDeleteUser}
          deletionObject={"пользователя"}
        />
      )}
    </AdminUsersInfoModalWindowWrapper>
  );
};

export default memo(AdminUsersInfoModalWindow);
