import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import DeleteModalWindow from "../../../../common/components/delete_modal_window";
import DeleteIcon from "../../../../common/assets/icons/create_lesson_icons/delete_icon";
import { BASE_API_URL } from "../../../../common/endpoints";
import { useParams } from "react-router-dom";
import { useUserDataContext } from "../../../../user_data_context";

const RemoveButtonContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }
`;

const RemoveLessonComponent = ({
  editBlockIndex,
  lessonData,
  resetAllActiveBlocks,
}) => {
  const { id } = useParams();
  const { userData } = useUserDataContext();

  const [openRemoveModalWindow, setOpenRemoveModalWindow] = useState(false);

  const onRemovePreviewBlock = useCallback((event) => {
    event.stopPropagation();
    setOpenRemoveModalWindow(true);
  }, []);

  const onClickRemoveButton = useCallback(
    async (event) => {
      event.stopPropagation();

      const updatedLessonData = lessonData.lesson_data.filter(
        (_, index) => index !== editBlockIndex
      );

      try {
        await axios.patch(`${BASE_API_URL}/lessons/${id}`, {
          ...lessonData,
          lesson_modified_at: new Date().toISOString(),
          lesson_modified_by: { _id: userData._id },
          lesson_data: updatedLessonData,
        });

        const { image_url } = lessonData.lesson_data[editBlockIndex] || {};
        if (image_url) {
          const imageName = new URL(image_url).pathname.split("/").pop();
          await axios.delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`);
        }

        resetAllActiveBlocks();
      } catch (error) {
        console.error("Ошибка при запросе:", error);
      }
    },
    [editBlockIndex, lessonData, id, userData, resetAllActiveBlocks]
  );

  return (
    <>
      <RemoveButtonContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={onRemovePreviewBlock}
      >
        <DeleteIcon />
      </RemoveButtonContainer>

      <DeleteModalWindow
        isOpen={openRemoveModalWindow}
        onClose={setOpenRemoveModalWindow}
        onClickRemoveButton={onClickRemoveButton}
      />
    </>
  );
};

export default memo(RemoveLessonComponent);
