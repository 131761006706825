import React from "react";

const ErrorIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4025_14899)">
        <circle cx="7.00433" cy="6.99999" r="6.99554" fill="#C74040" />
        <path
          d="M7.78584 3.32918L7.61675 8.50922H6.38707L6.21286 3.32918H7.78584ZM6.17188 10.1129C6.17188 9.89432 6.24702 9.71329 6.39732 9.56983C6.55103 9.42295 6.75256 9.34951 7.00191 9.34951C7.25468 9.34951 7.45621 9.42295 7.60651 9.56983C7.7568 9.71329 7.83195 9.89432 7.83195 10.1129C7.83195 10.3247 7.7568 10.504 7.60651 10.6509C7.45621 10.7978 7.25468 10.8712 7.00191 10.8712C6.75256 10.8712 6.55103 10.7978 6.39732 10.6509C6.24702 10.504 6.17188 10.3247 6.17188 10.1129Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4025_14899">
          <rect
            width="13.9911"
            height="13.9911"
            fill="white"
            transform="translate(0.00878906 0.00445557)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorIcon;
