import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 65px;
  padding: 10px 20px;
  background: ${({ background }) => background};
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.18);
    transition: 0.2s;
  }
`;

const LinkLessonComponent = ({ componentData, inComponent }) => {
  const linkUrl = inComponent === "preview_page" ? componentData.link_url : "#";

  return (
    <Link to={linkUrl}>
      <LinkPreviewContainer background={componentData.background}>
        {componentData.link_text}
      </LinkPreviewContainer>
    </Link>
  );
};

export default memo(LinkLessonComponent);
