import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { highlightWords } from "../../../utils/functions/bible_page_functions";

const VerseContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 12px;
  border-radius: 5px;
  margin-right: 8px;
  background: ${({ isSelected }) => (isSelected ? "#cacaca" : "none")};

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? "#cacaca" : "rgb(223 223 223)"};
    transition: 0.2s;
  }
`;

const VerseTextContainer = styled.div`
  flex: 1;
  height: 100%;
`;

const VerseText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const VerseNumber = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const BibleVerse = ({ verse, selectedVerses, inComponent, searchWords }) => {
  const verseRef = useRef(null);
  const [verseIsSelected, setVerseIsSelected] = useState(false);

  useEffect(() => {
    if (inComponent !== "bible_search") {
      const isSelectedVerse = selectedVerses
        ?.split(",")
        ?.some((num) => +num === verse.verse_num);

      isSelectedVerse && setVerseIsSelected(true);
    }

    if (inComponent === "bible_search" && verse.verse_num === selectedVerses) {
      setVerseIsSelected(true);
    }
  }, [selectedVerses, verse, inComponent]);

  useEffect(() => {
    if (verseIsSelected && verseRef.current) {
      verseRef.current.scrollIntoView({ block: "center" });
    }
  }, [verseIsSelected]);

  const renderedVerseText = useMemo(() => {
    return inComponent === "bible_search"
      ? highlightWords(verse.verse_text, searchWords)
      : verse.verse_text;
  }, [verse.verse_text, searchWords, inComponent]);

  return (
    <VerseContainer
      ref={verseRef}
      isSelected={verseIsSelected && inComponent !== "bible_search"}
    >
      <VerseNumber>{verse.verse_num}.</VerseNumber>
      <VerseTextContainer>
        <VerseText>{renderedVerseText}</VerseText>
      </VerseTextContainer>
    </VerseContainer>
  );
};

export default memo(BibleVerse);
