import React, { memo } from "react";
import styled from "styled-components";
import { UserInfoSectionContainer } from "..";
import CourseActivity from "./course_activity";

const UserActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const UserActivitySection = ({ selectedUserInfo }) => {
  return (
    <UserInfoSectionContainer>
      Активность (БЛОК НЕ ДОДЕЛАН)
      <UserActivityList>
        <CourseActivity />
      </UserActivityList>
    </UserInfoSectionContainer>
  );
};

export default memo(UserActivitySection);
