import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useBibleBookContext } from "./bible_book_context";
import VersesLoader from "./verses_loader";
import Verse from "./verse";
import VerticalLine from "../../../common/assets/icons/vertical_line";
import { BASE_API_URL } from "../../../common/endpoints";
import { BIBLE_BOOK_NAMES_DATA } from "../bible_page_data";
import StrongsCodeComponent from "./strongs_code_component";
import { createPortal } from "react-dom";
import BibleStrongModalWindow from "./bible_strong_modal_window";
import { useAppContext } from "../../../app_context";

const VersesWrapper = styled.div`
  padding: 12px 0 0 12px;
  flex: 1;
  color: #1e1e1e;
  border-radius: 15px;
  border: 2px solid #fff;
  height: calc(100vh - 80px - 60px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 100%;
  }
`;

const VersesContainer = styled.div`
  flex: 1;
  height: calc(100vh - 80px - 116px);
  padding-bottom: 15px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    height: calc(100% - 40px);
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const VersesHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BibleChapterAddressContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  margin-bottom: 16px;
  min-width: 215px;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const StrongButton = styled.div`
  display: flex;
  align-items: center;
  color: #1e1e1e;
  font-size: 12px;
  background: #fff;
  padding: 0 13px;
  margin-right: 12px;
  height: 24px;
  border-radius: 15px;
  cursor: pointer;

  &: hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    transition: 0.2s;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  display: flex;
  align-items: center;
  height: 13px;
`;

const VerseError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const transformTextWithStrongCode = (text) => {
  const cleanedText = text
    .replace(/<J>/g, "")
    .replace(/<\/J>/g, "")
    .replace(/<R>/g, "")
    .replace(/<\/R>/g, "")
    .replace(/<br\/?>/g, "")
    .replace(/<n>.*?<\/n>/g, "");

  const parts = cleanedText.split(/(<S>\d+<\/S>)/g);

  return parts.map((part, index) => {
    if (/<S>(\d+)<\/S>/.test(part)) {
      const code = part.replace(/<\/?S>/g, "");
      return <StrongsCodeComponent key={index}>{code}</StrongsCodeComponent>;
    }

    return <span key={index}>{part}</span>;
  });
};

const Verses = ({
  isFirstTranslation,
  selectedVerses,
  setSelectedVerses,
  currentTranslation,
}) => {
  const [searchParams] = useSearchParams();
  const versesRef = useRef(null);
  const { bibleFontSettings } = useAppContext();
  const {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  } = useBibleBookContext();

  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [versesError, setVersesError] = useState(null);

  const bibleNameData =
    BIBLE_BOOK_NAMES_DATA[currentTranslation?.bible_locale]?.[
      chapterData?.[0]?.book_code
    ];

  useEffect(() => {
    if (chapterData) {
      setCurrentTestament(chapterData?.[0]?.book_place);
    }
  }, [chapterData]);

  const currentBibleName = bibleNameData?.secondName
    ? bibleNameData.secondName
    : bibleNameData?.name;

  const bibleId = searchParams.get(isFirstTranslation ? "bibleId" : "2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");
  const selectVerses = searchParams.get("selectVerses");
  const selectBible = searchParams.get("selectBible");

  useEffect(() => {
    if (selectVerses && selectBible) {
      const selectedVersesNums = selectVerses.split(",").map(Number).sort();
      const selectedVersesObjs = chapterData.filter((verse) =>
        selectedVersesNums.includes(verse.verse_num)
      );
      if (chapterData?.[0]?.bible_code === selectBible) {
        setSelectedVerses({ bible: selectBible, verses: selectedVersesObjs });
      }
    } else {
      setSelectedVerses({});
    }
  }, [selectBible, selectVerses, chapterData]);

  useEffect(() => {
    if (versesRef.current) {
      versesRef.current.scrollTop = scrollTop;
    }
  }, [scrollTop]);

  const onScroll = useCallback(() => {
    if (versesRef.current) {
      handleScroll(versesRef.current.scrollTop);
    }
  }, [versesRef.current]);

  const fetchChapterData = async (bibleId, bookId, chapterId) => {
    try {
      const response = await fetch(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const json = await response.json();

      if (json.verses) {
        const parsedChapter = json.verses.map((verse) => ({
          ...verse,
          verse_text: transformTextWithStrongCode(verse.verse_text),
        }));
        return { data: parsedChapter, error: null };
      } else {
        return {
          data: [],
          error: "Данной книги/стиха нет в выбранном переводе",
        };
      }
    } catch (e) {
      console.log(e);
      return { data: [], error: "Произошла ошибка при загрузке данных" };
    }
  };

  useEffect(() => {
    if (bibleId && chapterId && bookId) {
      setIsLoading(true);
      fetchChapterData(bibleId, bookId, chapterId)
        .then(({ data, error }) => {
          setChapterData(data);
          setVersesError(error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [bibleId, chapterId, bookId]);

  const handleShowStrongsCodes = useCallback((event) => {
    event.stopPropagation();
    setShowStrongsCode((prevState) => !prevState);
  }, []);

  return (
    <VersesWrapper>
      <VersesHeaderContainer>
        {!!chapterData && (
          <BibleChapterAddressContainer>
            <BibleTranslationVersion>
              {bibleId?.toUpperCase()}
            </BibleTranslationVersion>
            <VerticalLineStyled />
            <BibleChapterAddress>
              {currentBibleName || chapterData[0]?.book_code_orig}{" "}
              {chapterData[0]?.chapter_num}
            </BibleChapterAddress>
          </BibleChapterAddressContainer>
        )}

        {currentTranslation?.bible_strong && (
          <StrongButton onClick={handleShowStrongsCodes}>Стронг</StrongButton>
        )}
      </VersesHeaderContainer>

      <VersesContainer
        ref={versesRef}
        size={bibleFontSettings?.fontSize}
        font={bibleFontSettings?.fontVariant}
        onScroll={onScroll}
      >
        {isLoading ? (
          <VersesLoader bibleFontSize={bibleFontSettings?.fontSize} />
        ) : (
          <div>
            <VerseError>{versesError}</VerseError>

            {chapterData?.map((verse, index) => (
              <Verse
                key={verse._id}
                verseIndex={index}
                verse={verse}
                versesRef={versesRef}
                bibleFontSettings={bibleFontSettings}
                selectedVerses={selectedVerses}
                blockHeight={mergedHeights[index]}
                setVerseHeights={
                  isFirstTranslation
                    ? setVerseHeightsFirstTransl
                    : setVerseHeightsSecondTransl
                }
                isFirstTranslation={isFirstTranslation}
                verseHeightsFirstTransl={verseHeightsFirstTransl}
                verseHeightsSecondTransl={verseHeightsSecondTransl}
              />
            ))}
          </div>
        )}
      </VersesContainer>

      {!!strongsCodeModalData &&
        currentTranslation?.bible_strong &&
        createPortal(
          <BibleStrongModalWindow
            strongsCodeModalData={strongsCodeModalData}
            currentTranslation={currentTranslation}
            currentTestament={currentTestament}
            setOpen={setStrongsCodeModalData}
            setShowStrongsCode={setShowStrongsCode}
          />,
          document.body
        )}
    </VersesWrapper>
  );
};

export default memo(Verses);
