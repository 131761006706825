import React from "react";
import styled from "styled-components";
import VerticalLine from "../../../../../assets/icons/vertical_line";

const TranslationHeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 30px;
  cursor: pointer;
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 14px;
  height: 100%;
  flex: 1;
  transition: 0.1s;
  overflow: hidden;
  cursor: pointer;
`;

const SelectedTranslationAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
  letter-spacing: 0.4px;
`;

const SelectedTranslationText = styled.div`
  color: #828282;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.2px;
`;

const TranslationPanelHeader = ({ currentTranslation }) => {
  return (
    <TranslationHeaderContainer>
      <HeaderInfoContainer>
        {currentTranslation && (
          <SelectedTranslationAbbreviation
            isActive={currentTranslation?.abbreviation}
          >
            {currentTranslation.bible_code.toUpperCase()}
          </SelectedTranslationAbbreviation>
        )}

        <VerticalLine height={"15px"} />

        <SelectedTranslationText>
          {currentTranslation?.bible_name || "Выберите перевод"}
        </SelectedTranslationText>
      </HeaderInfoContainer>
    </TranslationHeaderContainer>
  );
};

export default TranslationPanelHeader;
