import React from "react";

const PlusBlockDashedLineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="2"
      viewBox="0 0 630 2"
      fill="none"
    >
      <path
        d="M1 1H629"
        stroke="url(#paint0_linear_4324_5327)"
        strokeLinecap="round"
        strokeDasharray="10 10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4324_5327"
          x1="299.822"
          y1="0.816176"
          x2="299.822"
          y2="2.08824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusBlockDashedLineIcon;
