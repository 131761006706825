import React, { createContext, useContext, useEffect, useState } from "react";
import { BIBLE_DEFAULT_FONT } from "./pages/bible/bible_page_data";

const AppContext = createContext();

export const useAppState = () => {
  const [showAppHeader, setShowAppHeader] = useState(true);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [ForwardMessageChatId, setChatIdForForwardMessage] = useState(null);
  const [forwardMessage, setForwardMessage] = useState({});
  const [bibleFontSettings, setBibleFontSettings] = useState(
    JSON.parse(localStorage.getItem("bibleFontSettings")) || BIBLE_DEFAULT_FONT
  );

  useEffect(() => {
    localStorage.setItem(
      "bibleFontSettings",
      JSON.stringify(bibleFontSettings)
    );
  }, [bibleFontSettings]);

  return {
    showAppHeader,
    setShowAppHeader,
    selectedChatId,
    setSelectedChatId,
    forwardMessage,
    setForwardMessage,
    ForwardMessageChatId,
    setChatIdForForwardMessage,
    bibleFontSettings,
    setBibleFontSettings,
  };
};

export const AppProvider = ({ children }) => {
  const {
    showAppHeader,
    setShowAppHeader,
    selectedChatId,
    setSelectedChatId,
    forwardMessage,
    setForwardMessage,
    ForwardMessageChatId,
    setChatIdForForwardMessage,
    bibleFontSettings,
    setBibleFontSettings,
  } = useAppState();

  return (
    <AppContext.Provider
      value={{
        showAppHeader,
        setShowAppHeader,
        selectedChatId,
        setSelectedChatId,
        forwardMessage,
        setForwardMessage,
        ForwardMessageChatId,
        setChatIdForForwardMessage,
        bibleFontSettings,
        setBibleFontSettings,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
