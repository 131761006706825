import React, { memo } from "react";
import styled from "styled-components";
import AnswerTypeIsButtons from "./answer_type_buttons";
import QuestionSettingsOption from "./question_settings_option";

const QuestionSettingsAnswerContainer = styled.div``;

const QuestionSettingsOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuestionSettingsAnswer = ({
  settingsBlockData,
  setSettingsBlockData,
}) => {
  return (
    <QuestionSettingsAnswerContainer>
      <AnswerTypeIsButtons
        settingsBlockData={settingsBlockData}
        setSettingsBlockData={setSettingsBlockData}
      />

      {settingsBlockData.answer_type === "options" && (
        <QuestionSettingsOptionsContainer>
          {settingsBlockData.data?.answer_options?.map(
            (option, index, array) => {
              const isLastElement = index === array.length - 1;

              return (
                <QuestionSettingsOption
                  key={"option-" + (index + 1)}
                  optionIndex={index}
                  optionData={option}
                  isLastElement={isLastElement}
                  setSettingsBlockData={setSettingsBlockData}
                />
              );
            }
          )}
        </QuestionSettingsOptionsContainer>
      )}
    </QuestionSettingsAnswerContainer>
  );
};

export default memo(QuestionSettingsAnswer);
