import React from "react";

const DeleteIcon = ({ className }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_5129_17507)">
        <path
          d="M24.0726 24.0711C27.9778 20.1658 27.9778 13.8342 24.0726 9.92893C20.1673 6.02369 13.8357 6.02369 9.93044 9.92893C6.0252 13.8342 6.0252 20.1658 9.93044 24.0711C13.8357 27.9763 20.1673 27.9763 24.0726 24.0711Z"
          stroke="#F12B2B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0039 13.9978L20.0039 19.9978"
          stroke="#F12B2B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0039 19.9978L20.0039 13.9978"
          stroke="#F12B2B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5129_17507">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.03125 17) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
