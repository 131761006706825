import React, { memo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ShareIcon from "../../../common/assets/icons/share_icon";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import LeftArrowMobileIcon from "../../../common/assets/icons/left_arrow_mobile_icon";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import CourseInfoAuthor from "../study_page_courses/course_card/course_author";

const CoursePageHeaderContainer = styled.div`
  max-width: 1100px;
  height: 240px;
  margin: 0 auto;
  border-radius: 0px 0px 25px 25px;
  background-image: url(${({ headerBackground }) => headerBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 542px) {
    width: 100%;
    border-radius: 0px;
  }

  @media (max-width: 412px) {
    max-width: 412px;
    width: 100%;
    height: 287px;
  }
`;

const CoursePageHeaderShadowWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 25px;
  border-radius: 0px 0px 25px 25px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.67) 0%,
    rgba(0, 0, 0, 0.16) 75.44%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 590px) {
    padding-top: 50px;
  }

  @media (max-width: 542px) {
    border-radius: 0px;
  }

  @media (max-width: 412px) {
    padding: 100px 0 0 30px;
  }
`;

const CoursePageHeaderContent = styled.div`
  position: relative;
  width: 510px;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 514px) {
    width: 100%;
  }
`;

const CourseAuthorsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ShareIconStyles = styled(ShareIcon)`
  position: absolute;
  top: 7px;
  right: 0px;
  width: 24px;
  height: 22px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 590px) {
    top: -34px;
    right: 18px;
  }

  @media (max-width: 530px) {
    right: 28px;
  }

  @media (max-width: 412px) {
    top: -37px;
  }
`;

const ArrowsContainer = styled.div`
  position: absolute;
  top: 16px;
  left: -60px;
  cursor: pointer;
  padding: 6px 5px;

  &:hover {
    transform: scale(1.2);
    transition: 0.2s;
  }

  @media (max-width: 665px) {
    left: -45px;
  }

  @media (max-width: 620px) {
    left: -25px;
  }

  @media (max-width: 590px) {
    top: -35px;
    left: 13px;
    padding: 3px;
  }

  @media (max-width: 514px) {
    left: 17px;
  }

  @media (max-width: 412px) {
    top: -39px;
    left: -3px;
  }
`;

const LeftArrowStyles = styled(LeftArrowIcon)`
  @media (max-width: 590px) {
    display: none;
  }
`;

const LeftArrowMobileStyles = styled(LeftArrowMobileIcon)`
  display: none;

  @media (max-width: 590px) {
    display: block;
  }
`;

const CoursePageHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 92%;
  height: 100%;
  padding-left: 20px;
  color: #ffffff;

  @media (max-width: 570px) {
    width: 97%;
  }

  @media (max-width: 412px) {
    padding-left: 0;
  }
`;

const CourseTopic = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 590px) {
    font-size: 22px;
    line-height: 120%;
  }

  @media (max-width: 412px) {
    font-size: 20px;
  }
`;

const CourseDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;

  @media (max-width: 590px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.1px;
  }

  @media (max-width: 412px) {
    font-size: 12px;
  }
`;

const StartButton = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: max-content;
  padding: 0 22px;
  background: #f8d254;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: "Raleway";

  @media (max-width: 590px) {
    min-width: 120px;
    height: 33px;
    padding: 0 44px;
    line-height: 140%;
  }
`;

const CoursePageHeader = ({ courseData, inComponent }) => {
  const onArrowUrl =
    inComponent === "admin_page"
      ? APPLICATION_ROUTES.ADMIN_COURSES + "?courseId=" + courseData._id
      : APPLICATION_ROUTES.STUDY_COURSES;

  return (
    <CoursePageHeaderContainer
      headerBackground={courseData.course_header_image}
    >
      <CoursePageHeaderShadowWrapper>
        <CoursePageHeaderContent>
          <Link to={onArrowUrl}>
            <ArrowsContainer>
              <LeftArrowStyles />
              <LeftArrowMobileStyles />
            </ArrowsContainer>
          </Link>
          <ShareIconStyles />

          <CoursePageHeaderInfo>
            <CourseTopic>{courseData.course_title}</CourseTopic>
            <CourseDescription>
              {courseData.course_description}
            </CourseDescription>

            <CourseAuthorsContainer>
              {courseData?.course_authors?.map((author, index) => {
                return (
                  <CourseInfoAuthor
                    key={"course_author-" + index}
                    authorId={author._id}
                  />
                );
              })}
            </CourseAuthorsContainer>

            {inComponent === "admin_page" && <StartButton>Начать</StartButton>}

            {inComponent !== "admin_page" && (
              <>
                {courseData.course_status === "open" && (
                  <StartButton>Начать</StartButton>
                )}

                {courseData.course_status === "announcement" && (
                  <StartButton>Уведомить</StartButton>
                )}
              </>
            )}
          </CoursePageHeaderInfo>
        </CoursePageHeaderContent>
      </CoursePageHeaderShadowWrapper>
    </CoursePageHeaderContainer>
  );
};

export default memo(CoursePageHeader);
