import React from "react";

const DownArrowIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <rect
        y="0.5"
        width="24"
        height="24"
        rx="12"
        fill="url(#paint0_linear_3970_11047)"
        fillOpacity="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96062 9.76884C6.31176 9.41039 6.88106 9.41039 7.2322 9.76884L11.9913 14.6271L16.7504 9.76884C17.1015 9.41039 17.6708 9.41039 18.0219 9.76884C18.3731 10.1273 18.3731 10.7085 18.0219 11.0669L12.6271 16.5742C12.2759 16.9326 11.7066 16.9326 11.3555 16.5742L5.96062 11.0669C5.60948 10.7085 5.60948 10.1273 5.96062 9.76884Z"
        fill="#383838"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3970_11047"
          x1="11.4199"
          y1="-3.91176"
          x2="11.4199"
          y2="26.6176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DownArrowIcon;
