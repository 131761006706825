import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../common/components/checkbox";
import AskQuestionArrowIcon from "../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import CoursesAuthor from "./courses_author";
import GroupsAuthor from "./groups_author";
import { useUserDataContext } from "../../../../../user_data_context";
import {
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../../admin_page_components/admin_page_styled_components";

const UserPublicityWrapper = styled.div`
  color: #828282;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
`;

const UserPublicityContainer = styled.div`
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const AuthorStatusInputContainer = styled.div`
  padding: 6px 10px;
`;

const AuthorStatusInput = styled.input`
  height: 40px;
  width: 100%;
  padding: 0 15px;
  border-radius: 8px;
  background: #eaeaea;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
`;

const UserPublicity = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
  setOpenAddingCourseModal,
}) => {
  const { userData } = useUserDataContext();
  const [extendedUserIsAuthor, setExtendedUserIsAuthor] = useState(false);

  const isSuperAdmin = userData.user_role === "superadmin";

  const isInvite = userRolesSettingsData.user_type === "invite";
  const userIsAuthor = userRolesSettingsData.user_access.is_author;
  const authorStatus = userRolesSettingsData.user_publicity.author_info;
  const coursesAuthorsList = userRolesSettingsData.user_publicity.courses;
  const groupsAuthorsList = userRolesSettingsData.user_publicity.groups;

  useEffect(() => {
    if (!userIsAuthor) {
      setExtendedUserIsAuthor(false);
    }
  }, [userIsAuthor]);

  const handleUserIsAuthor = useCallback(
    (event) => {
      event.stopPropagation();
      if (isSuperAdmin) {
        setUserRolesSettingsData((prevState) => {
          return {
            ...prevState,
            user_access: {
              ...prevState.user_access,
              is_author: !prevState.user_access.is_author,
            },
            ...(prevState.user_access.is_author && {
              user_publicity: {
                ...prevState.user_publicity,
                courses: [],
                groups: [],
              },
            }),
          };
        });

        setUsersList((prevState) =>
          prevState.map((user) =>
            user._id === userRolesSettingsData._id || user._id === "new_invite"
              ? {
                  ...user,
                  user_access: {
                    ...user.user_access,
                    is_author: !user.user_access.is_author,
                  },
                }
              : user
          )
        );
      }
    },
    [userRolesSettingsData, isSuperAdmin]
  );

  const handleExtendUserIsAuthor = useCallback(() => {
    if (userIsAuthor && isSuperAdmin) {
      setExtendedUserIsAuthor((prevState) => !prevState);
    }
  }, [userIsAuthor, isSuperAdmin]);

  const handleAuthorStatusInput = useCallback((event) => {
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_publicity: {
        ...prevState.user_publicity,
        author_info: event.target.value,
      },
    }));
  }, []);

  return (
    <UserPublicityWrapper>
      Публичность
      <UserPublicityContainer>
        <AdminPageSettingsHeader
          clickable={userIsAuthor && isSuperAdmin && !isInvite}
          onClick={handleExtendUserIsAuthor}
        >
          <Checkbox
            checked={userIsAuthor}
            onClickCheckbox={handleUserIsAuthor}
            disabled={!isSuperAdmin}
            color={"#1e1e1e"}
            fontSize={"14px"}
          >
            Автор
          </Checkbox>

          {userIsAuthor && isSuperAdmin && !isInvite && (
            <AdminPageSettingsHeaderArrow
              extendedSection={extendedUserIsAuthor}
            >
              <AskQuestionArrowIcon />
            </AdminPageSettingsHeaderArrow>
          )}
        </AdminPageSettingsHeader>

        {extendedUserIsAuthor && !isInvite && (
          <div>
            <AuthorStatusInputContainer>
              <AuthorStatusInput
                type="text"
                placeholder="Статус"
                value={authorStatus}
                onChange={handleAuthorStatusInput}
              />
            </AuthorStatusInputContainer>

            <CoursesAuthor
              userRolesSettingsData={userRolesSettingsData}
              coursesAuthorsList={coursesAuthorsList}
              setOpenAddingCourseModal={setOpenAddingCourseModal}
              setUserRolesSettingsData={setUserRolesSettingsData}
            />
            <GroupsAuthor groupsAuthorsList={groupsAuthorsList} />
          </div>
        )}
      </UserPublicityContainer>
    </UserPublicityWrapper>
  );
};

export default memo(UserPublicity);
