import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import FramedBibleVerse from "../../../common/components/framed_bible_verse";
import SpinnerLoader from "../../../common/components/loaders/spinner_loader";
import { BIBLE_TRANSLATIONS_LANGUAGES } from "../bible_page_data";
import BackToBibleChapterButton from "../back_to_bible_chapter_button";

const BibleCompareVersesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  padding: 20px 0 20px 20px;
  max-height: calc(100vh - 80px - 58px);
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
`;

const BibleCompareVersesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  padding-bottom: 15px;
  padding-right: 17px;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

const BibleVerseVariantContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  color: gray;
`;

const BibleVerseVariantName = styled.div`
  color: #828282;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.1px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const BibleCompareVerses = ({
  comparedTranslationsData,
  isLoading,
  setActiveMainSection,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onBibleCompareAddress = useCallback((bible_code) => {
    setSearchParams((prev) => {
      prev.set("bibleId", bible_code);
      prev.set("selectBible", bible_code);
      return prev;
    });
    setActiveMainSection("bible_chapter");
  }, []);

  return (
    <BibleCompareVersesWrapper>
      <BackToBibleChapterButton setActiveMainSection={setActiveMainSection} />

      <BibleCompareVersesContainer>
        {isLoading ? (
          <LoaderContainer>
            <SpinnerLoader />
          </LoaderContainer>
        ) : (
          <>
            {comparedTranslationsData.map((variant, index) => {
              return (
                <BibleVerseVariantContainer
                  key={variant.verses?.verseId || variant.bible_code + index}
                >
                  <BibleVerseVariantName>
                    {variant.bible_name} (
                    {BIBLE_TRANSLATIONS_LANGUAGES[variant.lang].name})
                  </BibleVerseVariantName>

                  <FramedBibleVerse
                    verse={variant}
                    background={"#eaeaea"}
                    inComponent={"bible_compare"}
                    onBibleCompareAddress={onBibleCompareAddress}
                  />
                </BibleVerseVariantContainer>
              );
            })}{" "}
          </>
        )}
      </BibleCompareVersesContainer>
    </BibleCompareVersesWrapper>
  );
};

export default BibleCompareVerses;
