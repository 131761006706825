import React, { memo, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import LanguageSelect from "./language_select";
import TranslationSelect from "./translation_select";
import TranslationHeader from "./bible_translations_header";
import { BASE_API_URL } from "../../../../common/endpoints";
import { BIBLE_TRANSLATIONS_LANGUAGES } from "../../bible_page_data";

const BibleTranslationsPanelContainer = styled.div`
  max-width: 236px;
`;

const TranslationContainer = styled.section`
  max-width: 236px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const BibleTranslationsPanel = ({
  activeMainSection,
  bibleTranslations,
  setBibleTranslations,
  currentTranslation,
  setCurrentTranslation,
  toggleOpenBibleTranslationsPanel,
  isExpanded,
  isSecond,
  setSecondTranslateIsEnabled,
  setExpandedSectionName,
}) => {
  const translationPanelRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [isBiblesLoading, setBiblesLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("translations"); //translations, languages
  const [currentBibleLanguage, setCurrentBibleLanguage] = useState(null);

  const bibleId = searchParams.get(isSecond ? "2bibleId" : "bibleId");
  const translationsLanguages = Object.values(BIBLE_TRANSLATIONS_LANGUAGES);

  useEffect(() => {
    if (
      bibleId &&
      bibleTranslations &&
      currentTranslation?.bible_code !== bibleId
    ) {
      const translation = bibleTranslations.find(
        (transl) => transl.bible_code === bibleId
      );

      setCurrentTranslation(translation);
    }
  }, [bibleTranslations, bibleId, currentTranslation]);

  useEffect(() => {
    if (currentTranslation) {
      const language =
        BIBLE_TRANSLATIONS_LANGUAGES[currentTranslation.bible_locale];
      setCurrentBibleLanguage(language);
    }
  }, [currentTranslation]);

  useEffect(() => {
    setBiblesLoading(true);
    fetch(`${BASE_API_URL}/bible/transl`)
      .then((resp) => resp.json())
      .then((translations) => {
        setBibleTranslations(
          translations.bibles.map((transl) => {
            return { ...transl, isFavorite: false };
          })
        );
      })
      .finally(() => setBiblesLoading(false));
  }, []);

  return (
    <BibleTranslationsPanelContainer ref={translationPanelRef}>
      <TranslationContainer>
        <TranslationHeader
          currentTranslation={currentTranslation}
          isLoading={isBiblesLoading}
          activeMainSection={activeMainSection}
          toggleOpenBibleTranslationsPanel={toggleOpenBibleTranslationsPanel}
          isSecondTranslation={isSecond}
          setSecondTranslateIsEnabled={setSecondTranslateIsEnabled}
          setExpandedSectionName={setExpandedSectionName}
        />

        {isExpanded && (
          <>
            {currentScreen === "translations" && (
              <TranslationSelect
                setCurrentTranslation={setCurrentTranslation}
                bibleTranslations={bibleTranslations}
                setBibleTranslations={setBibleTranslations}
                translationsLanguages={translationsLanguages}
                setCurrentScreen={setCurrentScreen}
                currentBibleLanguage={currentBibleLanguage}
                currentTranslation={currentTranslation}
                isSecond={isSecond}
              />
            )}

            {currentScreen === "languages" && (
              <LanguageSelect
                translationsLanguages={translationsLanguages}
                setCurrentScreen={setCurrentScreen}
                currentBibleLanguage={currentBibleLanguage}
                setCurrentBibleLanguage={setCurrentBibleLanguage}
              />
            )}
          </>
        )}
      </TranslationContainer>
    </BibleTranslationsPanelContainer>
  );
};

export default memo(BibleTranslationsPanel);
