import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../../../common/components/checkbox";
import CreateLessonSaveButton from "../../create_lesson_components/create_lesson_save_button";
import { SettingsBlockFixedWrapper } from "../../create_lesson_components/create_lesson_styled_components";
import { createComponentId } from "../../";

const NoteAndQuestionSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 219px;
  gap: 30px;
  padding: 26px 24px;
`;

const NoteInput = styled.textarea`
  resize: none;
  max-height: 268px;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const QuestionInput = styled(NoteInput)`
  max-height: 80px;
`;

const DEFAULT_SETTINGS_DATA_NOTE_AND_QUESTION = {
  type: "NOTE_AND_QUESTION",
  note: "",
  question: "",
  with_question: false,
  id: "",
};

const NoteAndQuestionSettings = ({
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const noteRef = useRef(null);
  const questionRef = useRef(null);

  useEffect(() => {
    if (editBlockIndex === null) {
      const defaultNoteAndQuestionData = {
        ...DEFAULT_SETTINGS_DATA_NOTE_AND_QUESTION,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultNoteAndQuestionData);
      setSettingsBlockMemoData(defaultNoteAndQuestionData);
    }
  }, [editBlockIndex]);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();
    setSettingsBlockData((prevState) => ({
      ...prevState,
      with_question: !prevState.with_question,
    }));
  }, []);

  const updateSettingsData = useCallback((key, value) => {
    setSettingsBlockData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  useEffect(() => {
    if (!settingsBlockData.with_question) {
      updateSettingsData("question", "");
    }
  }, [settingsBlockData.with_question]);

  useEffect(() => {
    if (noteRef.current) {
      noteRef.current.style.height = "auto";
      noteRef.current.style.height = noteRef.current.scrollHeight + "px";
    }
  }, [settingsBlockData.note, noteRef]);

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.style.height = "auto";
      questionRef.current.style.height =
        questionRef.current.scrollHeight + "px";
    }
  }, [settingsBlockData.question, questionRef]);

  useEffect(() => {
    if (settingsBlockData?.note) {
      if (settingsBlockData.with_question) {
        if (settingsBlockData.question && settingsBlockData.note) {
          setDisabledSaveButton(false);
        } else {
          setDisabledSaveButton(true);
        }
      } else {
        setDisabledSaveButton(false);
      }
    } else {
      setDisabledSaveButton(true);
    }
  }, [settingsBlockData]);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <NoteAndQuestionSettingsContainer>
        <Checkbox
          checked={settingsBlockData.with_question}
          onClickCheckbox={onClickCheckbox}
        >
          Вопрос
        </Checkbox>

        {settingsBlockData?.with_question && (
          <QuestionInput
            placeholder="Напишите вопрос..."
            ref={questionRef}
            rows={1}
            type="text"
            value={settingsBlockData.question}
            onChange={(e) => updateSettingsData("question", e.target.value)}
          />
        )}

        <NoteInput
          placeholder="Напишите заметку..."
          ref={noteRef}
          rows={1}
          type="text"
          value={settingsBlockData.note}
          onChange={(e) => updateSettingsData("note", e.target.value)}
        />

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </NoteAndQuestionSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(NoteAndQuestionSettings);
