import React from "react";

const LeftArrowMobileIcon = ({ className, color = "#fff" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_820_11065)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3039 7.47036C13.5863 7.747 13.5863 8.19552 13.3039 8.47216L9.47644 12.2216L13.3039 15.9709C13.5863 16.2476 13.5863 16.6961 13.3039 16.9728C13.0215 17.2494 12.5637 17.2494 12.2813 16.9728L7.94243 12.7225C7.66002 12.4458 7.66002 11.9973 7.94243 11.7207L12.2813 7.47036C12.5637 7.19372 13.0215 7.19372 13.3039 7.47036Z"
          fill={color}
        />
      </g>
      <rect
        x="0.69697"
        y="1.19697"
        width="22.6061"
        height="22.6061"
        rx="11.303"
        stroke={color}
        strokeWidth="1.39394"
      />
      <defs>
        <clipPath id="clip0_820_11065">
          <rect
            width="9.91736"
            height="9.91736"
            fill={color}
            transform="translate(15.5819 7.2663) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftArrowMobileIcon;
