import React from "react";

const MinusTranslationIcon = () => (
  <div
    style={{
      padding: "10px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <svg
      width="15"
      height="2"
      viewBox="0 0 15 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.791748 1C0.791748 0.516751 1.1835 0.125 1.66675 0.125H13.3334C13.8167 0.125 14.2084 0.516751 14.2084 1C14.2084 1.48325 13.8167 1.875 13.3334 1.875H1.66675C1.1835 1.875 0.791748 1.48325 0.791748 1Z"
        fill="#383838"
      />
    </svg>
  </div>
);

export default MinusTranslationIcon;
