import React, { memo, useCallback } from "react";
import styled from "styled-components";
import TextEditor from "../../../../common/components/text_editor";

const CourseAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > h3 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }
`;

const CourseAbout = ({ courseSettingsData, setCourseSettingsData }) => {
  const onEditorChange = useCallback((updatedInputText) => {
    setCourseSettingsData((prevState) => ({
      ...prevState,
      about_course: updatedInputText,
    }));
  }, []);

  return (
    <CourseAboutContainer>
      <h3>Про курс:</h3>

      <TextEditor
        contentStateData={courseSettingsData.about_course}
        onEditorChange={onEditorChange}
        placeholder="О чём курс?"
        inComponent={"course_settings"}
        key={courseSettingsData._id}
      />
    </CourseAboutContainer>
  );
};

export default memo(CourseAbout);
