import { useMqtt } from "./useMqtt.js";
import "./mqtt.events";
import { API_EVENTS } from "./mqtt.types";
export * from "./useMqtt";

export async function testMqtt() {
  const mqtt = useMqtt();
  await mqtt.connect();

  await mqtt.subscribeOn(["user1", "group1"]);

  mqtt.sendToMqtt("user1", {
    eventName: API_EVENTS.EVENT_TEXT_MESSAGE,
    eventParams: {
      toUser: "",
      fromUser: "",
      text: "hello user",
    },
  });

  mqtt.sendToMqtt("group1", {
    eventName: API_EVENTS.EVENT_TEXT_MESSAGE,
    eventParams: {
      text: "hello group",
    },
  });
}
