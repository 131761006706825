import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";

const StaffListModalWindowButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  cursor: default;
  user-select: none;

  ${({ isDisableConfirmButton }) =>
    !isDisableConfirmButton &&
    `
  &:hover {
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
    width: 53%;
    cursor: pointer;
  }
`}
`;

const ConfirmButton = styled(ButtonStyles)`
  color: ${({ isHoverOnCancel, isDisableConfirmButton }) =>
    isHoverOnCancel || isDisableConfirmButton
      ? "rgba(255, 255, 255, 0.5)"
      : "#fff"};
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ activeButton }) =>
    activeButton ? "#fff" : "rgba(255, 255, 255, 0.5)"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 2px;
`;

const StaffListModalWindowButtons = ({
  handleClose,
  onClickConfirmButton,
  isDisableConfirmButton,
}) => {
  const [isHoverOnCancel, setIsHoverOnCancel] = useState(false);

  const toggleHoverOnConfirmButton = useCallback(() => {
    if (!isDisableConfirmButton) {
      setIsHoverOnCancel((prevState) => !prevState);
    }
  }, [isDisableConfirmButton]);

  return (
    <StaffListModalWindowButtonsContainer>
      <ConfirmButton
        isHoverOnCancel={isHoverOnCancel}
        isDisableConfirmButton={isDisableConfirmButton}
        onClick={onClickConfirmButton}
      >
        Сохранить
      </ConfirmButton>
      <VerticalLineStyled />
      <CancelButton
        onMouseEnter={toggleHoverOnConfirmButton}
        onMouseLeave={toggleHoverOnConfirmButton}
        activeButton={isDisableConfirmButton}
        onClick={handleClose}
      >
        Отмена
      </CancelButton>
    </StaffListModalWindowButtonsContainer>
  );
};

export default memo(StaffListModalWindowButtons);
