import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const BookChapterSelect = styled.div`
  width: 30px;
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)"
      : "#D9D9D9"};

  ${({ isActive }) =>
    !isActive &&
    `
  &:hover {
    background: #bfbebe;
    transition: 0.2s;
  }`}
`;

const ChapterNavigationItem = ({
  book,
  chapterNum,
  currentBibleParams,
  setCurrentTranslationParams,
  setSelectedVerses,
}) => {
  const [selectedChapter, setSelectedChapter] = useState(false);

  useEffect(() => {
    const isSelected =
      chapterNum === currentBibleParams.chapterId &&
      book.book_code === currentBibleParams.bookId;
    setSelectedChapter(isSelected);
  }, [currentBibleParams]);

  const handleBibleParams = useCallback(
    (event) => {
      event.stopPropagation();
      if (chapterNum) {
        setCurrentTranslationParams((prevState) => ({
          ...prevState,
          chapterId: chapterNum,
          bookId: book.book_code,
        }));
        setSelectedVerses([]);
      }
    },
    [chapterNum, book]
  );

  return (
    <BookChapterSelect isActive={selectedChapter} onClick={handleBibleParams}>
      {chapterNum}
    </BookChapterSelect>
  );
};

export default memo(ChapterNavigationItem);
