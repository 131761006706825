import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";
import TextStyleButtons from "./text_editor_toolbar_buttons/text_style_buttons";
import TextBackgroundButtons from "./text_editor_toolbar_buttons/text_background_buttons";
import TextSizeButtons from "./text_editor_toolbar_buttons/text_size_buttons";
import TextListButtons from "./text_editor_toolbar_buttons/text_list_buttons";
import TextLinkButton from "./text_editor_toolbar_buttons/text_link_button";
import TextBibleButton from "./text_editor_toolbar_buttons/text_bible_button";
import BibleVerseItem from "./bible_verse_item";
import BibleModalWindow from "../../../common/components/modal_windows/bible_modal_window";
import {
  TextSettingsBibleProvider,
  useTextSettingsBibleContext,
} from "./text_editor_bible_context";
import { LESSON_COLORS_THEME } from "../../utils/enums";
import TextColorButtons from "./text_editor_toolbar_buttons/text_color_buttons";

const TextEditorWrapper = styled.div`
  background: #fff;
  border-radius: 14px;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
  border-bottom: 1px solid #cacaca;
`;

const StyledEditor = styled.div`
  position: relative;
  padding: 10px 0 10px 12px;
  cursor: text;

  .DraftEditor-root {
  }

  .public-DraftEditor-content {
    padding-right: 9px;

    min-height: 48px;
    ${({ inComponent }) =>
      inComponent === "text_component"
        ? "min-height: 200px;"
        : inComponent === "course_settings"
        ? "max-height: 330px;"
        : inComponent === "note"
        ? `
        min-height: 160px;
        max-height: 240px;
        `
        : "max-height: 160px;"}
    overflow: auto;
    font-size: 16px;
    line-height: 24px;

    ::-webkit-scrollbar {
      width: 3px;
    }
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 11px;
  left: 15px;
  color: #aaa;
  pointer-events: none;
  font-size: 16px;
  line-height: 24px;
  z-index: 2;
`;

const textBackgroundStyle = (background) => ({
  background,
  display: "inline-block",
  borderRadius: "6px",
  padding: "0 2px",
  ...(background === "#FFF" && {
    background: "rgb(231 231 231)",
  }),
});

const { GRADIENTS, COLORS } = LESSON_COLORS_THEME["LIGHT"];

export const styleMap = {
  NOTE: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  H2: {
    fontSize: "22px",
    lineHeight: "26px",
  },
  H1: {
    fontSize: "34px",
    lineHeight: "40px",
    letterSpacing: "-0.4px",
  },
  BG_FUCHSIA: textBackgroundStyle(GRADIENTS.FUCHSIA),
  BG_BLUE: textBackgroundStyle(GRADIENTS.BLUE),
  BG_AQUA: textBackgroundStyle(GRADIENTS.AQUA),
  BG_CYAN: textBackgroundStyle(GRADIENTS.CYAN),
  BG_MAGENTA: textBackgroundStyle(GRADIENTS.MAGENTA),
  BG_PURPLE: textBackgroundStyle(GRADIENTS.PURPLE),
  BG_VIOLET: textBackgroundStyle(GRADIENTS.VIOLET),
  BG_DEEP: textBackgroundStyle(GRADIENTS.DEEP),
  BG_ORANGE: textBackgroundStyle(GRADIENTS.ORANGE),
  BG_SKYLOR: textBackgroundStyle(GRADIENTS.SKYLOR),
  BG_FUSORA: textBackgroundStyle(GRADIENTS.FUSORA),
  BG_MINT: textBackgroundStyle(GRADIENTS.MINT),
  BG_GREEN: textBackgroundStyle(GRADIENTS.GREEN),
  BG_TEAL: textBackgroundStyle(GRADIENTS.TEAL),
  BG_SPACE: textBackgroundStyle(GRADIENTS.SPACE),
  BG_WHITE: textBackgroundStyle(GRADIENTS.WHITE),
  BG_BLACK: textBackgroundStyle(GRADIENTS.BLACK),
  TEXT_FUCHSIA: { color: COLORS.FUCHSIA },
  TEXT_BLUE: { color: COLORS.BLUE },
  TEXT_AQUA: { color: COLORS.AQUA },
  TEXT_CYAN: { color: COLORS.CYAN },
  TEXT_MAGENTA: { color: COLORS.MAGENTA },
  TEXT_PURPLE: { color: COLORS.PURPLE },
  TEXT_VIOLET: { color: COLORS.VIOLET },
  TEXT_DEEP: { color: COLORS.DEEP },
  TEXT_ORANGE: { color: COLORS.ORANGE },
  TEXT_SKYLOR: { color: COLORS.SKYLOR },
  TEXT_FUSORA: { color: COLORS.FUSORA },
  TEXT_MINT: { color: COLORS.MINT },
  TEXT_GREEN: { color: COLORS.GREEN },
  TEXT_TEAL: { color: COLORS.TEAL },
  TEXT_SPACE: { color: COLORS.SPACE },
  TEXT_WHITE: { color: COLORS.WHITE },
  TEXT_BLACK: { color: COLORS.BLACK },
  DEFAULT_SIZE: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  LINK: {
    color: "rgb(33 58 192)",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
  },
  BIBLE_VERSE: {
    border: "1px solid #2440D2",
    color: "#2440D2",
    fontSize: "16px",
    borderRadius: "4px",
    padding: "1px 7px",
    background: "#fff",
    userSelect: "none",
    cursor: "pointer",
  },
};

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styleMap.LINK}>
      {props.children}
    </a>
  );
};

const findEntityEntities =
  (entityType) => (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === entityType
      );
    }, callback);
  };

const decorator = new CompositeDecorator([
  {
    strategy: findEntityEntities("LINK"),
    component: Link,
  },
  {
    strategy: findEntityEntities("BIBLE_VERSE"),
    component: (props) => <BibleVerseItem {...props} />,
  },
]);

const textEditorHOC = (WrappedComponent) => (props) => {
  return (
    <TextSettingsBibleProvider>
      <WrappedComponent {...props} />
    </TextSettingsBibleProvider>
  );
};

const TextEditor = ({
  contentStateData,
  onEditorChange,
  placeholder = "",
  inComponent = "",
  withBackground = false,
  withoutToolbar = false,
}) => {
  const {
    openBibleModalWindow,
    setOpenBibleModalWindow,
    editedVersesData,
    setEditedVersesData,
  } = useTextSettingsBibleContext();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(decorator)
  );
  const [textLinkModal, setTextLinkModal] = useState(false);
  const [currentLink, setCurrentLink] = useState(null);
  const [openTextSettings, setOpenTextSettings] = useState(""); // text_size, text_style, text_list, text_background, text_color
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const currentStyle = editorState.getCurrentInlineStyle();

  const closeSettingsButtons = useCallback(() => {
    setOpenTextSettings("");
  }, [setOpenTextSettings]);

  useEffect(() => {
    const content = editorState.getCurrentContent().getPlainText();
    setShowPlaceholder(content.length === 0);
  }, [editorState]);

  useEffect(() => {
    if (contentStateData?.blocks) {
      try {
        const contentState = convertFromRaw(contentStateData);
        setEditorState(EditorState.createWithContent(contentState, decorator));
      } catch (error) {
        console.error("Invalid contentStateData format", error);
      }
    }
  }, []);

  useEffect(() => {
    const contentState = editorState.getCurrentContent();

    if (contentState) {
      const updatedContentState = convertToRaw(contentState);
      const hasChanges =
        JSON.stringify(contentStateData) !==
        JSON.stringify(updatedContentState);

      if (hasChanges) {
        onEditorChange(updatedContentState);
      }
    }
  }, [editorState]);

  const toggleInlineStyle = (style) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    let newEditorState = editorState;

    const backgroundStyles = Object.keys(
      LESSON_COLORS_THEME.LIGHT.GRADIENTS
    ).map((color) => `BG_${color}`);
    const textColorStyles = Object.keys(LESSON_COLORS_THEME.LIGHT.COLORS).map(
      (color) => `TEXT_${color}`
    );
    const textStyles = ["NOTE", "H2", "H1"];

    const applyToggleStyles = (stylesList) => {
      stylesList.forEach((item) => {
        if (item !== style && currentStyle.has(item)) {
          newEditorState = RichUtils.toggleInlineStyle(newEditorState, item);
        }
      });
    };

    if (backgroundStyles.includes(style)) {
      applyToggleStyles(backgroundStyles);
    }

    if (textColorStyles.includes(style)) {
      applyToggleStyles(textColorStyles);
    }

    if (textStyles.includes(style)) {
      applyToggleStyles(textStyles);
    }

    newEditorState = RichUtils.toggleInlineStyle(newEditorState, style);
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleAddBibleVerses = (addedVerse) => {
    const contentState = editorState.getCurrentContent();
    let newEditorState = editorState;

    let updatedContentState;
    if (editedVersesData.id) {
      const entityKey = editedVersesData.id;
      updatedContentState = contentState.replaceEntityData(
        entityKey,
        addedVerse
      );
    } else {
      const contentStateWithEntity = contentState.createEntity(
        "BIBLE_VERSE",
        "IMMUTABLE",
        addedVerse
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const selectionState = editorState.getSelection();

      updatedContentState = Modifier.insertText(
        contentStateWithEntity,
        selectionState,
        " ",
        null,
        entityKey
      );

      const updatedSelection = updatedContentState.getSelectionAfter();
      updatedContentState = Modifier.insertText(
        updatedContentState,
        updatedSelection,
        " "
      );
    }

    newEditorState = EditorState.push(
      newEditorState,
      updatedContentState,
      "insert-characters"
    );

    const newStateWithSelection = EditorState.forceSelection(
      newEditorState,
      updatedContentState.getSelectionAfter()
    );

    newEditorState = newStateWithSelection;

    setEditorState(newEditorState);

    const updatedContentRaw = convertToRaw(updatedContentState);

    onEditorChange(updatedContentRaw);

    setOpenBibleModalWindow(false);
    setEditedVersesData({});
  };

  return (
    <TextEditorWrapper>
      {!withoutToolbar && (
        <Toolbar onClick={closeSettingsButtons}>
          {inComponent !== "article_text" && (
            <TextSizeButtons
              editorState={editorState}
              currentStyle={currentStyle}
              setEditorState={setEditorState}
              toggleInlineStyle={toggleInlineStyle}
              openTextSettings={openTextSettings}
              setOpenTextSettings={setOpenTextSettings}
            />
          )}
          <TextStyleButtons
            currentStyle={currentStyle}
            toggleInlineStyle={toggleInlineStyle}
            openTextSettings={openTextSettings}
            setOpenTextSettings={setOpenTextSettings}
          />
          <TextListButtons
            editorState={editorState}
            setEditorState={setEditorState}
            openTextSettings={openTextSettings}
            setOpenTextSettings={setOpenTextSettings}
          />
          <TextBackgroundButtons
            currentStyle={currentStyle}
            toggleInlineStyle={toggleInlineStyle}
            openTextSettings={openTextSettings}
            setOpenTextSettings={setOpenTextSettings}
            withBackground={withBackground}
          />
          <TextColorButtons
            currentStyle={currentStyle}
            toggleInlineStyle={toggleInlineStyle}
            openTextSettings={openTextSettings}
            setOpenTextSettings={setOpenTextSettings}
            withBackground={withBackground}
          />
          <TextLinkButton
            textLinkModal={textLinkModal}
            editorState={editorState}
            currentLink={currentLink}
            setEditorState={setEditorState}
            setCurrentLink={setCurrentLink}
            setTextLinkModal={setTextLinkModal}
          />
          <TextBibleButton setOpenBibleModalWindow={setOpenBibleModalWindow} />
        </Toolbar>
      )}

      <StyledEditor onClick={closeSettingsButtons} inComponent={inComponent}>
        <Editor
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
        />
        {showPlaceholder && placeholder && (
          <Placeholder>{placeholder}</Placeholder>
        )}
      </StyledEditor>

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            handleAddBibleVerses={handleAddBibleVerses}
            editedVersesData={editedVersesData}
            setEditedVersesData={setEditedVersesData}
            inComponent={"text_editor"}
          />,
          document.body
        )}
    </TextEditorWrapper>
  );
};

export default textEditorHOC(TextEditor);
