import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import TranslationComparePanel from "./translation_compare_panel";

const TranslationComparePanelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 236px;
  max-width: 236px;
  height: 100%;
  padding-bottom: 30px;

  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

const TranslationComparePanels = ({
  bibleTranslations,
  comparedTranslationsList,
  selectedVerses,
  setIsLoading,
  setBibleTranslations,
  setComparedTranslationsList,
  setComparedTranslationsData,
}) => {
  const [currentOpenTranslationIndex, setCurrentOpenTranslationIndex] =
    useState(null);

  const handleOpenTranslationIndex = useCallback((event, panelIndex) => {
    event.stopPropagation();
    setCurrentOpenTranslationIndex((prevState) =>
      prevState === panelIndex ? null : panelIndex
    );
  }, []);

  const onClickEmptyPlace = useCallback((event) => {
    event.stopPropagation();
    setCurrentOpenTranslationIndex(null);
  }, []);

  return (
    <TranslationComparePanelsContainer onClick={onClickEmptyPlace}>
      {comparedTranslationsList.map((translation, index) => {
        return (
          <TranslationComparePanel
            key={index + translation.bible_code}
            panelIndex={index}
            translation={translation}
            selectedVerses={selectedVerses}
            bibleTranslations={bibleTranslations}
            setIsLoading={setIsLoading}
            isExpanded={currentOpenTranslationIndex === index}
            setBibleTranslations={setBibleTranslations}
            setComparedTranslationsList={setComparedTranslationsList}
            setComparedTranslationsData={setComparedTranslationsData}
            setCurrentOpenTranslationIndex={setCurrentOpenTranslationIndex}
            toggleOpenComparedTranslationHeader={(e) =>
              handleOpenTranslationIndex(e, index)
            }
          />
        );
      })}
    </TranslationComparePanelsContainer>
  );
};

export default memo(TranslationComparePanels);
