import React from "react";

const ReplyMessageInInputIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4481_4702)">
        <path
          d="M8.94068 6.93195C16.641 7.27069 23.0508 13.14 23.9947 20.8489C24.0337 21.1666 23.8532 21.4703 23.5556 21.5881C23.4714 21.6214 23.3837 21.6375 23.2973 21.6375C23.0779 21.6375 22.8651 21.5346 22.7296 21.3497C20.1354 17.8051 15.9701 15.689 11.5875 15.689C10.7045 15.689 9.81666 15.7754 8.94068 15.9464V17.8044C8.94068 19.1242 8.22162 19.5926 7.60602 19.5926C7.1576 19.5926 6.70276 19.3652 6.25452 18.9169L0.82013 13.4826C-0.273377 12.389 -0.273377 10.6098 0.82013 9.5163L6.25452 4.08191C6.70276 3.63367 7.1576 3.40625 7.60602 3.40625C8.22162 3.40625 8.94068 3.87463 8.94068 5.19446V6.93195Z"
          fill="url(#paint0_linear_4481_4702)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4481_4702"
          x1="-8.01208"
          y1="26.1953"
          x2="32.4039"
          y2="24.3115"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <clipPath id="clip0_4481_4702">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReplyMessageInInputIcon;
