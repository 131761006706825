import React, { memo, useCallback, useEffect, useState } from "react";
import AskQuestionArrowIcon from "../../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import styled from "styled-components";
import Checkbox from "../../../../../../common/components/checkbox";
import GroupOrGroupPreview from "../course_or_group_preview";
import PlusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import {
  AddUserButton,
  AddUserButtonContainer,
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../../../admin_page_components/admin_page_styled_components";

const GroupsAdminGroupsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 2px 10px 8px;
  border-bottom: 1px solid #cacaca;
`;

const AddGroupButtonContainer = styled(AddUserButtonContainer)`
  padding: 0;
`;

const GroupsAdmin = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
}) => {
  const [extendedUserIsGroupsAdmin, setExtendedUserIsGroupsAdmin] =
    useState(false);

  const isInvite = userRolesSettingsData.user_type === "invite";
  const userIsGroupsAdmin =
    userRolesSettingsData.user_access.is_admin_of_groups;
  const userGroupList = userRolesSettingsData.user_access.admin_of_groups;

  useEffect(() => {
    if (!userIsGroupsAdmin) {
      setExtendedUserIsGroupsAdmin(false);
    }
  }, [userIsGroupsAdmin]);

  const handleUserIsGroupsAdmin = useCallback(
    (event) => {
      event.stopPropagation();
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          is_admin_of_groups: !prevState.user_access.is_admin_of_groups,
        },
      }));

      setUsersList((prevState) =>
        prevState.map((user) =>
          user._id === userRolesSettingsData._id || user._id === "new_invite"
            ? {
                ...user,
                user_access: {
                  ...user.user_access,
                  is_admin_of_groups: !user.user_access.is_admin_of_groups,
                },
              }
            : user
        )
      );
    },
    [userRolesSettingsData]
  );

  const handleExtendUserIsGroupsAdmin = useCallback(() => {
    if (userIsGroupsAdmin) {
      setExtendedUserIsGroupsAdmin((prevState) => !prevState);
    }
  }, [userIsGroupsAdmin]);

  return (
    <div>
      <AdminPageSettingsHeader
        clickable={userIsGroupsAdmin && !isInvite}
        onClick={handleExtendUserIsGroupsAdmin}
      >
        <Checkbox
          checked={userIsGroupsAdmin}
          onClickCheckbox={handleUserIsGroupsAdmin}
          color={"#1e1e1e"}
          fontSize={"14px"}
        >
          Админ групп
        </Checkbox>

        {userIsGroupsAdmin && !isInvite && (
          <AdminPageSettingsHeaderArrow
            extendedSection={extendedUserIsGroupsAdmin}
          >
            <AskQuestionArrowIcon />
          </AdminPageSettingsHeaderArrow>
        )}
      </AdminPageSettingsHeader>

      {extendedUserIsGroupsAdmin && !isInvite && (
        <GroupsAdminGroupsList>
          {userGroupList.map((course) => {
            return <GroupOrGroupPreview key={course} component={"group"} />;
          })}

          <AddGroupButtonContainer>
            <AddUserButton>
              <PlusIcon />
            </AddUserButton>
          </AddGroupButtonContainer>
        </GroupsAdminGroupsList>
      )}
    </div>
  );
};

export default memo(GroupsAdmin);
