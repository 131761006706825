import React from "react";

const FavoriteIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4012_4847)">
        <path
          d="M19.947 8.18222C19.7956 7.71606 19.3444 7.39578 18.7093 7.30346L13.5331 6.5512L11.2181 1.86093C10.9342 1.28537 10.4901 0.955322 10 0.955322C9.50992 0.955322 9.06589 1.28537 8.78177 1.86093L6.46716 6.5512L1.29059 7.30346C0.65552 7.39578 0.204316 7.71606 0.0529485 8.18222C-0.0984192 8.64837 0.0782781 9.17267 0.537874 9.62051L4.28362 13.2715L3.39921 18.427C3.28614 19.0863 3.47886 19.4815 3.66044 19.6968C3.87361 19.9495 4.18413 20.0887 4.53508 20.0887C4.79936 20.0887 5.08028 20.0115 5.3702 19.859L10 17.425L14.63 19.859C14.9199 20.0115 15.2009 20.0887 15.465 20.0888C15.8159 20.0888 16.1268 19.9495 16.3398 19.6968C16.5214 19.4815 16.7141 19.0863 16.6009 18.427L15.7168 13.2715L19.4622 9.62067C19.9218 9.17267 20.0985 8.64837 19.947 8.18222ZM18.6443 8.78143L14.6782 12.6474C14.5402 12.782 14.4771 12.9761 14.5098 13.1661L15.4459 18.6252C15.4732 18.7841 15.4615 18.8742 15.4506 18.9164C15.4088 18.9136 15.3191 18.8974 15.1754 18.8219L10.2727 16.2444C10.1873 16.1995 10.0937 16.1771 10 16.1771C9.90635 16.1771 9.81266 16.1995 9.72736 16.2444L4.825 18.8218C4.68126 18.8973 4.59139 18.9136 4.54958 18.9164C4.5389 18.8742 4.52699 18.7841 4.55431 18.6252L5.49074 13.1662C5.52324 12.9761 5.46022 12.782 5.32213 12.6476L1.3559 8.78143C1.24054 8.66897 1.19706 8.58917 1.18088 8.54873C1.21766 8.52554 1.29975 8.48648 1.4592 8.46328L6.94064 7.66677C7.13153 7.639 7.29647 7.51907 7.38177 7.34618L9.8328 2.37958C9.90421 2.23508 9.96662 2.16916 10.0002 2.14124C10.0336 2.16916 10.0962 2.23508 10.1674 2.37958L12.6186 7.34618C12.7041 7.51907 12.869 7.639 13.0599 7.66677L18.5409 8.46328C18.7005 8.48648 18.7824 8.52554 18.8192 8.54873C18.803 8.58902 18.7597 8.66897 18.6443 8.78143Z"
          fill="url(#paint0_linear_4012_4847)"
        />
        <path
          d="M19.947 8.18222C19.7956 7.71606 19.3444 7.39578 18.7093 7.30346L13.5331 6.5512L11.2181 1.86093C10.9342 1.28537 10.4901 0.955322 10 0.955322C9.50992 0.955322 9.06589 1.28537 8.78177 1.86093L6.46715 6.5512L1.29059 7.30346C0.65552 7.39578 0.204316 7.71606 0.0529485 8.18222C-0.0984192 8.64837 0.0782781 9.17267 0.537874 9.62051L4.28362 13.2715L3.39921 18.427C3.28614 19.0863 3.47886 19.4815 3.66044 19.6968C3.87361 19.9495 4.18413 20.0887 4.53508 20.0887C4.79936 20.0887 5.08028 20.0115 5.3702 19.859L10 17.425L14.63 19.859C14.9199 20.0115 15.2009 20.0887 15.465 20.0888C15.8159 20.0888 16.1268 19.9495 16.3398 19.6968C16.5214 19.4815 16.7141 19.0863 16.6009 18.427L15.7168 13.2715L19.4622 9.62067C19.9218 9.17267 20.0985 8.64837 19.947 8.18222Z"
          fill="url(#paint1_linear_4012_4847)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4012_4847"
          x1="9.51662"
          y1="-2.56187"
          x2="9.51662"
          y2="21.7771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4012_4847"
          x1="9.51662"
          y1="-2.56187"
          x2="9.51662"
          y2="21.7771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <clipPath id="clip0_4012_4847">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.486572)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FavoriteIcon;
