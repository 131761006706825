import React from "react";

const UnderlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        d="M18.3984 0.85C17.6529 0.85 17.0484 1.45444 17.0484 2.2V12.6C17.0484 15.3844 14.7828 17.65 11.9983 17.65C10.6491 17.65 9.3815 17.1248 8.42746 16.1709C7.47336 15.2169 6.94836 13.9491 6.94844 12.5999V2.2C6.94844 1.45444 6.344 0.85 5.59844 0.85C4.85287 0.85 4.24844 1.45444 4.24844 2.2V12.5998C4.24836 14.6696 5.05456 16.6163 6.51821 18.0801C7.98178 19.5436 9.92849 20.35 11.9983 20.35C16.2718 20.35 19.7484 16.8735 19.7484 12.6V2.2C19.7484 1.45444 19.144 0.85 18.3984 0.85Z"
        fill="#383838"
        stroke="#383838"
        strokeWidth="0.3"
      />
      <path
        d="M18.3984 22.4496H5.59844C4.85287 22.4496 4.24844 23.054 4.24844 23.7996C4.24844 24.5452 4.85287 25.1496 5.59844 25.1496H18.3984C19.144 25.1496 19.7484 24.5452 19.7484 23.7996C19.7484 23.054 19.144 22.4496 18.3984 22.4496Z"
        fill="#383838"
        stroke="#383838"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default UnderlineIcon;
