import React from "react";
import styled from "styled-components";

const VerseLoader = styled.div.attrs(({ lineHeight, lineWidth }) => ({
  style: {
    height: lineHeight ? `${lineHeight * 1.3}px` : "24px",
    width: `${lineWidth}%`,
  },
}))`
  margin-bottom: 10px;
  border-radius: 10px;
  background: rgb(223 223 223);
`;

const VersesLoader = ({ bibleFontSize }) => {
  return (
    <>
      {Array(12 + Math.round(Math.random() * 10))
        .fill()
        .map((_, index) => (
          <VerseLoader
            lineHeight={bibleFontSize}
            lineWidth={60 + Math.random() * 30}
            key={index + "loader" + Math.round(Math.random() * 1000)}
          />
        ))}
    </>
  );
};

export default VersesLoader;
