import React, { useState, useEffect, useCallback, memo } from "react";
import styled from "styled-components";

const ScrollToTopButtonContainer = styled.button`
  position: absolute;
  bottom: 40px;
  left: -55px;
  width: 51px;
  height: 51px;
  padding: 8px 10px;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
  z-index: 999;

  &: hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const ScrollToTopButton = ({ parentRef, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef?.current) {
        setIsVisible(parentRef.current.scrollTop > 200);
      }
    };

    if (parentRef?.current) {
      parentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (parentRef?.current) {
        parentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToTop = useCallback(
    (event) => {
      event.stopPropagation();

      parentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    [parentRef.current]
  );

  return (
    <ScrollToTopButtonContainer
      onClick={scrollToTop}
      visible={isVisible}
      className={className}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
      >
        <path
          d="M27.125 21.959L15.5 10.334L3.87501 21.959"
          stroke="#5172EA"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ScrollToTopButtonContainer>
  );
};

export default memo(ScrollToTopButton);
