import React from "react";

const SendMessageIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
    >
      <path
        d="M22.5 2.5L11.5 13.5"
        stroke="url(#paint0_linear_3644_2548)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 2.5L15.5 22.5L11.5 13.5L2.5 9.5L22.5 2.5Z"
        stroke="url(#paint1_linear_3644_2548)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3644_2548"
          x1="7.82779"
          y1="16.25"
          x2="26.3688"
          y2="15.5935"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3644_2548"
          x1="-4.17674"
          y1="27.5"
          x2="29.5342"
          y2="26.3064"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SendMessageIcon;
