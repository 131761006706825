import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import ArticlePreview from "../../../common/components/article_preview";
import { BASE_API_URL } from "../../../common/endpoints";
import SpinnerLoader from "../../../common/components/loaders/spinner_loader";

const HomePageArticlesSectionContainer = styled.div`
  width: 100%;
  padding-bottom: 90px;

  @media (max-width: 1024px) {
    padding-bottom: 160px;
  }

  @media (max-width: 940px) {
    padding-bottom: 110px;
  }

  @media (max-width: 880px) {
    padding-bottom: 90px;
  }

  @media (max-width: 744px) {
    padding-bottom: 70px;
  }

  @media (max-width: 670px) {
    padding-bottom: 60px;
  }

  @media (max-width: 412px) {
    padding-bottom: 50px;
  }
`;

const ArticleSectionTitle = styled.p`
  color: #1e1e1e;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.4px;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 412px) {
    font-size: 16px;
    line-height: 145%;
    letter-spacing: 0.24px;
  }
`;

const ArticlesContainer = styled.div`
  margin: 35px auto 0;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  alingn-items: center;
  padding-top: 40px;
`;

const HomePageArticlesSection = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchArticlesData = () => {
    setIsLoading(true);
    axios
      .get(BASE_API_URL + "/articles")
      .then((response) => {
        const filteredArticles = response.data.filter(
          (article) => article.article_is_visible
        );
        const sortedArticles = filteredArticles
          .sort((a, b) => {
            return (
              new Date(b.article_created_at) - new Date(a.article_created_at)
            );
          })
          .sort((a, b) => {
            return b.article_is_pinned - a.article_is_pinned;
          });
        setArticles(sortedArticles);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchArticlesData();
  }, []);

  //?            для адаптива обернуть в <ResponsiveMason columnsCountBreakPoints={{ }}>

  return (
    <HomePageArticlesSectionContainer>
      <ArticleSectionTitle>Новости</ArticleSectionTitle>
      {isLoading ? (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      ) : (
        <ArticlesContainer>
          <Masonry columnsCount={2} gutter="20px">
            {articles.map((article, index) => (
              <ArticlePreview
                key={index + article._id}
                article={article}
                inComponent={"home_page"}
              />
            ))}
          </Masonry>
        </ArticlesContainer>
      )}
    </HomePageArticlesSectionContainer>
  );
};

export default HomePageArticlesSection;
