import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import RequiredIcon from "../../../../../common/assets/icons/home_page_icons/required_icon";
import { AskQuestionLabel } from ".";

const AskQuestionInputContainer = styled.div`
  width: 100%;
`;

const QuestionInput = styled.textarea`
  display: flex;
  padding: 10px 15px;
  min-height: 40px;
  max-height: 140px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 20px;
  resize: none;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const Question = ({ askQuestionData, setAskQuestionData }) => {
  const [limitReached, setLimitReached] = useState(false);
  const questionInputRef = useRef(null);
  const maxQuestionLength = 500;

  const handleQuestionInputValue = useCallback((event) => {
    const inputValue = event.target.value;

    if (inputValue.length <= maxQuestionLength) {
      setAskQuestionData((prevState) => ({
        ...prevState,
        question: inputValue,
      }));
      setLimitReached(false);
    } else setLimitReached(true);
  }, []);

  useEffect(() => {
    if (questionInputRef.current) {
      questionInputRef.current.style.height = "auto";
      questionInputRef.current.style.height =
        questionInputRef.current.scrollHeight + "px";
    }
  }, [askQuestionData, questionInputRef]);

  return (
    <AskQuestionInputContainer>
      <AskQuestionLabel limitReached={limitReached}>
        Напишите ваш вопрос (500 символов макс.)
        <RequiredIcon />
      </AskQuestionLabel>

      <QuestionInput
        placeholder="Текст вопроса"
        ref={questionInputRef}
        rows={1}
        type="text"
        value={askQuestionData.question}
        onChange={handleQuestionInputValue}
      />
    </AskQuestionInputContainer>
  );
};

export default memo(Question);
