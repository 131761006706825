import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  AuthButton,
  ButtonSection,
  AuthFormWrapper,
  InputSection,
  AuthNavButton,
  AuthNavSection,
  AuthPageWrapper,
  TextInputComponent,
  VendorButton,
  PasswordInput,
} from "./auth_components";
import { useValueWithDebounce } from "../../common/hooks/use_value_with_debounce";
import { GoogleIcon } from "../../common/assets/icons";
import { getOAuthGmailUrl } from "./auth_utils";

const getValidationMessages = ({ email, password }) => {
  const validationMsgObject = {};
  //   if (!email) validationMsgObject.email = "This field is required";
  //   if (!password) validationMsgObject.password = "This field is required";
  if (!email) validationMsgObject.email = "Это поле обязательно для заполнения";
  if (!password)
    validationMsgObject.password = "Это поле обязательно для заполнения";

  return validationMsgObject;
};

const LoginPage = () => {
  const [validationMessages, setValidationMessages] = useState({});

  const [email, setEmail, debounceEmail] = useValueWithDebounce({
    onChange: () => setValidationMessages({ ...validationMessages, email: "" }),
  });

  const [password, setPassword, debouncePassword] = useValueWithDebounce({
    onChange: () =>
      setValidationMessages({ ...validationMessages, password: "" }),
  });

  const userFormData = useMemo(
    () => ({
      email: debounceEmail,
      password: debouncePassword,
    }),
    [debounceEmail, debouncePassword]
  );

  const onSubmit = () => {
    const newValidationMessages = getValidationMessages({
      email,
      password,
    });
    setValidationMessages(newValidationMessages);
  };

  return (
    <AuthPageWrapper>
      <AuthFormWrapper>
        <AuthNavSection>
          <AuthNavButton isActive>Вход</AuthNavButton>
          <Link to={"/auth/registration"}>
            <AuthNavButton>Регистрация</AuthNavButton>
          </Link>
        </AuthNavSection>
        <InputSection>
          <TextInputComponent
            placeholder={"Почта"}
            validationMessage={validationMessages.email}
            value={email}
            onChange={setEmail}
          />
          <PasswordInput
            placeholder={"Пароль"}
            validationMessage={validationMessages.password}
            value={password}
            onChange={setPassword}
          />
        </InputSection>
        <ButtonSection>
          <AuthButton onClick={onSubmit} isActive>
            {"Войти".toUpperCase()}
          </AuthButton>
          <VendorButton href={getOAuthGmailUrl()?.href}>
            <GoogleIcon /> {"Продолжить с google".toUpperCase()}{" "}
          </VendorButton>
        </ButtonSection>
      </AuthFormWrapper>
    </AuthPageWrapper>
  );
};

export default LoginPage;
