import React from "react";

const SmallArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M1.25 3.125L5 6.875L8.75 3.125"
        stroke="url(#paint0_linear_3886_5840)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_5840"
          x1="5"
          y1="3.125"
          x2="5"
          y2="6.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2440D2" />
          <stop offset="1" stopColor="#334653" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SmallArrowIcon;
