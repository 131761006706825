import React from "react";

const ButtonIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 7H18C18.6566 7 19.3068 7.12933 19.9134 7.3806C20.52 7.63188 21.0712 8.00017 21.5355 8.46447C21.9998 8.92876 22.3681 9.47996 22.6194 10.0866C22.8707 10.6932 23 11.3434 23 12C23 12.6566 22.8707 13.3068 22.6194 13.9134C22.3681 14.52 21.9998 15.0712 21.5355 15.5355C21.0712 15.9998 20.52 16.3681 19.9134 16.6194C19.3068 16.8707 18.6566 17 18 17H15M9 17H6C5.34339 17 4.69321 16.8707 4.08658 16.6194C3.47995 16.3681 2.92876 15.9998 2.46447 15.5355C1.52678 14.5979 1 13.3261 1 12C1 10.6739 1.52678 9.40215 2.46447 8.46447C3.40215 7.52678 4.67392 7 6 7H9"
        stroke="url(#paint0_linear_7665_6522)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12H16"
        stroke="url(#paint1_linear_7665_6522)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7665_6522"
          x1="-6.34441"
          y1="19.5"
          x2="30.5601"
          y2="16.6253"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7665_6522"
          x1="5.32931"
          y1="13.25"
          x2="17.8278"
          y2="9.70971"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ButtonIcon;
