import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import styled from "styled-components";

const AuthorsItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 21px;
`;

const AuthorPhotoStyles = styled.img`
  border-radius: 50%;
  width: 74px;
  height: 74px;
  border-radius: 52px;
  border: 2px solid var(--Vote-gradient, #05baa4);
  background: url(<path-to-image>) lightgray -39.147px 4.517px / 192.901% 171.648%
    no-repeat;

  @media (max-width: 420px) {
    width: 55px;
    height: 55px;
  }
`;

const AuthorName = styled.p`
  color: #1e1e1e;
  font-size: 23px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 3px;

  @media (max-width: 420px) {
    font-size: 18px;
  }
`;

const AuthorStatus = styled.p`
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  overflow-wrap: break-word;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const AboutCourseAuthorsItem = ({ authorId }) => {
  const [authorInfo, setAuthorInfo] = useState({});

  const courseAuthorName = authorInfo.user_name
    ? `${authorInfo.user_name} ${authorInfo.user_second_name}`
    : "";

  const fetchAuthorInfoData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + "/users/" + authorId);
      setAuthorInfo(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных автора курса:", error);
    }
  }, [authorId]);

  useEffect(() => {
    if (authorId) {
      fetchAuthorInfoData();
    }
  }, [authorId]);

  return (
    <AuthorsItemContainer>
      {authorInfo.user_image && (
        <AuthorPhotoStyles src={authorInfo.user_image} alt="Author photo" />
      )}
      <div>
        <AuthorName>{courseAuthorName}</AuthorName>
        <AuthorStatus>
          {authorInfo.user_publicity?.author_info || ""}
        </AuthorStatus>
      </div>
    </AuthorsItemContainer>
  );
};

export default memo(AboutCourseAuthorsItem);
