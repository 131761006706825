import React, { useState } from "react";
import { Avatar } from "../../../avatar_styles";
import {
  MessageBlockContainer,
  MessageContentContainer,
  MessageMainContent,
  AuthorsName,
  MessageText,
  FavoriteIconStyled,
  UnFavoriteIconStyled,
  FavoriteIconContainer,
  MessageStatusAndTimeContainer,
} from "./message_styled_components";
import { useChatContentContext } from "../../chat_content_context";
import useMessageContextMenu from "../use_message_context_menu";
import ReplyInMessage from "../reply_in_message";
import MessageEmoji from "../message_emoji";
import FramedBibleVerse from "../../../../../../common/components/framed_bible_verse";
import DeleteModalWindow from "../../../../../../common/components/delete_modal_window";
import ForwardMessageToChat from "../use_message_context_menu/forward_message_to_chat";

const AdminMessage = ({
  message,
  setContextMenuMessageData,
  contextMenuMessageData,
}) => {
  const { isAdmin } = useChatContentContext();
  const [openForwardMessageToChat, setOpenForwardMessageToChat] =
    useState(false);
  const [openDeleteMessageQuestion, setOpenDeleteMessageQuestion] =
    useState(false);

  const [MessageContextMenu, handleContextMenu] = useMessageContextMenu({
    setContextMenuMessageData,
    contextMenuMessageData,
    message,
    setOpenDeleteMessageQuestion,
    openDeleteMessageQuestion,
    setOpenForwardMessageToChat,
  });

  return (
    <MessageBlockContainer>
      <Avatar url={message.avatarUrl} size={"40px"} />
      <MessageContentContainer
        background={"#f8d254"}
        onContextMenu={handleContextMenu}
      >
        {contextMenuMessageData?.id === message.id && <MessageContextMenu />}

        <MessageMainContent>
          <AuthorsName>{message.authorName} (администратор)</AuthorsName>
          {message.replyInMessage && (
            <ReplyInMessage replyInMessage={message.replyInMessage} />
          )}
          {!!message.biblicalVerses.length &&
            message.biblicalVerses.map((biblicalVerse, index) => (
              <FramedBibleVerse
                key={biblicalVerse._id + message.id + index}
                verse={biblicalVerse}
                inComponent={"chats_page-messages"}
                background={"#f8d254"}
              />
            ))}
          <MessageText>{message.text}</MessageText>
          <MessageEmoji messageEmoji={message.emoji} />
        </MessageMainContent>

        {isAdmin && (
          <FavoriteIconContainer>
            {message.favorite ? (
              <FavoriteIconStyled />
            ) : (
              <UnFavoriteIconStyled />
            )}
          </FavoriteIconContainer>
        )}

        <MessageStatusAndTimeContainer>
          {message.edited && <div>(изменено)</div>}
          <div>{message.time}</div>
        </MessageStatusAndTimeContainer>

        <DeleteModalWindow
          isOpen={openDeleteMessageQuestion}
          onClose={setOpenDeleteMessageQuestion}
          onClickRemoveButton={() => {}}
        />

        <ForwardMessageToChat
          isOpen={openForwardMessageToChat}
          onClose={setOpenForwardMessageToChat}
        />
      </MessageContentContainer>
    </MessageBlockContainer>
  );
};

export default AdminMessage;
