import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import DailyVerseArrow from "../../../common/assets/icons/home_page_icons/daily_verse_arrow";
import FramedBibleVerse from "../../../common/components/framed_bible_verse";
import { BASE_API_URL } from "../../../common/endpoints";
import { Link } from "react-router-dom";

const HomePageDailyVerseContainer = styled.div`
  position: relative;
  display: flex;
  gap: 35px;
  height: 100%;
  padding: 35px 0 0 40px;
  flex: 1;
`;

const HomePageDailyVerseTitle = styled.div`
  font-family: Caveat;
  font-size: 50px;
  font-weight: 600;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  padding: 0 5px 4px 0;
`;

const DailyVerseArrowContainer = styled.div`
  margin-top: 30px;
`;

const DailyVerseContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0px;
  max-width: 390px;
  height: 180px;
  display: flex;
  align-items: center;
`;

const DailyVerseHoverWrapper = styled.div`
  padding: 15px 15px 5px;
  border-radius: 22px;
  cursor: pointer;

  &:hover {
    background: rgb(244 244 244);
    transition: 0.3s;
  }
`;

const HomePageDailyVerse = () => {
  const [homePageDailyVerse, setHomePageDailyVerse] = useState({});

  const bibleId = homePageDailyVerse?.versesParams?.bibleId;
  const bookId = homePageDailyVerse?.versesParams?.bookId;
  const chapterId = homePageDailyVerse?.versesParams?.chapterId;
  const selectBible = homePageDailyVerse?.versesParams?.bibleId;
  const selectVerses = homePageDailyVerse?.versesData
    ?.map((verse) => verse.verse_num)
    .join("");

  const versePathInBiblePage = `/bible/?bibleId=${bibleId}&bookId=${bookId}&chapterId=${chapterId}&selectVerses=${selectVerses}&selectBible=${selectBible}`;

  const fetchArticlesData = async () => {
    try {
      const response = await axios.get(
        BASE_API_URL + "/settings/daily_bible_verse"
      );
      setHomePageDailyVerse(response.data.scope_props.bible_verse);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    fetchArticlesData();
  }, []);

  return (
    <HomePageDailyVerseContainer>
      <HomePageDailyVerseTitle>Прочти главу</HomePageDailyVerseTitle>
      <DailyVerseArrowContainer>
        <DailyVerseArrow />
      </DailyVerseArrowContainer>
      <DailyVerseContainer>
        <Link to={versePathInBiblePage}>
          <DailyVerseHoverWrapper>
            {!!Object.values(homePageDailyVerse).length && (
              <FramedBibleVerse
                verse={homePageDailyVerse}
                background={"#EAEAEA"}
                fontSize={"18px"}
                lineHeight={"26px"}
                inComponent={"home_page"}
              />
            )}
          </DailyVerseHoverWrapper>
        </Link>
      </DailyVerseContainer>
    </HomePageDailyVerseContainer>
  );
};

export default HomePageDailyVerse;
