import React, { memo, useCallback } from "react";
import styled from "styled-components";
import AdminPagePersonPreview from "../../../../admin_page_components/admin_page_person_preview";
import PlusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import { AddUserButtonContainer } from "../../../../admin_page_components/admin_page_styled_components";

const MentorUserStudentsContainer = styled.div`
  padding-top: 10px;

  & > h5 {
    color: #828282;
    font-size: 10px;
    line-height: 12px;
    padding: 0 10px;
  }
`;

const MentorUserStudentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 9px;
  padding: 0 10px;
  max-height: 320px;
  overflow: auto;
`;

const AddStudentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding: 2px 5px 0;
  height: 30px;
  width: max-content;
  cursor: pointer;

  &: hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`;

const MentorUserStudents = ({
  students,
  usersList,
  setSelectedUserInfo,
  setUserRolesSettingsData,
  userRolesSettingsData,
  setUsersListByRole,
  setOpenUsersListModal,
}) => {
  const onRemoveStudent = useCallback((event, removedStudentId) => {
    event.stopPropagation();
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_access: {
        ...prevState.user_access,
        mentor_role: {
          ...prevState.user_access.mentor_role,
          user_disciples:
            prevState.user_access.mentor_role.user_disciples.filter(
              (user) => user._id !== removedStudentId
            ),
        },
      },
    }));
  }, []);

  const openUsersList = useCallback(
    (roleTitle) => {
      const students =
        userRolesSettingsData.user_access.mentor_role.user_disciples;

      const filteredUsers = usersList.filter(
        (user) =>
          user.user_name &&
          user._id !== userRolesSettingsData._id &&
          !students.find((student) => student._id === user._id) &&
          !user.user_access.is_mentor
      );

      setUsersListByRole(filteredUsers);
      setOpenUsersListModal(roleTitle);
    },
    [usersList, userRolesSettingsData]
  );

  return (
    <MentorUserStudentsContainer>
      <h5>Ученики: {students.length}</h5>

      <MentorUserStudentsList>
        {students.map((student) => {
          return (
            <AdminPagePersonPreview
              key={student._id}
              setSelectedUserInfo={setSelectedUserInfo}
              onRemoveButton={onRemoveStudent}
              personId={student._id}
              modalWindow={"admin_users_info"}
            />
          );
        })}
      </MentorUserStudentsList>

      <AddUserButtonContainer onClick={() => openUsersList("students")}>
        <AddStudentButton>
          <PlusIcon />
        </AddStudentButton>
      </AddUserButtonContainer>
    </MentorUserStudentsContainer>
  );
};

export default memo(MentorUserStudents);
