import React, { memo } from "react";
import styled from "styled-components";

const UserProfilePageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
  color: #1e1e1e;
  font-size: 16px;
`;

const UserProfilePage = () => {
  return <UserProfilePageContainer>User Profile</UserProfilePageContainer>;
};

export default memo(UserProfilePage);
