import React, { useCallback, useState } from "react";
import styled from "styled-components";
import AskQuestionModalWindow from "./ask_question_modal_window";
import AuthModalWindow from "./ask_question_modal_window/auth_modal_window";
import FinalModalWindow from "./ask_question_modal_window/final_modal_window";
import { useUserDataContext } from "../../../../user_data_context";

const VIDEO_EXAMPLE_URL =
  "https://api.on-bible.com/uploads/f2951e82f630fa5735af.mp4";

const ASK_QUESTION_DEFAULT_DATA = {
  question: "",
  bibleVerses: [1],
  recipient: {},
};

const HomePageAskQuestionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 82px;
  height: 204px;
  padding: 12px 29px;
  margin-bottom: 107px;
  border-radius: 25px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
`;

const AskQuestionVideoContainer = styled.div`
  width: 469px;
  height: 152px;
  border-radius: 15px;
  overflow: hidden;
`;

const AskQuestionVideo = styled.video`
  height: auto;
`;

const AskQuestionDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 27px;
  width: 386px;
  height: 100%;
  padding: 15px 0px 22px;
`;

const AskQuestionDescriptionText = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 26px;
`;

const AskQuestionDescriptionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  max-height: 64px;
  width: max-content;
  padding: 0 50px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
  }

  p {
    font-family: Geometria;
    font-size: 20px;
    font-weight: 700;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const HomePageAskQuestion = () => {
  const [askQuestionData, setAskQuestionData] = useState(
    ASK_QUESTION_DEFAULT_DATA
  );
  const [openAskQuestionModal, setOpenAskQuestionModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openFinalModal, setOpenFinalModal] = useState(false);
  const { userAuthorized } = useUserDataContext();

  const handleAskQuestionModalWindow = useCallback(
    (event) => {
      event.stopPropagation();
      if (userAuthorized) {
        setOpenAskQuestionModal(true);
      } else {
        setOpenAuthModal(true);
      }
    },
    [userAuthorized]
  );

  const onCloseAskQuestionModalWindow = useCallback((event) => {
    event.stopPropagation();
    setOpenAskQuestionModal(false);
    setOpenFinalModal(false);
    setAskQuestionData(ASK_QUESTION_DEFAULT_DATA);
  }, []);

  return (
    <>
      <HomePageAskQuestionContainer>
        <AskQuestionVideoContainer>
          <AskQuestionVideo autoPlay loop muted>
            <source src={VIDEO_EXAMPLE_URL} type={"video/mp4"} />
            Ваш браузер не поддерживает видео в формате mp4.
          </AskQuestionVideo>
        </AskQuestionVideoContainer>
        <AskQuestionDescription>
          <AskQuestionDescriptionText>
            Задайте вопрос на интересующий вас библейский текст или тему
          </AskQuestionDescriptionText>
          <AskQuestionDescriptionButton onClick={handleAskQuestionModalWindow}>
            <p>ЗАДАТЬ ВОПРОС</p>
          </AskQuestionDescriptionButton>
        </AskQuestionDescription>
      </HomePageAskQuestionContainer>

      <AskQuestionModalWindow
        isOpen={openAskQuestionModal}
        askQuestionData={askQuestionData}
        setOpenAskQuestionModal={setOpenAskQuestionModal}
        setAskQuestionData={setAskQuestionData}
        setOpenFinalModal={setOpenFinalModal}
        onCloseAskQuestionModalWindow={onCloseAskQuestionModalWindow}
      />

      <FinalModalWindow
        isOpen={openFinalModal}
        setOpenFinalModal={setOpenFinalModal}
        onClose={onCloseAskQuestionModalWindow}
      />

      <AuthModalWindow isOpen={openAuthModal} onClose={setOpenAuthModal} />
    </>
  );
};

export default HomePageAskQuestion;
