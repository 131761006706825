import React from "react";
import styled from "styled-components";
import LeaderUser from "./leader_user";
import MentorUser from "./mentor_user";

const UserTeachingWrapper = styled.div`
  color: #828282;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
`;

const UserTeachingContainer = styled.div`
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const UserTeaching = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  usersList,
  setUsersList,
  setSelectedUserInfo,
  setUsersListByRole,
  setOpenUsersListModal,
}) => {
  return (
    <UserTeachingWrapper>
      Учительство
      <UserTeachingContainer>
        <MentorUser
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          usersList={usersList}
          setUsersList={setUsersList}
          setSelectedUserInfo={setSelectedUserInfo}
          setUsersListByRole={setUsersListByRole}
          setOpenUsersListModal={setOpenUsersListModal}
        />
        <LeaderUser
          userRolesSettingsData={userRolesSettingsData}
          setUserRolesSettingsData={setUserRolesSettingsData}
          usersList={usersList}
          setUsersList={setUsersList}
          setSelectedUserInfo={setSelectedUserInfo}
          setOpenUsersListModal={setOpenUsersListModal}
          setUsersListByRole={setUsersListByRole}
        />
      </UserTeachingContainer>
    </UserTeachingWrapper>
  );
};

export default UserTeaching;
