import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { BASE_API_URL } from "../../../../../endpoints";
import TranslationPanelHeader from "./translation_panel_header";
import LanguageSelect from "../../../../../../pages/bible/bible_navigation_panel/bible_translations_panel/language_select";
import ModalWindowTranslations from "./modal_window_translations";
import {
  BIBLE_TRANSLATIONS_LANGUAGES,
  USER_LANGUAGE,
} from "../../../../../../pages/bible/bible_page_data";

const TranslationPanelWrapper = styled.div`
  max-width: 236px;
`;

const TranslationPanelContainer = styled.div`
  max-width: 236px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const TranslationPanel = ({
  bibleTranslations,
  currentTranslation,
  setBibleTranslations,
  setCurrentTranslationParams,
  setSelectedVerses,
}) => {
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("translations"); //translations, languages
  const [currentBibleLanguage, setCurrentBibleLanguage] = useState(
    BIBLE_TRANSLATIONS_LANGUAGES[USER_LANGUAGE]
  );

  const translationLanguages = Object.values(BIBLE_TRANSLATIONS_LANGUAGES);

  const handleExpendedPanel = useCallback((event) => {
    event.stopPropagation();
    setExpandedPanel((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (currentTranslation?.bible_code) {
      setCurrentBibleLanguage(
        BIBLE_TRANSLATIONS_LANGUAGES[currentTranslation.bible_locale]
      );
    } else {
      setCurrentBibleLanguage(BIBLE_TRANSLATIONS_LANGUAGES[USER_LANGUAGE]);
    }
  }, [currentTranslation]);

  const fetchTranslations = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/bible/transl`);
      const translations = response.data;
      setBibleTranslations(
        translations.bibles.map((transl) => {
          return { ...transl, isFavorite: false };
        })
      );
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  useEffect(() => {
    fetchTranslations();
  }, []);

  return (
    <TranslationPanelWrapper>
      <TranslationPanelContainer onClick={handleExpendedPanel}>
        <TranslationPanelHeader currentTranslation={currentTranslation} />

        {expandedPanel && (
          <>
            {currentScreen === "translations" && (
              <ModalWindowTranslations
                bibleTranslations={bibleTranslations}
                currentBibleLanguage={currentBibleLanguage}
                translation={currentTranslation}
                setCurrentScreen={setCurrentScreen}
                setBibleTranslations={setBibleTranslations}
                setSelectedVerses={setSelectedVerses}
                setCurrentTranslationParams={setCurrentTranslationParams}
              />
            )}

            {currentScreen === "languages" && (
              <LanguageSelect
                translationsLanguages={translationLanguages}
                setCurrentScreen={setCurrentScreen}
                currentBibleLanguage={currentBibleLanguage}
                setCurrentBibleLanguage={setCurrentBibleLanguage}
              />
            )}
          </>
        )}
      </TranslationPanelContainer>
    </TranslationPanelWrapper>
  );
};

export default memo(TranslationPanel);
