import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "../../../../../axios_config";
import BlackArrowDownIcon from "../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
  QuestionsFilterOptionItem,
  QuestionsFilterOptionsContainer,
} from "../../question_styled_components";
import styled from "styled-components";
import { BASE_API_URL } from "../../../../../common/endpoints";

const QUESTION_RECIPIENTS = ["Андрей Бедратый", "Феликс Понятовский"];

const SearchRecipientInput = styled.input`
  height: 30px;
  min-width: 240px;
  padding: 0 8px;
  border-radius: 6px;
  border: 1px solid #5172ea;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;

  &::placeholder {
    color: rgba(81, 114, 234, 0.4);
  }
`;

const QuestionsFilterByRecipient = ({
  extendedOption,
  hadleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  const [authors, setAuthors] = useState([]);
  const [recipientInputValue, setRecipientInputValue] = useState("");
  const [filteredRecipient, setFilteredRecipients] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users`);
      const authors = response.data.filter(
        (user) => user.user_access.is_author
      );
      setAuthors(authors);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleRecipientInputValue = useCallback((event) => {
    setRecipientInputValue(event.target.value);
  }, []);

  const handleStatusFilter = useCallback((event, recipient) => {
    setQuestionsFilterSettings((prevState) => ({
      ...prevState,
      question_recipient: recipient,
    }));
    hadleExtendOptions(event, "");
  }, []);

  useEffect(() => {
    if (recipientInputValue) {
      const filteredArray = authors.filter(
        (recipient) =>
          recipient.user_name
            .toLowerCase()
            .includes(recipientInputValue.toLowerCase()) ||
          recipient.user_second_name
            .toLowerCase()
            .includes(recipientInputValue.toLowerCase())
      );

      setFilteredRecipients(filteredArray);
    } else {
      setFilteredRecipients(authors);
    }
  }, [recipientInputValue, authors]);

  return (
    <QuestionsFilterOption
      isExtended={extendedOption === "recipient"}
      onClick={(e) => hadleExtendOptions(e, "recipient")}
    >
      Адресат
      <QuestionsFilterOptionArrow isExtended={extendedOption === "recipient"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>
      {extendedOption === "recipient" && (
        <QuestionsFilterOptionsContainer onClick={(e) => e.stopPropagation()}>
          <SearchRecipientInput
            placeholder="Поиск"
            value={recipientInputValue}
            onChange={handleRecipientInputValue}
          />

          {filteredRecipient.map((recipient) => (
            <QuestionsFilterOptionItem
              key={recipient._id}
              onClick={(e) => handleStatusFilter(e, recipient)}
            >
              {recipient.user_name} {recipient.user_second_name}
            </QuestionsFilterOptionItem>
          ))}
        </QuestionsFilterOptionsContainer>
      )}
    </QuestionsFilterOption>
  );
};

export default memo(QuestionsFilterByRecipient);
