import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

const BibleSearchPlaceButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
`;

const SearchWholeBibleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  background: #fff;
  opacity: 0.6;
  color: #1e1e1e;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `
  opacity: 1;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  `}

  &: hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const BibleSearchPlaceButton = styled(SearchWholeBibleButton)`
  width: max-content;
  flex: 1;
`;

const BibleSearchPlaceButtons = ({ bibleSearchPlace, setBibleSearchPlace }) => {
  const handleTestaments = useCallback((part) => {
    setBibleSearchPlace(part);
  }, []);

  useEffect(() => {
    return () => setBibleSearchPlace("");
  }, []);

  return (
    <BibleSearchPlaceButtonsContainer>
      <SearchWholeBibleButton
        isSelected={bibleSearchPlace === ""}
        onClick={() => handleTestaments("")}
      >
        Вся Библия
      </SearchWholeBibleButton>

      <BibleSearchPlaceButton
        isSelected={bibleSearchPlace === "ot"}
        onClick={() => handleTestaments("ot")}
      >
        Ветхий Завет
      </BibleSearchPlaceButton>

      <BibleSearchPlaceButton
        isSelected={bibleSearchPlace === "nt"}
        onClick={() => handleTestaments("nt")}
      >
        Новый Завет
      </BibleSearchPlaceButton>
    </BibleSearchPlaceButtonsContainer>
  );
};

export default BibleSearchPlaceButtons;
