import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import EmojiBar from "./emoji_bar";
import ContextMenuList from "./context_menu_list";

const ContextMenuContainer = styled.div`
  position: fixed;
  top: ${(props) => props.position.y}px;
  left: ${(props) => props.position.x}px;
  padding-top: 62px;
  width: 255px;
  cursor: default;
  z-index: 9990;
`;

const menuWidth = 255;
const offset = 10;

const useMessageContextMenu = ({
  setContextMenuMessageData,
  contextMenuMessageData,
  message,
  isYourMessage = false,
  setOpenDeleteMessageQuestion,
  openDeleteMessageQuestion,
  setOpenForwardMessageToChat,
}) => {
  const contextMenuRef = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [menuHeight, setMenuHeight] = useState(270);

  useEffect(() => {
    if (isYourMessage) {
      setMenuHeight(312);
    } else setMenuHeight(270);
  }, [isYourMessage]);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuMessageData(null);
  }, []);

  const handleContextMenu = (e) => {
    e.preventDefault();

    if (contextMenuMessageData) {
      setContextMenuMessageData(null);
      return;
    }

    const x =
      e.clientX + menuWidth + offset > window.innerWidth
        ? Math.max(window.innerWidth - menuWidth, 0)
        : e.clientX + offset;

    const y =
      e.clientY - menuHeight / 2 < 0
        ? 10
        : e.clientY + menuHeight > window.innerHeight
        ? e.clientY > window.innerHeight - menuHeight / 2
          ? Math.max(window.innerHeight - menuHeight, 0)
          : e.clientY - menuHeight / 2
        : e.clientY - menuHeight / 2;

    setPosition({ x, y });

    setContextMenuMessageData(message);
  };

  useEffect(() => {
    const handleResize = () => {
      if (contextMenuMessageData) {
        const x =
          position.x + menuWidth + offset > window.innerWidth
            ? Math.max(window.innerWidth - menuWidth, 0)
            : position.x + offset;

        const y =
          position.y + menuHeight > window.innerHeight
            ? position.y > window.innerHeight - menuHeight / 2
              ? Math.max(window.innerHeight - menuHeight, 0)
              : position.y - menuHeight / 2
            : position.y - menuHeight / 2;

        setPosition({ x: x, y: y });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contextMenuMessageData, position]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuMessageData &&
        event.target.closest(ContextMenuContainer) === null
      ) {
        handleCloseContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenuMessageData, handleCloseContextMenu]);

  useEffect(() => {
    if (openDeleteMessageQuestion) {
      handleCloseContextMenu();
    }
  }, [openDeleteMessageQuestion]);

  const MessageContextMenu = () => (
    <ContextMenuContainer position={position} ref={contextMenuRef}>
      <EmojiBar isYourMessage={isYourMessage} />
      <ContextMenuList
        isYourMessage={isYourMessage}
        message={message}
        setContextMenuMessageData={setContextMenuMessageData}
        setOpenDeleteMessageQuestion={setOpenDeleteMessageQuestion}
        setOpenForwardMessageToChat={setOpenForwardMessageToChat}
      />
    </ContextMenuContainer>
  );

  return [MessageContextMenu, handleContextMenu];
};

export default useMessageContextMenu;
