import React from "react";
import styled from "styled-components";

const ChatBlockingNoticeWrapper = styled.div`
  padding: 0 15px;
  margin: 15px auto 0;

  @media (max-width: 560px) {
    padding: 0;
    width: 100%;
  }
`;

const ChatBlockingNoticeContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 5px 16px;
  border-radius: 15px;
  background: #f8d254;

  @media (max-width: 560px) {
    width: 100%;
    border-radius: 0;
  }
`;

const ChatBlockingNoticeText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  text-align: center;

  @media (max-width: 420px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ChatBlockingNotice = ({ timeLeft }) => {
  return (
    <ChatBlockingNoticeWrapper>
      <ChatBlockingNoticeContainer>
        <ChatBlockingNoticeText>
          Вам заблокирована возможность писать сообщения на <b>{timeLeft}</b>
        </ChatBlockingNoticeText>
      </ChatBlockingNoticeContainer>
    </ChatBlockingNoticeWrapper>
  );
};

export default ChatBlockingNotice;
