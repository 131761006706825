import React, { useCallback } from "react";
import styled from "styled-components";
import ForwardMessageInInputIcon from "../../../../../common/assets/icons/chat_page_icons/forward_message_in_input_icon";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";
import { useChatContentContext } from "../chat_content_context";
import { useAppContext } from "../../../../../app_context";
import ReplyMessageInInputIcon from "../../../../../common/assets/icons/chat_page_icons/reply_message_in_input_icon";

const ReplyOrForwardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 15px;
  font-size: 14px;
`;

const ChatInputReplyOrForwardMessage = styled.div`
  flex: 1;
  overflow: hidden;
`;

const MessageAuthor = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: #828282;
  line-height: 140%; /* 14px */
  letter-spacing: -0.1px;
`;

const MessageText = styled.div`
  display: block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 140%; /* 16.8px */
  color: #1e1e1e;
  letter-spacing: -0.12px;
`;

const ReplyIconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

const RemoveIconContainer = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(1.15);
  }
`;

const ChatInputReplyOrForward = ({ message, component }) => {
  const { setForwardMessage } = useAppContext();
  const { setReplyMessage } = useChatContentContext();

  const onCancelReplyOrForwardMessage = useCallback(() => {
    if (component === "reply") {
      setReplyMessage({});
    } else {
      setForwardMessage({});
    }
  }, []);

  return (
    <ReplyOrForwardContainer>
      <ReplyIconContainer>
        {component === "reply" ? (
          <ReplyMessageInInputIcon />
        ) : (
          <ForwardMessageInInputIcon />
        )}
      </ReplyIconContainer>

      <ChatInputReplyOrForwardMessage>
        <MessageAuthor>{message.authorName}</MessageAuthor>
        <MessageText>{message.text}</MessageText>
      </ChatInputReplyOrForwardMessage>

      <RemoveIconContainer onClick={onCancelReplyOrForwardMessage}>
        <RemoveIcon />
      </RemoveIconContainer>
    </ReplyOrForwardContainer>
  );
};

export default ChatInputReplyOrForward;
