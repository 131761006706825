import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const QuestionSettingsTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #828282;
  font-size: 10px;
  line-height: 110%;
`;

const QuestionSettingsTagsInput = styled.textarea`
  height: fit-content;
  min-height: 40px;
  max-height: 80px;
  border-radius: 8px;
  background: #fff;
  padding: 8px 15px;
  color: #383838;
  font-size: 16px;
  line-height: 24px;
  resize: none;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const QuestionSettingsTags = ({ questionTags, setQuestionSettingsData }) => {
  const questionTagsInputRef = useRef(null);

  const hadleQuestionTagsInput = useCallback((event) => {
    setQuestionSettingsData((prevState) => ({
      ...prevState,
      question_tags: event.target.value,
    }));
  }, []);

  useEffect(() => {
    if (questionTagsInputRef.current) {
      questionTagsInputRef.current.style.height = "auto";
      questionTagsInputRef.current.style.height =
        questionTagsInputRef.current.scrollHeight + "px";
    }
  }, [questionTags, questionTagsInputRef]);

  return (
    <QuestionSettingsTagsContainer>
      Теги
      <QuestionSettingsTagsInput
        ref={questionTagsInputRef}
        placeholder="Впишите теги"
        rows={1}
        value={questionTags}
        onChange={hadleQuestionTagsInput}
      />
    </QuestionSettingsTagsContainer>
  );
};

export default QuestionSettingsTags;
