import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import AdminPageQuestionsList from "./admin_page_questions_list";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";
import AdminPageQuestionSettings from "./admin_page_question_settings";
import { BASE_API_URL } from "../../../common/endpoints";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import { ToastContainerStyled } from "../../../common/components/toast_notification_styled";
import UserInfoModalWindow from "../../../common/components/modal_windows/user_info_modal_window";

const DEFAULT_QUESTIONS_FILTERS_SETTINGS = {
  question_status: "",
  question_recipient: {},
  question_date_range: [],
  question_bible: [],
  search_words: [],
};

const AdminPageQuestionsWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageQuestionsContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
`;

const AdminPageQuestions = () => {
  const adminPageQuestionsRef = useRef(null);
  const [questionsList, setQuestionsList] = useState([]);
  const [questionSettingsData, setQuestionSettingsData] = useState({});
  const [questionSettingsMemoData, setQuestionSettingsMemoData] = useState({});
  const [questionsFilterSettings, setQuestionsFilterSettings] = useState(
    DEFAULT_QUESTIONS_FILTERS_SETTINGS
  );
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});

  const fetchQuestions = async () => {
    axios
      .get(BASE_API_URL + "/questions")
      .then((response) => {
        setQuestionsList(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const closeAllSettings = useCallback(() => {
    setQuestionSettingsData({});
    setQuestionSettingsMemoData({});
    setOpenEditQuestionModal(false);
    setOpenDeleteQuestionModal(false);
    fetchQuestions();
  }, []);

  const onCloseQuestionSettings = useCallback(() => {
    if (compareObjects(questionSettingsData, questionSettingsMemoData)) {
      closeAllSettings();
    } else {
      setOpenEditQuestionModal(true);
    }
  }, [questionSettingsData, questionSettingsMemoData]);

  return (
    <AdminPageQuestionsWrapper onMouseDown={onCloseQuestionSettings}>
      <AdminPageQuestionsContainer ref={adminPageQuestionsRef}>
        <AdminPageQuestionsList
          questionsList={questionsList}
          questionsFilterSettings={questionsFilterSettings}
          questionSettingsData={questionSettingsData}
          questionSettingsMemoData={questionSettingsMemoData}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
          setQuestionSettingsData={setQuestionSettingsData}
          setQuestionSettingsMemoData={setQuestionSettingsMemoData}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
        />
        <AdminPageQuestionSettings
          questionSettingsData={questionSettingsData}
          setQuestionSettingsData={setQuestionSettingsData}
          setQuestionsList={setQuestionsList}
          openEditQuestionModal={openEditQuestionModal}
          setOpenEditQuestionModal={setOpenEditQuestionModal}
          closeAllSettings={closeAllSettings}
          openDeleteQuestionModal={openDeleteQuestionModal}
          setOpenDeleteQuestionModal={setOpenDeleteQuestionModal}
          setSelectedUserInfo={setSelectedUserInfo}
        />
      </AdminPageQuestionsContainer>

      <ScrollToTopButton parentRef={adminPageQuestionsRef} />
      <ToastContainerStyled />

      {selectedUserInfo._id && (
        <UserInfoModalWindow
          selectedUserInfo={selectedUserInfo}
          setSelectedUserInfo={setSelectedUserInfo}
        />
      )}
    </AdminPageQuestionsWrapper>
  );
};

export default memo(AdminPageQuestions);
