import React, { memo } from "react";
import {
  ButtonGroup,
  SettingsButtonsContainer,
  ToolbarButton,
} from "../editor_styled_components";
import TextSettingsButtonsHeader from "./text_settings_buttons_header";
import TextStyleIcon from "../../../../common/assets/icons/text_editor_icons/text_style_icons/text_style_icon";
import styled from "styled-components";
import BoldIcon from "../../../../common/assets/icons/text_editor_icons/text_style_icons/bold_icon";
import ItalicIcon from "../../../../common/assets/icons/text_editor_icons/text_style_icons/italic_icon";
import UnderlineIcon from "../../../../common/assets/icons/text_editor_icons/text_style_icons/underline_icon";

const TextStyleButton = styled(ToolbarButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 44px;
`;

const TextStyleButtons = ({
  currentStyle,
  toggleInlineStyle,
  openTextSettings,
  setOpenTextSettings,
}) => {
  return (
    <ButtonGroup>
      <TextSettingsButtonsHeader
        isOpen={openTextSettings === "text_style"}
        setOpen={setOpenTextSettings}
        buttonsGroup={"text_style"}
      >
        <TextStyleIcon />
      </TextSettingsButtonsHeader>

      {openTextSettings === "text_style" && (
        <SettingsButtonsContainer>
          <TextStyleButton
            active={currentStyle.has("BOLD")}
            onClick={() => toggleInlineStyle("BOLD")}
          >
            <BoldIcon />
          </TextStyleButton>
          <TextStyleButton
            active={currentStyle.has("ITALIC")}
            onClick={() => toggleInlineStyle("ITALIC")}
          >
            <ItalicIcon />
          </TextStyleButton>
          <TextStyleButton
            active={currentStyle.has("UNDERLINE")}
            onClick={() => toggleInlineStyle("UNDERLINE")}
          >
            <UnderlineIcon />
          </TextStyleButton>
        </SettingsButtonsContainer>
      )}
    </ButtonGroup>
  );
};

export default memo(TextStyleButtons);
