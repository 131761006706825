export const entityStyleFn = (entity) => {
  const entityType = entity.getType();
  if (entityType === "BIBLE_VERSE") {
    const data = entity.getData();
    const selectedVerseNum = data.versesData
      .map((verse) => verse.verse_num)
      .join();

    return {
      element: "input",
      attributes: {
        className: "bible-verse",
        type: "button",
        value: data.verseAddress,
        "data-bible-id": data.versesParams.bibleId,
        "data-book-id": data.versesParams.bookId,
        "data-chapter-id": data.versesParams.chapterId,
        "data-selected-verses": selectedVerseNum || "",
      },
      style: {
        border: "1px solid #2440D2",
        color: "#2440D2",
        fontSize: "16px",
        borderRadius: "4px",
        padding: "1px 7px",
        userSelect: "none",
        cursor: "pointer",
        backgroundColor: "transparent",
      },
    };
  }
  return null;
};

export const replacePlaceholderWithStudentName = (htmlContent, userName) => {
  return htmlContent.replace(/\*ИМЯ/g, `<span>${userName}</span>`);
};

export const replaceSpacesWithNbsp = (htmlContent) => {
  return htmlContent.replace(/(\S)\s+(<\/span>)/g, "$1&nbsp;$2");
};

const createColorStyle = (background) => ({
  style: {
    background,
    display: "inline-block",
    borderRadius: "6px",
    padding: "0 2px",
  },
});

export const getInlineStyles = () => ({
  NOTE: { style: { fontSize: "12px", lineHeight: "16px" } },
  H1: {
    style: { fontSize: "22px", lineHeight: "26px" },
  },
  H2: {
    style: {
      fontSize: "34px",
      lineHeight: "40px",
      letterSpacing: "-0.4px",
    },
  },
  PERIWINKLE: createColorStyle("#98AEFD"),
  LIGHT_BLUE: createColorStyle("#46D7EB"),
  ORCHID: createColorStyle("#EF83E4"),
});
