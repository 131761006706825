import React from "react";

const HeaderMoreIcon = ({ className }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99609 4.75C6.99609 5.7165 6.21259 6.5 5.24609 6.5C4.2796 6.5 3.49609 5.7165 3.49609 4.75C3.49609 3.7835 4.2796 3 5.24609 3C6.21259 3 6.99609 3.7835 6.99609 4.75ZM6.99609 11.5C6.99609 12.4665 6.21259 13.25 5.24609 13.25C4.2796 13.25 3.49609 12.4665 3.49609 11.5C3.49609 10.5335 4.2796 9.75 5.24609 9.75C6.21259 9.75 6.99609 10.5335 6.99609 11.5ZM5.24609 20C6.21259 20 6.99609 19.2165 6.99609 18.25C6.99609 17.2835 6.21259 16.5 5.24609 16.5C4.2796 16.5 3.49609 17.2835 3.49609 18.25C3.49609 19.2165 4.2796 20 5.24609 20ZM13.7461 4.75C13.7461 5.7165 12.9626 6.5 11.9961 6.5C11.0296 6.5 10.2461 5.7165 10.2461 4.75C10.2461 3.7835 11.0296 3 11.9961 3C12.9626 3 13.7461 3.7835 13.7461 4.75ZM11.9961 13.25C12.9626 13.25 13.7461 12.4665 13.7461 11.5C13.7461 10.5335 12.9626 9.75 11.9961 9.75C11.0296 9.75 10.2461 10.5335 10.2461 11.5C10.2461 12.4665 11.0296 13.25 11.9961 13.25ZM13.7461 18.25C13.7461 19.2165 12.9626 20 11.9961 20C11.0296 20 10.2461 19.2165 10.2461 18.25C10.2461 17.2835 11.0296 16.5 11.9961 16.5C12.9626 16.5 13.7461 17.2835 13.7461 18.25ZM18.7461 6.5C19.7126 6.5 20.4961 5.7165 20.4961 4.75C20.4961 3.7835 19.7126 3 18.7461 3C17.7796 3 16.9961 3.7835 16.9961 4.75C16.9961 5.7165 17.7796 6.5 18.7461 6.5ZM20.4961 11.5C20.4961 12.4665 19.7126 13.25 18.7461 13.25C17.7796 13.25 16.9961 12.4665 16.9961 11.5C16.9961 10.5335 17.7796 9.75 18.7461 9.75C19.7126 9.75 20.4961 10.5335 20.4961 11.5ZM18.7461 20C19.7126 20 20.4961 19.2165 20.4961 18.25C20.4961 17.2835 19.7126 16.5 18.7461 16.5C17.7796 16.5 16.9961 17.2835 16.9961 18.25C16.9961 19.2165 17.7796 20 18.7461 20Z"
      />
    </svg>
  );
};

export default HeaderMoreIcon;
