import React, { useState } from "react";
import styled from "styled-components";
import AdminMessage from "./message_types/admin_message";
import TeacherMessage from "./message_types/teacher_message";
import UserMessage from "./message_types/user_message";
import YourMessage from "./message_types/your_message";
import { ToastContainer } from "react-toastify";

const MessagesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 14px;
  overflow: auto;
  width: 100%;
  padding: 15px 0 0 25px;

  @media (max-width: 900px) {
    padding-left: 15px;
  }
`;

const ChatIsEmpty = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #383838;
`;

const ToastContainerStyled = styled(ToastContainer)`
  width: 265px;
  top: 140px;
  right: 140px;
  padding: 0;
  width: max-content;
  background: none;

  @media (max-width: 1280px) {
    right: 20px;
  }

  @media (max-width: 1024px) {
    top: 65px;
    right: 15px;
  }

  @media (max-width: 744px) {
    top: 80px;
    right: 10px;
  }

  @media (max-width: 482px) {
    top: 118px;
    left: calc(100vw - 285px);
  }

  .Toastify__toast {
    padding: 5px 10px 5px;
    margin: 0;
    width: max-content;
    height: max-content;
    background: none;
    box-shadow: none;
  }

  .Toastify__toast-body {
    margin: 0 auto;
    text-align: center;
    padding: 0;
    height: 30px;
    width: 124px;
    font-size: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 4.462px 4.462px 7.808px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const MESSAGE_TYPE = {
  ADMIN: "ADMIN",
  YOUR: "YOUR",
  TEACHER: "TEACHER",
  USER: "USER",
};

const MESSAGE_RENDERERS = {
  ADMIN: AdminMessage,
  TEACHER: TeacherMessage,
  USER: UserMessage,
  YOUR: YourMessage,
};

const ChatMessages = ({ messages }) => {
  const [contextMenuMessageData, setContextMenuMessageData] = useState(null);

  const sortedMessagesByDate = messages.sort(
    (a, b) => a.createdAt - b.createdAt
  );

  if (!messages.length) {
    return <ChatIsEmpty>Напишите ваше первое сообщение...</ChatIsEmpty>;
  }

  return (
    <MessagesContainer>
      {sortedMessagesByDate.map((message, index) => {
        const MessageComponent = MESSAGE_RENDERERS[message.messageType];
        return (
          <MessageComponent
            message={message}
            key={`message${index}`}
            contextMenuMessageData={contextMenuMessageData}
            setContextMenuMessageData={setContextMenuMessageData}
          />
        );
      })}
      <ToastContainerStyled />
    </MessagesContainer>
  );
};

export default ChatMessages;
