import React from "react";
import styled from "styled-components";
import { AuthorsName } from "./message_types/message_styled_components";

const ReplyInMessageContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 4px 9px;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 97%;
  background: #000;
  margin-top: -2px;
`;

const ReplyInMessageText = styled.div`
  margin-top: 2px;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.12px;
  color: #1e1e1e;
`;

const ReplyInMessage = ({ replyInMessage }) => {
  return (
    <ReplyInMessageContainer>
      <VerticalLine />
      <div>
        <AuthorsName>{replyInMessage.authorName}</AuthorsName>
        <ReplyInMessageText>{replyInMessage.text}</ReplyInMessageText>
      </div>
    </ReplyInMessageContainer>
  );
};

export default ReplyInMessage;
