import React from "react";

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5008 1.5C11.5008 0.671573 10.8292 -4.21423e-08 10.0008 0C9.17236 -4.21423e-08 8.50079 0.671573 8.50079 1.5V8.49786H1.50391C0.675479 8.49786 0.00390621 9.16943 0.00390625 9.99786C0.00390621 10.8263 0.675479 11.4979 1.50391 11.4979H8.50079V18.4956C8.50079 19.324 9.17236 19.9956 10.0008 19.9956C10.8292 19.9956 11.5008 19.324 11.5008 18.4956V11.4979H18.4995C19.3279 11.4979 19.9995 10.8263 19.9995 9.99786C19.9995 9.16943 19.3279 8.49786 18.4995 8.49786H11.5008V1.5Z"
        fill="url(#paint0_linear_3875_4549)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3875_4549"
          x1="9.51842"
          y1="-3.67566"
          x2="9.51842"
          y2="21.7599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusIcon;
