import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useBibleBookContext } from "./bible_book_context";
import { useAppContext } from "../../../app_context";

const StrongsCodeComponentContainer = styled.div`
  display: ${({ showStrongsCode }) =>
    showStrongsCode ? "inline-block" : "none"};
  margin: 0 3px 0 1px;
  color: #5172ea;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 110%;
  vertical-align: top;
`;

const StrongsCodeComponent = ({ children }) => {
  const { bibleFontSettings } = useAppContext();
  const { showStrongsCode, setStrongsCodeModalData, currentTestament } =
    useBibleBookContext();

  const onClickStrongsCode = useCallback((e) => {
    e.stopPropagation();
    setStrongsCodeModalData(children);
  }, []);

  return (
    <StrongsCodeComponentContainer
      showStrongsCode={showStrongsCode}
      onClick={onClickStrongsCode}
      fontSize={bibleFontSettings.fontSize * 0.88}
    >
      {currentTestament === "ot" ? "H" : "G"}
      {children}
    </StrongsCodeComponentContainer>
  );
};

export default memo(StrongsCodeComponent);
