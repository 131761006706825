import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import axios from "../../../../axios_config";
import styled from "styled-components";
import { BASE_API_URL } from "../../../../common/endpoints";
import CourseLessonDuration from "./course_lesson_duration";
import {
  convertHoursAndMinutesToMinutes,
  convertMinutesToHoursAndMinutes,
} from "../../../../common/utils/functions/convert_minutes_and_hours";

const LessonSettingsWrapper = styled.div`
  position: relative;
  width: 437px;
`;

const LessonSettingsContainer = styled.div`
  position: fixed;
  padding: 26px 24px;
  width: 434px;
  border-left: 1px solid #828282;
  min-height: 220px;
`;

const LessonTitleInput = styled.textarea`
  resize: none;
  min-height: 40px;
  max-height: 90px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const AdminPageCourseLessonSettings = ({
  lessonSettingsData,
  setLessonSettingsData,
  fetchCourse,
  fetchLesson,
}) => {
  const lessonTitleInputRef = useRef(null);
  const timerRef = useRef(null);

  const [showHoursesOptions, setShowHoursesOptions] = useState(false);
  const [showMinutesOptions, setShowMinutesOptions] = useState(false);
  const [lessonHourses, setLessonHourses] = useState(0);
  const [lessonMinutes, setLessonMinutes] = useState(0);

  useEffect(() => {
    if (!lessonSettingsData._id) {
      setShowHoursesOptions(false);
      setShowMinutesOptions(false);
    }
  }, [lessonSettingsData._id]);

  useEffect(() => {
    if (lessonTitleInputRef.current) {
      lessonTitleInputRef.current.style.height = "auto";
      lessonTitleInputRef.current.style.height =
        lessonTitleInputRef.current.scrollHeight + "px";
    }
  }, [lessonSettingsData.lesson_name, lessonTitleInputRef]);

  useEffect(() => {
    if (lessonSettingsData._id) {
      const { hours, minutes } = convertMinutesToHoursAndMinutes(
        lessonSettingsData.lesson_duration_min
      );

      setLessonHourses(hours);
      setLessonMinutes(minutes);
    }
  }, [lessonSettingsData]);

  const updateLessonData = useCallback(async (lessonSettingsData) => {
    const { _id, lesson_name, lesson_duration_min } = lessonSettingsData;

    try {
      const updatedLesson = {
        ...lessonSettingsData,
        lesson_name,
        lesson_duration_min,
      };

      await axios.patch(`${BASE_API_URL}/lessons/${_id}`, updatedLesson);

      await fetchCourse();
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  const handleLessonTitleInput = useCallback(
    (event) => {
      const newLessonName = event.target.value;
      setLessonSettingsData((prevState) => ({
        ...prevState,
        lesson_name: newLessonName,
      }));

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        updateLessonData({
          ...lessonSettingsData,
          lesson_name: newLessonName,
        });
      }, 1000);
    },
    [lessonSettingsData, setLessonSettingsData]
  );

  const handleShowHoursesOptions = useCallback((event) => {
    event.stopPropagation();
    setShowHoursesOptions((prevState) => !prevState);
    setShowMinutesOptions(false);
  }, []);

  const handleShowMinutesOptions = useCallback((event) => {
    event.stopPropagation();
    setShowMinutesOptions((prevState) => !prevState);
    setShowHoursesOptions(false);
  }, []);

  const handleHourseOptions = useCallback(
    (hourses, event) => {
      event.stopPropagation();
      setLessonHourses(hourses);
      setShowHoursesOptions(false);
      setTimeout(
        () =>
          updateLessonData({
            ...lessonSettingsData,
            lesson_duration_min: convertHoursAndMinutesToMinutes(
              hourses,
              lessonMinutes
            ),
          }),
        500
      );
    },
    [lessonMinutes, lessonSettingsData]
  );

  const handleMinutesOptions = useCallback(
    (minutes, event) => {
      event.stopPropagation();
      setLessonMinutes(minutes);
      setShowMinutesOptions(false);
      setTimeout(
        () =>
          updateLessonData({
            ...lessonSettingsData,
            lesson_duration_min: convertHoursAndMinutesToMinutes(
              lessonHourses,
              minutes
            ),
          }),
        500
      );
    },
    [lessonHourses, lessonSettingsData]
  );

  const closeAllDurationOptions = useCallback((event) => {
    event.stopPropagation();
    setShowHoursesOptions(false);
    setShowMinutesOptions(false);
  }, []);

  return (
    <LessonSettingsWrapper>
      {lessonSettingsData._id && (
        <LessonSettingsContainer
          onClick={closeAllDurationOptions}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <LessonTitleInput
            ref={lessonTitleInputRef}
            placeholder="Название урока"
            type="text"
            rows={1}
            value={lessonSettingsData.lesson_name}
            onChange={handleLessonTitleInput}
          />

          <CourseLessonDuration
            lessonHourses={lessonHourses}
            lessonMinutes={lessonMinutes}
            showHoursesOptions={showHoursesOptions}
            showMinutesOptions={showMinutesOptions}
            handleShowHoursesOptions={handleShowHoursesOptions}
            handleShowMinutesOptions={handleShowMinutesOptions}
            handleHourseOptions={handleHourseOptions}
            handleMinutesOptions={handleMinutesOptions}
          />
        </LessonSettingsContainer>
      )}
    </LessonSettingsWrapper>
  );
};

export default memo(AdminPageCourseLessonSettings);
