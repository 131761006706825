import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../common/assets/icons/vertical_line";

const UnsentInviteModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const UnsentInviteModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  padding: 42px;
  border-radius: 25px;
  background: #fff;
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.24px;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: 33%;
  }
`;

const SendButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const UnsendButton = styled(SendButton)``;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSend }) =>
    isHoverOnSend ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
  margin-top: 4px;
`;

const UnsentInviteModalWindow = ({
  setOpen,
  onClickSendInviteButton,
  onClickUnsendButton,
}) => {
  const [isHoverOnSend, setIsHoverOnSend] = useState(false);

  const toggleHoverOnSendButton = useCallback(() => {
    setIsHoverOnSend((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <UnsentInviteModalWindowWrapper onClick={handleClose}>
      <UnsentInviteModalWindowContainer onClick={(e) => e.stopPropagation()}>
        Вы не завершили отправку приглашения!
        <ModalButtonsContainer>
          <SendButton
            onMouseEnter={toggleHoverOnSendButton}
            onMouseLeave={toggleHoverOnSendButton}
            onClick={onClickSendInviteButton}
          >
            Отправить
          </SendButton>
          <VerticalLineStyled color={"#5172EA"} />
          <UnsendButton
            onMouseEnter={toggleHoverOnSendButton}
            onMouseLeave={toggleHoverOnSendButton}
            onClick={onClickUnsendButton}
          >
            Не отправлять
          </UnsendButton>
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton isHoverOnSend={isHoverOnSend} onClick={handleClose}>
            Отмена
          </CancelButton>
        </ModalButtonsContainer>
      </UnsentInviteModalWindowContainer>
    </UnsentInviteModalWindowWrapper>
  );
};

export default memo(UnsentInviteModalWindow);
