import React from "react";

const BibleIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94086 19.6C3.50381 19.6 3.08455 19.7737 2.77534 20.0831C2.46611 20.3925 2.29229 20.8122 2.29229 21.25C2.29229 21.8575 1.7998 22.35 1.19229 22.35C0.584772 22.35 0.0922852 21.8575 0.0922852 21.25C0.0922852 20.2291 0.49762 19.2499 1.2193 18.5279C1.94101 17.8058 2.91997 17.4 3.94086 17.4H18.7832C19.3907 17.4 19.8832 17.8925 19.8832 18.5C19.8832 19.1075 19.3907 19.6 18.7832 19.6H3.94086Z"
        fill="url(#paint0_linear_3998_1826)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94086 3.09999C3.50381 3.09999 3.08455 3.27369 2.77534 3.58307C2.46611 3.89246 2.29229 4.3122 2.29229 4.74999V21.25C2.29229 21.6878 2.46611 22.1075 2.77534 22.4169C3.08455 22.7263 3.50381 22.9 3.94086 22.9H17.6832V3.09999H3.94086ZM1.2193 2.02783C1.94101 1.30576 2.91997 0.899994 3.94086 0.899994H18.7832C19.3907 0.899994 19.8832 1.39248 19.8832 1.99999V24C19.8832 24.6075 19.3907 25.1 18.7832 25.1H3.94086C2.91997 25.1 1.94101 24.6942 1.2193 23.9722C0.49762 23.2501 0.0922852 22.2709 0.0922852 21.25V4.74999C0.0922852 3.72909 0.49762 2.74989 1.2193 2.02783Z"
        fill="url(#paint1_linear_3998_1826)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9917 6C10.2678 6 10.4917 6.22386 10.4917 6.5V9H12.9917C13.2678 9 13.4917 9.22386 13.4917 9.5C13.4917 9.77614 13.2678 10 12.9917 10H10.4917V14.5C10.4917 14.7761 10.2678 15 9.9917 15C9.71556 15 9.4917 14.7761 9.4917 14.5V10H6.9917C6.71556 10 6.4917 9.77614 6.4917 9.5C6.4917 9.22386 6.71556 9 6.9917 9H9.4917V6.5C9.4917 6.22386 9.71556 6 9.9917 6Z"
        fill="url(#paint2_linear_3998_1826)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3998_1826"
          x1="9.50939"
          y1="16.4901"
          x2="9.50939"
          y2="22.7868"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3998_1826"
          x1="9.50939"
          y1="-3.54854"
          x2="9.50939"
          y2="27.2353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3998_1826"
          x1="9.82252"
          y1="4.34559"
          x2="9.82252"
          y2="15.7941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BibleIcon;
