import React, { useState } from "react";
import styled from "styled-components";
// import backgroundLineImage from "../../common/assets/images/background_line_image.svg";
import {
  HidePasswordIcon,
  ShowPasswordIcon,
} from "../../common/assets/icons/index";
import { devices } from "../../common/utils/enums";

export const AuthPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: 100% min(100%, 906px);
  overflow-x: hidden;
  padding: 10px;
  @media ${devices.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    background-color: #eaeaea;
  }
`;

export const AuthFormWrapper = styled.section`
  width: 100%;
  max-width: 425px;
  height: max-content;
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 112px;
  @media ${devices.tablet} {
    padding-top: 10px;
  }
`;

export const AuthNavSection = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const COLOR_ENABLED_BUTTON =
  "linear-gradient(180deg, #05BAA4 0%, #288BCE 100%)";
const COLOR_DISABLED_BUTTON = "#cacaca";
export const AuthNavButton = styled.button`
  padding: 4px 40px;
  height: 44px;
  font-style: normal;
  font-weight: 700;
  border: none;
  font-size: 20px;
  line-height: 28px;
  //width: 100%;
  color: #ffffff;
  left: 0;
  top: 0;
  background: ${({ isActive }) =>
    isActive ? COLOR_ENABLED_BUTTON : COLOR_DISABLED_BUTTON};
  border-radius: 25px;
  transition: 0.3s box-shadow;
  ${({ isActive }) =>
    !isActive &&
    `
  &:hover {
    cursor: pointer;
    box-shadow: 5px 5px 25px 1px ${COLOR_DISABLED_BUTTON};
  }`}
`;
export const AuthButton = styled.button`
  background: ${({ isActive }) =>
    isActive ? COLOR_ENABLED_BUTTON : COLOR_DISABLED_BUTTON};

  box-shadow: 0px 4.68908px 4.68908px rgba(0, 0, 0, 0.25);
  border-radius: 46.8908px;
  width: 100%;
  height: 52.75px;
  font-style: normal;
  font-weight: 600;
  font-size: 16.4118px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #ffffff;
  transition: 0.3s box-shadow;
  border: none;
  margin-top: 20px;
  ${({ isActive }) =>
    isActive &&
    `
  &:hover {
    cursor: pointer;
    box-shadow: 5px 5px 25px 1px ${COLOR_DISABLED_BUTTON};
  }`}
`;

export const VendorButton = styled.a`
  background: #ffffff;
  border-radius: 46.8908px;
  height: 53px;
  width: 100%;
  border: none;
  margin-top: 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.06em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 0.3s box-shadow;
  color: #000;

  &:hover {
    cursor: pointer;
    box-shadow: 5px 5px 25px 1px ${COLOR_DISABLED_BUTTON};
  }
`;

export const ValidationMessageContainer = styled.div`
  position: absolute;
  color: #ef6f6f;
  display: ${({ validationMessage }) => (validationMessage ? "block" : "none")};
  z-index: 1;
  font-size: 10px;
  bottom: 2px;
  width: 100%;
  padding: 0 25px;
`;

export const CaptchaContainer = styled.div`
  position: relative;

  ${ValidationMessageContainer} {
    top: 100%;
    padding-left: 0;
  }
`;

export const InputSection = styled.section`
  margin-top: 43px;
`;

export const TextInput = styled.input`
  height: 58.613468170166016px;
  width: 100%;
  border-radius: 47px;
  background: rgba(255, 255, 255, 0.7);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  position: relative;
  line-height: 26px;
  border: none;
  padding: 20px 45px 20px 20px;
  color: #1e1e1e;

  &:focus {
    border: 3px solid ${COLOR_DISABLED_BUTTON};
    outline: white;
    box-shadow: 5px 5px 10px 1px ${COLOR_DISABLED_BUTTON};
  }
`;

const TextInputContainer = styled.div`
  position: relative;
  margin-bottom: 26px;
`;

export const TextInputComponent = ({ validationMessage, ...props }) => {
  return (
    <TextInputContainer>
      <TextInput {...props} />
      <ValidationMessageContainer validationMessage={validationMessage}>
        {validationMessage}
      </ValidationMessageContainer>
    </TextInputContainer>
  );
};

const PasswordVisibleToggler = styled.div`
  padding: 21px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const CityInput = styled(TextInput)`
  border-radius: 6px 29px 29px 6px;
  width: 49%;

  //margin-right: 20px;
  margin-bottom: 0;
`;

export const CountryInput = styled(TextInput)`
  border-radius: 29px 6px 6px 29px;
  width: 49%;
  margin-bottom: 0;
`;

export const LocationSection = styled(AuthNavSection)``;

export const PasswordInput = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisible = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div style={{ position: "relative" }}>
      <TextInputComponent
        type={isPasswordVisible ? "text" : "password"}
        {...props}
      />
      <PasswordVisibleToggler onClick={togglePasswordVisible}>
        {!isPasswordVisible ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </PasswordVisibleToggler>
    </div>
  );
};

export const ButtonSection = styled.section`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AgreementSection = styled.section`
  margin-top: 20px;
`;

export const Agreement = styled.span`
  width: 100%;
  //height: 30px;
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin: 0;
`;
