import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../common/assets/icons/vertical_line";

const AfterActivationModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const AfterActivationModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  gap: 21px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
`;

const AfterActivationModalWindowText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  & > h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
  }

  & > h4 {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }
`;

const AfterActivationButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 20px;
  padding-top: 3px;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;

  &:hover {
    font-size: 18px;
    color: #fff;
    transition: 0.2s;
    width: 53%;
    cursor: pointer;
  }
`;

const ToLearningButton = styled(ButtonStyles)`
  color: ${({ isHoverOnToLearning }) =>
    isHoverOnToLearning ? "#fff" : "rgba(255, 255, 255, 0.5)"};
`;

const CancelButton = styled(ButtonStyles)`
  color: rgba(255, 255, 255, 0.5);
`;

const TemporaryCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 160px;
  font-size: 18px;
  color: #fff;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  cursor: pointer;

  &: hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    transition: 0.2s;
  }
`;

const AfterActivationModalWindow = ({ inviteUserName, setOpen }) => {
  const [isHoverOnToLearning, setIsHoverOnToLearning] = useState(true);

  const hadleClickToLearningButton = useCallback(() => {}, []);

  const toggleHoverOnDeleteButton = useCallback(() => {
    setIsHoverOnToLearning((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <AfterActivationModalWindowWrapper onClick={handleClose}>
      <AfterActivationModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <AfterActivationModalWindowText>
          <h3>Поздравляем {inviteUserName}!</h3>
          <h4>Вы успешно завершили регистрацию!</h4>
          <p>
            Вы можете пройти обучение по навигации <br /> и использвованию{" "}
            <b>OnBible</b>
          </p>
        </AfterActivationModalWindowText>

        {/* <AfterActivationButtonsContainer>
          <CancelButton
            onMouseEnter={toggleHoverOnDeleteButton}
            onMouseLeave={toggleHoverOnDeleteButton}
            onClick={handleClose}
          >
            Нет, спасибо :)
          </CancelButton>
          <VerticalLineStyled color={"#fff"} />
          <ToLearningButton
            isHoverOnToLearning={isHoverOnToLearning}
            onClick={hadleClickToLearningButton}
          >
            Пройти обучение
          </ToLearningButton>
        </AfterActivationButtonsContainer> */}
        <TemporaryCloseButton onClick={handleClose}>
          Закрыть
        </TemporaryCloseButton>
      </AfterActivationModalWindowContainer>
    </AfterActivationModalWindowWrapper>
  );
};

export default memo(AfterActivationModalWindow);
