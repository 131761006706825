import React, { memo, useCallback } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import axios from "../../axios_config";
import { BASE_API_URL } from "../../common/endpoints";

const FinishLessonButtonWrapper = styled(motion.div)`
  width: max-content;
  margin: 0 auto;
`;

const FinishLessonButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: max-content;
  padding: 0 36px;
  margin: 0 auto 120px;
  border-radius: 15px;
  background: ${({ background }) => background};
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
  user-select: none;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (max-width: 720px) {
    font-size: 20px;
    border-radius: 12px;
  }

  @media (max-width: 412px) {
    margin-bottom: 100px;
  }
`;

const FinishLessonButton = ({
  courseMainGradient,
  userLessonInfo,
  userAnswersData,
  lessonIsFinished,
  setLessonIsFinished,
  fetchUserLessonData,
}) => {
  const onClickFinishLesson = useCallback(
    async (event) => {
      event.stopPropagation();
      const userLessonAnswers = !lessonIsFinished ? userAnswersData : [];

      const { _id, study_lesson_id, study_user_id } = userLessonInfo;

      const newUserAnswersData = {
        study_lesson_id: study_lesson_id,
        study_user_id: study_user_id,
        study_answers: userLessonAnswers,
      };

      try {
        await axios.patch(
          `${BASE_API_URL}/userstudy/${_id}`,
          newUserAnswersData
        );
      } catch (error) {
        console.error("Ошибка обновления урока пользователя:", error);
      } finally {
        localStorage.removeItem(userLessonInfo._id);
        setLessonIsFinished((prevState) => !prevState);

        if (lessonIsFinished) {
          fetchUserLessonData();
        }
      }
    },
    [userLessonInfo, userAnswersData, lessonIsFinished]
  );

  return (
    <FinishLessonButtonWrapper
      whileTap={{
        scale: 0.9,
        transition: { type: "spring", stiffness: 500, damping: 15 },
      }}
    >
      <FinishLessonButtonContainer
        background={courseMainGradient}
        onClick={onClickFinishLesson}
      >
        {!lessonIsFinished ? "Завершить урок" : "Пройти повторно"}
      </FinishLessonButtonContainer>
    </FinishLessonButtonWrapper>
  );
};

export default memo(FinishLessonButton);
