import React, { memo } from "react";
import { CreateLessonPreviewContainer } from "../create_lesson_components/create_lesson_styled_components";
import RemoveLessonComponent from "../create_lesson_components/remove_lesson_component";
import { LESSON_COMPONENTS_MAPPING } from "..";
import CreateLessonArrows from "../create_lesson_components/create_lesson_arrows";

const CreateLessonPreviewComponent = ({
  previewInfo,
  lessonData,
  editBlockIndex,
  settingsBlockData,
  setEditBlockIndex,
  setTextEditorComponentHtml,
  resetAllActiveBlocks,
  fetchLessonData,
  onClickPreviewBlock,
}) => {
  const currentIndexInLessonArray = lessonData.lesson_data?.findIndex(
    (item) => item.id === previewInfo.id
  );

  const currentInfo =
    currentIndexInLessonArray === editBlockIndex
      ? settingsBlockData
      : previewInfo;

  const CurrentPreviewComponent =
    LESSON_COMPONENTS_MAPPING?.[previewInfo?.type]?.component;

  return (
    <CreateLessonPreviewContainer
      isEdite={currentIndexInLessonArray === editBlockIndex}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(event) =>
        onClickPreviewBlock(event, previewInfo, currentIndexInLessonArray)
      }
    >
      <CurrentPreviewComponent
        componentData={currentInfo}
        inComponent="create_lesson"
        setTextEditorComponentHtml={setTextEditorComponentHtml}
        setLessonData={() => {}}
      />

      {currentIndexInLessonArray === editBlockIndex && (
        <RemoveLessonComponent
          editBlockIndex={editBlockIndex}
          lessonData={lessonData}
          resetAllActiveBlocks={resetAllActiveBlocks}
        />
      )}

      {currentIndexInLessonArray === editBlockIndex && (
        <CreateLessonArrows
          currentIndex={currentIndexInLessonArray}
          lessonData={lessonData}
          setEditBlockIndex={setEditBlockIndex}
          fetchLessonData={fetchLessonData}
        />
      )}
    </CreateLessonPreviewContainer>
  );
};

export default memo(CreateLessonPreviewComponent);
