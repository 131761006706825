import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import ImageDropzone from "../../../../common/components/image_dropzone";
import Checkbox from "../../../../common/components/checkbox";

const CourseVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  border-bottom: 1px solid #cacaca;
  color: #1e1e1e;
`;

const CourseVideoTitleInput = styled.input`
  min-height: 40px;
  width: 100%;
  padding: 0 15px;
  border-radius: 8px;
  border: 1px solid rgb(149 120 255 / 50%);
  background: #fff;
  font-size: 16px;
  line-height: 120%;
  color: #1e1e1e;
`;

const VideoImageContainer = styled.div`
  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 6px;
  }
`;

const CourseVideo = ({
  courseSettingsData,
  setCourseSettingsData,
  updateImageSettings,
  isCurrentVideoImage,
}) => {
  const videoUrlInputRef = useRef(null);
  const videoUrl = courseSettingsData.course_video_url;

  useEffect(() => {
    if (videoUrlInputRef.current) {
      videoUrlInputRef.current.style.height = "auto";
      videoUrlInputRef.current.style.height =
        videoUrlInputRef.current.scrollHeight + "px";
    }
  }, [videoUrl, videoUrlInputRef]);

  const handleVideoTitleInput = useCallback((event) => {
    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_video_url: event.target.value,
    }));
  }, []);

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => {
      let newState = {
        ...prevState,
        course_video_enabled: !prevState.course_video_enabled,
      };

      if (prevState.course_video_enabled) {
        newState.course_video_image = "";
        newState.course_video_url = "";
      }

      return newState;
    });
  }, []);

  return (
    <CourseVideoContainer>
      <Checkbox
        checked={courseSettingsData.course_video_enabled}
        onClickCheckbox={onClickCheckbox}
        fontSize={"14px"}
      >
        Видео
      </Checkbox>

      {courseSettingsData.course_video_enabled && (
        <>
          <CourseVideoTitleInput
            ref={videoUrlInputRef}
            placeholder="Ссылка"
            type="text"
            rows={1}
            value={videoUrl}
            onChange={handleVideoTitleInput}
          />

          <VideoImageContainer>
            <p>Обложка видео</p>
            <ImageDropzone
              imageUrl={courseSettingsData.course_video_image}
              handleImageSettings={(image) =>
                updateImageSettings(image, "course_video_image")
              }
              isCurrentImage={isCurrentVideoImage}
            />
          </VideoImageContainer>
        </>
      )}
    </CourseVideoContainer>
  );
};

export default memo(CourseVideo);
