import React from "react";

const OnBibleLogo = () => (
  <svg
    width="147"
    height="38"
    viewBox="0 0 147 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_998_998)">
      <path
        d="M22.9829 15.8298H20.167V13.7109H19.173V15.8298H16.3571V16.8075H19.173V25.1922H20.167V16.8075H22.9829V15.8298Z"
        fill="black"
      />
      <path
        d="M8.02964 12.4733C10.5935 7.63691 16.3381 5.59669 20.8612 7.91713C19.939 7.44337 18.9778 7.12685 18.0043 6.95348C17.8424 6.92525 17.6805 6.89904 17.5186 6.87888C17.3567 6.85872 17.1927 6.84058 17.0308 6.82848C16.8689 6.81639 16.7049 6.80631 16.543 6.80227C16.0553 6.78615 15.5695 6.80631 15.09 6.86074C14.9301 6.87888 14.7703 6.90106 14.6125 6.92525C14.5325 6.93735 14.4546 6.95146 14.3747 6.96557C14.2169 6.9938 14.0591 7.02807 13.9033 7.06436C13.8255 7.0825 13.7476 7.10266 13.6697 7.12282C13.2824 7.22362 12.8991 7.3466 12.5261 7.49175C12.1531 7.63691 11.7883 7.80424 11.4338 7.99374C11.2924 8.06834 11.153 8.14898 11.0136 8.22962C9.49499 9.13481 8.19974 10.4472 7.31848 12.1105C6.83686 13.0177 6.5151 13.9632 6.33884 14.9208C6.31015 15.0801 6.28351 15.2393 6.26301 15.3986C6.24252 15.5579 6.22407 15.7191 6.21178 15.8784C6.19948 16.0377 6.18923 16.199 6.18514 16.3582C6.17489 16.6767 6.18104 16.9973 6.20153 17.3138C6.22203 17.6303 6.25892 17.9468 6.31015 18.2593C6.32245 18.338 6.33679 18.4146 6.35114 18.4932C6.4987 19.2714 6.74258 20.0274 7.07255 20.7491C7.10534 20.8217 7.14018 20.8923 7.17502 20.9648C7.31438 21.2511 7.46809 21.5293 7.63614 21.8015C7.8042 22.0736 7.98455 22.3377 8.17925 22.5938C8.27557 22.7208 8.37599 22.8478 8.48052 22.9708C8.58504 23.0937 8.69161 23.2167 8.80228 23.3357C8.91295 23.4546 9.02567 23.5715 9.14249 23.6865C9.8434 24.3719 10.6591 24.9646 11.5813 25.4384C7.0582 23.1179 5.46783 17.3178 8.03169 12.4814L8.02964 12.4733Z"
        fill="black"
      />
      <path
        d="M5.57879 11.2189C8.99317 4.77567 16.6458 2.05807 22.6712 5.14863C21.4436 4.51963 20.1606 4.09425 18.8674 3.86443C18.6522 3.82612 18.435 3.79387 18.2198 3.76564C18.0025 3.73742 17.7873 3.71524 17.5701 3.69911C17.3529 3.68097 17.1377 3.67089 16.9204 3.66282C16.2728 3.64266 15.6252 3.66887 14.9858 3.74145C14.7726 3.76564 14.5595 3.79387 14.3484 3.82814C14.2418 3.84427 14.1373 3.86241 14.0328 3.88257C13.8217 3.92087 13.6126 3.96523 13.4036 4.01361C13.2991 4.0378 13.1966 4.06401 13.0921 4.09022C12.5756 4.22328 12.0653 4.38859 11.5693 4.58213C11.0713 4.77567 10.5856 4.99743 10.1142 5.24943C9.92567 5.35023 9.73917 5.45507 9.55472 5.56393C7.52987 6.76951 5.80628 8.51741 4.63195 10.733C3.99252 11.9406 3.56009 13.2026 3.32645 14.4748C3.28751 14.6864 3.25472 14.9001 3.22603 15.1118C3.19734 15.3255 3.17479 15.5372 3.1584 15.7509C3.13995 15.9646 3.12971 16.1763 3.12151 16.39C3.10716 16.8154 3.11536 17.2407 3.14405 17.6621C3.17274 18.0834 3.22193 18.5048 3.28956 18.9201C3.30596 19.0249 3.3244 19.1277 3.3449 19.2305C3.54164 20.2668 3.86546 21.2748 4.30609 22.2344C4.35118 22.3312 4.39626 22.4259 4.44135 22.5207C4.62785 22.9017 4.83075 23.2727 5.05414 23.6335C5.27753 23.9964 5.51731 24.3472 5.77759 24.6879C5.90671 24.8593 6.03992 25.0266 6.17928 25.1899C6.31659 25.3552 6.46006 25.5165 6.60762 25.6758C6.75518 25.835 6.90684 25.9903 7.06054 26.1415C7.99304 27.0527 9.0813 27.845 10.3089 28.474C4.28354 25.3834 2.16647 17.656 5.58084 11.2148L5.57879 11.2189Z"
        fill="black"
      />
      <path
        d="M35.3529 26.422C40.579 16.5596 37.3388 4.72959 28.1163 0C29.9957 0.963659 31.6598 2.17529 33.0883 3.57038C33.326 3.80222 33.5576 4.04011 33.7831 4.28405C34.0085 4.52597 34.2257 4.77394 34.4389 5.02594C34.65 5.27795 34.8549 5.53398 35.0537 5.79606C35.6481 6.57828 36.1809 7.40082 36.6502 8.25359C36.806 8.53785 36.9556 8.82615 37.0991 9.11645C37.1708 9.26161 37.2384 9.40878 37.3061 9.55595C37.4413 9.85028 37.5684 10.1466 37.6893 10.447C37.7487 10.5962 37.8082 10.7474 37.8635 10.8986C38.1463 11.6546 38.382 12.4268 38.5726 13.211C38.7632 13.9952 38.9046 14.7895 38.9968 15.5939C39.0337 15.9145 39.0645 16.237 39.085 16.5596C39.3227 20.1159 38.6034 23.777 36.806 27.1679C35.8264 29.0166 34.5946 30.6536 33.1764 32.0588C32.9407 32.2926 32.6989 32.5205 32.4509 32.7422C32.205 32.964 31.9529 33.1777 31.6967 33.3873C31.4405 33.595 31.1803 33.7966 30.9138 33.9921C30.383 34.3812 29.8338 34.7441 29.2722 35.0808C28.7086 35.4155 28.1307 35.7239 27.5384 36.0041C27.3908 36.0747 27.2412 36.1412 27.0916 36.2078C25.5976 36.871 24.0256 37.3569 22.4148 37.6553C22.2529 37.6855 22.093 37.7137 21.9311 37.7379C21.2835 37.8428 20.6297 37.9153 19.9718 37.9577C19.314 38 18.654 38.0121 17.9921 37.9899C17.6621 37.9798 17.3301 37.9617 16.9981 37.9355C16.6661 37.9093 16.3361 37.875 16.0041 37.8327C15.6721 37.7903 15.3421 37.7399 15.0122 37.6815C13.0304 37.3287 11.069 36.6795 9.18765 35.7159C18.4122 40.4454 30.1268 36.2864 35.3529 26.424V26.422Z"
        fill="black"
      />
      <path
        d="M3.14594 9.96947C7.50101 1.75015 17.2646 -1.7154 24.95 2.22593C23.3842 1.42355 21.7488 0.881241 20.0969 0.588918C19.8223 0.540533 19.5456 0.498197 19.271 0.461908C18.9943 0.42562 18.7197 0.397396 18.443 0.375219C18.1663 0.353043 17.8917 0.338931 17.615 0.328851C16.7871 0.302643 15.9632 0.336915 15.1454 0.429652C14.8729 0.459892 14.6023 0.496181 14.3318 0.540533C14.1966 0.562709 14.0613 0.584886 13.9281 0.611094C13.6596 0.661494 13.3932 0.715927 13.1267 0.778424C12.9935 0.808664 12.8624 0.842936 12.7291 0.875193C12.0692 1.04655 11.4196 1.25622 10.7863 1.50218C10.1509 1.74813 9.53201 2.03239 8.92947 2.35294C8.68969 2.48196 8.4499 2.61502 8.21627 2.75413C5.63397 4.29235 3.43491 6.52207 1.93676 9.34652C1.12108 10.8868 0.569783 12.4955 0.272614 14.1205C0.223427 14.3906 0.180389 14.6628 0.143499 14.9329C0.106608 15.2051 0.0779162 15.4752 0.0553723 15.7474C0.0328284 16.0196 0.0184823 16.2897 0.00823507 16.5619C-0.0081605 17.1042 3.72903e-05 17.6465 0.0369273 18.1848C0.0738174 18.723 0.135301 19.2593 0.223427 19.7895C0.245971 19.9226 0.268515 20.0556 0.295158 20.1867C0.547239 21.5072 0.959178 22.7954 1.52073 24.0191C1.57606 24.1421 1.6355 24.2631 1.69493 24.384C1.93267 24.8679 2.19295 25.3437 2.47782 25.8053C2.76269 26.267 3.07011 26.7166 3.40007 27.152C3.56607 27.3698 3.73618 27.5835 3.91243 27.7931C4.08868 28.0028 4.27108 28.2104 4.45758 28.412C4.64408 28.6136 4.83878 28.8132 5.03553 29.0068C6.22626 30.17 7.61373 31.18 9.17951 31.9824C1.48998 28.0451 -1.20914 18.1888 3.14594 9.96947Z"
        fill="black"
      />
      <path
        d="M58.059 28.5752C56.817 28.5752 55.6652 28.3676 54.6056 27.9523C53.5461 27.537 52.6218 26.9503 51.8348 26.1903C51.0458 25.4302 50.4371 24.5452 50.0067 23.5352C49.5763 22.5251 49.3611 21.4244 49.3611 20.2329C49.3611 19.0415 49.5763 17.9407 50.0067 16.9307C50.4371 15.9207 51.0458 15.0356 51.8348 14.2756C52.6238 13.5155 53.5461 12.9289 54.6056 12.5136C55.6652 12.0983 56.817 11.8906 58.059 11.8906C59.3009 11.8906 60.4527 12.0983 61.5123 12.5136C62.5718 12.9289 63.492 13.5135 64.2729 14.2655C65.0537 15.0175 65.6624 15.8985 66.101 16.9105C66.5396 17.9205 66.7589 19.0294 66.7589 20.237C66.7589 21.4446 66.5396 22.5513 66.101 23.5634C65.6624 24.5734 65.0537 25.4564 64.2729 26.2084C63.492 26.9604 62.5718 27.545 61.5123 27.9603C60.4527 28.3756 59.303 28.5833 58.059 28.5833V28.5752ZM58.059 27.4704C59.1267 27.4704 60.1146 27.291 61.0225 26.9302C61.9304 26.5693 62.7194 26.0612 63.3875 25.402C64.0556 24.7428 64.5783 23.9767 64.9533 23.0977C65.3283 22.2207 65.5149 21.2651 65.5149 20.2309C65.5149 19.1967 65.3263 18.2209 64.9533 17.352C64.5783 16.4831 64.0577 15.7191 63.3875 15.0598C62.7174 14.4006 61.9304 13.8925 61.0225 13.5317C60.1146 13.1708 59.1267 12.9914 58.059 12.9914C56.9912 12.9914 56.0034 13.1708 55.0955 13.5317C54.1876 13.8925 53.3944 14.4006 52.7181 15.0598C52.0418 15.717 51.5151 16.4811 51.14 17.352C50.765 18.2209 50.5785 19.1826 50.5785 20.2309C50.5785 21.2792 50.765 22.2207 51.14 23.0977C51.5151 23.9747 52.0397 24.7428 52.7181 25.402C53.3944 26.0592 54.1876 26.5693 55.0955 26.9302C56.0034 27.291 56.9912 27.4704 58.059 27.4704Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M76.696 16.0938C77.7146 16.0938 78.604 16.2853 79.3603 16.6703C80.1165 17.0554 80.7068 17.634 81.129 18.4102C81.5511 19.1863 81.7622 20.146 81.7622 21.289V28.458H80.5674V21.3838C80.5674 20.0048 80.2129 18.9545 79.5037 18.2348C78.7946 17.515 77.8027 17.1542 76.528 17.1542C75.5565 17.1542 74.7121 17.3497 73.9948 17.7408C73.2775 18.1319 72.7324 18.6803 72.3573 19.3859C71.9823 20.0915 71.7958 20.9362 71.7958 21.9241V28.458H70.601V16.1885H71.7486V19.5976L71.5806 19.2206C71.9638 18.2489 72.593 17.4848 73.4681 16.9284C74.3432 16.372 75.4192 16.0938 76.694 16.0938H76.696Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M86.3969 28.4528V12H94.234C96.2404 12 97.7713 12.3871 98.8227 13.1632C99.8741 13.9394 100.401 14.9777 100.401 16.278C100.401 17.155 100.194 17.9049 99.7798 18.5218C99.3658 19.1407 98.8125 19.6185 98.1197 19.9552C97.427 20.2919 96.6749 20.4612 95.8613 20.4612L96.2916 19.6145C97.2303 19.6145 98.0747 19.7839 98.8248 20.1205C99.5728 20.4572 100.167 20.9471 100.606 21.5902C101.044 22.2333 101.264 23.0317 101.264 23.9873C101.264 25.3985 100.714 26.4952 99.6158 27.2774C98.5173 28.0596 96.8839 28.4528 94.7177 28.4528H86.4031H86.3969ZM89.5039 26.0557H94.5209C95.683 26.0557 96.5765 25.8682 97.1975 25.4912C97.8185 25.1142 98.13 24.5114 98.13 23.6808C98.13 22.8502 97.8185 22.2676 97.1975 21.8825C96.5765 21.4995 95.683 21.306 94.5209 21.306H89.2641V18.9311H93.8999C94.9841 18.9311 95.8162 18.7436 96.3962 18.3666C96.9782 17.9896 97.2692 17.4271 97.2692 16.6752C97.2692 15.9232 96.9782 15.3345 96.3962 14.9595C95.8141 14.5825 94.982 14.395 93.8999 14.395H89.5039V26.0537V26.0557Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M105.799 13.8327C105.242 13.8327 104.783 13.6614 104.426 13.3146C104.068 12.9699 103.889 12.5546 103.889 12.0687C103.889 11.5828 104.07 11.1494 104.426 10.8107C104.785 10.474 105.242 10.3047 105.799 10.3047C106.357 10.3047 106.816 10.466 107.173 10.7865C107.531 11.1071 107.71 11.5123 107.71 11.9961C107.71 12.5142 107.533 12.9477 107.183 13.3005C106.832 13.6533 106.371 13.8287 105.797 13.8287L105.799 13.8327ZM104.293 28.4529V15.9012H107.279V28.4529H104.293Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M111.199 28.4562V11.0156H114.185V18.8196L113.945 22.1582L114.042 25.4967V28.4582H111.199V28.4562ZM118.272 28.6215C117.188 28.6215 116.224 28.3857 115.38 27.9159C114.536 27.4462 113.87 26.7366 113.384 25.789C112.898 24.8415 112.654 23.6379 112.654 22.1804C112.654 20.7228 112.904 19.5011 113.406 18.5596C113.908 17.6201 114.585 16.9185 115.437 16.4569C116.29 15.9952 117.233 15.7634 118.27 15.7634C119.528 15.7634 120.639 16.0295 121.602 16.5617C122.565 17.0939 123.33 17.8399 123.895 18.7955C124.461 19.751 124.744 20.88 124.744 22.1804C124.744 23.4807 124.461 24.6097 123.895 25.5653C123.33 26.5209 122.565 27.2688 121.602 27.8091C120.639 28.3494 119.528 28.6195 118.27 28.6195L118.272 28.6215ZM117.938 26.1056C118.655 26.1056 119.296 25.9483 119.862 25.6358C120.428 25.3233 120.879 24.8677 121.213 24.273C121.547 23.6783 121.715 22.9807 121.715 22.1804C121.715 21.38 121.547 20.6643 121.213 20.0776C120.879 19.489 120.428 19.0394 119.862 18.7269C119.296 18.4144 118.655 18.2572 117.938 18.2572C117.22 18.2572 116.575 18.4144 116.003 18.7269C115.429 19.0414 114.976 19.491 114.64 20.0776C114.306 20.6643 114.138 21.3659 114.138 22.1804C114.138 22.9948 114.306 23.6762 114.64 24.273C114.974 24.8677 115.429 25.3233 116.003 25.6358C116.577 25.9503 117.222 26.1056 117.938 26.1056Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M127.591 28.4562V11.0156H130.577V28.4562H127.591Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M140.494 28.616C139.076 28.616 137.838 28.3378 136.778 27.7814C135.719 27.2249 134.899 26.4609 134.317 25.4891C133.735 24.5174 133.444 23.4126 133.444 22.1748C133.444 20.937 133.727 19.812 134.292 18.8484C134.858 17.8847 135.639 17.1287 136.635 16.5803C137.631 16.032 138.766 15.7578 140.039 15.7578C141.312 15.7578 142.388 16.0239 143.361 16.5562C144.333 17.0884 145.097 17.8404 145.655 18.8121C146.212 19.7838 146.491 20.9269 146.491 22.2434C146.491 22.3683 146.487 22.5095 146.478 22.6667C146.47 22.824 146.458 22.9732 146.444 23.1143H135.811V21.1628H144.868L143.697 21.7736C143.714 21.0539 143.562 20.4189 143.244 19.8705C142.925 19.3221 142.492 18.8907 141.943 18.5782C141.394 18.2657 140.76 18.1085 140.043 18.1085C139.326 18.1085 138.67 18.2657 138.119 18.5782C137.57 18.8927 137.139 19.3262 136.828 19.8826C136.516 20.439 136.362 21.0922 136.362 21.8462V22.3159C136.362 23.0679 136.537 23.7352 136.889 24.3138C137.24 24.8944 137.733 25.34 138.371 25.6545C139.008 25.969 139.74 26.1242 140.57 26.1242C141.287 26.1242 141.933 26.0153 142.505 25.7956C143.078 25.5758 143.587 25.2311 144.033 24.7614L145.634 26.5718C145.06 27.229 144.339 27.735 143.472 28.0878C142.603 28.4406 141.611 28.616 140.496 28.616H140.494Z"
        fill="black"
        stroke="black"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_998_998">
        <rect width="146.593" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OnBibleLogo;
