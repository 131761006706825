import React, { memo, useCallback } from "react";
import styled from "styled-components";
import CourseCard from "../../../study/study_page_courses/course_card";

const CardImageModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const CardImageModalWindowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: canter;
  width: max-content;
  background: #eaeaea;
  padding: 50px;
  border-radius: 25px;
`;

const CourseCardImageModalWindow = ({
  courseSettingsData,
  setOpenCardModalWindow,
}) => {
  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpenCardModalWindow(false);
  }, []);

  return (
    <CardImageModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <CardImageModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <CourseCard course={courseSettingsData} inComponent="course_settings" />
      </CardImageModalWindowContainer>
    </CardImageModalWindowWrapper>
  );
};

export default memo(CourseCardImageModalWindow);
