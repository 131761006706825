import React from "react";

const BibleSearchArrowBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <rect
        x="24.5"
        width="24"
        height="24"
        rx="12"
        transform="rotate(90 24.5 0)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7343 6.2507C15.0755 6.58498 15.0755 7.12694 14.7343 7.46121L10.1094 11.9917L14.7343 16.5222C15.0755 16.8565 15.0755 17.3984 14.7343 17.7327C14.3931 18.067 13.8398 18.067 13.4986 17.7327L8.25585 12.597C7.91461 12.2627 7.91461 11.7207 8.25585 11.3864L13.4986 6.2507C13.8398 5.91643 14.3931 5.91643 14.7343 6.2507Z"
        fill="#725FED"
      />
    </svg>
  );
};

export default BibleSearchArrowBackIcon;
