import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import UserPreview from "./user_preview";
import UsersListHeader from "./users_list_header";

const UsersListWrapper = styled.div`
  position: relative;
  flex: 1;
  padding-top: 42px;
  height: max-content;
  max-width: 630px;
  margin: 0 auto;
  padding-bottom: 120px;
`;

const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UsersList = ({
  usersList,
  activeSettingsBlock,
  userRolesSettingsData,
  userRolesSettingsMemoData,
  setActiveSettingsBlock,
  setUserRolesSettingsData,
  setUserRolesSettingsMemoData,
  setSelectedUserInfo,
  setOpenEditUserModal,
}) => {
  const [filteredUsersBySearch, setFilteredUsersBySearch] = useState([]);
  const [searchWords, setSearchWords] = useState("");

  useEffect(() => {
    const filteredUsers = usersList.filter(
      (user) =>
        user.user_name.toLowerCase().includes(searchWords.toLowerCase()) ||
        user.user_second_name.toLowerCase().includes(searchWords.toLowerCase())
    );
    setFilteredUsersBySearch(filteredUsers);
  }, [usersList, searchWords]);

  return (
    <UsersListWrapper>
      <UsersListHeader
        activeSettingsBlock={activeSettingsBlock}
        setActiveSettingsBlock={setActiveSettingsBlock}
        setUserRolesSettingsData={setUserRolesSettingsData}
        setUserRolesSettingsMemoData={setUserRolesSettingsMemoData}
        setSearchWords={setSearchWords}
      />

      <UsersListContainer>
        {filteredUsersBySearch.map((user) => {
          return (
            <UserPreview
              key={user._id}
              userInfo={user}
              userRolesSettingsData={userRolesSettingsData}
              userRolesSettingsMemoData={userRolesSettingsMemoData}
              setActiveSettingsBlock={setActiveSettingsBlock}
              setUserRolesSettingsData={setUserRolesSettingsData}
              setUserRolesSettingsMemoData={setUserRolesSettingsMemoData}
              setSelectedUserInfo={setSelectedUserInfo}
              setOpenEditUserModal={setOpenEditUserModal}
            />
          );
        })}
      </UsersListContainer>
    </UsersListWrapper>
  );
};

export default memo(UsersList);
