import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Checkbox from "../../../../common/components/checkbox";
import styled from "styled-components";
import CheckIcon from "../../../../common/assets/icons/admin_page_icons/check_icon";
import { COURSE_CERTIFICATE_IMAGES } from "../../../../common/utils/enums";

const CourseCertificateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #cacaca;
`;

const InputWrapper = styled.div`
  border: 1px solid rgb(149 120 255 / 50%);
  border-radius: 8px;
  overflow: hidden;
`;

const CertificateDescriptionInput = styled.textarea`
  resize: none;
  max-height: 90px;
  width: 100%;
  padding: 9px 15px 7px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const CertificateImagesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const CertificateImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ imageUrl }) => `url(${imageUrl})`} no-repeat center/cover;
  cursor: pointer;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.18);
    transition: box-shadow 0.2s;
  }
`;

const CheckedIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background: #fff;
  padding: 1px 0 0 0;
  border-radius: 50%;
`;

const CourseCertificate = ({ courseSettingsData, setCourseSettingsData }) => {
  const certificateDescriptionInputRef = useRef(null);
  const [value, setValue] = useState("");

  const onClickCheckbox = useCallback((event) => {
    event.stopPropagation();

    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_certificate_is_enabled: !prevState.course_certificate_is_enabled,
    }));
  }, []);

  const handleCertificateDescription = useCallback((event) => {
    //  setCourseSettingsData((prevState) => ({
    //    ...prevState,
    //  }));
    setValue(event.target.value);
  }, []);

  //! Добавить в юзЭффект courseSettingsData.course_certificate...
  //! Изменить (инпут) с локального стейта на общий стейт курса
  //! Переделать на новую вложенность!!

  useEffect(() => {
    if (certificateDescriptionInputRef.current) {
      certificateDescriptionInputRef.current.style.height = "auto";
      certificateDescriptionInputRef.current.style.height =
        certificateDescriptionInputRef.current.scrollHeight + "px";
    }
  }, [value, certificateDescriptionInputRef]);

  return (
    <CourseCertificateContainer>
      <Checkbox
        checked={courseSettingsData.course_certificate_is_enabled}
        onClickCheckbox={onClickCheckbox}
        fontSize={"14px"}
      >
        Сертификат
      </Checkbox>

      <CertificateImagesContainer>
        {COURSE_CERTIFICATE_IMAGES.map((image, index) => {
          return (
            <CertificateImage
              key={index + 1 + "_certificate_image"}
              imageUrl={image.smallImage}
            >
              {1 === image && (
                <CheckedIconContainer>
                  <CheckIcon />
                </CheckedIconContainer>
              )}
            </CertificateImage>
          );
        })}
      </CertificateImagesContainer>

      {courseSettingsData.course_certificate_is_enabled && (
        <InputWrapper>
          <CertificateDescriptionInput
            ref={certificateDescriptionInputRef}
            placeholder="Описание"
            type="text"
            rows={1}
            value={value}
            onChange={handleCertificateDescription}
          />
        </InputWrapper>
      )}
    </CourseCertificateContainer>
  );
};

export default memo(CourseCertificate);
