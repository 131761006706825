import styled from "styled-components";
import FavoriteIcon from "../../../../../../common/assets/icons/chat_page_icons/favorite_icon";
import UnFavoriteIcon from "../../../../../../common/assets/icons/chat_page_icons/unfavorite_icon";

export const MessageBlockContainer = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 88%;
  gap: 5px;
`;

export const MessageContentContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  padding: 12px 16px 14px;
  border-radius: 15px;
  background: ${({ background }) => background || "#fff"};
  cursor: pointer;
`;

export const YourMessageBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;

  @media (max-width: 900px) {
    padding-right: 15px;
  }
`;

export const MessageMainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3px;
`;

export const MessageStatusAndTimeContainer = styled.div`
  position: absolute;
  bottom: 6px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 3px;

  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.1px;
  color: #828282;
`;

export const MessageStatusContainer = styled.div`
  margin-left: 5px;
`;

export const AuthorsName = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.1px;
  color: #828282;
`;

export const MessageText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #383838;

  @media (max-width: 420px) {
    font-size: 12px;
    line-height: 150%;
  }
`;

export const FavoriteIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: -24px;
  width: 20px;
  height: 20px;
`;

export const YourFavoriteIconContainer = styled(FavoriteIconContainer)`
  left: -24px;
`;

export const FavoriteIconStyled = styled(FavoriteIcon)`
  cursor: pointer;

  &:hover {
    transform: scale(1.08);
  }
`;

export const UnFavoriteIconStyled = styled(UnFavoriteIcon)`
  cursor: pointer;

  &:hover {
    transform: scale(1.08);
  }
`;
