import React, { memo, useEffect, useRef } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";

const LessonDurationWrapper = styled.div`
  margin-top: 30px;
  user-select: none;

  & > h6 {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
  }
`;

const LessonDurationContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const LessonDurationItem = styled.div`
  margin-top: 10px;
  background: #fff;
  width: 100px;
  border-radius: 8px;
  min-height: 36px;
  height: max-content;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const LessonDurationItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 18px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const LessonDurationArrowIcon = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const LessonDurationOptionsContainer = styled.div`
  padding: 4px;
  max-height: 300px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
`;

const LessonDurationOptionItem = styled.div`
  padding: 8px 8px 8px 16px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;
  ${({ selected }) => selected && "background: rgb(233 233 233);"}

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;
const createNumberArray = (num) => {
  return Array.from({ length: num + 1 }, (_, index) => index);
};

const CourseLessonDuration = ({
  handleShowHoursesOptions,
  handleShowMinutesOptions,
  handleHourseOptions,
  showHoursesOptions,
  showMinutesOptions,
  lessonHourses,
  lessonMinutes,
  handleMinutesOptions,
}) => {
  const currentMinutesRef = useRef(null);

  const hoursesList = createNumberArray(5);
  const minutesList = createNumberArray(59);

  useEffect(() => {
    if (currentMinutesRef?.current && showMinutesOptions) {
      currentMinutesRef.current.scrollIntoView({
        block: "center",
      });
    }
  }, [currentMinutesRef.current, showMinutesOptions]);

  return (
    <LessonDurationWrapper>
      <h6>Длительность:</h6>

      <LessonDurationContainer>
        <LessonDurationItem>
          <LessonDurationItemHeader
            showOptions={showHoursesOptions}
            onClick={handleShowHoursesOptions}
          >
            {+lessonHourses} ч.
            <LessonDurationArrowIcon showOptions={showHoursesOptions}>
              <AskQuestionArrowIcon />
            </LessonDurationArrowIcon>
          </LessonDurationItemHeader>

          {showHoursesOptions && (
            <LessonDurationOptionsContainer>
              {hoursesList.map((hourses) => {
                return (
                  <LessonDurationOptionItem
                    key={hourses + "-hourses"}
                    selected={hourses === lessonHourses}
                    onClick={(e) => handleHourseOptions(hourses, e)}
                  >
                    {hourses} ч.
                  </LessonDurationOptionItem>
                );
              })}
            </LessonDurationOptionsContainer>
          )}
        </LessonDurationItem>

        <LessonDurationItem>
          <LessonDurationItemHeader
            showOptions={showMinutesOptions}
            onClick={handleShowMinutesOptions}
          >
            {+lessonMinutes} м.
            <LessonDurationArrowIcon showOptions={showMinutesOptions}>
              <AskQuestionArrowIcon />
            </LessonDurationArrowIcon>
          </LessonDurationItemHeader>

          {showMinutesOptions && (
            <LessonDurationOptionsContainer>
              {minutesList.map((minutes) => {
                return (
                  <LessonDurationOptionItem
                    key={minutes + "-minutes"}
                    selected={minutes === lessonMinutes}
                    ref={minutes === lessonMinutes ? currentMinutesRef : null}
                    onClick={(e) => handleMinutesOptions(minutes, e)}
                  >
                    {minutes} м.
                  </LessonDurationOptionItem>
                );
              })}
            </LessonDurationOptionsContainer>
          )}
        </LessonDurationItem>
      </LessonDurationContainer>
    </LessonDurationWrapper>
  );
};

export default memo(CourseLessonDuration);
