import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";
import EditMessageInInputIcon from "../../../../../common/assets/icons/chat_page_icons/edit_message_in_input_icon";
import { useChatContentContext } from "../chat_content_context";

const ChatInputEditContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 15px;
  font-size: 14px;
`;

const ChatInputEditMessage = styled.div`
  flex: 1;
  overflow: hidden;
`;

const MessageText = styled.div`
  display: block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 140%; /* 16.8px */
  color: #1e1e1e;
  letter-spacing: -0.12px;
`;

const ChatInputEditTitle = styled.div`
  color: rgb(40, 139, 206);
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
`;

const EditIconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

const RemoveIconContainer = styled.div`
  cursor: pointer;

  &:hover {
    transform: scale(1.15);
  }
`;

const ChatInputEdit = () => {
  const { editMessage, setEditMessage, setChatInputValue } =
    useChatContentContext();

  const onCancelEditMessage = useCallback(() => {
    setEditMessage({});
    setChatInputValue("");
  }, []);

  useEffect(() => {
    return () => onCancelEditMessage();
  }, []);

  return (
    <ChatInputEditContainer>
      <EditIconContainer>
        <EditMessageInInputIcon />
      </EditIconContainer>

      <ChatInputEditMessage>
        <ChatInputEditTitle>Редактирование сообщения</ChatInputEditTitle>
        <MessageText>{editMessage.text}</MessageText>
      </ChatInputEditMessage>

      <RemoveIconContainer onClick={onCancelEditMessage}>
        <RemoveIcon />
      </RemoveIconContainer>
    </ChatInputEditContainer>
  );
};

export default ChatInputEdit;
