import React from "react";
import { Link } from "react-router-dom";
import { OnBibleLogo, HeaderDonateIcon } from "../../assets/icons";
import { APPLICATION_ROUTES } from "../../utils/routes";
import {
  HomeIcon,
  ChatIcon,
  BibleIcon,
  MainNavContainer,
  HeaderButtonsContainer,
  StickyHeaderContainer,
  StudyIcon,
  UserIcon,
  DonateIconContainer,
  HeaderLogoContainer,
  UserIconContainer,
} from "./header_components/header_styled_components";
import HeaderMenuItem from "./header_components/header_menu_item";
import HeaderLanguageSelector from "./header_components/header_language_selector";
import HeaderMoreMenu from "./header_components/header_more_menu";
import { useAppContext } from "../../../app_context";
import HeaderAdminIcon from "../../assets/icons/header_icons/header_admin_icon";
import { useUserDataContext } from "../../../user_data_context";

const NAV_LINKS = [
  {
    path: APPLICATION_ROUTES.HOME,
    icon: HomeIcon,
    text: "Главная",
  },
  {
    path: APPLICATION_ROUTES.STUDY,
    icon: StudyIcon,
    text: "Учиться",
  },
  {
    path: APPLICATION_ROUTES.BIBLE,
    icon: BibleIcon,
    text: "Библия",
  },
  {
    path: APPLICATION_ROUTES.CHATS,
    icon: ChatIcon,
    text: "Чаты",
  },
];

const Header = () => {
  const { showAppHeader } = useAppContext();
  const { userData } = useUserDataContext();

  const showAdminButton =
    userData?.user_role === "superadmin" ||
    userData?.user_access?.is_admin_of_platform ||
    userData?.user_access?.is_admin_of_courses ||
    userData?.user_access?.is_admin_of_groups ||
    userData?.user_access?.is_author ||
    userData?.user_access?.is_leader ||
    userData?.user_access?.is_mentor;

  return (
    <>
      {showAppHeader && (
        <StickyHeaderContainer>
          <HeaderLogoContainer>
            <Link to={APPLICATION_ROUTES.HOME}>
              <OnBibleLogo />
            </Link>
          </HeaderLogoContainer>

          <MainNavContainer>
            {NAV_LINKS.map((menuItem) => (
              <HeaderMenuItem key={menuItem.path} menuItem={menuItem} />
            ))}
            <HeaderMoreMenu />
          </MainNavContainer>

          <HeaderButtonsContainer>
            <DonateIconContainer to={APPLICATION_ROUTES.DONATE}>
              <HeaderDonateIcon />
            </DonateIconContainer>
            <UserIconContainer to={APPLICATION_ROUTES.LOGIN}>
              <UserIcon />
            </UserIconContainer>
            <HeaderLanguageSelector />
            {showAdminButton && (
              <UserIconContainer to={APPLICATION_ROUTES.ADMIN}>
                <HeaderAdminIcon />
              </UserIconContainer>
            )}
          </HeaderButtonsContainer>
        </StickyHeaderContainer>
      )}
    </>
  );
};

export default Header;
