import React, { useCallback } from "react";
import styled from "styled-components";
import {
  ReplyIcon,
  DeleteIcon,
  CopyIcon,
  ForwardIcon,
  EditIcon,
} from "../../../../../../common/assets/icons/chat_page_icons/message_context_menu_icons";
import { useAppContext } from "../../../../../../app_context";
import { useChatContentContext } from "../../chat_content_context";
import { toast, Slide } from "react-toastify";

const ContextMenuListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 15px 0;
  width: 176px;
  border-radius: 14px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const ContextMenulistItem = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
  height: 44px;
  padding-left: 23px;
  cursor: pointer;

  &:hover {
    background: rgb(239 239 239);
    transition: 0.2s;
  }
`;

const ContextMenuList = ({
  isYourMessage,
  setOpenDeleteMessageQuestion,
  setOpenForwardMessageToChat,
  setContextMenuMessageData,
  message,
}) => {
  const { setForwardMessage } = useAppContext();
  const {
    setReplyMessage,
    editMessage,
    setEditMessage,
    setChatInputValue,
    isAdmin,
  } = useChatContentContext();

  const handleOpenDeletionQuestion = useCallback(() => {
    setOpenDeleteMessageQuestion(true);
  }, []);

  const replyOrForwardMessage = {
    authorName: message.authorName,
    text: message.text,
  };

  const onReplyMessage = useCallback(() => {
    setReplyMessage(replyOrForwardMessage);
    setContextMenuMessageData(null);
    setForwardMessage({});
    if (Object.entries(editMessage).length) {
      setChatInputValue("");
      setEditMessage({});
    }
  }, [editMessage]);

  const onForwardMessage = useCallback(() => {
    setContextMenuMessageData(null);
    setOpenForwardMessageToChat(true);
    setReplyMessage({});
    if (Object.entries(editMessage).length) {
      setChatInputValue("");
      setEditMessage({});
    }
    setForwardMessage(replyOrForwardMessage);
  }, [editMessage]);

  const onEditMessage = useCallback(() => {
    setEditMessage(message);
    setContextMenuMessageData(null);
    setForwardMessage({});
    setReplyMessage({});
  }, []);

  const showNotification = () => {
    toast("Сообщение скопировано!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
      alert("Копирование не поддерживается вашим браузером.");
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => showNotification(),
      setContextMenuMessageData(null),
      (error) => {
        console.error("Ошибка копирования: ", error);
      }
    );
  };

  const handleCopy = () => {
    copyToClipboard(message.text);
  };

  return (
    <ContextMenuListContainer>
      <ContextMenulistItem onClick={onReplyMessage}>
        <ReplyIcon />
        Ответить
      </ContextMenulistItem>
      <ContextMenulistItem onClick={handleCopy}>
        <CopyIcon />
        Копировать
      </ContextMenulistItem>
      {isYourMessage && (
        <ContextMenulistItem onClick={onEditMessage}>
          <EditIcon />
          Редактировать
        </ContextMenulistItem>
      )}
      <ContextMenulistItem onClick={onForwardMessage}>
        <ForwardIcon />
        Переслать
      </ContextMenulistItem>

      {(isYourMessage || isAdmin) && (
        <ContextMenulistItem onClick={handleOpenDeletionQuestion}>
          <DeleteIcon />
          Удалить
        </ContextMenulistItem>
      )}
    </ContextMenuListContainer>
  );
};

export default ContextMenuList;
