import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BIBLE_BOOK_NAMES_DATA } from "../bible_page_data";
import BibleCopyLeftArrowIcon from "../../../common/assets/icons/bible_page_icons/bible_copy_left_arrow_icon";
import BibleCopyRightArrowIcon from "../../../common/assets/icons/bible_page_icons/bible_copy_right_arrow_icon";
import BibleCopyModalIcon from "../../../common/assets/icons/bible_page_icons/bible_copy_modal_icon";
import { bibleVersesWithoutTags } from "../../../common/utils/functions/bible_page_functions";

const BibleCopyModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const BibleCopyModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 500px;
  height: 410px;
  padding: 25px;
  border-radius: 25px;
  background: #eaeaea;
`;

const CopyTextInputContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 245px;
`;

const CopyTextInput = styled.textarea`
  resize: none;
  width: 100%;
  height: 100%;
  padding: 14px 15px;
  font-size: 16px;
  line-height: 150%;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const CopyVersesRef = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: max-content;
  margin-top: 8px;
  padding: 0 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
`;

const BibleCopyModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const FooterSettings = styled.div`
  & > p {
    margin-bottom: 10px;
    color: #1e1e1e;
    font-size: 10px;
    line-height: 120%;
  }
`;

const FooterSettingsButtons = styled.div`
  display: flex;
  gap: 5px;
`;

const FooterSettingsButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: max-content;
  height: 25px;
  border-radius: 4px;
  background: #fff;
  user-select: none;

  & > p {
    color: #383838;
    font-size: 14px;
    line-height: 120%;
  }
`;

const ArrowIconContainer = styled.div`
  height: 100%;
  ${({ right }) =>
    right ? "padding: 6px 7px 0 4px;" : "padding: 6px 4px 0 7px;"}
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: box-shadow 0.2s;
  }
`;

const CopyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: box-shadow 0.2s;
  }
`;

const extractText = (elements) => {
  return elements
    .map((el) => el.props.children)
    .filter((text) => {
      const trimmedText = text.trim();
      return trimmedText !== "" && !/^\d+$/.test(trimmedText);
    })
    .join(" ")
    .replace(/\s+/g, " ");
};

const BibleCopyModalWindow = ({
  selectedVerses,
  selectedSearchVerse,
  activeMainSection,
  setOpenCopyModal,
  bibleTranslations,
  copyToClipboard,
}) => {
  const [copyInputValue, setCopyInputValue] = useState("");
  const [versesRefLength, setVersesRefLength] = useState("short"); // short, long
  const [versesRefPlace, setVersesRefPlace] = useState("end"); // start, end
  const [versesRefValue, setVersesRefValue] = useState("");

  const selectedRefLength =
    versesRefLength === "short" ? "Короткая" : "Длинная";
  const selectedRefPlace = versesRefPlace === "start" ? "В начале" : "В конце";

  const getBibleCode = () => {
    return activeMainSection !== "bible_search"
      ? selectedVerses.bible
      : selectedSearchVerse.bible_code;
  };

  const currentBibleLocale = bibleTranslations.find(
    (transl) => transl.bible_code === getBibleCode()
  )?.bible_locale;

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("copyBibleVerses"));
    if (savedSettings) {
      if (savedSettings.refLength) {
        setVersesRefLength(savedSettings.refLength);
      }
      if (savedSettings.refPlace) {
        setVersesRefPlace(savedSettings.refPlace);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "copyBibleVerses",
      JSON.stringify({ refLength: versesRefLength, refPlace: versesRefPlace })
    );
  }, [versesRefLength, versesRefPlace]);

  useEffect(() => {
    let text = "";
    let versesRef = "";

    if (
      activeMainSection === "bible_chapter" &&
      selectedVerses?.verses?.length
    ) {
      const firstVerse = selectedVerses.verses[0];
      const lastVerse = selectedVerses.verses[selectedVerses.verses.length - 1];

      const verseNumbers =
        selectedVerses.verses.length === 1
          ? `${firstVerse.verse_num}`
          : `${firstVerse.verse_num}-${lastVerse.verse_num}`;

      const bookName =
        versesRefLength === "short"
          ? firstVerse.book_code_orig
          : BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[firstVerse.book_code]
              ?.name;

      versesRef = `${bookName} ${firstVerse.chapter_num}:${verseNumbers}`;

      text = selectedVerses.verses
        .reduce((acc, verse) => {
          return acc + `${verse.verse_num}. ${extractText(verse.verse_text)}\n`;
        }, "")
        .trimEnd();
    }

    if (activeMainSection === "bible_search" && selectedSearchVerse) {
      const bookName =
        versesRefLength === "short"
          ? selectedSearchVerse.book_code_orig
          : BIBLE_BOOK_NAMES_DATA[currentBibleLocale]?.[
              selectedSearchVerse.book_code
            ]?.name;

      versesRef = `${bookName} ${selectedSearchVerse.chapter_num}:${selectedSearchVerse.verse_num}`;

      text = bibleVersesWithoutTags(selectedSearchVerse.verse_text[0]).trim();
      text = `«${text}»`;
    }

    setVersesRefValue(versesRef);
    setCopyInputValue(text);
  }, [
    versesRefLength,
    selectedVerses,
    currentBibleLocale,
    activeMainSection,
    selectedSearchVerse,
  ]);

  const handleCopyValue = (event) => {
    setCopyInputValue(event.target.value);
  };

  const handleCloseModalWindow = (event) => {
    event.stopPropagation();
    setOpenCopyModal(false);
  };

  const handleRefLengthChange = () => {
    setVersesRefLength((prev) => (prev === "short" ? "long" : "short"));
  };

  const handleRefPlaceChange = () => {
    setVersesRefPlace((prev) => (prev === "start" ? "end" : "start"));
  };

  const onClickCopyButton = (event) => {
    event.stopPropagation();

    const formattedText =
      versesRefPlace === "start"
        ? `(${versesRefValue})\n${copyInputValue}`
        : `${copyInputValue}\n(${versesRefValue})`;

    copyToClipboard(formattedText);
  };

  return (
    <BibleCopyModalWindowWrapper onClick={handleCloseModalWindow}>
      <BibleCopyModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <CopyTextInputContainer>
          <CopyTextInput value={copyInputValue} onChange={handleCopyValue} />
        </CopyTextInputContainer>
        <CopyVersesRef>{versesRefValue}</CopyVersesRef>

        <BibleCopyModalFooter>
          <FooterSettings>
            <p>Настройки ссылки</p>
            <FooterSettingsButtons>
              <FooterSettingsButton>
                <ArrowIconContainer onClick={handleRefLengthChange}>
                  <BibleCopyLeftArrowIcon />
                </ArrowIconContainer>
                {selectedRefLength}
                <ArrowIconContainer
                  right={true}
                  onClick={handleRefLengthChange}
                >
                  <BibleCopyRightArrowIcon />
                </ArrowIconContainer>
              </FooterSettingsButton>

              <FooterSettingsButton>
                <ArrowIconContainer onClick={handleRefPlaceChange}>
                  <BibleCopyLeftArrowIcon />
                </ArrowIconContainer>
                {selectedRefPlace}
                <ArrowIconContainer right={true} onClick={handleRefPlaceChange}>
                  <BibleCopyRightArrowIcon />
                </ArrowIconContainer>
              </FooterSettingsButton>
            </FooterSettingsButtons>
          </FooterSettings>

          <CopyButton onClick={onClickCopyButton}>
            <BibleCopyModalIcon />
          </CopyButton>
        </BibleCopyModalFooter>
      </BibleCopyModalWindowContainer>
    </BibleCopyModalWindowWrapper>
  );
};

export default BibleCopyModalWindow;
