import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../axios_config";
import styled from "styled-components";
import CoursePageHeader from "./course_page_header";
import { BASE_API_URL } from "../../../common/endpoints";
import CourseLessons from "./course_lessons";
import AboutCourse from "./about_course";
import CourseVideoModalWindow from "./course_video_modal_window";
import AboutCourseAuthors from "./about_course_authors";
import CourseVideo from "./course_video";
import CourseCertificate from "./course_certificate";
import CourseAnnouncementMessage from "./course_announcement_message";
import CourseLevelAndLessonsInfo from "./course_level_and_lessons_info";

export const LESSON_BG_COLOR = {
  AVAILABLE: {
    SMALL: "linear-gradient(180deg, #05baa4 0%, #288bce 100%)",
    LARGE: "linear-gradient(50deg, #05BAA4 8.65%, #2046E9 100%)",
  },
  CURRENT: "#F8D254",
  UNAVAILABLE: "#CACACA",
};

const CoursePageContainer = styled.div`
  padding-bottom: 80px;

  @media (max-width: 770px) {
    padding-bottom: 50px;
  }
`;

const CoursePage = () => {
  const { id } = useParams();
  const [courseData, setCourseData] = useState({});
  const [openCourseVideoModal, setOpenCourseVideoModal] = useState(false);

  const fetchLessonData = useCallback(async () => {
    try {
      const response = await axios(BASE_API_URL + `/courses/${id}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка загрузки данных курса:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CoursePageContainer>
      <CoursePageHeader courseData={courseData} />

      {courseData.course_status === "open" && (
        <CourseLevelAndLessonsInfo courseData={courseData} />
      )}

      {courseData.course_status === "announcement" && (
        <CourseAnnouncementMessage />
      )}

      <AboutCourse courseData={courseData} />

      {courseData.course_video_enabled && (
        <CourseVideo
          videoImage={courseData?.course_video_image}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}

      <AboutCourseAuthors courseData={courseData} />

      <CourseLessons courseData={courseData} />

      {courseData.course_certificate_is_enabled && <CourseCertificate />}

      {openCourseVideoModal && courseData?.course_video_url && (
        <CourseVideoModalWindow
          videoUrl={courseData?.course_video_url}
          setOpenCourseVideoModal={setOpenCourseVideoModal}
        />
      )}
    </CoursePageContainer>
  );
};

export default memo(CoursePage);
