import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { BASE_API_URL } from "../../../../../common/endpoints";
import SpinnerLoader from "../../../../../common/components/loaders/spinner_loader";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";
import { BIBLE_BOOK_NAMES_DATA } from "../../../bible_page_data";
import { highlightWordBeforeTag } from ".";

const ConcordanceChapterWrapper = styled.div`
  border-radius: 15px;
  border: 2px solid #fff;
  overflow: auto;
  height: 100%;
`;

const ConcordanceChapterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 12px 0 0 12px;
`;

const BibleChapterAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 25px 0 10px;
  width: 215px;
  width: max-content;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: 0.2s;
  }
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
  margin-top: 1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
  margin-top: 2px;
`;

const ConcordanceChapterVerses = styled.div`
  padding-bottom: 30px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const ConcordanceChapterVerse = styled.div`
  margin-right: 9px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  padding: 0 12px;
  border-radius: 5px;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

const ConcordanceChapter = ({
  bibleVerse,
  currentTranslation,
  currentCodeStrong,
  setOpen,
  setShowStrongsCode,
}) => {
  const targetVerseRef = useRef(null);
  const chapterRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [chapterData, setChapterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const codeNumber = currentCodeStrong?.slice(1);
  const bookName =
    BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale]?.[
      bibleVerse.book_code
    ]?.name;

  useEffect(() => {
    if (targetVerseRef.current) {
      const parentContainer = chapterRef.current;
      const targetElement = targetVerseRef.current;

      if (parentContainer && targetElement) {
        const parentRect = parentContainer.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const offsetTop =
          targetRect.top - parentRect.top + parentContainer.scrollTop;

        parentContainer.scrollTo({
          top:
            offsetTop -
            parentContainer.clientHeight / 2 +
            targetElement.clientHeight / 2,
        });
      }
    }
  }, [targetVerseRef, isLoading]);

  const chapterFetch = useCallback(
    async ({ bible_code, book_code, chapter_num }) => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_API_URL}/bible/verses?bible=${bible_code}&book=${book_code}&chapter=${chapter_num}`
        );

        setChapterData(response.data?.verses);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (bibleVerse) {
      chapterFetch(bibleVerse);
    }
  }, [bibleVerse]);

  const onClickChapterAddress = useCallback(
    (event) => {
      event.stopPropagation();
      setOpen(null);
      setShowStrongsCode(false);
      searchParams.set("bibleId", bibleVerse.bible_code);
      searchParams.set("bookId", bibleVerse.book_code);
      searchParams.set("chapterId", bibleVerse.chapter_num);
      searchParams.set("selectVerses", bibleVerse.verse_num);
      searchParams.set("selectBible", bibleVerse.bible_code);
      searchParams.delete("2bibleId");
      setSearchParams(searchParams);
    },
    [bibleVerse]
  );

  return (
    <ConcordanceChapterWrapper>
      <ConcordanceChapterContainer>
        <BibleChapterAddressContainer onClick={onClickChapterAddress}>
          <BibleTranslationVersion>
            {bibleVerse.bible_code?.toUpperCase()}
          </BibleTranslationVersion>
          <VerticalLineStyled />
          <BibleChapterAddress>
            {bookName} {bibleVerse.chapter_num}
          </BibleChapterAddress>
        </BibleChapterAddressContainer>

        <ConcordanceChapterVerses ref={chapterRef}>
          {!isLoading ? (
            <>
              {chapterData?.map((verse) => {
                const currentVerse = verse._id === bibleVerse._id;

                return (
                  <ConcordanceChapterVerse
                    key={verse._id}
                    ref={currentVerse ? targetVerseRef : null}
                    currentVerse={currentVerse}
                  >
                    {verse.verse_num}.{" "}
                    {highlightWordBeforeTag(verse.verse_text, codeNumber)}
                  </ConcordanceChapterVerse>
                );
              })}
            </>
          ) : (
            <LoaderContainer>
              <SpinnerLoader />
            </LoaderContainer>
          )}
        </ConcordanceChapterVerses>
      </ConcordanceChapterContainer>
    </ConcordanceChapterWrapper>
  );
};

export default memo(ConcordanceChapter);
