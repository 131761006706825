import React, { memo, useCallback, forwardRef } from "react";
import styled from "styled-components";
import { BIBLE_BOOK_NAMES_DATA } from "../../../bible_page_data";
import { highlightWordBeforeTag } from ".";

const ConcordanceVerseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
`;

const ConcordanceVerseText = styled.div`
  width: 100%;
  padding: 8px 10px;
  border-radius: 15px;
  border: 1px solid #5172ea;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const ConcordanceVerseAddress = styled.div`
  display: flex;
  align-items: center;
  margin: -9px 18px 0 0;
  padding: 0 6px;
  min-height: 18px;
  max-height: 18px;
  width: max-content;
  min-width: 60px;
  border-radius: 4px;
  border: 1px solid #2440d2;
  background: #eaeaea;
`;

const ConcordanceVerse = forwardRef(
  (
    {
      verse,
      currentCodeStrong,
      currentTranslation,
      setCurrentScreen,
      setConcordanceSelectedVerse,
    },
    ref
  ) => {
    const codeNumber = currentCodeStrong?.slice(1);
    const bookName =
      BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale]?.[verse.book_code]
        ?.name;
    const address =
      (bookName || verse.book_code_orig) +
      " " +
      verse.chapter_num +
      ":" +
      verse.verse_num;

    const onClickVerse = useCallback(
      (event) => {
        event.stopPropagation();
        setConcordanceSelectedVerse(verse);
        setCurrentScreen("chapter");
      },
      [verse]
    );

    return (
      <ConcordanceVerseContainer ref={ref} onClick={onClickVerse}>
        <ConcordanceVerseText>
          {highlightWordBeforeTag(verse.verse_text, codeNumber)}
        </ConcordanceVerseText>
        <ConcordanceVerseAddress>{address}</ConcordanceVerseAddress>
      </ConcordanceVerseContainer>
    );
  }
);

export default memo(ConcordanceVerse);
