import React from "react";

const AdminPageCalendarArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
    >
      <path
        d="M5.57712 11.6568L1 6.49977L5.57712 1.34277"
        stroke="#3461D6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdminPageCalendarArrow;
