import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import { COURSE_COMPLEXITY_LEVELS } from "..";

const CourseComplexityLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > h3 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }
`;

const ComplexityLevelContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  min-height: 36px;
  overflow: hidden;
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const ComplexityLevelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 36px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const ComplexityLevelArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const ComplexityLevelOptionsContainer = styled.div`
  padding: 4px;
`;

const ComplexityLevelOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const CourseComplexityLevel = ({
  courseSettingsData,
  setCourseSettingsData,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const selectedLevel =
    COURSE_COMPLEXITY_LEVELS[courseSettingsData.course_level] || "";

  const handleShowOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);

  const handleSelectOptions = useCallback((option) => {
    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_level: option,
    }));
    setShowOptions(false);
  }, []);

  return (
    <CourseComplexityLevelContainer>
      <h3>Уровень сложности*</h3>
      <ComplexityLevelContainer>
        <ComplexityLevelHeader
          showOptions={showOptions}
          onClick={handleShowOptions}
        >
          {selectedLevel || "Выберите уровень"}
          <ComplexityLevelArrowIconContainer showOptions={showOptions}>
            <AskQuestionArrowIcon />
          </ComplexityLevelArrowIconContainer>
        </ComplexityLevelHeader>

        {showOptions && (
          <ComplexityLevelOptionsContainer>
            <ComplexityLevelOptionItem
              onClick={() => handleSelectOptions("basic")}
            >
              Начальный
            </ComplexityLevelOptionItem>
            <ComplexityLevelOptionItem
              onClick={() => handleSelectOptions("medium")}
            >
              Средний
            </ComplexityLevelOptionItem>
            <ComplexityLevelOptionItem
              onClick={() => handleSelectOptions("advanced")}
            >
              Продвинутый
            </ComplexityLevelOptionItem>
          </ComplexityLevelOptionsContainer>
        )}
      </ComplexityLevelContainer>
    </CourseComplexityLevelContainer>
  );
};

export default memo(CourseComplexityLevel);
