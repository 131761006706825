import React, { useCallback, useState } from "react";
import styled from "styled-components";
import ChatsListItem from "./chats_list_item";
import SearchChats from "../search_chats";

const ChatsListWrapper = styled.div`
  width: 300px;
  height: calc(100vh - 80px);
  border-right: 1px solid #cacaca;

  @media (max-width: 1024px) {
    height: calc(100vh - 96px);
  }

  @media (max-width: 790px) {
    width: 270px;
  }

  @media (max-width: 744px) {
    padding-top: 47px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-right: none;
    height: calc(100vh - 96px);
  }

  @media (max-width: 425px) {
    padding: 47px 10px 0;
    width: 100%;
  }
`;

const ChatsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
  width: 100%;
  overflow: auto;

  @media (max-width: 1024px) {
    max-height: calc(100vh - 151px);
  }

  @media (max-width: 744px) {
    max-height: calc(100vh - 299px);
    padding-bottom: 5px;
  }
`;

const ChatsPageTitle = styled.div`
  display: none;
  font-size: 26px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.39px;
  color: #383838;
  margin-left: 25px;
  padding-top: 38px;

  @media (max-width: 744px) {
    display: block;
    height: 100px;
  }
`;
const SearchChatsStyled = styled(SearchChats)`
  height: 54px;
  width: 100%;
  padding: 0 28px;
  border-bottom: 1px solid #cacaca;
`;

const ChatsList = ({ chats, selectedChatId, onChatClick }) => {
  const [searchInputValue, setSearchInputValue] = useState("");

  const setChatId = useCallback((id) => () => onChatClick(id), [onChatClick]);

  const currentChats = chats.filter((chat) =>
    chat.title.toLowerCase().includes(searchInputValue.toLowerCase())
  );

  return (
    <ChatsListWrapper>
      <ChatsPageTitle>Chats</ChatsPageTitle>
      <SearchChatsStyled
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      />
      <ChatsListContainer>
        {currentChats.map((chat) => (
          <ChatsListItem
            chat={chat}
            setChatId={setChatId}
            selectedChatId={selectedChatId}
            key={chat.id + chat.title}
          />
        ))}
      </ChatsListContainer>
    </ChatsListWrapper>
  );
};

export default ChatsList;
