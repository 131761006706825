import React from "react";

const CheckLargeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5751 3.88368C24.1416 4.43692 24.1416 5.33391 23.5751 5.88715L9.06965 20.0538C8.50317 20.6071 7.58474 20.6071 7.01826 20.0538L0.424856 13.6144C-0.141619 13.0612 -0.141619 12.1642 0.424856 11.611C0.991331 11.0577 1.90977 11.0577 2.47624 11.611L8.04396 17.0486L21.5238 3.88368C22.0902 3.33044 23.0087 3.33044 23.5751 3.88368Z"
        fill="url(#paint0_linear_7304_6993)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7304_6993"
          x1="11.4199"
          y1="0.34375"
          x2="11.4199"
          y2="21.9688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckLargeIcon;
