import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../../../../common/components/checkbox";
import AskQuestionArrowIcon from "../../../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";
import AdminPageWhiteArrowIcon from "../../../../../../common/assets/icons/admin_page_icons/admin_page_white_arrow_icon";
import {
  UserRoleSetCount,
  UserRoleSetCountContainer,
  UserTeachingExtendedSettings,
  WhiteArrowLeft,
  WhiteArrowRight,
} from "../../user_roles_styled_components";
import MentorUserStudents from "./mentor_user_students";
import {
  AdminPageSettingsHeader,
  AdminPageSettingsHeaderArrow,
} from "../../../../admin_page_components/admin_page_styled_components";

const MentorUser = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  usersList,
  setUsersList,
  setSelectedUserInfo,
  setUsersListByRole,
  setOpenUsersListModal,
}) => {
  const [extendedUserIsMentor, setExtendedUserIsMentor] = useState(false);

  const isInvite = userRolesSettingsData.user_type === "invite";
  const userIsMentor = userRolesSettingsData.user_access.is_mentor;
  const countOfStudents =
    userRolesSettingsData.user_access.mentor_role.max_disciples_amount;
  const students = userRolesSettingsData.user_access.mentor_role.user_disciples;

  useEffect(() => {
    if (!userIsMentor) {
      setExtendedUserIsMentor(false);
    }
  }, [userIsMentor]);

  const handleUserIsMentor = useCallback(
    (event) => {
      event.stopPropagation();
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          is_mentor: !prevState.user_access.is_mentor,
        },
      }));

      setUsersList((prevState) =>
        prevState.map((user) =>
          user._id === userRolesSettingsData._id || user._id === "new_invite"
            ? {
                ...user,
                user_access: {
                  ...user.user_access,
                  is_mentor: !user.user_access.is_mentor,
                },
              }
            : user
        )
      );
    },
    [userRolesSettingsData]
  );

  const handleExtendUserIsMentor = useCallback(() => {
    if (userIsMentor) {
      setExtendedUserIsMentor((prevState) => !prevState);
    }
  }, [userIsMentor]);

  const handleMinusCountOfStudents = useCallback(() => {
    if (countOfStudents > 1) {
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          mentor_role: {
            ...prevState.user_access.mentor_role,
            max_disciples_amount:
              prevState.user_access.mentor_role.max_disciples_amount - 1,
          },
        },
      }));
    }
  }, [countOfStudents]);

  const handlePlusCountOfStudents = useCallback(() => {
    if (countOfStudents < 500) {
      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_access: {
          ...prevState.user_access,
          mentor_role: {
            ...prevState.user_access.mentor_role,
            max_disciples_amount:
              prevState.user_access.mentor_role.max_disciples_amount + 1,
          },
        },
      }));
    }
  }, [countOfStudents]);

  return (
    <div>
      <AdminPageSettingsHeader
        clickable={userIsMentor}
        onClick={handleExtendUserIsMentor}
      >
        <Checkbox
          checked={userIsMentor}
          onClickCheckbox={handleUserIsMentor}
          color={"#1e1e1e"}
          fontSize={"14px"}
        >
          Наставник
        </Checkbox>
        {userIsMentor && (
          <AdminPageSettingsHeaderArrow extendedSection={extendedUserIsMentor}>
            <AskQuestionArrowIcon />
          </AdminPageSettingsHeaderArrow>
        )}
      </AdminPageSettingsHeader>

      {extendedUserIsMentor && (
        <UserTeachingExtendedSettings>
          <UserRoleSetCountContainer>
            <UserRoleSetCount>
              <WhiteArrowLeft onClick={handleMinusCountOfStudents}>
                <AdminPageWhiteArrowIcon />
              </WhiteArrowLeft>
              <h5>{countOfStudents}</h5>
              <WhiteArrowRight onClick={handlePlusCountOfStudents}>
                <AdminPageWhiteArrowIcon />
              </WhiteArrowRight>
            </UserRoleSetCount>
            Максимум учеников
          </UserRoleSetCountContainer>

          {!isInvite && (
            <MentorUserStudents
              students={students}
              usersList={usersList}
              setSelectedUserInfo={setSelectedUserInfo}
              setUserRolesSettingsData={setUserRolesSettingsData}
              userRolesSettingsData={userRolesSettingsData}
              setUsersListByRole={setUsersListByRole}
              setOpenUsersListModal={setOpenUsersListModal}
            />
          )}
        </UserTeachingExtendedSettings>
      )}
    </div>
  );
};

export default MentorUser;
