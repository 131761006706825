import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AskQuestionArrowIcon from "../../../../common/assets/icons/home_page_icons/ask_question_arrow_icon";

const CREATE_INVITE_LANG = {
  uk: { name: "Украинский", code: "uk" },
  ru: { name: "Русский", code: "ru" },
  en: { name: "Английский", code: "en" },
};

const CreateUserInviteContainer = styled.div`
  position: relative;
`;

const CreateUserInviteEmailInput = styled.input`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0 15px;
  border-radius: 8px;
  background: #fff;
  color: #383838;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${({ emailInputError }) => emailInputError && "border: 1px solid red;"}

  &: focus {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    transition: 0.2s;
  }
`;

const SelectInviteLangContainer = styled.div`
  margin-top: 28px;
  background: #fff;
  border-radius: 8px;
  min-height: 30px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const SelectInviteLangHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 0 14px;
  align-items: center;
  gap: 10px;
  background: #fff;
  color: #828282;
  font-size: 16px;
  border-bottom: ${({ showOptions }) =>
    showOptions ? "1px solid #cacaca;" : "1px solid transparent;"};
`;

const SelectInviteLangArrowIconContainer = styled.div`
  padding-top: 3px;
  ${({ showOptions }) => showOptions && "transform: rotate(180deg);"}
`;

const SelectInviteLangOptionsContainer = styled.div`
  padding: 4px;
`;

const SelectInviteLangOptionItem = styled.div`
  padding: 8px;
  color: #383838;
  font-size: 16px;
  border-radius: 6px;

  &: hover {
    background: #e0e0fb;
    transition: 0.2s;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -17px;
  left: 7px;
  color: red;
  font-size: 12px;
`;

const CreateUserInvite = ({
  userRolesSettingsData,
  setUserRolesSettingsData,
  setUsersList,
  emailInputError,
}) => {
  const emailInputRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  const handleEmailInputValue = useCallback((event) => {
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_email: event.target.value,
    }));

    setUsersList((prevState) =>
      prevState.map((user) =>
        user._id === "new_invite"
          ? { ...user, user_email: event.target.value }
          : user
      )
    );
  }, []);

  const handleShowOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);

  const handleSelectOptions = useCallback((event, option) => {
    event.stopPropagation();
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_lang: option,
    }));
    setShowOptions(false);
  }, []);

  useEffect(() => {
    if (emailInputError) {
      emailInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [emailInputError]);

  return (
    <CreateUserInviteContainer>
      {emailInputError && <ErrorMessage>{emailInputError}</ErrorMessage>}
      <CreateUserInviteEmailInput
        ref={emailInputRef}
        placeholder="Почта пользователя*"
        emailInputError={!!emailInputError}
        value={userRolesSettingsData.user_email}
        onChange={handleEmailInputValue}
      />

      <SelectInviteLangContainer>
        <SelectInviteLangHeader
          showOptions={showOptions}
          onClick={handleShowOptions}
        >
          {CREATE_INVITE_LANG[userRolesSettingsData.user_lang]?.name ||
            "Выберите язык приглашения"}
          <SelectInviteLangArrowIconContainer showOptions={showOptions}>
            <AskQuestionArrowIcon />
          </SelectInviteLangArrowIconContainer>
        </SelectInviteLangHeader>

        {showOptions && (
          <SelectInviteLangOptionsContainer>
            {Object.values(CREATE_INVITE_LANG).map((language, index) => {
              return (
                <SelectInviteLangOptionItem
                  key={language + index}
                  onClick={(e) => handleSelectOptions(e, language.code)}
                >
                  {language.name}
                </SelectInviteLangOptionItem>
              );
            })}
          </SelectInviteLangOptionsContainer>
        )}
      </SelectInviteLangContainer>
    </CreateUserInviteContainer>
  );
};

export default memo(CreateUserInvite);
