import React, { memo, useCallback } from "react";
import styled from "styled-components";
import AdminPagePersonPreview from "../../../../admin_page_components/admin_page_person_preview";
import PlusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import {
  AddUserButton,
  AddUserButtonContainer,
} from "../../../../admin_page_components/admin_page_styled_components";

const LeaderUserWardsContainer = styled.div`
  padding: 10px 0 8px;

  & > h5 {
    color: #828282;
    font-size: 10px;
    line-height: 12px;
    padding: 0 10px;
  }
`;

const LeaderUserWardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 9px;
  padding: 0 10px;
  max-height: 320px;
  overflow: auto;
`;

const LeaderUserWards = ({
  wards,
  setSelectedUserInfo,
  setUserRolesSettingsData,
  userRolesSettingsData,
  usersList,
  setUsersListByRole,
  setOpenUsersListModal,
}) => {
  const onRemoveWard = useCallback((event, removedWardId) => {
    event.stopPropagation();
    setUserRolesSettingsData((prevState) => ({
      ...prevState,
      user_access: {
        ...prevState.user_access,
        leader_role: {
          ...prevState.user_access.leader_role,
          user_wards: prevState.user_access.leader_role.user_wards.filter(
            (user) => user._id !== removedWardId
          ),
        },
      },
    }));
  }, []);

  const openUsersList = useCallback(
    (roleTitle) => {
      const wards = userRolesSettingsData.user_access.leader_role.user_wards;
      const filteredUsers = usersList.filter(
        (user) =>
          user.user_name &&
          user._id !== userRolesSettingsData._id &&
          user.user_access.is_mentor &&
          !wards.find((ward) => ward._id === user._id)
      );

      setUsersListByRole(filteredUsers);
      setOpenUsersListModal(roleTitle);
    },
    [usersList, userRolesSettingsData]
  );

  return (
    <LeaderUserWardsContainer>
      <h5>Подопечные: {wards.length}</h5>
      <LeaderUserWardsList>
        {wards.map((ward) => {
          return (
            <AdminPagePersonPreview
              key={ward._id}
              setSelectedUserInfo={setSelectedUserInfo}
              onRemoveButton={onRemoveWard}
              personId={ward._id}
              modalWindow={"admin_users_info"}
            />
          );
        })}
      </LeaderUserWardsList>

      <AddUserButtonContainer onClick={() => openUsersList("wards")}>
        <AddUserButton>
          <PlusIcon />
        </AddUserButton>
      </AddUserButtonContainer>
    </LeaderUserWardsContainer>
  );
};

export default memo(LeaderUserWards);
