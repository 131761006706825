import React, { useCallback } from "react";
import BlackArrowDownIcon from "../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
  QuestionsFilterOptionItem,
  QuestionsFilterOptionsContainer,
} from "../../question_styled_components";

const QuestionsFilterByStatus = ({
  extendedOption,
  hadleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  const handleStatusFilter = useCallback((status) => {
    setQuestionsFilterSettings((prevState) => ({
      ...prevState,
      question_status: status,
    }));
  }, []);

  return (
    <QuestionsFilterOption
      isExtended={extendedOption === "status"}
      onClick={(e) => hadleExtendOptions(e, "status")}
    >
      Статус
      <QuestionsFilterOptionArrow isExtended={extendedOption === "status"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>
      {extendedOption === "status" && (
        <QuestionsFilterOptionsContainer>
          <QuestionsFilterOptionItem
            onClick={() => handleStatusFilter("noanswer")}
          >
            Без ответа
          </QuestionsFilterOptionItem>
          <QuestionsFilterOptionItem
            onClick={() => handleStatusFilter("answered")}
          >
            Отвеченый
          </QuestionsFilterOptionItem>
        </QuestionsFilterOptionsContainer>
      )}
    </QuestionsFilterOption>
  );
};

export default QuestionsFilterByStatus;
