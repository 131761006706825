import React from "react";

const CopyIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3970_12641)">
        <path
          d="M15.0781 24H6.125C4.05719 24 2.375 22.3178 2.375 20.25V7.54688C2.375 5.47906 4.05719 3.79688 6.125 3.79688H15.0781C17.1459 3.79688 18.8281 5.47906 18.8281 7.54688V20.25C18.8281 22.3178 17.1459 24 15.0781 24ZM6.125 5.67188C5.09119 5.67188 4.25 6.51306 4.25 7.54688V20.25C4.25 21.2838 5.09119 22.125 6.125 22.125H15.0781C16.1119 22.125 16.9531 21.2838 16.9531 20.25V7.54688C16.9531 6.51306 16.1119 5.67188 15.0781 5.67188H6.125ZM22.5781 17.9062V3.75C22.5781 1.68219 20.8959 0 18.8281 0H8.42188C7.90405 0 7.48438 0.419678 7.48438 0.9375C7.48438 1.45532 7.90405 1.875 8.42188 1.875H18.8281C19.8619 1.875 20.7031 2.71619 20.7031 3.75V17.9062C20.7031 18.4241 21.1228 18.8438 21.6406 18.8438C22.1584 18.8438 22.5781 18.4241 22.5781 17.9062Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_3970_12641">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
