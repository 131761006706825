import React from "react";

const AdminPageHomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M14.9621 0.837431C15.2479 0.570986 15.6167 0.423828 15.9989 0.423828C16.381 0.423828 16.7499 0.570986 17.0356 0.837431L31.0879 13.9538C31.3817 14.2468 31.5554 14.6482 31.5725 15.0733C31.5896 15.4983 31.4487 15.9137 31.1795 16.2317C30.9104 16.5497 30.534 16.7454 30.13 16.7774C29.726 16.8094 29.326 16.6752 29.0144 16.4032L28.4898 15.9163V28.2948C28.4898 29.1645 28.1608 29.9986 27.5752 30.6135C26.9896 31.2285 26.1953 31.5739 25.3671 31.5739H6.63068C5.80248 31.5739 5.0082 31.2285 4.42257 30.6135C3.83695 29.9986 3.50795 29.1645 3.50795 28.2948V15.9163L2.98333 16.4032C2.67177 16.6752 2.27174 16.8094 1.86773 16.7774C1.46372 16.7454 1.08739 16.5497 0.818216 16.2317C0.549047 15.9137 0.408125 15.4983 0.42522 15.0733C0.442315 14.6482 0.616089 14.2468 0.909833 13.9538L14.9621 0.837431ZM6.63068 12.9979V28.2948H11.3148C11.3148 28.2948 19.2437 28.2948 20.683 28.2948C22.1223 28.2948 25.3671 28.2948 25.3671 28.2948V12.9996L15.9989 4.25588L6.63068 12.9979Z"
        fill="url(#paint0_linear_5890_5251)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5890_5251"
          x1="15.246"
          y1="-5.30229"
          x2="15.246"
          y2="34.3225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AdminPageHomeIcon;
