import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import AskQuestionInput from "./ask_question_input";
import AskQuestionBibleVerses from "./ask_question_bible_verses";
import AskQuestionRecipient from "./ask_question_recipient";
import AskQuestionButtons from "./ask_question_buttons";
import { BASE_API_URL } from "../../../../../common/endpoints";
import { useUserDataContext } from "../../../../../user_data_context";

export const AskQuestionLabel = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 8px;
  font-size: 10px;
  color: ${({ limitReached }) => (limitReached ? "red" : "#828282")};
`;

export const AskQuestionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: max-content;
  padding: 20px 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: 0.24px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  cursor: pointer;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.15);
    transition: 0.2s;
  }
`;

const AskQuestionModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 9999;
`;

const AskQuestionModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 480px;
  min-height: 310px;
  max-height: 94vh;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;
  cursor: default;
  z-index: 9999;
`;

const AskQuestionModalWindow = ({
  isOpen,
  askQuestionData,
  setOpenAskQuestionModal,
  setOpenFinalModal,
  setAskQuestionData,
  onCloseAskQuestionModalWindow,
}) => {
  const [activeSendButton, setActiveSendButton] = useState(false);
  const { userData } = useUserDataContext();

  const onClickSendButton = useCallback(
    (event, askQuestionData) => {
      event.stopPropagation();

      const questionData = {
        question_text: askQuestionData.question,
        question_bible_verses:
          askQuestionData.bibleVerses[0] === 1
            ? []
            : askQuestionData.bibleVerses,
        question_from_author: {
          _id: userData._id,
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
        },
        question_from_author_mentor: { _id: userData.user_mentor._id } || {},
        question_to_author: askQuestionData.recipient
          ? { _id: askQuestionData.recipient._id }
          : {},
        question_status: "noanswer",
        question_tags: "",
      };

      axios
        .post(BASE_API_URL + "/questions", questionData)
        .then((response) => setOpenFinalModal(true))
        .catch((error) => {
          console.error("Error making POST request:", error);
        })
        .finally(() => {
          setOpenAskQuestionModal(false);
        });
    },
    [userData]
  );

  const stopPropagationOnClick = useCallback(
    (event) => event.stopPropagation(),
    []
  );

  useEffect(() => {
    if (askQuestionData.question) {
      setActiveSendButton(true);
    } else setActiveSendButton(false);
  }, [askQuestionData]);

  return (
    <AskQuestionModalWindowWrapper
      isOpen={isOpen}
      onClick={onCloseAskQuestionModalWindow}
    >
      <AskQuestionModalWindowContainer onClick={stopPropagationOnClick}>
        <AskQuestionInput
          askQuestionData={askQuestionData}
          setAskQuestionData={setAskQuestionData}
        />
        <AskQuestionBibleVerses
          askQuestionData={askQuestionData}
          setAskQuestionData={setAskQuestionData}
        />
        <AskQuestionRecipient
          askQuestionData={askQuestionData}
          setAskQuestionData={setAskQuestionData}
          userData={userData}
        />
        <AskQuestionButtons
          activeSendButton={activeSendButton}
          onClickSendButton={onClickSendButton}
          askQuestionData={askQuestionData}
          onCloseAskQuestionModalWindow={onCloseAskQuestionModalWindow}
        />
      </AskQuestionModalWindowContainer>
    </AskQuestionModalWindowWrapper>
  );
};

export default memo(AskQuestionModalWindow);
