import React from "react";

const BibleStrongBibleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.23284 2.9983C6.23284 2.87077 6.12946 2.76739 6.00193 2.76739C5.8744 2.76739 5.77102 2.87077 5.77102 2.9983V4.15285H4.61647C4.48894 4.15285 4.38556 4.25623 4.38556 4.38376C4.38556 4.51129 4.48894 4.61467 4.61647 4.61467H5.77102V6.69286C5.77102 6.82039 5.8744 6.92377 6.00193 6.92377C6.12946 6.92377 6.23284 6.82039 6.23284 6.69286V4.61467H7.38739C7.51492 4.61467 7.6183 4.51129 7.6183 4.38376C7.6183 4.25623 7.51492 4.15285 7.38739 4.15285H6.23284V2.9983Z"
        fill="#5172EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.57 0.920111V11.0802C10.57 11.3607 10.3426 11.5882 10.062 11.5882H3.20752C2.73606 11.5882 2.28395 11.4008 1.95066 11.0673C1.61737 10.7338 1.43018 10.2816 1.43018 9.81015V2.19012C1.43018 1.71864 1.61737 1.26643 1.95066 0.932969C2.28395 0.599499 2.73606 0.412109 3.20752 0.412109H10.062C10.3426 0.412109 10.57 0.63955 10.57 0.920111ZM3.20752 1.42811C3.00569 1.42811 2.81207 1.50833 2.66927 1.65121C2.52646 1.79409 2.44618 1.98794 2.44618 2.19012V8.20352C2.68191 8.09174 2.94164 8.03214 3.20752 8.03214H9.55399V1.42811H3.20752ZM2.66927 10.3491C2.52646 10.2062 2.44618 10.0123 2.44618 9.81015C2.44618 9.60797 2.52646 9.41412 2.66927 9.27124C2.81207 9.12836 3.00569 9.04814 3.20752 9.04814H9.55399V10.5721H3.20752C3.00569 10.5721 2.81207 10.4919 2.66927 10.3491Z"
        fill="#5172EA"
      />
    </svg>
  );
};

export default BibleStrongBibleIcon;
