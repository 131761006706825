import React from "react";

const ReplyIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3970_12639)">
        <path
          d="M24.4947 20.3489C23.5508 12.64 17.141 6.77069 9.44068 6.43195V4.69446C9.44068 3.37463 8.72162 2.90625 8.10602 2.90625C7.6576 2.90625 7.20276 3.13367 6.75452 3.58191L1.32013 9.0163C0.226623 10.1098 0.226623 11.889 1.32013 12.9826L6.75452 18.4169C7.20276 18.8652 7.6576 19.0926 8.10602 19.0926C8.72162 19.0926 9.44068 18.6242 9.44068 17.3044V15.4464C10.3167 15.2754 11.2045 15.189 12.0875 15.189C16.4701 15.189 20.6354 17.3051 23.2296 20.8497C23.3651 21.0346 23.5779 21.1375 23.7973 21.1375C23.8837 21.1375 23.9714 21.1214 24.0556 21.0881C24.3532 20.9703 24.5337 20.6666 24.4947 20.3489ZM19.0805 15.4843C16.9367 14.3712 14.5186 13.7831 12.0875 13.7831C10.9132 13.7831 9.73182 13.9215 8.57587 14.1949C8.25873 14.27 8.03479 14.5532 8.03479 14.879V17.3044C8.03479 17.452 8.02015 17.5598 8.00348 17.6331C7.93976 17.5931 7.85315 17.5272 7.74878 17.4227L2.31421 11.9885C1.76892 11.443 1.76892 10.5559 2.31421 10.0104L7.7486 4.57599C7.85315 4.47162 7.93958 4.40552 8.00348 4.3656C8.02015 4.43903 8.03479 4.54669 8.03479 4.69446V7.11969C8.03479 7.50788 8.34955 7.82264 8.73774 7.82264C15.1479 7.82264 20.6894 12.0304 22.5463 17.9564C21.5115 16.9759 20.3415 16.1391 19.0805 15.4843Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_3970_12639">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReplyIcon;
