import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SelectedCheckboxLarge from "../../../../../../common/components/checkbox/selected_checkbox_large";
import UnselectedCheckboxLarge from "../../../../../../common/components/checkbox/unselected_checkbox_large";
import PlusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import MinusIcon from "../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/minus_icon";
import {
  PlusAndMinusButtonsContainer,
  PlusOrMinusButton,
} from "../../../create_lesson_components/create_lesson_styled_components";

const AnswerOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const AnswerOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const CheckboxIconsContainer = styled.div`
  position: relative;
  width: max-content;
  height: 100%;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
`;

const IconContainer = styled.div`
  height: 22px;
`;

const AnswerOptionInput = styled.textarea`
  flex: 1;
  resize: none;
  max-height: 268px;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const AnswerOption = ({ option, optionIndex, setSettingsBlockData }) => {
  const optionRef = useRef(null);

  const handleCheckbox = useCallback(() => {
    const updatedOption = { ...option, right_answer: !option.right_answer };

    setSettingsBlockData((prevState) => ({
      ...prevState,
      data: prevState.data.map((item, index) =>
        index === optionIndex ? updatedOption : item
      ),
    }));
  }, [option, optionIndex]);

  const handleOptionInputValue = useCallback(
    (event) => {
      const updatedOption = { ...option, option_text: event.target.value };

      setSettingsBlockData((prevState) => ({
        ...prevState,
        data: prevState.data.map((item, index) =>
          index === optionIndex ? updatedOption : item
        ),
      }));
    },
    [option, optionIndex]
  );

  useEffect(() => {
    if (optionRef.current && option.option_text) {
      optionRef.current.style.height = "auto";
      optionRef.current.style.height = optionRef.current.scrollHeight + "px";
    }
  }, [option.option_text, optionRef]);

  const onClickButton = useCallback(
    (button) => {
      setSettingsBlockData((prevState) => {
        if (button === "plus") {
          return {
            ...prevState,
            data: [
              ...prevState.data,
              { option_text: "", right_answer: false, user_answer: false },
            ],
          };
        } else {
          return {
            ...prevState,
            data: prevState.data.filter((_, index) => index !== optionIndex),
          };
        }
      });
    },
    [optionIndex]
  );

  return (
    <AnswerOptionWrapper>
      <AnswerOptionContainer>
        <CheckboxIconsContainer onClick={handleCheckbox}>
          <HiddenCheckbox
            checked={option.right_answer}
            onChange={handleCheckbox}
            aria-hidden="true"
          />
          {option.right_answer ? (
            <IconContainer>
              <SelectedCheckboxLarge alt="Checked" />
            </IconContainer>
          ) : (
            <IconContainer>
              <UnselectedCheckboxLarge alt="Unchecked" />
            </IconContainer>
          )}
        </CheckboxIconsContainer>

        <AnswerOptionInput
          placeholder={"Ответ " + (optionIndex + 1)}
          ref={optionRef}
          rows={1}
          type="text"
          value={option.option_text}
          onChange={handleOptionInputValue}
        />
      </AnswerOptionContainer>

      {!!optionIndex && (
        <PlusAndMinusButtonsContainer>
          {optionIndex > 1 && (
            <PlusOrMinusButton onClick={() => onClickButton("minus")}>
              <MinusIcon />
            </PlusOrMinusButton>
          )}
          <PlusOrMinusButton onClick={() => onClickButton("plus")}>
            <PlusIcon />
          </PlusOrMinusButton>
        </PlusAndMinusButtonsContainer>
      )}
    </AnswerOptionWrapper>
  );
};

export default memo(AnswerOption);
