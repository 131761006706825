import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CourseLesson from "./course_lesson";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";

const CourseLessonsContainer = styled.div`
  width: 506px;
  margin: 62px auto 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 514px) {
    margin: 38px auto 0;
    width: 380px;
    gap: 21px;
  }

  @media (max-width: 412px) {
    width: 330px;
  }
`;

const NoLessonInCourseMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #1e1e1e;
`;

const CourseLessons = ({ courseData, inComponent }) => {
  const timerRef = useRef(null);
  const navigate = useNavigate();

  const [showMessageId, setShowMessageId] = useState(null);

  const showLessonDuration = courseData.course_duration_is_enabled;

  const handleClickOnLesson = useCallback(
    (event, lessonId, lessonStatus, lessonNum) => {
      event.stopPropagation();

      if (lessonStatus === "current" && inComponent !== "admin_page") {
        console.log("current lesson");
      }

      if (lessonStatus === "unavailable" && inComponent !== "admin_page") {
        setShowMessageId(lessonId);
      }

      if (lessonStatus === "available") {
        navigate(
          `${APPLICATION_ROUTES.ADMIN_LESSON_PREVIEW}/${lessonId}?n=${lessonNum}`
        );
      }
    },
    [inComponent]
  );

  useEffect(() => {
    if (showMessageId) {
      timerRef.current = setTimeout(() => {
        setShowMessageId(null);
      }, 4500);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [showMessageId]);

  return (
    <CourseLessonsContainer>
      {courseData?.course_lessons?.length ? (
        <>
          {courseData.course_lessons.map((lesson, index) => (
            <CourseLesson
              key={lesson._id}
              lessonId={lesson._id}
              index={index}
              lessonNum={index + 1}
              showLessonDuration={showLessonDuration}
              inComponent={inComponent}
              showMessageId={showMessageId}
              setShowMessageId={setShowMessageId}
              handleClickOnLesson={handleClickOnLesson}
            />
          ))}
        </>
      ) : (
        <NoLessonInCourseMessage>
          В курсе пока нет уроков...
        </NoLessonInCourseMessage>
      )}
    </CourseLessonsContainer>
  );
};

export default memo(CourseLessons);
