import React, { memo, useEffect, useState } from "react";
import {
  ButtonGroup,
  SettingsButtonsContainer,
  ToolbarButton,
} from "../editor_styled_components";
import TextSettingsButtonsHeader from "./text_settings_buttons_header";
import styled from "styled-components";
import { LESSON_COLORS_2 } from "../../../utils/enums";

const TextBackground = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: ${({ background }) => background};
  ${({ background }) =>
    background === "#FFF" &&
    "box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"};
`;

const BackgroundsSettingsContainer = styled(SettingsButtonsContainer)`
  width: 314px;
  flex-wrap: wrap;
  flex-direction: row;
`;

const colorStyles = {
  LIGHT_BLUE: "#46D7EB",
  PERIWINKLE: "#98AEFD",
  ORCHID: "#EF83E4",
};

const TextBackgroundButtons = ({
  currentStyle,
  toggleInlineStyle,
  openTextSettings,
  setOpenTextSettings,
}) => {
  const [activeColor, setActiveColor] = useState("#FFF");

  const colorStyles = LESSON_COLORS_2.LIGHT_THEME.GRADIENTS;

  useEffect(() => {
    for (const [style, color] of Object.entries(colorStyles)) {
      if (currentStyle.has(style)) {
        setActiveColor(color);
        return;
      }
    }
    setActiveColor("#FFF");
  }, [currentStyle]);

  const handleToggleInlineStyle = (style) => {
    toggleInlineStyle(style);
    setActiveColor(colorStyles[style]);
  };

  return (
    <ButtonGroup>
      <TextSettingsButtonsHeader
        isOpen={openTextSettings === "text_background"}
        setOpen={setOpenTextSettings}
        buttonsGroup={"text_background"}
      >
        <TextBackground background={activeColor} />
      </TextSettingsButtonsHeader>

      {openTextSettings === "text_background" && (
        <BackgroundsSettingsContainer>
          {Object.entries(colorStyles).map(([style, color]) => (
            <ToolbarButton
              key={style}
              active={currentStyle.has(style)}
              onClick={() => handleToggleInlineStyle(style)}
            >
              <TextBackground background={color} />
            </ToolbarButton>
          ))}
        </BackgroundsSettingsContainer>
      )}
    </ButtonGroup>
  );
};

export default memo(TextBackgroundButtons);
