import React, { memo } from "react";
import styled from "styled-components";

const MainInfoContainer = styled.div``;

const StrongLexeme = styled.div`
  color: #2250f2;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
`;

const StrongShortDefinition = styled.div`
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
`;

const ItemContainer = styled.p``;

const ItemTitle = styled.span`
  color: #828282;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-right: 5px;
`;

const ItemValue = styled.span`
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
`;

const BibleStrongModalMainInfo = ({ strongsData }) => {
  return (
    <MainInfoContainer>
      <StrongLexeme>{strongsData.strong_lexeme}</StrongLexeme>
      <StrongShortDefinition>
        {strongsData.strong_short_def}
      </StrongShortDefinition>
      <ItemContainer>
        <ItemTitle>Оригинал:</ItemTitle>
        <ItemValue>
          <b>{strongsData.strong_lexeme}</b>
        </ItemValue>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>Транслитерация:</ItemTitle>
        <ItemValue>
          <b>{strongsData.strong_translit}</b>
        </ItemValue>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>Произношение:</ItemTitle>
        <ItemValue>
          <b>{strongsData.strong_pronun}</b>
        </ItemValue>
      </ItemContainer>
    </MainInfoContainer>
  );
};

export default memo(BibleStrongModalMainInfo);
