import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  AdminPageDiplomaIcon,
  AdminPageAddGroupIcon,
  AdminPageHomeIcon,
  AdminPageQuestionsIcon,
  AdminPageUsersIcon,
} from "../../common/assets/icons/admin_page_icons/admin_page_sidebar_icons";
import { APPLICATION_ROUTES } from "../../common/utils/routes";

const sidebarItems = [
  { icon: AdminPageHomeIcon, path: APPLICATION_ROUTES.ADMIN_HOME },
  { icon: AdminPageDiplomaIcon, path: APPLICATION_ROUTES.ADMIN_COURSES },
  //   { icon: AdminPageAddGroupIcon, path: APPLICATION_ROUTES.ADMIN_GROUPS },
  { icon: AdminPageQuestionsIcon, path: APPLICATION_ROUTES.ADMIN_QUESTIONS },
  { icon: AdminPageUsersIcon, path: APPLICATION_ROUTES.ADMIN_USERS },
];

const AdminPageSidebarWrapper = styled.div`
  padding-top: 22px;
`;

const AdminPageSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 64px;
`;

const AdminPageSidebarItem = styled.div`
  display: flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  background: var(--1, #fff);
  box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.12);
  opacity: 0.6;
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }

  ${({ isActive }) =>
    isActive &&
    `
    width: 64px;
    height: 64px;
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    opacity: 1;
  `}
`;

const AdminPageSidebar = () => {
  const location = useLocation();

  return (
    <AdminPageSidebarWrapper onClick={(e) => e.stopPropagation()}>
      <AdminPageSidebarContainer>
        {sidebarItems.map((item, index) => {
          const Icon = item.icon;
          const active = location.pathname.includes(item.path);

          return (
            <Link key={index + item.path} to={item.path}>
              <AdminPageSidebarItem isActive={active}>
                <Icon />
              </AdminPageSidebarItem>
            </Link>
          );
        })}
      </AdminPageSidebarContainer>
    </AdminPageSidebarWrapper>
  );
};

export default memo(AdminPageSidebar);
