import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../../../../user_data_context";
import { insertUserNameInText } from "../../../../../common/utils/functions/admin_page_functions";
import TestAnswerOption from "./test_answer_option";

const TestPreviewContainer = styled.div`
  width: 100%;
  min-height: 160px;
  padding: 17px 20px;
  background: #fff;
  border-radius: 25px;
`;

const TestPreviewQuestion = styled.div`
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;

  & > p {
    color: gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

const TestAnswerOptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
`;

const TestLessonComponent = ({
  componentData,
  lessonIsFinished,
  setLessonData,
  inComponent,
}) => {
  const { userData } = useUserDataContext();
  const [showAllAnswers, setShowAllAnswers] = useState(false);

  useEffect(() => {
    const allCorrectAnswersMatch = componentData.data.every(
      (item) => item.right_answer === item.user_answer
    );

    if (allCorrectAnswersMatch) {
      setShowAllAnswers(false);
    } else {
      setShowAllAnswers(true);
    }
  }, [componentData]);

  const currentUserName = userData.user_name || "";

  const correctAnswers = componentData?.data?.filter(
    (answer) => answer.right_answer
  );

  const multipleCorrectAnswers = correctAnswers?.length > 1 ? true : false;

  return (
    <TestPreviewContainer>
      <TestPreviewQuestion>
        {insertUserNameInText(componentData.question, currentUserName)}{" "}
        <p>{multipleCorrectAnswers && "(Несколько правильных ответов)"}</p>
      </TestPreviewQuestion>

      <TestAnswerOptionsList>
        {componentData?.data?.map((option, index) => {
          return (
            <TestAnswerOption
              key={index + 1 + "-preview_option"}
              option={option}
              componentData={componentData}
              setLessonData={setLessonData}
              multipleCorrectAnswers={multipleCorrectAnswers}
              lessonIsFinished={lessonIsFinished}
              inComponent={inComponent}
              showAllAnswers={showAllAnswers}
            />
          );
        })}
      </TestAnswerOptionsList>
    </TestPreviewContainer>
  );
};

export default memo(TestLessonComponent);
