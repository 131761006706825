import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import BookNavigation from "./book_navigation";
import SimpleListLoader from "./simple_list_loader";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import { BASE_API_URL } from "../../../../common/endpoints";
import { BIBLE_BOOK_NAMES_DATA } from "../../bible_page_data";

const BookListNavigationWrapper = styled.div`
  overflow: hidden;
  background: #fff;
  border-radius: 15px;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const BookListNavigationContainer = styled.div`
  padding: 7px 10px 8px 12px;
  overflow-y: auto;
  max-height: calc(100% - 30px);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BiblePartsButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;
  width: 100%;
  ${({ activeMainSection }) =>
    activeMainSection !== "bible_search" && "border-bottom: 1px solid #cacaca;"}
`;

const BiblePartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: ${({ isSelected }) => (isSelected ? "#1E1E1E" : "#828282")};
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
`;

const BookListNavigation = ({
  currentTranslation,
  activeMainSection,
  expandedSectionName,
}) => {
  const [searchParams] = useSearchParams();
  const scrollContainerRef = useRef(null);

  const [expandedBookID, setExpandedBookID] = useState("");
  const [currentTranslationData, setCurrentTranslationData] = useState([]);
  const [selectedTestament, setSelectedTestament] = useState("ot"); // ot, nt
  const [sortedBooksByTestaments, setSortedBooksByTestaments] = useState([]);
  const [isTranslationLoading, setTranslationLoading] = useState(false);

  const bibleBookId = searchParams.get("bookId");

  const handleTestaments = useCallback((part) => {
    setSelectedTestament(part);
  }, []);

  useEffect(() => {
    const currentTestament = currentTranslationData?.find(
      (book) => book.book_code === bibleBookId
    );

    currentTestament && setSelectedTestament(currentTestament.book_place);
  }, [currentTranslationData, bibleBookId]);

  useEffect(() => {
    if (bibleBookId) {
      setExpandedBookID(bibleBookId);
    }
  }, [bibleBookId]);

  useEffect(() => {
    if (currentTranslationData) {
      const sortByTestaments = currentTranslationData
        .filter((book) => book.book_place === selectedTestament)
        .sort((a, b) => a.number - b.number);

      setSortedBooksByTestaments(sortByTestaments);
    }
  }, [currentTranslationData, selectedTestament]);

  useEffect(() => {
    if (currentTranslation?.bible_code) {
      setTranslationLoading(true);
      fetch(
        `${BASE_API_URL}/bible/books?bible=${currentTranslation.bible_code}`
      )
        .then((response) => response.json())
        .then((bibleData) => {
          const updateBooksNames = bibleData.books.map((book) => {
            if (BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale]) {
              return {
                ...book,
                book_name:
                  BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale][
                    book.book_code
                  ].name,
                number:
                  BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale][
                    book.book_code
                  ].number,
              };
            } else {
              return book;
            }
          });
          setCurrentTranslationData(updateBooksNames);
          if (bibleBookId) {
            setExpandedBookID(bibleBookId);
          }
        })
        .finally(() => {
          setTranslationLoading(false);
        });
    }
  }, [currentTranslation]);

  return (
    <BookListNavigationWrapper>
      <BiblePartsButtonsContainer activeMainSection={activeMainSection}>
        <BiblePartButton
          isSelected={selectedTestament === "ot"}
          onClick={() => handleTestaments("ot")}
        >
          Ветхий Завет
        </BiblePartButton>
        <VerticalLineStyled />
        <BiblePartButton
          isSelected={selectedTestament === "nt"}
          onClick={() => handleTestaments("nt")}
        >
          Новый Завет
        </BiblePartButton>
      </BiblePartsButtonsContainer>

      <>
        {!isTranslationLoading ? (
          <BookListNavigationContainer ref={scrollContainerRef}>
            {currentTranslationData ? (
              sortedBooksByTestaments.map((book) => (
                <BookNavigation
                  key={book.book_code}
                  book={book}
                  scrollContainerRef={scrollContainerRef}
                  isExpanded={expandedBookID === book.book_code}
                  expandedSectionName={expandedSectionName}
                  setIsActive={setExpandedBookID}
                />
              ))
            ) : (
              <div>Пожалуйста, выберите перевод Библии в секции переводов.</div>
            )}
          </BookListNavigationContainer>
        ) : (
          <SimpleListLoader />
        )}
      </>
    </BookListNavigationWrapper>
  );
};

export default memo(BookListNavigation);
