import React, { useEffect } from "react";
//import { useParams } from "react-router-dom";
import styled from "styled-components";
import GroupPageHeader from "./group_page_header";
import RegistEndsAndPlacesInfo from "../study_page_components/regist_ends_and_places_info";
import GroupPageAuthorMessage from "./group_page_author_message";
import GroupDescription from "./group_description";

const GroupPageContainer = styled.div`
  padding-bottom: 70px;

  @media (max-width: 412px) {
    padding-bottom: 90px;
  }
`;

const RegistEndsAndPlacesInfoContainer = styled.div`
  margin: 0 auto;
  padding-top: 21px;
  width: 508px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 25px 25px;

  @media (max-width: 528px) {
    width: 100%;
    height: 71px;
    padding-top: 17px;
    border-radius: 0;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
  }
`;

const GroupPage = () => {
  //const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <GroupPageContainer>
      <GroupPageHeader />

      <RegistEndsAndPlacesInfoContainer>
        <RegistEndsAndPlacesInfo blockVariant="SMALL_SIZE" />
      </RegistEndsAndPlacesInfoContainer>

      <GroupPageAuthorMessage />
      <GroupDescription />
    </GroupPageContainer>
  );
};

export default GroupPage;
