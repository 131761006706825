import React, { memo } from "react";
import styled from "styled-components";

const CardShadowWrapperStyles = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.56) 0%,
    rgba(0, 0, 0, 0) 49.29%
  );
  border-radius: 37px;

  @media (max-width: 880px) {
    border-radius: 31px;
  }

  @media (max-width: 800px) {
    border-radius: 25px;
  }

  @media (max-width: 744px) {
    border-radius: 20px;
  }

  @media (max-width: 670px) {
    border-radius: 25px;
  }

  @media (max-width: 412px) {
    border-radius: 22x;
  }
`;

const CardShadowWrapper = ({ children }) => {
  return <CardShadowWrapperStyles>{children}</CardShadowWrapperStyles>;
};

export default memo(CardShadowWrapper);
