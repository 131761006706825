import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API;

if (process.env.NODE_ENV == "development" && process.env.REACT_APP_USER) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${process.env.REACT_APP_USER}`;
  //console.log("REACT_APP_USER", process.env.REACT_APP_USER);
} else {
  //console.log("user from local storage");
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("access_token");
}

export default axios;
