import React, { memo, useEffect, useState } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import styled from "styled-components";
import { useUserDataContext } from "../../../../user_data_context";
import {
  replacePlaceholderWithStudentName,
  entityStyleFn,
  getInlineStyles,
  replaceSpacesWithNbsp,
} from "../../../../common/components/text_editor/text_editor_functions";
import { TextEditorViewComponent } from "../../../../common/components/text_editor/editor_styled_components";

const TextComponentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 24px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: ${({ background }) => background || "transparent"};
  ${({ withBackground }) =>
    withBackground &&
    `
  border-radius: 15px;
  padding: 20px;
  color: #fff;
  `}

  ${({ isGroup }) =>
    isGroup &&
    `border-radius: 25px;
     padding: 18px 0px 18px 20px;
     border-left: 3px solid #f8d254;
   `}

   @media (max-width: 720px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const TextContent = styled(TextEditorViewComponent)`
  width: ${({ withImage }) => (withImage ? "49%" : "100%")};

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 100%;
  min-width: 48%;
  max-width: 48%;
  object-fit: contain;
  border-radius: 15px;

  @media (max-width: 720px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const NoImage = styled.div`
  padding: 20px;
  color: #383838;
  min-width: 49%;
  max-width: 49%;
  height: 180px;
  border-radius: 10px;
  background: #fff;
  line-height: 20px;
`;

const TexLessonComponent = ({
  componentData,
  setTextEditorComponentHtml = () => {},
}) => {
  const { userData } = useUserDataContext();
  const [html, setHtml] = useState("");

  const currentUserName = userData.user_name || "";

  useEffect(() => {
    if (componentData.contentState?.blocks?.length) {
      try {
        let contentState = convertFromRaw(componentData.contentState);

        let htmlContent = stateToHTML(contentState, {
          entityStyleFn,
          inlineStyles: getInlineStyles(),
        });

        htmlContent = replacePlaceholderWithStudentName(
          htmlContent,
          currentUserName
        );
        htmlContent = replaceSpacesWithNbsp(htmlContent);

        setHtml(htmlContent);
      } catch (error) {
        console.error("Failed to convert contentState from raw:", error);
      }
    }
  }, [componentData.contentState, currentUserName]);

  useEffect(() => {
    setTextEditorComponentHtml({});
  }, [html]);

  return (
    <TextComponentContainer
      isGroup={componentData.is_group}
      background={componentData.background}
      withBackground={componentData.background !== "transparent"}
    >
      <TextContent
        dangerouslySetInnerHTML={{ __html: html }}
        withImage={componentData.with_image}
        withBackground={componentData.background !== "transparent"}
      />

      {componentData.with_image &&
        (componentData.image_url ? (
          <Image src={componentData.image_url} alt="Component image" />
        ) : (
          <NoImage>Загрузите изображение...</NoImage>
        ))}
    </TextComponentContainer>
  );
};

export default memo(TexLessonComponent);
