import React from "react";

const HeartIcon = ({ className, fill, stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="heart">
        <path
          id="Vector"
          d="M17.3671 3.84319C16.9415 3.41736 16.4361 3.07956 15.8799 2.84909C15.3237 2.61862 14.7275 2.5 14.1254 2.5C13.5234 2.5 12.9272 2.61862 12.371 2.84909C11.8147 3.07956 11.3094 3.41736 10.8838 3.84319L10.0004 4.72652L9.11709 3.84319C8.25735 2.98344 7.09129 2.50045 5.87542 2.50045C4.65956 2.50045 3.4935 2.98344 2.63376 3.84319C1.77401 4.70293 1.29102 5.86899 1.29102 7.08485C1.29102 8.30072 1.77401 9.46678 2.63376 10.3265L3.51709 11.2099L10.0004 17.6932L16.4838 11.2099L17.3671 10.3265C17.7929 9.90089 18.1307 9.39553 18.3612 8.83932C18.5917 8.2831 18.7103 7.68693 18.7103 7.08485C18.7103 6.48278 18.5917 5.88661 18.3612 5.33039C18.1307 4.77418 17.7929 4.26882 17.3671 3.84319Z"
          fill={fill || "white"}
          stroke={stroke || "white"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default HeartIcon;
