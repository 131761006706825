import React, { memo } from "react";
import { CreateLessonSkeletWrapper } from "../create_lesson_components/create_lesson_styled_components";
import { LESSON_COMPONENTS_MAPPING } from "..";

const CreateLessonSkeletComponent = ({
  firstElement,
  lastElement,
  settingsBlockData,
}) => {
  const CurrentSkeletComponent =
    LESSON_COMPONENTS_MAPPING?.[settingsBlockData?.type]?.component;

  return (
    <CreateLessonSkeletWrapper
      firstElement={firstElement}
      lastElement={lastElement}
    >
      <CurrentSkeletComponent componentData={settingsBlockData} />
    </CreateLessonSkeletWrapper>
  );
};

export default memo(CreateLessonSkeletComponent);
