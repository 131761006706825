import React from "react";

const SearchIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_3685_14896)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.10072 1.80501C4.62374 1.80501 1.8051 4.62365 1.8051 8.10063C1.8051 11.5776 4.62374 14.3963 8.10072 14.3963C11.5777 14.3963 14.3963 11.5776 14.3963 8.10063C14.3963 4.62365 11.5777 1.80501 8.10072 1.80501ZM0.00634766 8.10063C0.00634766 3.63023 3.63032 0.0062561 8.10072 0.0062561C12.5711 0.0062561 16.1951 3.63023 16.1951 8.10063C16.1951 12.571 12.5711 16.195 8.10072 16.195C3.63032 16.195 0.00634766 12.571 0.00634766 8.10063Z"
          fill="#383838"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5461 12.5461C12.8974 12.1949 13.4668 12.1949 13.818 12.5461L17.7303 16.4584C18.0816 16.8096 18.0816 17.3791 17.7303 17.7303C17.3791 18.0815 16.8096 18.0815 16.4584 17.7303L12.5461 13.818C12.1949 13.4668 12.1949 12.8973 12.5461 12.5461Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_3685_14896">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
