import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import SearchChats from "../../../../search_chats";
import { CHATS } from "../../../../../fake_chats_data";
import ForwardMessageToChatItem from "./forward_message_to_chat_item";
import { useAppContext } from "../../../../../../../app_context";

const ForwardMessageToChatWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const ForwardMessageToChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 297px;
  height: 437px;
  background: #fff;
  border-radius: 15px;
`;

const SearchChatsStyled = styled(SearchChats)`
  width: 100%;
  padding: 15px 20px;
`;

const ForwardMessageToChatsListContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 6px 0;
  border-bottom: 1px solid #cacaca;
  border-top: 1px solid #cacaca;
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  height: 27px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
`;

const ForwardMessageToChat = ({ isOpen, onClose }) => {
  const { setForwardMessage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const currentChats = CHATS.filter((chat) =>
    chat.title.toLowerCase().includes(searchInputValue.toLowerCase())
  );

  const handleCloseForwardMessage = useCallback((e) => {
    e.stopPropagation();
    onClose(false);
    setForwardMessage({});
  }, []);

  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    <ForwardMessageToChatWrapper
      isOpen={isOpen}
      onClick={handleCloseForwardMessage}
    >
      <ForwardMessageToChatContainer onClick={stopPropagationOnClick}>
        <SearchChatsStyled
          component={"forward"}
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
        />

        <ForwardMessageToChatsListContainer>
          {currentChats.map((chat, index) => (
            <ForwardMessageToChatItem
              key={index}
              chat={chat}
              onClose={onClose}
            />
          ))}
        </ForwardMessageToChatsListContainer>

        <CancelButton onClick={handleCloseForwardMessage}>Отмена</CancelButton>
      </ForwardMessageToChatContainer>
    </ForwardMessageToChatWrapper>
  );
};

export default ForwardMessageToChat;
