import React from "react";

const HeaderDonateIcon = () => (
  <svg
    width="33"
    height="29"
    viewBox="0 0 33 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7728 4.22726C28.067 3.52115 27.229 2.96102 26.3067 2.57886C25.3844 2.1967 24.3958 2 23.3975 2C22.3991 2 21.4106 2.1967 20.4883 2.57886C19.5659 2.96102 18.728 3.52115 18.0222 4.22726L16.5575 5.692L15.0927 4.22726C13.6671 2.80164 11.7335 2.00074 9.71742 2.00074C7.70129 2.00074 5.76774 2.80164 4.34212 4.22726C2.9165 5.65288 2.1156 7.58643 2.1156 9.60256C2.1156 11.6187 2.9165 13.5522 4.34212 14.9779L5.80686 16.4426L16.5575 27.1932L27.3081 16.4426L28.7728 14.9779C29.4789 14.2721 30.039 13.4341 30.4212 12.5118C30.8033 11.5895 31 10.6009 31 9.60256C31 8.60421 30.8033 7.61564 30.4212 6.69333C30.039 5.77102 29.4789 4.93304 28.7728 4.22726Z"
      fill="#F8D254"
      stroke="#F8D254"
      strokeWidth="3.31638"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeaderDonateIcon;
