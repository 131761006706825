import styled from "styled-components";

export const UserTeachingExtendedSettings = styled.div`
  border-top: 1px solid #cacaca;
  padding: 16px 0 10px;
`;

export const UserRoleSetCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #383838;
  font-size: 14px;
  padding: 0 10px;
`;

export const UserRoleSetCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76px;
  height: 24px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(81, 114, 234, 0.3) -18.38%,
    rgba(122, 90, 238, 0.3) 108.82%
  );
  overflow: hidden;

  & > h5 {
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const WhiteArrowLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background: rgb(199 194 247);
    transition: 0.2s;
  }
`;

export const WhiteArrowRight = styled(WhiteArrowLeft)`
  transform: rotate(180deg);
`;

export const UserRolesInfoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    transform: scale(1.1);
  }
`;
