import React from "react";

const PlayVideoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
    >
      <path
        d="M16.9708 7.73049C19.3867 9.12844 19.3867 12.6233 16.9708 14.0213L5.64658 20.5742C3.23074 21.9722 0.210937 20.2247 0.210938 17.4288L0.210938 4.32297C0.210938 1.52705 3.23074 -0.220396 5.64658 1.17756L16.9708 7.73049Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayVideoIcon;
