import React from "react";

const BranchYellowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11.0001 10.0048L17 10.0049L17 17.0049M11.0001 10.0048L5.00012 10.0048L5.00012 17.0049M11.0001 10.0048L11.0001 17.0049M11.0001 10.0048V5.00484M17 17.0049H16M17 17.0049L18 17.0049M5.00012 17.0049L6 17.0049M5.00012 17.0049L4 17.0049M11.0001 17.0049H12M11.0001 17.0049H10M11.0001 5.00484L14 5.00492M11.0001 5.00484H8.00004"
        stroke="#F8D254"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BranchYellowIcon;
