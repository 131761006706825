import React, { memo } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import { COURSE_COMPLEXITY_LEVELS } from "../../../admin/admin_page_courses";
import { convertMinutesToHoursAndMinutes } from "../../../../common/utils/functions/convert_minutes_and_hours";

const BLOCK_VARIANT = {
  TITLE: {
    LARGE_SIZE: `
      font-weight: 600;
      color: #cacaca;

      @media (max-width: 1024px) {
        font-weight: 400;
        letter-spacing: 0;
      }

      @media (max-width: 920px) {
        line-height: 120%;
      }
   
      @media (max-width: 744px) {
        line-height: 130%;
      }

      @media (max-width: 412px) {
         font-size: 10px;
         line-height: 140%; 
         letter-spacing: -0.1px;
      }
   `,
    SMALL_SIZE: `
      font-weight: 400;
      color: #828282;
      letter-spacing: 0;

      @media (max-width: 412px) {
         color: #CACACA;
      }
   `,
  },
  SUBTITLE: {
    LARGE_SIZE: `
      font-size: 24px;
      line-height: 28px;
      gap: 8px;

      @media (max-width: 1024px) {
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
      }

      @media (max-width: 880px) {
        line-height: 120%;
      }

      @media (max-width: 880px) {
        font-size: 18px;
      }

      @media (max-width: 800px) {
        font-size: 16px;
        letter-spacing: 0.24px;
        line-height: 145%;
      }

      @media (max-width: 744px) {
        line-height: 100%;
      }
    `,
    SMALL_SIZE: `
      font-size: 20px;
      line-height: 28px;
      gap: 5px;
    `,
  },
  VERTICAL_LINE: {
    LARGE_SIZE: `
      height: 32px;

      @media (max-width: 1024px) {
        height: 28px;
      }

      @media (max-width: 920px) {
        margin-top: 3px;
      }

      @media (max-width: 880px) {
        margin-top: 5px;
       }

      @media (max-width: 744px) {
        height: 21px;
      }
   `,
    SMALL_SIZE: `
      height: 36px;

      @media (max-width: 412px) {
         height: 25px;
       }
    `,
  },
  CONTAINER: {
    LARGE_SIZE: `
      height: 74px;
      padding-top: 0px;

      @media (max-width: 1024px) {
        height: 60px;
      }

      @media (max-width: 880px) {
        height: 60px;
      }

      @media (max-width: 800px) {
        height: 50px;
      }

      @media (max-width: 744px) {
        height: 44px;
      }

      @media (max-width: 670px) {
         height: 50px;
       }

      @media (max-width: 412px) {
         height: 48px;
       }
    `,
    SMALL_SIZE: `
      height: 76px;
      padding-top: 5px;

      @media (max-width: 412px) {
         height: 69px;
       }
    `,
  },
};

const LevelAndLessonInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) =>
    size === "LARGE_SIZE"
      ? BLOCK_VARIANT.CONTAINER.LARGE_SIZE
      : BLOCK_VARIANT.CONTAINER.SMALL_SIZE}
`;

const LessonAndDurationInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LevelAndLessonInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 14px 0 6px 0;

  @media (max-width: 1024px) {
    padding: 10px 0 6px 0;
  }

  @media (max-width: 920px) {
    padding: 11px 0 6px 0;
  }

  @media (max-width: 880px) {
    padding: 8px 0 6px 0;
  }

  @media (max-width: 800px) {
    padding: 9px 0 6px 0;
  }

  @media (max-width: 744px) {
    padding: 6px 0 6px 0;
  }
`;

const LevelAndLessonInfoTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #cacaca;

  ${({ size }) =>
    size === "LARGE_SIZE"
      ? BLOCK_VARIANT.TITLE.LARGE_SIZE
      : BLOCK_VARIANT.TITLE.SMALL_SIZE}
`;

const LevelAndLessonInfoSubtitle = styled.p`
  font-weight: 700;
  color: #f8d254;
  display: flex;

  ${({ size }) =>
    size === "LARGE_SIZE"
      ? BLOCK_VARIANT.SUBTITLE.LARGE_SIZE
      : BLOCK_VARIANT.SUBTITLE.SMALL_SIZE}
`;

const DurationBlockStyles = styled(LevelAndLessonInfoBlock)`
  padding-right: 34px;

  @media (max-width: 1024px) {
    padding-right: 27px;
  }

  @media (max-width: 880px) {
    padding-right: 23px;
  }

  @media (max-width: 800px) {
    padding-right: 18px;
  }

  @media (max-width: 744px) {
    padding-right: 8px;
  }

  @media (max-width: 412px) {
    padding-right: 14px;
  }
`;

const VerticalLineStyles = styled(VerticalLine)`
  ${({ size }) =>
    size === "LARGE_SIZE"
      ? BLOCK_VARIANT.VERTICAL_LINE.LARGE_SIZE
      : BLOCK_VARIANT.VERTICAL_LINE.SMALL_SIZE};
`;

const LevelAndLessonInfo = ({ blockVariant, courseData }) => {
  const courseLevel = COURSE_COMPLEXITY_LEVELS[courseData?.course_level];
  const showDuration = courseData.course_duration_is_enabled;
  const duration = convertMinutesToHoursAndMinutes(
    courseData?.course_duration_min
  );

  return (
    <LevelAndLessonInfoContainer size={blockVariant}>
      <LevelAndLessonInfoBlock>
        <LevelAndLessonInfoTitle size={blockVariant}>
          Уровень:
        </LevelAndLessonInfoTitle>
        <LevelAndLessonInfoSubtitle size={blockVariant}>
          {courseLevel || "-"}
        </LevelAndLessonInfoSubtitle>
      </LevelAndLessonInfoBlock>

      <VerticalLineStyles size={blockVariant} />

      <LessonAndDurationInfoContainer>
        <LevelAndLessonInfoBlock>
          <LevelAndLessonInfoTitle size={blockVariant}>
            Уроки:
          </LevelAndLessonInfoTitle>
          <LevelAndLessonInfoSubtitle size={blockVariant}>
            {courseData?.course_lessons?.length}
          </LevelAndLessonInfoSubtitle>
        </LevelAndLessonInfoBlock>

        {showDuration && (
          <DurationBlockStyles>
            <LevelAndLessonInfoTitle size={blockVariant}>
              Длительность:
            </LevelAndLessonInfoTitle>
            <LevelAndLessonInfoSubtitle size={blockVariant}>
              {!!duration.hours && <span>{duration.hours}ч </span>}
              {!!duration.minutes && (
                <span>
                  {duration.minutes}
                  {blockVariant === "SMALL_SIZE" ? "мин" : "м"}
                </span>
              )}
              {!duration.hours && !duration.minutes && <span> - </span>}
            </LevelAndLessonInfoSubtitle>
          </DurationBlockStyles>
        )}
      </LessonAndDurationInfoContainer>
    </LevelAndLessonInfoContainer>
  );
};

export default memo(LevelAndLessonInfo);
