import React, { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { useValueWithDebounce } from "../../common/hooks/use_value_with_debounce";
import { getOAuthGmailUrl } from "./auth_utils";

import {
  Agreement,
  AgreementSection,
  AuthButton,
  ButtonSection,
  CityInput,
  CountryInput,
  AuthFormWrapper,
  InputSection,
  LocationSection,
  AuthNavButton,
  AuthNavSection,
  AuthPageWrapper,
  PasswordInput,
  TextInputComponent,
  VendorButton,
} from "./auth_components";
import { GoogleIcon } from "../../common/assets/icons";

const passwordRegex = /^(?=.*\d)[A-Za-z\d@$!%*#?&-_+=/\\.,():;~]{8,}$/;

const emailRegex = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/;

const getValidationMessages = ({
  name,
  email,
  password,
  confirmPassword,
  captchaToken,
}) => {
  const validationMsgObject = {};
  //   if (!name) validationMsgObject.name = "This field is required";
  //   if (!email) validationMsgObject.email = "This field is required";
  //   if (email && !emailRegex.test(email))
  //     validationMsgObject.email = "Email is not valid";
  //   if (!password) validationMsgObject.password = "This field is required";
  //   if (password && !passwordRegex.test(password))
  //     validationMsgObject.password = "Minimum 8 characters, one number";
  //   if (!confirmPassword)
  //     validationMsgObject.password_confirmation = "This field is required";
  //   if (confirmPassword && password && confirmPassword !== password)
  //     validationMsgObject.confirmPassword =
  //       "This field mast be equals to password";
  //   if (!captchaToken) {
  //     validationMsgObject.captchaToken = "Confirm you are not a robot";
  //   }

  if (!name) validationMsgObject.name = "Это поле обязательно для заполнения";
  if (!email) validationMsgObject.email = "Это поле обязательно для заполнения";
  if (email && !emailRegex.test(email))
    validationMsgObject.email = "Некорректный адрес электронной почты";
  if (!password)
    validationMsgObject.password = "Это поле обязательно для заполнения";
  if (password && !passwordRegex.test(password))
    validationMsgObject.password =
      "Минимум 8 символов (латинские буквы и цифры)";
  if (!confirmPassword)
    validationMsgObject.password_confirmation =
      "Это поле обязательно для заполнения";
  if (confirmPassword && password && confirmPassword !== password)
    validationMsgObject.confirmPassword = "Это поле должно совпадать с паролем";
  if (!captchaToken) {
    validationMsgObject.captchaToken = "Подтвердите, что вы не робот";
  }

  return validationMsgObject;
};

const RegistrationPage = () => {
  const [validationMessages, setValidationMessages] = useState({});
  const [captchaToken, setCaptchaToken] = useState("");
  const [email, setEmail, debounceEmail] = useValueWithDebounce({
    onChange: () => setValidationMessages({ ...validationMessages, email: "" }),
  });
  const [name, setName, debounceName] = useValueWithDebounce({
    onChange: () => setValidationMessages({ ...validationMessages, name: "" }),
  });
  const [password, setPassword, debouncePassword] = useValueWithDebounce({
    onChange: () =>
      setValidationMessages({ ...validationMessages, password: "" }),
  });
  const [confirmPassword, setConfirmPassword, debounceConfirmPassword] =
    useValueWithDebounce({
      onChange: () =>
        setValidationMessages({ ...validationMessages, confirmPassword: "" }),
    });

  const handleChangeCaptcha = useCallback((token) => {
    setValidationMessages({ ...validationMessages, email: "" });
    setCaptchaToken(token);
  }, []);

  const userFormData = useMemo(
    () => ({
      name: debounceName,
      email: debounceEmail,
      password: debouncePassword,
      password_confirmation: debounceConfirmPassword,
      g_recaptcha_response: captchaToken,
    }),
    [
      debounceName,
      debounceEmail,
      debouncePassword,
      debounceConfirmPassword,
      captchaToken,
    ]
  );

  const onSubmit = () => {
    const newValidationMessages = getValidationMessages({
      name,
      email,
      password,
      confirmPassword,
      captchaToken,
    });
    setValidationMessages(newValidationMessages);

    if (!Object.values(newValidationMessages).length) {
      return false; // place to send request
    }
  };

  return (
    <AuthPageWrapper>
      <AuthFormWrapper>
        <AuthNavSection>
          <Link to={"/auth/login"}>
            <AuthNavButton>Вход</AuthNavButton>
          </Link>
          <AuthNavButton isActive>Регистрация</AuthNavButton>
        </AuthNavSection>
        <InputSection>
          <TextInputComponent
            placeholder={"Почта"}
            value={email}
            onChange={setEmail}
            type={"email"}
            validationMessage={validationMessages.email}
          />
          <TextInputComponent
            placeholder={"Имя"}
            value={name}
            onChange={setName}
            type={"name"}
            validationMessage={validationMessages.name}
          />
          <PasswordInput
            placeholder={"Пароль"}
            value={password}
            onChange={setPassword}
            validationMessage={validationMessages.password}
          />
          <PasswordInput
            value={confirmPassword}
            placeholder={"Повторите пароль"}
            onChange={setConfirmPassword}
            validationMessage={validationMessages.confirmPassword}
          />
          <LocationSection>
            <CountryInput placeholder={"Страна"} />
            <CityInput placeholder={"Город"} />
          </LocationSection>
        </InputSection>
        <ButtonSection>
          <AuthButton onClick={onSubmit} isActive>
            {"Зарегестрироваться".toUpperCase()}
          </AuthButton>
          <VendorButton href={getOAuthGmailUrl()?.href}>
            {" "}
            <GoogleIcon /> {"Продолжить с google".toUpperCase()}{" "}
          </VendorButton>
        </ButtonSection>
        <AgreementSection>
          <Agreement> By Register, I agree </Agreement>
          <Agreement>
            <a href={"#"}>Terms and Conditions</a>
            and
            <a href={"#"}>Privacy Policy</a>
          </Agreement>
        </AgreementSection>
      </AuthFormWrapper>
    </AuthPageWrapper>
  );
};

export default RegistrationPage;
