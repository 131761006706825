import React from "react";

const BibleStrongArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clipPath="url(#clip0_7862_16171)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.18952 0.192332C7.4553 0.452682 7.4553 0.874792 7.18952 1.13514L3.58742 4.66374L7.18952 8.19233C7.4553 8.45268 7.4553 8.87479 7.18952 9.13514C6.92375 9.39549 6.49285 9.39549 6.22707 9.13514L2.14374 5.13514C1.87797 4.87479 1.87797 4.45268 2.14374 4.19233L6.22707 0.192332C6.49285 -0.0680171 6.92375 -0.0680171 7.18952 0.192332Z"
          fill="#5172EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_7862_16171">
          <rect
            width="9.33333"
            height="9.33333"
            fill="white"
            transform="translate(9.33331) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BibleStrongArrowLeftIcon;
