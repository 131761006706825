import React from "react";

const AdminPageInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        d="M9.84635 17.8333C14.2186 17.8333 17.763 14.2889 17.763 9.91667C17.763 5.54441 14.2186 2 9.84635 2C5.4741 2 1.92969 5.54441 1.92969 9.91667C1.92969 14.2889 5.4741 17.8333 9.84635 17.8333Z"
        stroke="#CACACA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.92969 14L9.92969 9"
        stroke="#CACACA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.92969 7H9.93802"
        stroke="#CACACA"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AdminPageInfoIcon;
