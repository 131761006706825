import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const NavLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  color: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
  text-decoration: none;

  @media (max-width: 1024px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

const HeaderMenuItem = ({ menuItem }) => {
  const { path, icon: Icon, text } = menuItem;

  const location = useLocation();

  const isActive = location.pathname
    .split("/")
    .find((item) => "/" + item === path);

  return (
    <Link to={path}>
      <NavLinkContainer active={isActive}>
        <Icon active={isActive} />
        {text}
      </NavLinkContainer>
    </Link>
  );
};

export default HeaderMenuItem;
