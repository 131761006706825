import React, { memo } from "react";
import styled from "styled-components";
import DialogueEditorItem from "./dialogue_editor_item";

const DialogueEditorContainer = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DialogueEditor = ({ settingsBlockData, setSettingsBlockData }) => {
  return (
    <DialogueEditorContainer>
      {settingsBlockData?.selectedBranches?.map((branch) => {
        return (
          <DialogueEditorItem
            key={branch}
            branch={branch}
            settingsBlockData={settingsBlockData}
            setSettingsBlockData={setSettingsBlockData}
          />
        );
      })}
    </DialogueEditorContainer>
  );
};

export default memo(DialogueEditor);
