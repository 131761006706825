import React from "react";

const TextBibleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94232 19.6004C3.50528 19.6004 3.08602 19.7741 2.77681 20.0835C2.46758 20.3929 2.29375 20.8126 2.29375 21.2504C2.29375 21.8579 1.80126 22.3504 1.19375 22.3504C0.586237 22.3504 0.09375 21.8579 0.09375 21.2504C0.09375 20.2295 0.499085 19.2503 1.22077 18.5282C1.94247 17.8062 2.92143 17.4004 3.94232 17.4004H18.7846C19.3921 17.4004 19.8846 17.8929 19.8846 18.5004C19.8846 19.1079 19.3921 19.6004 18.7846 19.6004H3.94232Z"
        fill="url(#paint0_linear_7457_87)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94232 3.10039C3.50528 3.10039 3.08602 3.27409 2.77681 3.58346C2.46758 3.89285 2.29375 4.3126 2.29375 4.75039V21.2504C2.29375 21.6882 2.46758 22.1079 2.77681 22.4173C3.08602 22.7267 3.50528 22.9004 3.94232 22.9004H17.6846V3.10039H3.94232ZM1.22077 2.02823C1.94247 1.30615 2.92143 0.900391 3.94232 0.900391H18.7846C19.3921 0.900391 19.8846 1.39288 19.8846 2.00039V24.0004C19.8846 24.6079 19.3921 25.1004 18.7846 25.1004H3.94232C2.92143 25.1004 1.94247 24.6946 1.22077 23.9726C0.499085 23.2505 0.09375 22.2713 0.09375 21.2504V4.75039C0.09375 3.72949 0.499085 2.75029 1.22077 2.02823Z"
        fill="url(#paint1_linear_7457_87)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99331 6.00039C10.2695 6.00039 10.4933 6.22425 10.4933 6.50039V9.00039H12.9933C13.2695 9.00039 13.4933 9.22425 13.4933 9.50039C13.4933 9.77653 13.2695 10.0004 12.9933 10.0004H10.4933V14.5004C10.4933 14.7765 10.2695 15.0004 9.99331 15.0004C9.71717 15.0004 9.49331 14.7765 9.49331 14.5004V10.0004H6.99331C6.71717 10.0004 6.49331 9.77653 6.49331 9.50039C6.49331 9.22425 6.71717 9.00039 6.99331 9.00039H9.49331V6.50039C9.49331 6.22425 9.71717 6.00039 9.99331 6.00039Z"
        fill="url(#paint2_linear_7457_87)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7457_87"
          x1="9.51086"
          y1="-3.54814"
          x2="9.51086"
          y2="27.2357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7457_87"
          x1="9.51086"
          y1="-3.54814"
          x2="9.51086"
          y2="27.2357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7457_87"
          x1="9.51086"
          y1="-3.54814"
          x2="9.51086"
          y2="27.2357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TextBibleIcon;
