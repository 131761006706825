import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PinnedIcon from "../assets/icons/admin_page_icons/pinned_icon";

const ArticlePreviewContainer = styled.div`
  width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "310px" : "540px"};
  display: inline-block;
  vertical-align: top;
  padding-bottom: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "15px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "15px" : "25px"};
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  cursor: ${({ inComponent }) => inComponent === "admin_page" && "pointer"};
  opacity: ${({ opacity }) => opacity};
`;

const ArticlePreviewHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 13px;
  padding: ${({ inComponent }) =>
    inComponent === "admin_page" ? "18px 17px" : "30px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "15px" : "25px"};
  width: 100%;
  background: ${({ background }) =>
    background || "linear-gradient(30.13deg, #05baa4 11.17%, #2046e9 92.55%)"};
`;

const ArticleTitle = styled.div`
  color: #fff;
  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "18px" : "31px"};
  font-weight: 700;
  line-height: 129%;
  letter-spacing: -0.479px;
  max-width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "158px" : "271px"};
  max-height: ${({ inComponent }) =>
    inComponent === "admin_page" ? "300px" : "540px"};
  min-height: ${({ inComponent }) =>
    inComponent === "admin_page" ? "90px" : "160px"};
  ${({ inComponent }) => inComponent === "admin_page" && "padding-top: 4px"};
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ArticleImage = styled.div`
  flex: 1;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  min-width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "105px" : "186px"};
  max-width: ${({ inComponent }) =>
    inComponent === "admin_page" ? "105px" : "186px"};
  border-radius: ${({ inComponent }) =>
    inComponent === "admin_page" ? "12px" : "20px"};
`;

const ArticleShowStatus = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ isShow }) => (isShow ? "#F12B2B" : "#A1A1A1")};
`;

const ArticleIsPinned = styled.div`
  position: absolute;
  top: 7px;
  left: 23px;
`;

const ArticleText = styled.p`
  line-height: 143%;
  padding: ${({ inComponent }) =>
    inComponent === "admin_page" ? "9px 9px 0" : "16px 16px 0"};

  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "18px"};
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 24px;

  ${({ showAllText }) =>
    !showAllText &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    `}

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    -webkit-line-clamp: 2;
  }

  @media (max-width: 412px) {
    margin-top: 12px;
  }
`;

const ReadAllButtonWrapper = styled.div`
  text-align: right;
  margin: ${({ inComponent }) =>
    inComponent === "admin_page" ? "2px 11px 0 0" : "8px 20px 0 0"};
`;

const ReadAllButton = styled.button`
  font-size: ${({ inComponent }) =>
    inComponent === "admin_page" ? "10px" : "18px"};
  line-height: 100%;
  color: #828282;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ArticlePreview = ({ article, inComponent, onClick, editedArticleId }) => {
  const [showAllText, setShowAllText] = useState(false);
  const [previewOpacity, setPreviewOpacity] = useState("1");
  const [showToggleButton, setShowToggleButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (inComponent === "admin_page") {
      setPreviewOpacity(
        editedArticleId && article._id !== editedArticleId ? "0.5" : "1"
      );
    }
  }, [editedArticleId, article._id, inComponent]);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      const isTextOverflowing =
        textElement.scrollHeight > textElement.clientHeight;
      setShowToggleButton(isTextOverflowing);
    }
  }, [showAllText, article.article_text]);

  const toggleShowAllText = useCallback((event) => {
    event.stopPropagation();
    setShowAllText((prevState) => !prevState);
  }, []);

  const renderArticleTitle = () => {
    if (article.article_title.includes("<br>")) {
      const lines = article.article_title.split("<br>");
      return lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== lines.length - 1 && <br />}{" "}
        </React.Fragment>
      ));
    } else {
      return article.article_title;
    }
  };

  const renderArticleText = () => {
    if (article.article_text.includes("<br>")) {
      const lines = article.article_text.split("<br>");
      return lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== lines.length - 1 && <br />}{" "}
        </React.Fragment>
      ));
    } else {
      return article.article_text;
    }
  };

  const onClickArticlePreview = (event, article) => {
    event.stopPropagation();
    if (inComponent === "admin_page") {
      onClick(event, article);
    }
  };

  return (
    <ArticlePreviewContainer
      inComponent={inComponent}
      opacity={previewOpacity}
      onClick={(e) => onClickArticlePreview(e, article)}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ArticlePreviewHeader
        background={article.article_background_color}
        inComponent={inComponent}
      >
        <ArticleTitle inComponent={inComponent}>
          {renderArticleTitle()}
        </ArticleTitle>

        <ArticleImage
          image={article.article_image_url}
          inComponent={inComponent}
        />

        {inComponent === "admin_page" && (
          <ArticleShowStatus isShow={article.article_is_visible} />
        )}

        {inComponent === "admin_page" && article.article_is_pinned && (
          <ArticleIsPinned>
            <PinnedIcon />
          </ArticleIsPinned>
        )}
      </ArticlePreviewHeader>

      <ArticleText
        ref={textRef}
        showAllText={showAllText}
        inComponent={inComponent}
      >
        {renderArticleText()}
      </ArticleText>

      {(showToggleButton || showAllText) && (
        <ReadAllButtonWrapper inComponent={inComponent}>
          <ReadAllButton onClick={toggleShowAllText} inComponent={inComponent}>
            {!showAllText ? "Развернуть" : "Свернуть"}
          </ReadAllButton>
        </ReadAllButtonWrapper>
      )}
    </ArticlePreviewContainer>
  );
};

export default memo(ArticlePreview);
