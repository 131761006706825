import React from "react";

const EditIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <path
        d="M17 3.50006C17.2626 3.23741 17.5744 3.02907 17.9176 2.88693C18.2608 2.74479 18.6286 2.67163 19 2.67163C19.3714 2.67163 19.7392 2.74479 20.0824 2.88693C20.4256 3.02907 20.7374 3.23741 21 3.50006C21.2626 3.7627 21.471 4.07451 21.6131 4.41767C21.7553 4.76083 21.8284 5.12862 21.8284 5.50006C21.8284 5.87149 21.7553 6.23929 21.6131 6.58245C21.471 6.92561 21.2626 7.23741 21 7.50006L7.5 21.0001L2 22.5001L3.5 17.0001L17 3.50006Z"
        stroke="#383838"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
