import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PlayVideoIcon from "../../../../../common/assets/icons/create_lesson_icons/play_video_icon";

const VideoComponentPlayerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  height: ${({ videoWithTitle }) => (videoWithTitle ? "306px" : "330px")};
  border-radius: ${({ videoWithTitle }) => (videoWithTitle ? "8px" : "15px")};
`;

const PlayButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ background }) => background};
  width: 61px;
  height: 61px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: 0.2s;

  &: hover {
    transform: translate(-50%, -50%) scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${({ videoWithTitle }) => (videoWithTitle ? "8px" : "15px")};
  object-fit: cover;
`;

const NoImage = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px;
  color: #383838;
  background: #fff;
  border-radius: ${({ videoWithTitle }) => (videoWithTitle ? "8px" : "15px")};
  object-fit: cover;
`;

const VideoComponentPlayer = ({ componentData }) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { with_title, image_url, video_url, background } = componentData;

  const getEmbedUrl = (url) => {
    const videoIdMatch = url?.match(
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
    );
    return videoIdMatch
      ? `https://www.youtube.com/embed/${videoIdMatch[1]}`
      : null;
  };

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  const handleClickOutside = (event) => {
    if (playerRef.current && !playerRef.current.contains(event.target)) {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      window.addEventListener("mousedown", handleClickOutside);
    } else {
      window.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPlaying]);

  const embedUrl = getEmbedUrl(video_url);

  return (
    <VideoComponentPlayerContainer ref={playerRef} videoWithTitle={with_title}>
      {isPlaying ? (
        <iframe
          width="100%"
          height="100%"
          src={`${embedUrl}?autoplay=1`}
          title="YouTube video player"
          style={{
            border: "none",
            borderRadius: with_title ? "8px" : "15px",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
          }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <>
          {image_url ? (
            <ThumbnailImage
              src={image_url}
              alt="Video thumbnail"
              videoWithTitle={with_title}
            />
          ) : (
            <NoImage videoWithTitle={with_title}>
              Загрузите обложку для видео...
            </NoImage>
          )}

          <PlayButton background={background} onClick={handlePlayVideo}>
            <PlayVideoIcon />
          </PlayButton>
        </>
      )}
    </VideoComponentPlayerContainer>
  );
};

export default memo(VideoComponentPlayer);
