import React, { memo, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import AdminPageSidebar from "./admin_page_sidebar";
import { APPLICATION_ROUTES } from "../../common/utils/routes";

const AdminPageContainer = styled.div`
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
`;

const AdminPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const lessonId = searchParams.get("lessonId");
  const courseId = searchParams.get("courseId");
  const lessonsPreviews = searchParams.get("lessons");

  useEffect(() => {
    const lastUrl = localStorage.getItem("lastAdminUrl");

    if (lessonId || courseId || lessonsPreviews) {
      return;
    }

    if (lastUrl) {
      navigate(lastUrl);
    } else {
      navigate(APPLICATION_ROUTES.ADMIN_HOME);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("lastAdminUrl", location.pathname);
  }, [location.pathname]);

  return (
    <AdminPageContainer>
      <AdminPageSidebar />

      <Outlet />
    </AdminPageContainer>
  );
};

export default memo(AdminPage);
