import React from "react";

const TextStyleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="24"
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.398438 1.9998C0.398438 1.33706 0.935696 0.799805 1.59844 0.799805H10.9318C12.4739 0.799805 13.9613 1.39013 15.0646 2.45401C16.1693 3.51932 16.7984 4.9737 16.7984 6.4998C16.7984 8.02591 16.1693 9.48029 15.0646 10.5456C15.0389 10.5703 15.0131 10.5948 14.987 10.619C15.3822 10.8527 15.7511 11.1337 16.0843 11.4586C17.1782 12.5251 17.7984 13.9781 17.7984 15.4998C17.7984 17.0215 17.1782 18.4745 16.0843 19.541C14.9915 20.6065 13.5157 21.1998 11.9831 21.1998H1.59844C0.935696 21.1998 0.398438 20.6625 0.398438 19.9998V1.9998ZM2.79844 3.1998V9.7998H10.9318C11.865 9.7998 12.7516 9.44192 13.3986 8.81797C14.0442 8.19546 14.3984 7.36065 14.3984 6.4998C14.3984 5.63896 14.0442 4.80415 13.3986 4.18164C12.7516 3.55769 11.865 3.1998 10.9318 3.1998H2.79844ZM2.79844 12.1998V18.7998H11.9831C12.8985 18.7998 13.7706 18.4449 14.4089 17.8226C15.0462 17.2012 15.3984 16.3651 15.3984 15.4998C15.3984 14.6345 15.0462 13.7984 14.4089 13.177C13.7706 12.5547 12.8985 12.1998 11.9831 12.1998H2.79844Z"
        fill="url(#paint0_linear_3886_5922)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_5922"
          x1="8.6779"
          y1="-2.9502"
          x2="8.6779"
          y2="22.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TextStyleIcon;
