import React from "react";
import styled from "styled-components";
import PatreonSponsorImg from "../../../common/assets/images/donate_page_images/patreon_sponsor.png";
import VisaSponsorImg from "../../../common/assets/images/donate_page_images/visa_sponsor.png";
import { Link } from "react-router-dom";

const SponsorshipVariantsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 23px;
  margin: 49px auto 0;

  @media (max-width: 412px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const SponsorshipVariant = styled.div`
  width: 282px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 20px;

  @media (max-width: 412px) {
    width: 100%;
  }
`;

const SponsorshipVariantImage = styled.img`
  width: 100%;
  border-radius: 12px;
  margin-bottom: 10px;
`;

const SponsorshipVariantButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8d254;
  border-radius: 35px;
  text-decoration: none;
  cursor: pointer;

  font-family: "Raleway";
  color: #ffffff;
  border-radius: 20px;
  width: 100%;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.474px;

  @media (max-width: 412px) {
    font-size: 18px;
  }
`;

const DonatePageSponsorshipVariants = () => {
  return (
    <SponsorshipVariantsContainer>
      <SponsorshipVariant>
        <SponsorshipVariantImage src={VisaSponsorImg} alt="visa sponsor" />
        <SponsorshipVariantButton>Стать спонсором</SponsorshipVariantButton>
      </SponsorshipVariant>

      <SponsorshipVariant>
        <SponsorshipVariantImage src={PatreonSponsorImg} alt="visa sponsor" />
        <SponsorshipVariantButton>Стать спонсором</SponsorshipVariantButton>
      </SponsorshipVariant>
    </SponsorshipVariantsContainer>
  );
};

export default DonatePageSponsorshipVariants;
