import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import merge from "lodash/merge";
import axios from "../../axios_config";
import { LESSON_COLORS_THEME } from "../../common/utils/enums";
import { useAppContext } from "../../app_context";
import { useUserDataContext } from "../../user_data_context";
import { BASE_API_URL } from "../../common/endpoints";
import BibleChapterModalWindow from "../../common/components/modal_windows/bible_chapter_modal_window";
import LessonPageHeader from "./user_lesson_page_header";
import FinishLessonButton from "./finish_lesson_button";
import UserLessonComponentsList from "./user_lesson_components_list";

const UserLessonPageContainer = styled.div`
  position: relative;
  max-width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  ${({ darkTheme }) => darkTheme && "background: #232323;"}

  @media (max-width: 1024px) {
    height: calc(100vh - 96px);
  }

  @media (max-width: 412px) {
    height: calc(100vh - 103px);
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const UserLessonPage = () => {
  const { id } = useParams();
  const { appColorTheme } = useAppContext();
  const { userData } = useUserDataContext();

  const [lessonData, setLessonData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [shouldShowFinishButton, setShouldShowFinishButton] = useState(false);
  const [stopDialogueIndex, setStopDialogueIndex] = useState(null);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [userLessonInfo, setUserLessonInfo] = useState({});
  const [lessonIsFinished, setLessonIsFinished] = useState(false);
  const [userAnswersData, setUserAnswersData] = useState([]);

  const lessonId = id;
  const userId = userData._id;

  const courseMainGradient =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      courseData.course_main_color
    ];
  const courseMainColor =
    LESSON_COLORS_THEME[appColorTheme]?.COLORS?.[courseData.course_main_color];

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (lessonData.lesson_course?._id) {
      const courseId = lessonData.lesson_course._id;
      fetchCourse(courseId);
    }
  }, [lessonData.lesson_course?._id]);

  useEffect(() => {
    setShouldShowFinishButton(
      lessonData.lesson_data?.length === filteredComponents?.length &&
        !isLoading
    );
  }, [lessonData.lesson_data?.length, filteredComponents?.length, isLoading]);

  useEffect(() => {
    if (stopDialogueIndex === null) {
      setFilteredComponents(lessonData.lesson_data);
    } else {
      const index = stopDialogueIndex;
      if (index >= 0 && index < lessonData.lesson_data.length) {
        setFilteredComponents(lessonData.lesson_data.slice(0, index + 1));
      }
    }
  }, [stopDialogueIndex, lessonData.lesson_data]);

  useEffect(() => {
    if (lessonData.lesson_data?.length) {
      const index = lessonData.lesson_data.findIndex((component) => {
        if (component?.type === "DIALOGUE") {
          const lastSelectedBranch =
            component.selectedBranches?.[component.selectedBranches.length - 1];

          if (lastSelectedBranch && component.data[lastSelectedBranch]) {
            const hasOtherBranches =
              component.data[lastSelectedBranch].answers?.answers_data?.length >
              0;
            return hasOtherBranches;
          }
        }
        return false;
      });

      if (index !== -1) {
        setStopDialogueIndex(index);
      } else {
        setStopDialogueIndex(null);
      }
    }
  }, [lessonData.lesson_data]);

  const fetchUserLessonData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(
        BASE_API_URL +
          `/userstudy/find?studyLessonId=${lessonId}&studyUserId=${userId}`
      );

      const { lesson, ...userLessonInfo } = response.data;

      setLessonIsFinished(!!userLessonInfo.study_answers?.length);
      setUserLessonInfo(userLessonInfo);
      setLessonData(lesson);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [lessonId, userId]);

  useEffect(() => {
    if (!lessonData._id && lessonId && userId) {
      fetchUserLessonData();
    }
  }, [lessonId, userId, lessonData._id]);

  useEffect(() => {
    if (userLessonInfo._id && userAnswersData?.length) {
      localStorage.setItem(userLessonInfo._id, JSON.stringify(userAnswersData));
    }
  }, [userAnswersData, userLessonInfo._id]);

  useEffect(() => {
    if (userLessonInfo._id && !lessonIsFinished) {
      const storageData = JSON.parse(localStorage.getItem(userLessonInfo._id));

      if (storageData?.length) {
        setUserAnswersData(storageData);
      } else {
        localStorage.setItem(userLessonInfo._id, JSON.stringify([]));
      }
    }
  }, [userLessonInfo._id, lessonIsFinished]);

  useEffect(() => {
    if (
      userLessonInfo._id &&
      lessonData._id &&
      userAnswersData?.length &&
      !lessonIsFinished
    ) {
      const updatedLessonData = lessonData.lesson_data.map(
        (lessonComponent) => {
          const matchingAnswer = userAnswersData.find(
            (answer) => answer.id === lessonComponent.id
          );

          return matchingAnswer
            ? merge({}, lessonComponent, matchingAnswer)
            : lessonComponent;
        }
      );

      setLessonData((prevState) => ({
        ...prevState,
        lesson_data: updatedLessonData,
      }));
    }
  }, [userLessonInfo._id, lessonIsFinished, userAnswersData, lessonData._id]);

  return (
    <UserLessonPageContainer darkTheme={appColorTheme === "DARK"}>
      <LessonPageHeader appColorTheme={appColorTheme} lessonData={lessonData} />

      <UserLessonComponentsList
        isLoading={isLoading}
        filteredComponents={filteredComponents}
        appColorTheme={appColorTheme}
        courseMainColor={courseMainColor}
        courseMainGradient={courseMainGradient}
        lessonIsFinished={lessonIsFinished}
        userAnswersData={userAnswersData}
        setLessonData={setLessonData}
        setUserAnswersData={setUserAnswersData}
        setTextBibleModalData={setTextBibleModalData}
      />

      {shouldShowFinishButton && !!courseMainGradient && (
        <FinishLessonButton
          userLessonInfo={userLessonInfo}
          userAnswersData={userAnswersData}
          courseMainGradient={courseMainGradient}
          lessonIsFinished={lessonIsFinished}
          setLessonIsFinished={setLessonIsFinished}
          fetchUserLessonData={fetchUserLessonData}
        />
      )}

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
        />
      )}
    </UserLessonPageContainer>
  );
};

export default memo(UserLessonPage);
