import React from "react";

const EditMessageInInputIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3.67157C18.7599 3.67157 18.5222 3.71886 18.3003 3.81075C18.0785 3.90264 17.8769 4.03732 17.7071 4.2071L4.39491 17.5193L3.42524 21.0748L6.9807 20.1051L20.2929 6.79289C20.4627 6.6231 20.5974 6.42154 20.6893 6.19971C20.7812 5.97787 20.8284 5.74011 20.8284 5.5C20.8284 5.25988 20.7812 5.02212 20.6893 4.80029C20.5974 4.57845 20.4627 4.37689 20.2929 4.2071C20.1231 4.03732 19.9216 3.90264 19.6997 3.81075C19.4779 3.71886 19.2401 3.67157 19 3.67157ZM17.5349 1.96299C17.9994 1.77059 18.4973 1.67157 19 1.67157C19.5028 1.67157 20.0006 1.77059 20.4651 1.96299C20.9296 2.15539 21.3516 2.43739 21.7071 2.79289C22.0626 3.14839 22.3446 3.57043 22.537 4.03492C22.7294 4.49941 22.8284 4.99724 22.8284 5.5C22.8284 6.00275 22.7294 6.50058 22.537 6.96507C22.3446 7.42956 22.0626 7.8516 21.7071 8.2071L8.20713 21.7071C8.08407 21.8302 7.93104 21.919 7.76314 21.9648L2.26314 23.4648C1.91693 23.5592 1.54667 23.4609 1.29292 23.2071C1.03917 22.9534 0.940838 22.5831 1.03526 22.2369L2.53526 16.7369C2.58105 16.569 2.66986 16.4159 2.79292 16.2929L16.2929 2.79289C16.6484 2.43739 17.0705 2.15539 17.5349 1.96299Z"
        fill="url(#paint0_linear_4574_5175)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4574_5175"
          x1="-6.28714"
          y1="28.9571"
          x2="30.5057"
          y2="27.6544"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0864582" stopColor="#05BAA4" />
          <stop offset="1" stopColor="#2046E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EditMessageInInputIcon;
