import React, { memo, useCallback } from "react";
import styled from "styled-components";
import DialogEditorItemDivider from "../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/dialog_editor_divider_icon";
import { PlusOrMinusButton } from "../../../../create_lesson_components/create_lesson_styled_components";
import { createComponentId } from "../../../..";
import PlusSmallIcon from "../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_small_icon";
import DeleteBranchIcon from "../../../../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/delete_branch_icon";
import { DIALOGUE_ANSWERS_TYPE_DATA } from "..";
import TextEditor from "../../../../../../../common/components/text_editor";

const DialogueEditorItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const DialogueAnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > p {
    color: #1e1e1e;
    font-size: 12px;
    line-height: 120%;
  }
`;

const AddAnswerButton = styled.button`
  width: 100%;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #5172ea;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  color: #fff;
  font-size: 16px;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    transition: 0.2s;
  }
`;

const ButtonContainer = styled(PlusOrMinusButton)`
  height: 28px;
  width: 34px;
`;

const DeleteBranchButton = styled.div`
  position: absolute;
  padding-left: 6px;
  top: -34px;
  right: -3px;
  background: #eaeaea;
  cursor: pointer;

  & > div {
    &: hover {
      transform: scale(1.12);
      transition: 0.2s;
    }
  }
`;

const DialogueEditorItem = ({
  branch,
  settingsBlockData,
  setSettingsBlockData,
}) => {
  const branchData = settingsBlockData?.data?.[branch];
  const showAddAnswerMainButton = !branchData?.answers?.answers_data?.length;

  const showDivider = branchData?.answers?.answers_data?.some(
    (answer) => settingsBlockData.data?.[answer.branch]
  );

  const isLastBranch =
    branch ===
    settingsBlockData.selectedBranches[
      settingsBlockData.selectedBranches.length - 1
    ];

  const onEditorChange = useCallback(
    (updatedInputText) => {
      setSettingsBlockData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [branch]: {
            ...prevState.data[branch],
            text: updatedInputText,
          },
        },
      }));
    },
    [branch]
  );

  const onAddNewAnswer = useCallback(
    (event, answerType) => {
      event.stopPropagation();

      const newAnswer = {
        ...DIALOGUE_ANSWERS_TYPE_DATA[answerType].defaultAnswerData,
        type: answerType,
        level: branchData.level,
        branch: createComponentId(),
      };

      setSettingsBlockData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [branch]: {
            ...prevState.data[branch],
            answers: {
              answers_type: answerType,
              answers_data: [
                ...prevState.data[branch].answers.answers_data,
                newAnswer,
              ],
            },
          },
        },
      }));
    },
    [branchData, branch]
  );

  const removeAnswer = useCallback(
    (event, answerIndex) => {
      event.stopPropagation();

      setSettingsBlockData((prevState) => {
        const updatedAnswers = prevState.data[
          branch
        ].answers.answers_data.filter((_, index) => index !== answerIndex);

        return {
          ...prevState,
          data: {
            ...prevState.data,
            [branch]: {
              ...prevState.data[branch],
              answers: {
                ...prevState.data[branch].answers,
                answers_data: updatedAnswers,
                answers_type:
                  updatedAnswers.length === 0
                    ? ""
                    : prevState.data[branch].answers.answers_type,
              },
            },
          },
        };
      });
    },
    [branch]
  );

  const handleAddBranch = useCallback(
    (event, answer) => {
      event.stopPropagation();

      const newBranchKey = answer.branch;
      const newBranchLevel = branchData.level + 1;

      setSettingsBlockData((prevState) => ({
        ...prevState,
        selectedBranches: [...prevState.selectedBranches, newBranchKey],
        data: {
          ...prevState.data,
          [newBranchKey]: {
            text: "",
            level: newBranchLevel,
            answers: { answers_type: "", answers_data: [] },
          },
        },
      }));
    },
    [branchData]
  );

  const onClickOnAnswer = useCallback(
    (event, answer) => {
      event.stopPropagation();

      const currentLevel = answer.level + 1;
      const currentBranch = answer.branch;
      const hasBranchData = settingsBlockData?.data?.[currentBranch];

      if (hasBranchData) {
        const currentIndex =
          settingsBlockData.selectedBranches.indexOf(currentBranch);

        if (currentIndex !== -1) {
          setSettingsBlockData((prevState) => ({
            ...prevState,
            selectedBranches: prevState.selectedBranches.slice(0, currentIndex),
          }));
        } else {
          setSettingsBlockData((prevState) => ({
            ...prevState,
            selectedBranches: [
              ...prevState.selectedBranches.slice(0, currentLevel),
              currentBranch,
            ],
          }));
        }
      } else {
        setSettingsBlockData((prevState) => ({
          ...prevState,
          selectedBranches: prevState.selectedBranches.slice(0, currentLevel),
        }));
      }
    },
    [settingsBlockData]
  );

  const deleteBranch = useCallback(
    (event) => {
      event.stopPropagation();

      setSettingsBlockData((prevState) => {
        const { [branch]: _, ...restData } = prevState.data;
        return {
          ...prevState,
          selectedBranches: prevState.selectedBranches.filter(
            (b) => b !== branch
          ),
          data: restData,
        };
      });
    },
    [branch]
  );

  return (
    <DialogueEditorItemContainer>
      <TextEditor
        contentStateData={branchData?.text}
        onEditorChange={onEditorChange}
        placeholder={"Текст " + (+branchData?.level + 1)}
        key={settingsBlockData.id + branch}
      />

      <DialogueAnswersContainer>
        <p>Ответы</p>
        {!!showAddAnswerMainButton && (
          <AddAnswerButton onClick={(e) => onAddNewAnswer(e, "BUTTON")}>
            Добавить ответ
          </AddAnswerButton>
        )}

        {branchData?.answers?.answers_data?.map((answer, index) => {
          const AnswerComponent =
            DIALOGUE_ANSWERS_TYPE_DATA[answer.type].answerSettingsComponent;

          return (
            <AnswerComponent
              key={index + "_" + answer.branch}
              currentIndex={index}
              answer={answer}
              branch={branch}
              settingsBlockData={settingsBlockData}
              setSettingsBlockData={setSettingsBlockData}
              removeAnswer={removeAnswer}
              onClickOnAnswer={onClickOnAnswer}
              handleAddBranch={handleAddBranch}
            />
          );
        })}

        {!showAddAnswerMainButton &&
          branchData.answers.answers_type === "BUTTON" && (
            <ButtonContainer
              onClick={(e) =>
                onAddNewAnswer(e, branchData.answers.answers_type)
              }
            >
              <PlusSmallIcon />
            </ButtonContainer>
          )}

        {showDivider && <DialogEditorItemDivider />}
      </DialogueAnswersContainer>

      {branch !== "first" && isLastBranch && (
        <DeleteBranchButton onClick={deleteBranch}>
          <div>
            <DeleteBranchIcon />
          </div>
        </DeleteBranchButton>
      )}
    </DialogueEditorItemContainer>
  );
};

export default memo(DialogueEditorItem);
