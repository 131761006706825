import React from "react";

const DeleteBranchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M13.0001 11.8178L20.091 11.8179L20.091 20.0906M13.0001 11.8178L5.90924 11.8178L5.90924 20.0906M13.0001 11.8178L13.0001 20.0906M13.0001 11.8178V5.90869M20.091 20.0906H18.9091M20.091 20.0906L21.2727 20.0906M5.90924 20.0906L7.09091 20.0906M5.90924 20.0906L4.72727 20.0906M13.0001 20.0906H14.1818M13.0001 20.0906H11.8182M13.0001 5.90869L16.5455 5.90878M13.0001 5.90869H9.45459"
        stroke="url(#paint0_linear_7634_6536)"
        strokeOpacity="0.5"
        strokeWidth="2.36364"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2913 7.4181C9.8298 6.95657 9.08151 6.95657 8.61998 7.4181C8.15846 7.87963 8.15846 8.62792 8.61998 9.08944L11.3303 11.7998L8.61998 14.5102C8.15846 14.9717 8.15846 15.72 8.61998 16.1815C9.08151 16.643 9.8298 16.643 10.2913 16.1815L13.0017 13.4711L15.7109 16.1804C16.1724 16.6419 16.9207 16.6419 17.3822 16.1804C17.8438 15.7188 17.8438 14.9705 17.3822 14.509L14.673 11.7998L17.3822 9.0906C17.8438 8.62907 17.8438 7.88078 17.3822 7.41925C16.9207 6.95773 16.1724 6.95773 15.7109 7.41925L13.0017 10.1285L10.2913 7.4181Z"
        fill="#F12B2B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7634_6536"
          x1="21.5755"
          y1="6.15938"
          x2="5.03874"
          y2="22.6961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DeleteBranchIcon;
